//@flow
import React from 'react';
import Testimonial from './Testimonial';
import './Testimonial.scss';
import Akshay from '../../static/testimonial/Akshay_Kanduri.jpeg';
import Shazmaan from '../../static/testimonial/Shazmaan.jpeg';
import Sanchit from '../../static/testimonial/Sanchit.jpeg';
import Nikhil from '../../static/testimonial/Nikhil.jpeg';
import Akshit from '../../static/testimonial/Akshit.jpeg';
import Supriya from '../../static/testimonial/Supriya.jpeg';

const testimonials1 = [
  {
    name: 'Shazmaan Malek (University of Buffalo)',
    img: Shazmaan,
    text:
      'Before I joined {S30}, companies like Microsoft, Amazon, Google, Facebook were a dream that seemed almost impossible. {S30} helped me grow not just a problem solver, but helped me gain confidence in my skills. The mentors, the weekly interviews, daily problem solving, developed a sense of consistency and before I realized it, I was interning at Microsoft.',
    company: 'Microsoft',
  },
  {
    name: 'Sanchit Pruthi (ASU)',
    img: Sanchit,
    text:
      'I find two words reverberating in my head: Discipline and Smart work. The course will teach you how to be self reliant and problem solving would become your second nature. The lectures and course are very informative and would significantly improve how one thinks of problems. Those FAANG companies you wanted to work in would be within your reach. A "Super" experience.',
    company: 'Amazon',
  },
  {
    name: 'Nikhil Agrawal (NEU)',
    img: Nikhil,
    text:
      'Thanks to {S30} I landed my first internship. I had a great experience and learnt to channel my ideas and come up with efficient solutions.',
    company: 'Credit Karma',
  },
];

const testimonials2 = [
  {
    name: 'Supriya Jain (SJSU)',
    img: Supriya,
    text:
      'Every day I looked forward to the class. Small things I learned in each class apart from the DS problems (like oops concepts), helped me to impress my interviewers. In mock interviews at {S30}, Interviewers will grill you like a real interviewer. The topic like Dynamic Programming which used to scare me initially, became pretty intuitive for me. Amazing Experience!',
    company: 'Amazon',
  },
  {
    name: 'Akshit Gupta (UC Irvine)',
    img: Akshit,
    text:
      'It just works. Special thanks to {S30}, it did help me in by overcoming what I was missing in my previous interviews.',
    company: 'Amazon, Barclays',
  },
  // {
  //   name: 'Surmeet (UC Irvine)',
  //   img: Surmeet,
  //   text:
  //     'Along with practicing the problems daily, discussing them in a team setting helped me learn different perspectives of solving a single problem. The mock interviews gave me a chance to improve my technical communication skills and boosted my confidence.',
  //   company: 'Amazon',
  // },
];

type Props = {};

const Testimonials = (props: Props) => {
  return (
    <div>
      <div className="success-stories">
        <div className="testimonial-container">
          {testimonials1.map((testimonial, index) => {
            return (
              <Testimonial
                key={index}
                name={testimonial.name}
                img={testimonial.img}
                text={testimonial.text}
                company={testimonial.company}
              />
            );
          })}
        </div>
      </div>
      <div className="success-stories">
        <div className="testimonial-container">
          {testimonials2.map((testimonial, index) => {
            return (
              <Testimonial
                key={index}
                name={testimonial.name}
                img={testimonial.img}
                text={testimonial.text}
                company={testimonial.company}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
