//@flow
import React from 'react';
import { Formik } from 'formik';
import AddPerformanceForm from './AddPerformanceForm';
import Sidebar from '../Sidebar/Sidebar';

type Props = {
  history: Object,
  name: string,
  id: string,
  isLeadership: Boolean,
};

const initialAddPerformanceValues = {
  interestLevel: 0,
  gaveValuableHints: 0,
  timeManagement: 0,
  communication: 0,
  thingsToImprove: '',
};

const AddPerformance = (props: Props) => {
  const { history } = props;
  const { id, fid } = props.match.params;
  initialAddPerformanceValues.interviewFeedbackId = fid;
  initialAddPerformanceValues.interviewerId = id;
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddPerformanceValues}
        render={props => <AddPerformanceForm history={history} {...props} />}
      />
    </div>
  );
};
export default AddPerformance;
