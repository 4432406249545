//@flow
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../Sidebar/Sidebar';
import UpdateRecomendedCandidateForm from './UpdateRecomendedCandidateForm';

type Props = {
  history: Object,
};

const UpdateRecomendedCandidateValidationSchema = Yup.object().shape({
  imageUrl: Yup.string().required('Required'),
  resumeUrl: Yup.string().required('Required'),
  pastInternships: Yup.string().required('Required'),
  recruitingCompaniesFromS30: Yup.string().required('Required'),
  recruitingCompaniesNotFromS30: Yup.string().required('Required'),
});

const initialUpdateRecomendedCandidateValues = {
  linkedInUrl: '',
  imageUrl: null,
  resumeUrl: null,
  experience: '',
  pastInternships: [],
  recruitingCompaniesFromS30: [],
  recruitingCompaniesNotFromS30: [],
};

const UpdateRecomendedCandidate = (props: Props) => {
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialUpdateRecomendedCandidateValues}
        render={props => <UpdateRecomendedCandidateForm {...props} />}
        validationSchema={UpdateRecomendedCandidateValidationSchema}
      />
    </div>
  );
};

export default UpdateRecomendedCandidate;
