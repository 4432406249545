import { CANDIDATES } from '../constants';

const initialState = {
  allCandidates: [],
  filteredCandidates: [],
  activePage: 0,
  perPage: 10,
  error: false,
  submitting: false,
};

const candidateStateReducer = (candidateState = initialState, action) => {
  switch (action.type) {
    case CANDIDATES.ADDCANDIDATESUCCESS:
      return {
        ...candidateState,
        allCandidates: [...candidateState.allCandidates, { ...action.payload }],
        submitting: false,
      };
    case CANDIDATES.GETCANDIDATESSUCCESS:
      return {
        ...candidateState,
        allCandidates: [...action.payload],
        filteredCandidates: [],
        submitting: false,
      };
    case CANDIDATES.GETRECOMMENDEDCANDIDATESSUCCESS:
      return {
        ...candidateState,
        allCandidates: [...action.payload],
        filteredCandidates: [],
        submitting: false,
      };
    case CANDIDATES.SEARCHCANDIDATE:
      return { ...candidateState, filteredCandidates: [...action.payload], submitting: false };
    case CANDIDATES.CHANGEACTIVECANDIDATEPAGE:
      return { ...candidateState, activePage: action.payload };
    case CANDIDATES.CANDIDATEERROR:
      return { ...candidateState, error: action.error, submitting: false };
    case CANDIDATES.SUBMITTING_CANDIDATE:
      return { ...candidateState, submitting: true };
    case CANDIDATES.UPDATECANDIDATESUCCESS:
      return {
        ...candidateState,
        allCandidates: [...action.payload],
        filteredCandidates: [],
        submitting: false,
      };
    case CANDIDATES.UPDATERECOMMENDEDCANDIDATESUCCESS:
      return {
        ...candidateState,
        allCandidates: [...action.payload],
        filteredCandidates: [],
        submitting: false,
      };
    case CANDIDATES.UPDATECANDIDATEPROFILESUCCESS:
      return { ...candidateState, submitting: false };
    case CANDIDATES.UNSUBSCRIBESUCCESS:
      return { ...candidateState };
    default:
      return candidateState;
  }
};

export default candidateStateReducer;
