//@flow
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AddJobOpeningForm from './AddJobOpeningForm';
import Sidebar from '../Sidebar/Sidebar';
import history from '../../history';

const AddJobOpeningValidationSchema = Yup.object().shape({
  Company: Yup.string().required('Required'),
  Link: Yup.string().required('Required'),
  Position: Yup.string().required('Required'),
});

const initialAddJobValues = {
  candidateName: '',
  Company: '',
  Link: '',
  Position: '',
  Title: '',
  Month: '',
  Year: '',
};

const AddJobOpening = (props: Props) => {
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddJobValues}
        render={props => <AddJobOpeningForm history={history} {...props} />}
        validationSchema={AddJobOpeningValidationSchema}
      />
    </div>
  );
};
export default AddJobOpening;
