//@flow
import React from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { updateSchool, toggleSchoolEdit } from '../../redux/actions';
import './Schools.scss';

type Props = {
  schools: Array<Object>,
  id: string,
  name: string,
  index: number,
  isEditing: boolean,
  updateSchool: Function,
  toggleSchoolEdit: Function,
};

const School = (props: Props) => {
  const { index, name, id, isEditing, schools, toggleSchoolEdit, updateSchool } = props;
  const form = () => (
    <Form>
      <div className="row">
        <div className="columnLarge">
          <Field name="name" type="text" />
        </div>
        <div className="columnAction">
          <button type="submit">Submit</button>
        </div>
        <div className="columnAction">
          <button type="submit">Cancel</button>
        </div>
      </div>
    </Form>
  );
  return (
    <div className="tbody">
      {!isEditing ? (
        <div className="row">
          <div className="columnSmall">{index}</div>
          <div className="columnLarge">{name}</div>
          <div className="columnAction">
            <button type="submit" onClick={() => toggleSchoolEdit(schools, id)}>
              Edit
            </button>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{
            id,
            name,
          }}
          onSubmit={values => {
            updateSchool(schools, values);
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('School Name is required'),
          })}
          component={form}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  schools: state.schoolState.allSchools,
});

export default connect(mapStateToProps, { updateSchool, toggleSchoolEdit })(School);
