import { call, put } from 'redux-saga/effects';
import { getAllProblemsWithUrl, addProblemWithUrl, updateProblemWithUrl } from '../../api';
import {
  getAllProblemsWithUrlSuccess,
  problemWithUrlError,
  submittingProblemWithUrlRequest,
  addProblemWithUrlSuccess,
  updateProblemWithUrlSuccess,
} from '../actions/index';
import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllProblemsWithUrlSaga(action) {
  try {
    yield put(submittingProblemWithUrlRequest());
    const { cohort } = action;
    const problemsWithUrl = yield call(getAllProblemsWithUrl, cohort);
    yield put(getAllProblemsWithUrlSuccess(problemsWithUrl.data));
  } catch (error) {
    yield put(problemWithUrlError(error.toString()));
    yield delay(3000);
    yield put(problemWithUrlError(''));
  }
}

export function* addProblemWithUrlSaga(action) {
  try {
    yield put(submittingProblemWithUrlRequest());
    const { problemObj } = action;
    const problemWithUrl = yield call(addProblemWithUrl, problemObj);
    yield put(addProblemWithUrlSuccess(problemWithUrl.data));
    history.push('/problemsWithUrl');
  } catch (error) {
    yield put(problemWithUrlError('Duplicate Entry'));
    yield delay(3000);
    yield put(problemWithUrlError(''));
  }
}
export function* updateProblemWithUrlSaga(action) {
  try {
    yield put(submittingProblemWithUrlRequest());
    const { probId, allProblems, videoUrl } = action;
    const updatedProblem = yield call(updateProblemWithUrl, probId, videoUrl);
    const modifiedProblems = allProblems.filter(prob => {
      if (prob.id === probId) {
        return updatedProblem;
      }
      return prob;
    });
    yield put(updateProblemWithUrlSuccess(modifiedProblems));
    history.push('/problemsWithUrl');
  } catch (error) {
    yield put(problemWithUrlError(error.toString()));
    yield delay(3000);
    yield put(problemWithUrlError(''));
  }
}