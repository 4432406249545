import React, { Component } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import ROOT_URL from '../../config/baseUrl';
import Sidebar from '../Sidebar/Sidebar';
import history from '../../history';
import { MenuItem } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem',
    alignItems: 'center',
    width: '40rem',
  },
  textField: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    fontSize: 18,
    fontWeight: 500,
  },
  resize: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};

class Referral extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      submitting: false,
      email: '',
      passReferralBonusToIncomingStudent: false,
      error: '',
    };
  }
  handleChange(event, type) {
    this.setState({ [type]: event.target.value });
  }
  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitting: true });
    await this.referFriend(this.state);
  }
  referFriend = async state => {
    const apiurl = `${ROOT_URL}/api/referral`;
    const { email, passReferralBonusToIncomingStudent } = state;
    const token = localStorage.getItem('token') || {};
    try {
      await axios.post(
        apiurl,
        { email, passReferralBonusToIncomingStudent },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      await this.setState({
        submitting: false,
        email: '',
        error: '',
        passReferralBonusToIncomingStudent: false,
      });
      history.push(`/referralSuccess`);
    } catch (error) {
      await this.setState({
        error: error.response.data.message,
      });
      setTimeout(() => {
        this.setState({ submitting: false, error: '' });
      }, 3000);
    }
  };
  render() {
    const { submitting, error } = this.state;
    const { classes } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <form
          style={{ margin: '5rem' }}
          className={classes.container}
          onSubmit={e => this.handleSubmit(e)}
        >
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Inviting...</p>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
                      
          <TextField
            required
            variant="outlined"
            type="email"
            style={styles.textField}
            value={this.state.email}
            onChange={e => this.handleChange(e, 'email')}
            label="Gmail Email"
            placeholder="tyrion.lannister@gmail.com"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />
          <TextField
            id="passReferralBonusToIncomingStudent"
            variant="outlined"
            select
            label=""
            style={styles.textField}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={this.state.passReferralBonusToIncomingStudent}
            onChange={e => this.handleChange(e, 'passReferralBonusToIncomingStudent')}
            margin="normal"
          >
            <MenuItem key={1} value={true} style={{ fontSize: 12 }}>
              $100 discount for my friend
            </MenuItem>
            <MenuItem key={1} value={false} style={{ fontSize: 12 }}>
              $50 for my friend + $50 for me
            </MenuItem>
          </TextField>
          {!this.state.passReferralBonusToIncomingStudent && (
            <p>
              Referral Bonus of $50 will be sent to your account in proportion to the fee
              installments paid by the incoming student.
            </p>
          )}
          {/* <p>Currently we are asking your fried to transfer you $50</p> */}
          <button style={{ width: '75%' }} type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(Referral);
