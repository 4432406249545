//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllPricings } from '../../redux/actions';
import Payment from './Payment';
import './Payment.scss';
type Props = {
  getAllPricings: Function,
  pricings: Array<Object>,
};
class Payments extends Component<Props> {
  componentDidMount() {
    const { getAllPricings } = this.props;
    getAllPricings();
  }

  render() {
    const { pricings } = this.props;
    return (
      <div className="mainBody" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="paymentPlans">
          {pricings.map((plan, index) => {
            return (
              index < 0 && (
                <Payment
                  key={plan._id}
                  id={plan._id}
                  amountPayableNow={plan.amountPayableNow}
                  postJobPayableAmount={plan.postJobPayableAmount}
                  totalAmountPayable={plan.totalAmountPayable}
                  name={plan.name}
                  dueDate={plan.dueDate}
                />
              )
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pricings: state.pricingState.allPricings,
  error: state.pricingState.error,
});

export default connect(mapStateToProps, { getAllPricings })(Payments);
