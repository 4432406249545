import { call, put } from 'redux-saga/effects';
import { getAllInterviewers, getAllActiveInterviewers, updateInterviewer } from '../../api';
import {
  getAllInterviewersSuccess,
  getAllActiveInterviewersSuccess,
  updateInterviewerSuccess,
  submittingGetInterviewerRequest,
  submittingUpdateInterviewerRequest,
  interviewerError,
} from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllInterviewersSaga() {
  try {
    yield put(submittingGetInterviewerRequest());
    const interviewers = yield call(getAllInterviewers);
    yield put(getAllInterviewersSuccess(interviewers.data));
  } catch (error) {
    yield put(interviewerError(error.toString()));
    yield delay(3000);
    yield put(interviewerError(''));
  }
}
export function* getAllActiveInterviewersSaga() {
  try {
    yield put(submittingGetInterviewerRequest());
    const interviewers = yield call(getAllActiveInterviewers);
    yield put(getAllActiveInterviewersSuccess(interviewers.data));
  } catch (error) {
    yield put(interviewerError(error.toString()));
    yield delay(3000);
    yield put(interviewerError(''));
  }
}
export function* updateInterviewerSaga(action) {
  try {
    yield put(submittingUpdateInterviewerRequest());
    const { allInterviewers, interviewerInfo } = action;
    const { id } = interviewerInfo;
    const updatedInterviewer = yield call(updateInterviewer, interviewerInfo);
    const modifiedInterviewer = allInterviewers.map(interviewer => {
      if (interviewer._id === id) {
        interviewer = updatedInterviewer.data.updatedInterviewer;
      }
      return interviewer;
    });
    yield put(updateInterviewerSuccess(modifiedInterviewer));
  } catch (error) {
    yield put(interviewerError(error.toString()));
    yield delay(3000);
    yield put(interviewerError(''));
  }
}
