//@flow
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateRecommendedCandidate } from '../../redux/actions';
import './RecommendedCandidate.scss';

import moment from 'moment';

type Props = {
  id: string,
  recommendedCandidateId: string,
  recommendCandidate: Function,
  name: string,
  email: string,
  index: number,
  history: Object,
  candidates: Array<Object>,
  updateRecommendedCandidate: Function,
};

const RecommendedCandidate = (props: Props) => {
  const {
    id,
    index,
    name,
    candidates,
    workExperience,
    linkedInUrl,
    availableFrom,
    history,
  } = props;
  return (
    <div className="tbody">
      <div className="row">
        <div className="columnSmall">{index + 1}</div>
        <div className="columnLarge">{name}</div>
        <div className="columnMedium">
          {availableFrom && (
            <>
              {moment(availableFrom)
                .format('MMM, YYYY')
                .toString()}
            </>
          )}
        </div>
        <div className="columnMedium">{workExperience}</div>
        <a style={{ cursor: 'pointer' }} onClick={() => window.open(`${linkedInUrl}`, '_blank')}>
          <div className="columnMedium"> LinkedIn Profile</div>
        </a>
        <div className="columnMedium">
          <button
            type="submit"
            onClick={e => {
              e.preventDefault();
              props.updateRecommendedCandidate(candidates, {
                id,
              });
            }}
          >
            Get Resume
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ candidates: state.candidateState.allCandidates });
const mapDispatchToProps = dispatch => ({
  updateRecommendedCandidate: (candidates, values) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    dispatch(updateRecommendedCandidate(candidates, values)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecommendedCandidate);
