import { takeEvery } from 'redux-saga/effects';
import {
  AUTH,
  APPLICANTS,
  SCHOOLS,
  ROLES,
  COMPANIES,
  PROBLEMS,
  DSPROBLEMS,
  PROBLEMSWITHURL,
  PRICINGS,
  COHORTS,
  CANDIDATES,
  INTERVIEWER,
  COHORTMANAGER,
  INTERVIEWFEEDBACKS,
  INTERVIEWPERFORMANCES,
  LEADERSHIPINTERVIEWFEEDBACKS,
  INTERVIEWKIT,
  JOBOPENING,
  ALUMNILIST,
  PRSTATUS,
  SALARY,
} from '../constants/index';
import {
  getAllSchoolsSaga,
  addSchoolSaga,
  updateSchoolSaga,
  toggleSchoolEditSaga,
} from './schoolSaga';
import { getAllRolesSaga } from './roleSaga';
import {
  getAllCompaniesSaga,
  addCompanySaga,
  toggleCompanyEditSaga,
  updateCompanySaga,
} from './companySaga';
import { addCohortSaga, getAllCohortsSaga, updateCohortSaga } from './cohortSaga';
import {
  addCandidateSaga,
  getAllCandidatesSaga,
  getRecommendedCandidatesSaga,
  updateCandidateSaga,
  updateCandidateProfileSaga,
  unsubscribeCandidateSaga,
  recommendCandidateSaga,
} from './candidateSaga';
import {
  signinSaga,
  signoutSaga,
  signupSaga,
  forgotPasswordSaga,
  resetPasswordSaga,
  getUpdatedUserSaga,
} from './authSaga';
import {
  getAllProblemsSaga,
  addProblemSaga,
  updateProblemSaga,
  deleteProblemSaga,
} from './problemSaga';
import {
  getAllDSProblemsSaga,
  addDSProblemSaga,
  updateDSProblemSaga,
  deleteDSProblemSaga,
} from './dsproblemSaga';
import {
  getAllProblemsWithUrlSaga,
  addProblemWithUrlSaga,
  updateProblemWithUrlSaga,
} from './problemWithUrlSaga';
import {
  getAllInterviewFeedbacksSaga,
  addInterviewFeedbackSaga,
  updateInterviewFeedbackSaga,
  deleteInterviewFeedbackSaga,
} from './interviewFeedbackSaga';

import {
  getAllInterviewPerformancesSaga,
  addInterviewPerformanceSaga,
  updateInterviewPerformanceSaga,
  deleteInterviewPerformanceSaga,
} from './interviewPerformanceSaga';

import {
  getAllLeadershipInterviewFeedbacksSaga,
  addLeadershipInterviewFeedbackSaga,
  updateLeadershipInterviewFeedbackSaga,
  deleteLeadershipInterviewFeedbackSaga,
} from './leadershipInterviewFeedbackSaga';

import {
  getAllInterviewersSaga,
  getAllActiveInterviewersSaga,
  updateInterviewerSaga,
} from './interviewerSaga';
import { getAllPricingsSaga } from './pricingSaga';
import { getAllCohortManagersSaga } from './cohortManagerSaga';

import updateRecommendedCandidateSaga from './recommendedCandidateSaga';

import { getAllApplicantsSaga, addApplicantSaga } from './applicantSaga';

import { getJobOpeningSaga, addJobOpeningSaga } from './jobOpeningSaga';

import {
  getInterviewKitCompaniesSaga,
  getInterviewKitByIdSaga,
  addInterviewQuestionSaga,
  updateInterviewQuestionSaga,
} from './interviewKitSaga';

import { getSalaryCompaniesSaga } from './salarySaga';
import { getAlumniListByIdSaga, getAlumniListCompaniesSaga, addAlumniSaga } from './alumniListSaga';
import {
  getAllActiveInterviewers,
  getInterviewKitCompaniesSuccess,
  updateProblemWithUrlSuccess,
  getJobOpening,
  addJobOpeningSuccess,
  addAlumni,
} from '../actions';
import { addJobOpening } from '../../api';

import { getprStatusSaga } from './prStatusSaga';
export function* watcherSaga() {
  // Auth
  yield takeEvery(AUTH.SIGNIN, signinSaga);
  yield takeEvery(AUTH.SIGNOUT, signoutSaga);
  yield takeEvery(AUTH.FORGOTPASSWORD, forgotPasswordSaga);
  yield takeEvery(AUTH.RESETPASSWORD, resetPasswordSaga);
  yield takeEvery(AUTH.SIGNUP, signupSaga);
  yield takeEvery(AUTH.GETUPDATEDUSER, getUpdatedUserSaga);

  // Schools
  yield takeEvery(SCHOOLS.GETSCHOOLS, getAllSchoolsSaga);
  yield takeEvery(SCHOOLS.ADDSCHOOL, addSchoolSaga);
  yield takeEvery(SCHOOLS.UPDATESCHOOL, updateSchoolSaga);
  yield takeEvery(SCHOOLS.TOGGLESCHOOLEDIT, toggleSchoolEditSaga);
  // Companies
  yield takeEvery(COMPANIES.ADDCOMPANY, addCompanySaga);
  yield takeEvery(COMPANIES.UPDATECOMPANY, updateCompanySaga);
  yield takeEvery(COMPANIES.GETCOMPANIES, getAllCompaniesSaga);
  yield takeEvery(COMPANIES.TOGGLECOMPANYEDIT, toggleCompanyEditSaga);
  // Cohorts
  yield takeEvery(COHORTS.ADDCOHORT, addCohortSaga);
  yield takeEvery(COHORTS.GETCOHORTS, getAllCohortsSaga);
  yield takeEvery(COHORTS.UPDATECOHORTBYID, updateCohortSaga);
  // Problems
  yield takeEvery(PROBLEMS.GETPROBLEMS, getAllProblemsSaga);
  yield takeEvery(PROBLEMS.ADDPROBLEM, addProblemSaga);
  yield takeEvery(PROBLEMS.UPDATEPROBLEMBYID, updateProblemSaga);
  yield takeEvery(PROBLEMS.DELETEPROBLEMBYID, deleteProblemSaga);

  // dsProblems
  yield takeEvery(DSPROBLEMS.GETDSPROBLEMS, getAllDSProblemsSaga);
  yield takeEvery(DSPROBLEMS.ADDDSPROBLEM, addDSProblemSaga);
  yield takeEvery(DSPROBLEMS.UPDATEDSPROBLEMBYID, updateDSProblemSaga);
  yield takeEvery(DSPROBLEMS.DELETEDSPROBLEMBYID, deleteDSProblemSaga);
  // Interview kit
  yield takeEvery(INTERVIEWKIT.GETINTERVIEWKITCOMPANIES, getInterviewKitCompaniesSaga);
  yield takeEvery(INTERVIEWKIT.GETINTERVIEWKIT, getInterviewKitByIdSaga);
  yield takeEvery(INTERVIEWKIT.ADDINTERVIEWQUESTION, addInterviewQuestionSaga);
  yield takeEvery(INTERVIEWKIT.UPDATEINTERVIEWQUESTION, updateInterviewQuestionSaga);

  // Salary
  yield takeEvery(SALARY.GETSALARYCOMPANIES, getSalaryCompaniesSaga);
  // Alumni List
  yield takeEvery(ALUMNILIST.GETALUMNILISTCOMPANIES, getAlumniListCompaniesSaga);
  yield takeEvery(ALUMNILIST.GETALUMNILIST, getAlumniListByIdSaga);
  yield takeEvery(ALUMNILIST.ADDALUMNI, addAlumniSaga);
  // Job Opening
  yield takeEvery(JOBOPENING.GETJOBOPENING, getJobOpeningSaga);
  yield takeEvery(JOBOPENING.ADDJOBOPENING, addJobOpeningSaga);

  // ProblemsWithUrl
  yield takeEvery(PROBLEMSWITHURL.ADDPROBLEMWITHURL, addProblemWithUrlSaga);
  yield takeEvery(PROBLEMSWITHURL.GETPROBLEMSWITHURL, getAllProblemsWithUrlSaga);
  yield takeEvery(PROBLEMSWITHURL.UPDATEPROBLEMWITHURLBYID, updateProblemWithUrlSaga);

  // Candidates
  yield takeEvery(CANDIDATES.ADDCANDIDATE, addCandidateSaga);
  yield takeEvery(CANDIDATES.GETCANDIDATES, getAllCandidatesSaga);
  yield takeEvery(CANDIDATES.GETRECOMMENDEDCANDIDATES, getRecommendedCandidatesSaga);
  yield takeEvery(CANDIDATES.UPDATECANDIDATE, updateCandidateSaga);
  yield takeEvery(CANDIDATES.UPDATECANDIDATEPROFILE, updateCandidateProfileSaga);
  yield takeEvery(CANDIDATES.RECOMMENDCANDIDATE, recommendCandidateSaga);
  yield takeEvery(CANDIDATES.UNSUBSCRIBE, unsubscribeCandidateSaga);
  // Interviewer
  yield takeEvery(INTERVIEWER.GETINTERVIEWERS, getAllInterviewersSaga);
  yield takeEvery(INTERVIEWER.GETACTIVEINTERVIEWERS, getAllActiveInterviewersSaga);
  yield takeEvery(INTERVIEWER.UPDATEINTERVIEWER, updateInterviewerSaga);

  // CohortManager
  yield takeEvery(COHORTMANAGER.GETCOHORTMANAGERS, getAllCohortManagersSaga);
  // RecommendedCandidate
  yield takeEvery(CANDIDATES.UPDATERECOMMENDEDCANDIDATE, updateRecommendedCandidateSaga);

  // Applicants
  yield takeEvery(APPLICANTS.GETAPPLICANTS, getAllApplicantsSaga);
  yield takeEvery(APPLICANTS.ADDAPPLICANT, addApplicantSaga);

  // Pricings
  yield takeEvery(PRICINGS.GETPRICINGS, getAllPricingsSaga);
  // interviewPerformance
  yield takeEvery(INTERVIEWPERFORMANCES.GETINTERVIEWPERFORMANCES, getAllInterviewPerformancesSaga);
  yield takeEvery(INTERVIEWPERFORMANCES.ADDINTERVIEWPERFORMANCE, addInterviewPerformanceSaga);

  // interviewFeedback
  yield takeEvery(INTERVIEWFEEDBACKS.GETINTERVIEWFEEDBACKS, getAllInterviewFeedbacksSaga);
  yield takeEvery(INTERVIEWFEEDBACKS.ADDINTERVIEWFEEDBACK, addInterviewFeedbackSaga);
  yield takeEvery(INTERVIEWFEEDBACKS.UPDATEINTERVIEWFEEDBACKBYID, updateInterviewFeedbackSaga);
  yield takeEvery(INTERVIEWFEEDBACKS.DELETEINTERVIEWFEEDBACKBYID, deleteInterviewFeedbackSaga);

  // leadershipInterviewFeedback
  yield takeEvery(
    LEADERSHIPINTERVIEWFEEDBACKS.GETLEADERSHIPINTERVIEWFEEDBACKS,
    getAllLeadershipInterviewFeedbacksSaga,
  );
  yield takeEvery(
    LEADERSHIPINTERVIEWFEEDBACKS.ADDLEADERSHIPINTERVIEWFEEDBACK,
    addLeadershipInterviewFeedbackSaga,
  );
  yield takeEvery(
    LEADERSHIPINTERVIEWFEEDBACKS.UPDATELEADERSHIPINTERVIEWFEEDBACKBYID,
    updateLeadershipInterviewFeedbackSaga,
  );
  yield takeEvery(
    LEADERSHIPINTERVIEWFEEDBACKS.DELETELEADERSHIPINTERVIEWFEEDBACKBYID,
    deleteLeadershipInterviewFeedbackSaga,
  );

  // roles
  yield takeEvery(ROLES.GETROLES, getAllRolesSaga);
  // prStatus
  yield takeEvery(PRSTATUS.GETPRSTATUS, getprStatusSaga);
}
