//@flow
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';

import {
  styles,
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
} from './Common';

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};
type Props = {
  classes: Object,
  theme: Object,
  label: string,
  suggestions: Object,
  value: Object,
  handleChange: Function,
};
class MultiSelect extends React.Component<Props> {
  render() {
    const { classes, theme, label, suggestions, value, handleChange } = this.props;
    const modifiedSuggestions = suggestions.map(suggestion => ({
      value: suggestion._id,
      label: suggestion.name == null ? suggestion.title : suggestion.name,
    }));
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label: `${label}`,
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={modifiedSuggestions}
            components={components}
            // value={this.state.multi}
            // onChange={this.handleChange('multi')}
            MultiValue={value}
            onChange={handleChange}
            isMulti
          />
        </NoSsr>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  classes: PropTypes.shape.isRequired,
  theme: PropTypes.shape.isRequired,
  suggestions: PropTypes.arrayOf.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(MultiSelect);
