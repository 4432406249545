//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import Rating from 'react-rating';
import ROOT_URL from '../../config/baseUrl';
import { connect } from 'react-redux';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import history from '../../history';

import axios from 'axios';
// import DisplayFormikState from '../DisplayFormikState';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  performances: Array<Object>,
  match: Object,
};
type State = {
  _id: string,
  gaveValuableHints: number,
  interestLevel: number,
  timeManagement: number,
  thingsToImprove: string,
  communication: number,
  rating: number,
  candidateId: string,
  roles: string,
};
class PerformanceForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      gaveValuableHints: 0,
      interestLevel: 0,
      timeManagement: 0,
      thingsToImprove: '',
      communication: 0,
      rating: 0,
      candidateId: '',
      roles: localStorage.getItem('s30Roles') || '',
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    const apiurl = `${ROOT_URL}/api/performance/${id}`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(apiurl, headers)
      .then(res => {
        console.log(res);
        const {
          _id,
          gaveValuableHints,
          interestLevel,
          timeManagement,
          thingsToImprove,
          communication,
          rating,
          candidateId,
        } = res.data;
        this.setState({
          _id,
          gaveValuableHints,
          interestLevel,
          timeManagement,
          communication,
          thingsToImprove,
          rating,
          candidateId,
        });
      })
      .catch(() => {
        console.log('error in getting performance');
      });
  }
  render() {
    const { performances } = this.props;
    const {
      _id,
      interestLevel,
      timeManagement,
      thingsToImprove,
      gaveValuableHints,
      communication,
      rating,
      roles,
    } = this.state;
    return (
      <div className="mainBody">
        <form style={styles.container}>
          <div style={styles.miniContainer}>
            <h1> Performance</h1>
            <br />
            <br />
            <label>Communication</label>
            <Rating initialRating={communication} stop={10} readonly />
            <br />
            <label>Interest Level</label>
            <Rating stop={10} initialRating={interestLevel} readonly />
            <br />
            <label>Time Management</label>
            <Rating stop={10} initialRating={timeManagement} readonly />
            <br />
            <label>Gave Valuable Hints</label>
            <Rating initialRating={gaveValuableHints} stop={10} readonly />
            <br />
            <br />
          </div>
          <div style={styles.miniContainer}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <label>Things that you need to improve</label>
            <p style={{ textAlign: 'center' }}>{thingsToImprove}</p>
            <br />
            <label>Result</label>
            <p style={{ textAlign: 'center' }}>{rating >= 8 ? 'Pass' : 'Need to improve'}</p>
            {/* {<DisplayFormikState {...values} />} */}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  performances: state.performanceState.allPerformances,
});

export default withStyles(styles)(connect(mapStateToProps, {})(PerformanceForm));
