import React, { Component } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import ROOT_URL from '../../config/baseUrl';
import Sidebar from '../Sidebar/Sidebar';
import SystemDesignNote from './SystemDesignNote';

import './SystemDesignNote';

import Snackbar from '@material-ui/core/Snackbar';

class SystemDesignNotes extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      notesObj: {},
    };
  }
  async componentDidMount() {
    const apiurl = `${ROOT_URL}/api/notes`;
    const token = localStorage.getItem('token') || {};
    await this.setState({
      submitting: true,
    });
    const notesResponse = await axios.get(apiurl, {
      headers: {
        Authorization: token,
      },
    });
    await this.setState({
      notesObj: notesResponse.data,
    });
  }

  render() {
    const { notesObj } = this.state;
    const notesKeys = Object.keys(notesObj);
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="systemDesignNote">
          <div className="table">
            <h3 className="title">System Design Notes</h3>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnLarge">Name</div>
              <div className="columnAction">Open Pdf</div>
            </div>
            <div className="tbody"></div>
            {notesKeys.length > 0 ? (
              notesKeys.map((note, index) => {
                return (
                  <div className="row">
                    <div className="columnSmall">{index + 1}</div>
                    <div className="columnLarge">{note}</div>
                    <button
                      onClick={() => window.open(`${notesObj[note]}`, '_blank')}
                      type="submit"
                    >
                      View Notes
                    </button>
                  </div>
                );
              })
            ) : (
              <div>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    Sorry, access to these notes is only for System Design Enrolled Students. Kindly
                    ping Sowmya in slack to enroll in System Design Cohort.
                  </li>
                  <li>
                    {/* To get access to previous batch videos and notes, please pay $65
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => window.open(`https://www.thes30.com/studentPayment`, '_blank')}
                    >
                      <> here </>
                    </a> */}
                    and ping Sowmya N on slack for access.
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SystemDesignNotes;
