// //@flow
// import React from 'react';
// import * as Yup from 'yup';
// import { Formik } from 'formik';
// import AddInterviewQuestionForm from './AddInterviewQuestionForm';
// import Sidebar from '../Sidebar/Sidebar';
// import history from '../../history';

// const AddInterviewQuestionValidationSchema = Yup.object().shape({
//   companyId: Yup.string().required('Required'),
//   title: Yup.string().required('Required'),
//   description: Yup.string().required('Required'),
// });

// const initialAddFeedbackValues = {
//   candidateAskedName: '',
//   files: [],
//   companyId: '',
//   title: '',
//   link: '',
//   description: '',
//   dateAsked: '',
//   position: '',
//   round: '',
// };

// const AddInterviewQuestion = (props: Props) => {
//   return (
//     <div className="mainBody">
//       <Sidebar />
//       <Formik
//         initialValues={initialAddFeedbackValues}
//         render={props => <AddInterviewQuestionForm history={history} {...props} />}
//         validationSchema={AddInterviewQuestionValidationSchema}
//       />
//     </div>
//   );
// };
// export default AddInterviewQuestion;

import React, { Component } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import ROOT_URL from '../../config/baseUrl';
import Sidebar from '../Sidebar/Sidebar';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getAllCompanies } from '../../redux/actions';
import Snackbar from '@material-ui/core/Snackbar';
import { MenuItem } from '@material-ui/core';

import moment from 'moment';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
    lineHeight: 1.5,
  },

  resizeName: {
    fontSize: 18,
  },
  Autocomplete: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 30,
    fontSize: 18,
    fontWeight: 500,
  },
  textField: {
    width: '90%',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};

class AddInterviewQuestion extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      files: [],
      companyId: '',
      title: '',
      link: '',
      description: '',
      dateAsked: moment().format('YYYY-MM-DD'),
      position: '',
      round: '',
      recruiterLinkedIn: '',
      recruiterName: '',
      experience: '',
      submitting: false,
    };
  }
  componentDidMount() {
    const { getAllCompanies } = this.props;
    getAllCompanies();
  }
  handleChangeFile(event) {
    event.preventDefault();
    this.setState({ files: event.target.files });
  }
  handleChange(event, type) {
    this.setState({ [type]: event.target.value });
  }
  handleCompanyChange(e) {
    this.setState({ companyId: e.target.value });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitting: true });
    await this.uploadFile(this.state.files);
  }

  uploadFile = async files => {
    const formData = new FormData();
    const apiurl = `${ROOT_URL}/api/interviewKitProblem`;
    for (let i = 0; i < files.length; i++) {
      formData.append(`images[${i}]`, files[i]);
    }
    let item = this.state;
    let keys = Object.keys(item);
    keys.forEach(ele => {
      if (item[ele]) {
        formData.append(ele, item[ele]);
      }
    });
    const token = localStorage.getItem('token') || {};
    await axios.post(apiurl, formData, {
      headers: {
        Authorization: token,
        'content-type': 'multipart/form-data',
      },
    });
    await this.setState({
      files: [],
      companyId: '',
      title: '',
      link: '',
      description: '',
      dateAsked: moment().format('YYYY-MM-DD'),
      position: '',
      round: '',
      recruiterLinkedIn: '',
      recruiterName: '',
      experience: '',
      submitting: false,
    });
    alert('Added Successfully! Will appear in interview Kits is 24 hours');
  };

  render() {
    const { allCompanies, classes, error } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <form
          style={{ overflow: 'auto' }}
          className={classes.container}
          onSubmit={e => this.handleSubmit(e)}
        >
          <div style={styles.miniContainer}>
            <h1>Add Interview Question</h1>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Submitting Problem Please wait....</p>}
            />
            <div>
              <input
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                type="file"
                id="file"
                multiple
                name="file"
                onChange={e => this.handleChangeFile(e)}
              />
            </div>
            <TextField
              required
              variant="outlined"
              style={styles.textField}
              value={this.state.title}
              onChange={e => this.handleChange(e, 'title')}
              label="Problem Title"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              multiline
              required
              rows={8}
              style={styles.textField}
              value={this.state.description}
              onChange={e => this.handleChange(e, 'description')}
              variant="outlined"
              id="description"
              label="Problem Description"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
            {/* to be removed after adding the data */}
            {/* <TextField
              id="frequency"
              type="number"
              label="Frequency"
              value={this.state.frequency}
              onChange={e => this.handleChange(e, 'frequency')}
              margin="normal"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.myHelperTextStyle,
                },
              }}
            /> */}
            <TextField
              style={styles.textField}
              required
              id="companyId"
              variant="outlined"
              select
              label="Company"
              value={this.state.companyId}
              onChange={e => this.handleCompanyChange(e)}
              margin="normal"
            >
              {allCompanies.map(company => (
                <MenuItem key={company._id} value={company._id} style={{ fontSize: 15 }}>
                  {company.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              style={styles.textField}
              value={this.state.recruiterName}
              onChange={e => this.handleChange(e, 'recruiterName')}
              label="Company Recruiter Name (Add once only)"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={this.state.recruiterLinkedIn}
              onChange={e => this.handleChange(e, 'recruiterLinkedIn')}
              label="Company Recruiter linkedIn profile link (Add once only)"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={this.state.link}
              onChange={e => this.handleChange(e, 'link')}
              label="Problem Source Link"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={this.state.position}
              onChange={e => this.handleChange(e, 'position')}
              label="Position"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={this.state.round}
              onChange={e => this.handleChange(e, 'round')}
              label="Round"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              multiline
              rows={8}
              style={styles.textField}
              value={this.state.experience}
              onChange={e => this.handleChange(e, 'experience')}
              variant="outlined"
              id="description"
              label="Interview Experience (Enter only once)"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />

            <TextField
              style={styles.textField}
              variant="outlined"
              id="date"
              label="Date Asked"
              value={this.state.dateAsked}
              type="date"
              onChange={e => this.handleChange(e, 'dateAsked')}
              InputLabelProps={{
                className: classes.resize,
                shrink: true,
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allCompanies: state.companyState.allCompanies,
  error: state.interviewKitState.error,
});
const mapDispatchToProps = dispatch => ({
  getAllCompanies: () => dispatch(getAllCompanies()),
});
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddInterviewQuestion),
);
