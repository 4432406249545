import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { signout } from '../../redux/actions';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};

// export default function DropdownMenu()
class DropdownMenu extends Component<Props, State> {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      signingOut: false,
    };
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.target,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { signout, classes } = this.props;
    const { anchorEl, signingOut } = this.state;
    return (
      <div style={{ marginRight: '4rem' }}>
        <li style={{ listStyle: 'none' }} onClick={this.handleClick}>
          Profile
        </li>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={signingOut}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Signing out...</p>}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <NavLink style={{ listStyle: 'none' }} to="/studentPayment">
            <MenuItem style={{ fontSize: '14px' }} onClick={this.handleClose}>
              Payment
            </MenuItem>
          </NavLink>
          <NavLink style={{ listStyle: 'none' }} to="/reset">
            <MenuItem style={{ fontSize: '14px' }} onClick={this.handleClose}>
              Change Password
            </MenuItem>
          </NavLink>
          <NavLink to="/">
            <MenuItem
              style={{ fontSize: '14px' }}
              onClick={e => {
                this.setState({ signingOut: true });
                e.preventDefault();
                setTimeout(() => {
                  this.setState({ signingOut: false });
                  signout();
                }, 3000);
              }}
            >
              Sign Out
            </MenuItem>
          </NavLink>
        </Menu>
      </div>
    );
  }
}
const mapStateToProps = state => ({});

export default withStyles(styles)(connect(mapStateToProps, { signout })(DropdownMenu));
