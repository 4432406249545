//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { updateCandidate, getAllCohorts } from '../../redux/actions';
import DisplayFormikState from '../DisplayFormikState';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  submitting: boolean,
  allCohorts: Array<Object>,
  candidates: Array<Object>,
  updateCandidate: Function,
  getAllCohorts: Function,
  history: Object,
  classes: Object,
  values: Object,
  handleChange: Function,
  resetForm: Function,
  error: string,
};

class UpdateCandidateForm extends Component<Props> {
  componentDidMount() {
    const { getAllCohorts } = this.props;
    getAllCohorts();
  }

  render() {
    const {
      values,
      handleChange,
      submitting,
      error,
      classes,
      allCohorts,
      candidates,
      updateCandidate,
      resetForm,
      history,
    } = this.props;
    return (
      <>
        <form
          className={classes.container}
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            console.log(values);
            updateCandidate(candidates, values, history);
            resetForm();
          }}
        >
          <h4>Add Candidate to new cohort</h4>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Adding new cohort for Candidate...</p>}
          />
                      
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <TextField
            id="cohortId"
            variant="outlined"
            select
            label="Cohort"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={values.newCohortId}
            onChange={handleChange('newCohortId')}
            margin="normal"
          >
            {allCohorts.map(cohort => (
              <MenuItem key={cohort._id} value={cohort._id} style={{ fontSize: 15 }}>
                {cohort.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            id="paymentToAdd"
            variant="outlined"
            select
            label="Payment to Add to total Fee"
            style={styles.textField}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={values.paymentToAdd}
            onChange={handleChange('paymentToAdd')}
            margin="normal"
          >
            <MenuItem key={1} value={799} style={{ fontSize: 13 }}>
              $799
            </MenuItem>
            <MenuItem key={2} value={699} style={{ fontSize: 13 }}>
              $699
            </MenuItem>
            <MenuItem key={6} value={599} style={{ fontSize: 13 }}>
              $599
            </MenuItem>
            <MenuItem key={3} value={499} style={{ fontSize: 13 }}>
              $499
            </MenuItem>
            <MenuItem key={5} value={399} style={{ fontSize: 13 }}>
              $399
            </MenuItem>
            <MenuItem key={7} value={299} style={{ fontSize: 13 }}>
              $299
            </MenuItem>
            <MenuItem key={8} value={249} style={{ fontSize: 13 }}>
              $249
            </MenuItem>
            <MenuItem key={4} value={199} style={{ fontSize: 13 }}>
              $199
            </MenuItem>
          </TextField>
          <TextField
            required
            id="noOfPendingInstallments"
            variant="outlined"
            select
            label="Installments"
            style={styles.textField}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={values.noOfPendingInstallments}
            onChange={handleChange('noOfPendingInstallments')}
            margin="normal"
          >
            <MenuItem key={1} value={1} style={{ fontSize: 13 }}>
              Single
            </MenuItem>
            <MenuItem key={2} value={2} style={{ fontSize: 13 }}>
              Two
            </MenuItem>
            <MenuItem key={3} value={3} style={{ fontSize: 13 }}>
              Three
            </MenuItem>
          </TextField>
          <button type="submit">Submit</button>
        </form>
        <form
          className={classes.container}
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            console.log(values);
            updateCandidate(candidates, values, history);
            resetForm();
          }}
        >
          <h4>Update Candidate</h4>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Updating Candidate...</p>}
          />
                      
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <TextField
            id="cohortId"
            variant="outlined"
            select
            label="Cohort"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            value={values.cohortId}
            onChange={handleChange('cohortId')}
            margin="normal"
          >
            {allCohorts.map(cohort => (
              <MenuItem key={cohort._id} value={cohort._id} style={{ fontSize: 15 }}>
                {cohort.name}
              </MenuItem>
            ))}
          </TextField>
          <button type="submit">Submit</button>
          {/* <DisplayFormikState {...values} /> */}
        </form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  allCohorts: state.cohortState.allCohorts,
  candidates: state.candidateState.allCandidates,
  error: state.candidateState.error,
  submitting: state.candidateState.submitting,
});

const mapDispatchToProps = dispatch => ({
  updateCandidate: (candidates, values, history) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    dispatch(updateCandidate(candidates, values, history)),
  getAllCohorts: () => dispatch(getAllCohorts()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UpdateCandidateForm),
);
