import { INTERVIEWKIT } from '../constants';

const initialState = {
  allInterviewKit: [],
  allInterviewKitCompanies: [],
  filteredInterviewKitCompanies: [],
  activePage: 0,
  activePageQues: 0,
  perPage: 27,
  perPageQues: 8,
  error: false,
  submitting: false,
};
const interviewKitReducer = (interviewKitState = initialState, action) => {
  switch (action.type) {
    case INTERVIEWKIT.CHANGEACTIVEINTERVIEWKITPAGE:
      return { ...interviewKitState, activePage: action.payload, activePageQues: 0 };
    case INTERVIEWKIT.CHANGEACTIVEINTERVIEWKITQUESTIONPAGE:
      return { ...interviewKitState, activePage: 0, activePageQues: action.payload };
    case INTERVIEWKIT.GETINTERVIEWKITSUCCESS:
      return {
        ...interviewKitState,
        allInterviewKit: [...action.payload],
        submitting: false,
      };
    case INTERVIEWKIT.SEARCHINTERVIEWKITCOMPANY:
      return {
        ...interviewKitState,
        filteredInterviewKitCompanies: [...action.payload],
        submitting: false,
      };
    case INTERVIEWKIT.ADDINTERVIEWQUESTIONSUCCESS:
      return {
        ...interviewKitState,
        submitting: false,
      };
    case INTERVIEWKIT.UPDATEINTERVIEWQUESTION:
      return {
        ...interviewKitState,
        submitting: false,
      };
    case INTERVIEWKIT.GETINTERVIEWKITCOMPANIESSUCCESS:
      return {
        ...interviewKitState,
        activePageQues: 0,
        activePage: 0,
        allInterviewKitCompanies: [...action.payload],
        submitting: false,
      };
    case INTERVIEWKIT.INTERVIEWKITERROR:
      return { ...interviewKitState, error: action.error, submitting: false };
    case INTERVIEWKIT.SUBMITTINGGETINTERVIEWKIT:
      return { ...interviewKitState, submitting: true };
    default:
      return interviewKitState;
  }
};

export default interviewKitReducer;
