//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import Rating from 'react-rating';
import ROOT_URL from '../../config/baseUrl';
import { connect } from 'react-redux';
import { deleteInterviewFeedbackById } from '../../redux/actions';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import history from '../../history';

import axios from 'axios';
// import DisplayFormikState from '../DisplayFormikState';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  feedbacks: Array<Object>,
  match: Object,
  deleteInterviewFeedbackById: Function,
};
type State = {
  _id: string,
  confidence: number,
  bugTracking: number,
  coding: number,
  thingsYouDidWell: string,
  thingsToImprove: string,
  problemSolving: number,
  communication: number,
  rating: number,
  candidateId: string,
  roles: string,
};
class FeedbackForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      confidence: 0,
      bugTracking: 0,
      coding: 0,
      thingsYouDidWell: '',
      thingsToImprove: '',
      problemSolving: 0,
      communication: 0,
      rating: 0,
      candidateId: '',
      roles: localStorage.getItem('s30Roles') || '',
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    const apiurl = `${ROOT_URL}/api/feedback/${id}`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(apiurl, headers)
      .then(res => {
        const {
          _id,
          confidence,
          bugTracking,
          coding,
          thingsYouDidWell,
          thingsToImprove,
          problemSolving,
          communication,
          rating,
          candidateId,
        } = res.data;
        this.setState({
          _id,
          confidence,
          bugTracking,
          coding,
          thingsYouDidWell,
          thingsToImprove,
          problemSolving,
          communication,
          rating,
          candidateId,
        });
      })
      .catch(() => {
        console.log('error in getting feedback');
      });
  }
  render() {
    const { feedbacks } = this.props;
    const {
      _id,
      bugTracking,
      coding,
      thingsYouDidWell,
      thingsToImprove,
      problemSolving,
      communication,
      rating,
      roles,
    } = this.state;
    return (
      <div className="mainBody">
        <form style={styles.container}>
          <div style={styles.miniContainer}>
            <h1> Feedback</h1>
            <br />
            {/* <label>Confidence</label>
            <Rating stop={10} initialRating={confidence} readonly /> */}
            <br />
            <label>Data structures and algorithm</label>
            <Rating stop={10} initialRating={bugTracking} readonly />
            <br />
            <label>Coding</label>
            <Rating stop={10} initialRating={coding} readonly />
            <br />
            <label>Problem Solving</label>
            <Rating initialRating={problemSolving} stop={10} readonly />
            <br />
            <label>Communication</label>
            <Rating initialRating={communication} stop={10} readonly />
            <br />
          </div>
          <div style={styles.miniContainer}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <label>Things that you did well</label>
            <p style={{ textAlign: 'center' }}>{thingsYouDidWell}</p>
            <br />
            <label>Things that you need to improve</label>
            <p style={{ textAlign: 'center' }}>{thingsToImprove}</p>
            <br />
            <label>Result</label>
            <p style={{ textAlign: 'center' }}>{rating >= 8 ? 'Pass' : 'Need to improve'}</p>
            {/* {<DisplayFormikState {...values} />} */}
            {roles.includes('Interviewer') && (
              <div>
                <button
                  onClick={() => {
                    history.push(`/feedback/update/${_id}`);
                  }}
                >
                  Edit
                </button>{' '}
                {/* <button
                  onClick={e => {
                    e.preventDefault();
                    const feedbackId = _id;
                    this.props.deleteInterviewFeedbackById(feedbacks, feedbackId);
                  }}
                >
                  Delete
                </button> */}
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  feedbacks: state.feedbackState.allFeedbacks,
});

export default withStyles(styles)(
  connect(mapStateToProps, { deleteInterviewFeedbackById })(FeedbackForm),
);
