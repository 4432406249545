//@flow
import React from 'react';
// import './Candidate.css';

const Contact = () => (
  <div
    className="mainBody"
    style={{
      display: 'block',
      textAlign: 'left',
      padding: '3rem',
      marginTop: '3rem',
      overflow: 'auto',
    }}
  >
    <p>
      <strong>Contact </strong>
    </p>
    <p>Email: jaspinder@thes30.com</p>
    <p>Contact Number: +91 9886944954</p>
    {/* <p>
      Address: S30, GROUND FLOOR, PLOT NO.57, INDUSTRIAL AREA, PHASE 1, CHANDIGARH, Chandigarh,
      Chandigarh, 160002
    </p> */}
    <p>Address: 7309 GF, Block H, Aerocity, Mohali, India 140306</p>
  </div>
);

export default Contact;
