//@flow
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../Sidebar/Sidebar';
import AddCohortForm from './AddCohortForm';
import moment from 'moment';

type Props = {
  history: Object,
};

const AddCohortValidationSchema = Yup.object().shape({
  name: Yup.string().required('Cohort name is required'),
  cohortManagerId: Yup.string().required('Cohort Manager is required'),
});

const initialAddCohortValues = {
  name: '',
  cohortManagerId: '',
  isDataScienceCohort: false,
  isMorningCohort: false,
  startDate: moment().format('YYYY-MM-DD'),
};

const AddCohort = (props: Props) => {
  const { history } = props;
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddCohortValues}
        render={props => <AddCohortForm history={history} {...props} />}
        validationSchema={AddCohortValidationSchema}
      />
    </div>
  );
};

export default AddCohort;
