import { COHORTS } from '../constants';

const initialState = {
  allCohorts: [],
  error: false,
  activePage: 0,
  perPage: 10,
  submitting: false,
};
const cohortReducer = (cohortState = initialState, action) => {
  switch (action.type) {
    case COHORTS.ADDCOHORTSUCCESS:
      return {
        ...cohortState,
        allCohorts: [...cohortState.allCohorts, { ...action.payload }],
        submitting: false,
      };
    case COHORTS.GETCOHORTSSUCCESS:
      return { ...cohortState, allCohorts: [...action.payload], submitting: false };
    case COHORTS.CHANGEACTIVECOHORTPAGE:
      return { ...cohortState, activePage: action.payload };
    case COHORTS.COHORTERROR:
      return { ...cohortState, error: action.error, submitting: false };
    case COHORTS.SUBMITTING_COHORT:
      return { ...cohortState, submitting: true };
    case COHORTS.UPDATECOHORTSUCCESS:
      return { ...cohortState, allCohorts: [...action.payload], submitting: false };
    default:
      return cohortState;
  }
};
export default cohortReducer;
