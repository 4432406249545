//@flow
import React from 'react';
import { connect } from 'react-redux';
import Interview from './Interview';
// $FlowFixMe.
import moment from 'moment';
type Props = {
  index: number,
  name: string,
  assignedCandidateIds: Array<Object>,
};
const Interviewer = (props: Props) => {
  const { index, name, assignedCandidateIds } = props;
  return (
    <>
      {assignedCandidateIds.map((candidate, idx) => {
        const { timeSlot } = candidate;
        const { firstName, lastName, slackId } = candidate.candidateId.userId;
        return (
          <Interview
            key={idx}
            index={`${index}.${idx + 1}`}
            name={name}
            slackId={slackId}
            candidate={`${firstName} ${lastName}`}
            timeslot={timeSlot}
          />
        );
      })}
    </>
  );
};

export default Interviewer;
