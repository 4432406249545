import { PRSTATUS } from '../constants';

const initialState = {
  prMap: {},
  activePage: 0,
  perPage: 15,
  error: false,
  submitting: false,
};
const prStatusReducer = (prStatusState = initialState, action) => {
  switch (action.type) {
    case PRSTATUS.CHANGEACTIVEPRSTATUSPAGE:
      return { ...prStatusState, activePage: action.payload };
    case PRSTATUS.PRSTATUSERROR:
      return { ...prStatusState, error: action.error, submitting: false };
    case PRSTATUS.GETPRSTATUSSUCCESS:
      return {
        ...prStatusState,
        prMap: { ...action.payload },
        submitting: false,
      };
    case PRSTATUS.SUBMITTING_PRSTATUS:
      return { ...prStatusState, submitting: true };
    default:
      return prStatusState;
  }
};

export default prStatusReducer;
