//@flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import UpdateFeedbackForm from './UpdateFeedbackForm';
import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';
type Props = {
  match: Object,
};
type State = {
  id: string,
  candidateId: string,
  bugTracking: number,
  coding: number,
  problemSolving: number,
  communication: number,
  thingsYouDidWell: string,
  thingsToImprove: string,
};
class UpdateFeedback extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      id: '',
      candidateId: '',
      bugTracking: 0,
      coding: 0,
      problemSolving: 0,
      communication: 0,
      thingsYouDidWell: '',
      thingsToImprove: '',
    };
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    const apiurl = `${ROOT_URL}/api/feedback/${id}`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    let feedback;
    try {
      const getFeedback = await axios.get(apiurl, headers);
      feedback = getFeedback.data;
      const {
        bugTracking,
        coding,
        thingsYouDidWell,
        thingsToImprove,
        problemSolving,
        communication,
        candidateId,
      } = feedback;
      this.setState({
        id,
        bugTracking,
        coding,
        thingsYouDidWell,
        thingsToImprove,
        problemSolving,
        communication,
        candidateId,
      });
    } catch (err) {
      console.log('error getting all feedbacks');
    }
  }

  render() {
    return (
      <div className="mainBody">
        <Formik
          enableReinitialize={true}
          initialValues={this.state}
          render={props => <UpdateFeedbackForm {...props} />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  feedbacks: state.feedbackState.allFeedbacks,
});

export default connect(mapStateToProps)(UpdateFeedback);
