//@flow
import React from 'react';
import './Testimonial.scss';

type Props = {
  img: string,
  text: string,
  company: string,
  name: string,
};

const Testimonial = (props: Props) => {
  const { img, text, company, name } = props;
  return (
    <div className="testimonial">
      <img alt="" src={img} className="img avatar" />
      <p>{name}</p>
      <p>
        <b> {company}</b>
      </p>
      <p style={{ fontSize: '13px' }}>{text}</p>
    </div>
  );
};

export default Testimonial;
