//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { updateCandidateProfile, getAllCompanies } from '../../../redux/actions';
import DisplayFormikState from '../../DisplayFormikState';
import MultiSelect from '../../Select/MultiSelect';
import Snackbar from '@material-ui/core/Snackbar';
import defaultUser from '../../../static/default-user.jpg';
import DropzoneDialoge from '../../DropzoneDialoge/DropzoneDialoge';
import moment from 'moment';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '3rem', width: '80rem' },
  containerBody: { display: 'flex', flexDirection: 'row' },
  containerLeft: { display: 'flex', flexDirection: 'column', margin: '3rem', width: '30rem' },
  containerRight: { display: 'flex', flexDirection: 'column', margin: '3rem', width: '30rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  user: Object,
  error: string,
  submitting: boolean,
  values: Object,
  errors: Object,
  handleChange: Function,
  resetForm: Function,
  allCompanies: Array<Object>,
  updateCandidateProfile: Function,
  getAllCompanies: Function,
  classes: Object,
  setFieldValue: Function,
};

class CandidateProfileForm extends Component<Props> {
  componentDidMount() {
    const { getAllCompanies } = this.props;
    getAllCompanies();
  }

  render() {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/bmp'];
    const acceptedResumeTypes = ['.pdf', '.docx', '.doc'];
    const {
      user,
      values,
      handleChange,
      errors,
      classes,
      submitting,
      error,
      allCompanies,
      updateCandidateProfile,
      resetForm,
      setFieldValue,
    } = this.props;
    return (
      <form
        className={classes.container}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          updateCandidateProfile(values);
          resetForm();
        }}
      >
        <h4>Complete Your Profile</h4>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding data to Profile,This may take some time</p>}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <div className={classes.containerBody}>
          <div className={classes.containerLeft}>
            {/* <img
              src={values.imageUrl ? values.imageUrl : defaultUser}
              alt="Submit form to display updated image"
              style={{ width: '300px', height: '300px' }}
            />
            <DropzoneDialoge
              setFieldValue={setFieldValue}
              field={'imageUrl'}
              ButtonTitle={'Add Profile Image'}
              acceptedFiles={acceptedImageTypes}
            /> */}
            <TextField
              required
              variant="outlined"
              value={values.linkedInUrl}
              onChange={handleChange}
              id="linkedInUrl"
              label="LinkedIn URL"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.myHelperTextStyle,
                },
              }}
              helperText={errors.linkedInUrl}
            />
            {/* <MultiSelect
              handleChange={handleChange('companiesToRecruit')}
              value={values.companiesToRecruit}
              label="Choose Companies to Recruit"
              suggestions={allCompanies}
            /> */}
          </div>
          <div className={classes.containerRight}>
            {/* <DropzoneDialoge
              ButtonTitle={'Add Resume'}
              setFieldValue={setFieldValue}
              field={'resumeUrl'}
              acceptedFiles={acceptedResumeTypes}
            />
            {values.resumeUrl && <p style={{ color: 'green' }}>Resume Added</p>} */}
            <TextField
              id="workExperience"
              type="number"
              label="No. of Years of experience"
              value={values.workExperience}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.myHelperTextStyle,
                },
              }}
              helperText={errors.workExperience}
            />
            {/* <TextField
              multiline
              rows={6}
              id="pastInternships"
              label="Past Internship"
              value={values.pastInternships}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.myHelperTextStyle,
                },
              }}
              helperText={errors.pastInternships}
            /> */}

            <TextField
              className={classes.textField}
              variant="outlined"
              id="availableFrom"
              label="Available from"
              type="date"
              onChange={handleChange}
              value={moment(values.availableFrom)
                .format('YYYY-MM-DD')
                .toString()}
              InputLabelProps={{
                className: classes.resize,
                shrink: true,
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
            {/* <MultiSelect
                handleChange={handleChange('approachedBy')}
                value={values.approachedBy}
                label="Other Companies in Pipeline"
                suggestions={allCompanies}
              /> */}
          </div>
        </div>
        <button type="submit">Submit</button>
        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  allCompanies: state.companyState.allCompanies,
  error: state.candidateState.error,
  submitting: state.candidateState.submitting,
});

const mapDispatchToProps = dispatch => ({
  updateCandidateProfile: values => dispatch(updateCandidateProfile(values)),
  getAllCompanies: () => dispatch(getAllCompanies()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CandidateProfileForm),
);
