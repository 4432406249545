import { call, put } from 'redux-saga/effects';
import {
  getAllDSProblems,
  addDSProblem,
  updateDSProblemById,
  deleteDSProblemById,
} from '../../api';
import {
  getAllDSProblemsSuccess,
  dsproblemError,
  addDSProblemSuccess,
  updateDSProblemSuccess,
  deleteDSProblemSuccess,
  submittingDSProblemRequest,
} from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllDSProblemsSaga() {
  try {
    yield put(submittingDSProblemRequest());
    var dsproblems = yield call(getAllDSProblems);
    yield put(getAllDSProblemsSuccess(dsproblems.data));
  } catch (error) {
    yield put(dsproblemError(error.toString()));
    yield delay(3000);
    yield put(dsproblemError(''));
  }
}

export function* addDSProblemSaga(action) {
  try {
    yield put(submittingDSProblemRequest());
    const { history, dsproblemObj } = action;
    const dsproblem = yield call(addDSProblem, dsproblemObj);
    yield put(addDSProblemSuccess(dsproblem));
    history.push('/dsproblems');
  } catch (error) {
    yield put(dsproblemError(error.toString()));
    yield delay(3000);
    yield put(dsproblemError(''));
  }
}

export function* updateDSProblemSaga(action) {
  try {
    yield put(submittingDSProblemRequest());
    const { dsprobId, allDSProblems, newDSProblem, history } = action;

    const updatedDSProblem = yield call(updateDSProblemById, dsprobId, newDSProblem);
    const modifiedDSProblems = allDSProblems.filter(prob => {
      if (prob.id === dsprobId) {
        return updatedDSProblem;
      }
      return prob;
    });
    yield put(updateDSProblemSuccess(modifiedDSProblems));
    history.push('/dsproblems');
  } catch (error) {
    yield put(dsproblemError(error.toString()));
    yield delay(3000);
    yield put(dsproblemError(''));
  }
}

export function* deleteDSProblemSaga(action) {
  try {
    yield put(submittingDSProblemRequest());
    const { probId, allDSProblems } = action;
    yield call(deleteDSProblemById, probId);
    const modifiedDSProblems = allDSProblems.filter(prob => prob._id !== probId);
    yield put(deleteDSProblemSuccess(modifiedDSProblems));
  } catch (error) {
    yield put(dsproblemError(error.toString()));
    yield delay(3000);
    yield put(dsproblemError(''));
  }
}
