import { INTERVIEWER } from '../constants';

const initialState = {
  allInterviewers: [],
  filteredInterviewers: [],
  error: false,
  activePage: 0,
  perPage: 10,
  submittingGetRequest: false,
  submittingPutRequest: false,
};

const interviewerReducer = (interviewerState = initialState, action) => {
  switch (action.type) {
    case INTERVIEWER.GETINTERVIEWERSSUCCESS:
      return {
        ...interviewerState,
        submittingGetRequest: false,
        allInterviewers: [...action.payload],
      };
    case INTERVIEWER.GETACTIVEINTERVIEWERSSUCCESS:
      return {
        ...interviewerState,
        submittingGetRequest: false,
        allInterviewers: [...action.payload],
      };
    case INTERVIEWER.UPDATEINTERVIEWERSUCCESS:
      return {
        ...interviewerState,
        allInterviewers: [...action.payload],
        submittingPutRequest: false,
      };
    case INTERVIEWER.SEARCHINTERVIEWER:
      return {
        ...interviewerState,
        filteredInterviewers: [...action.payload],
        submittingGetRequest: false,
        submittingPutRequest: false,
      };
    case INTERVIEWER.INTERVIEWERERROR:
      return { ...interviewerState, error: action.error };
    case INTERVIEWER.CHANGEACTIVEINTERVIEWERPAGE:
      return { ...interviewerState, activePage: action.payload };
    case INTERVIEWER.SUBMITTINGGETINTERVIEWER:
      return { ...interviewerState, submittingGetRequest: true };
    case INTERVIEWER.SUBMITTINGUPDATEINTERVIEWER:
      return { ...interviewerState, submittingPutRequest: true };
    default:
      return interviewerState;
  }
};
export default interviewerReducer;
