//@flow
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ReactMarkdown from 'react-markdown';
import color from '@material-ui/core/colors/amber';

const markdown = `

## Do's
- Profile photograph is first thing noticed by recruiters. Have a professionally taken profile photograph with white background. Here is good example of professional profile photo [Profile Pic](https://www.linkedin.com/in/mahima95/). Most US schools have photo vendors who click photos for $40/$50. 
- Have an impressive cover photo that reflects your professional intersts. Kindly don't use any stickers on profile pic like "Open to Network".
  1. [Sample 1](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/SWE_sample1.jpeg)
  2. [Sample 2](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/SWE_sample2.jpeg)
  3. [Sample 3](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/CoverPhoto.jpeg)
  4. [Sample 4](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/Cloud.jpeg)
- Have a headline that clearly communicates the following
  1. Type of role your are looking for.
  2. Your Availability.
  3. Degree and School.
  4. Sample: Actively looking for Full Time SDE New Grad roles starting Summer 2021 | Graduate Student at San Jose State University
- Have standard format bullets in content. Please remember each bullet should be impact bullet. It must have numbers in it. Without numbers its just a job responsibility and recruiters don't know if you did a good job or not.
- Add a working email in contact info.
`;
const LinkedInTips = () => (
  <div className="mainBody">
    <Sidebar />
    <div className="getInterviews">
      <ReactMarkdown source={markdown}></ReactMarkdown>
    </div>
  </div>
);

export default LinkedInTips;
