import { COMPANIES } from '../constants';

const initialState = {
  allCompanies: [],
  filteredCompanies: [],
  activePage: 0,
  perPage: 10,
  error: false,
  submitting: false,
};
const companyReducer = (companyState = initialState, action) => {
  switch (action.type) {
    case COMPANIES.ADDCOMPANYSUCCESS:
      return {
        ...companyState,
        allCompanies: [...companyState.allCompanies, { ...action.payload }],
        submitting: false,
      };
    case COMPANIES.GETCOMPANIESSUCCESS:
      return { ...companyState, allCompanies: [...action.payload], submitting: false };
    case COMPANIES.CHANGEACTIVECOMPANYPAGE:
      return { ...companyState, activePage: action.payload };
    case COMPANIES.COMPANYERROR:
      return { ...companyState, error: action.error, submitting: false };
    case COMPANIES.SUBMITTING_COMPANY:
      return { ...companyState, submitting: true };
    case COMPANIES.TOGGLECOMPANYEDITSUCCESS:
      return { ...companyState, allCompanies: [...action.payload], submitting: false };
    case COMPANIES.SEARCHCOMPANY:
      return { ...companyState, filteredCompanies: [...action.payload], submitting: false };
    case COMPANIES.UPDATECOMPANYSUCCESS:
      return { ...companyState, allCompanies: [...action.payload], submitting: false };
    default:
      return companyState;
  }
};
export default companyReducer;
