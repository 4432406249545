//@flow
import React from 'react';
import Faq from './Faq';

const faqs = [
  {
    'How are the mock interviews conducted?':
      'Mock Interviews are conducted on the weekend. Initially the mock interviews are done with the {S30} instructors. After a student attain required level of proficieny then she/he is graduated to quality assurance team for mock interviews. Quality assurance team consists of engineer from FAANGs.',
  },
  {
    'How are classes conducted?':
      'The classes are conducted over zoom and slack. The students are expected to keep their web cam on all the time.',
  },
  {
    'What if I am not able to attend a class on few days because of my school courses?':
      "We don't encourage students to miss classes as student gain a lot due to lively discussions in the class. However, we do understand that students have school commitments as well. The classes are always recorded and instructors post the youtube links in cohort slack channel. Student doubts are covered during extra sessions as well.",
  },
  {
    'How much time I should dedicate to interview prep apart from classes?':
      'Students are expected to work on their home assignments two hours a day apart from regular classes.',
  },
  {
    'Do you provide help with Resume and LinkedIn?': (
      <p>
        Yes, Resume and LinkedIn workshops are frequent at S30. In house HR specialist provides
        individual feedback on resumes and linkedIn as well.
      </p>
    ),
  },
  {
    'What is the process for admissions?':
      'Once you apply at https://www.thes30.com/apply , you will have an interview invite to check your fit the an {S30} cohort.',
  },
  {
    'Is the admit interview a coding interview?':
      'For MS students the admit interview is mainly an HR interview. Students are expected to have basic knowledge of data structures and algorithms. We are very confident to make students proficient in interview level data structures and algorithms.',
  },
  {
    'Do you allow students to religate to the next batch?': <p>No</p>,
  },
];
const Faqs = (props: {}) => {
  return (
    <div>
      <div>
        <h1>Frequently Asked Questions!</h1>
        <br />
        {faqs.map((faq, index) => {
          return (
            <Faq key={index} no={index} ques={Object.keys(faq)[0]} ans={Object.values(faq)[0]} />
          );
        })}
      </div>
    </div>
  );
};
export default Faqs;
