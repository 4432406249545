import { call, put } from 'redux-saga/effects';
import { addCohort, getAllCohorts, updateCohort } from '../../api';
import {
  addCohortSuccess,
  submittingCohortRequest,
  cohortError,
  getAllCohortsSuccess,
  updateCohortSuccess,
} from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllCohortsSaga() {
  try {
    yield put(submittingCohortRequest());
    const cohorts = yield call(getAllCohorts);
    yield put(getAllCohortsSuccess(cohorts.data));
  } catch (error) {
    yield put(cohortError(error.toString()));
    yield delay(3000);
    yield put(cohortError(''));
  }
}

export function* addCohortSaga(action) {
  try {
    yield put(submittingCohortRequest());
    const { history, cohortObj } = action;
    const cohort = yield call(addCohort, cohortObj);
    yield put(addCohortSuccess(cohort.data));
    history.push('/cohorts');
  } catch (error) {
    yield put(cohortError(error.toString()));
    yield delay(3000);
    yield put(cohortError(''));
  }
}

export function* updateCohortSaga(action) {
  try {
    yield put(submittingCohortRequest());
    const { cohortInfo, allCohorts } = action;
    const { id } = cohortInfo;
    const updatedCohort = yield call(updateCohort, cohortInfo);
    const modifiedCohorts = allCohorts.filter(cohort => {
      if (cohort.id === id) {
        return updatedCohort;
      }
      return cohort;
    });
    yield put(updateCohortSuccess(modifiedCohorts));
  } catch (error) {
    yield put(cohortError(error.toString()));
    yield delay(3000);
    yield put(cohortError(''));
  }
}
