//@flow
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddApplicantForm from './AddApplicantForm';

type Props = {
  history: Object,
};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const AddApplicantValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  linkedIn: Yup.string().required('Required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});

const initialAddApplicantValues = {
  firstName: '',
  lastName: '',
  email: '',
  school: '',
  linkedIn: '',
  month: '',
  plan: '',
  whyS30: '',
  phoneNumber: '',
  leetCodeProblemsCompleted: '',
  hoursCommitment: '',
  discountCoupon: '',
  termsOfService: false,
};

const AddApplicant = (props: Props) => {
  const { history } = props;
  return (
    <div className="mainBody" style={{ justifyContent: 'center' }}>
      <Formik
        initialValues={initialAddApplicantValues}
        render={props => <AddApplicantForm history={history} {...props} />}
        validationSchema={AddApplicantValidationSchema}
      />
    </div>
  );
};

export default AddApplicant;
