//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllCohorts, handleCohortPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Cohort from './Cohort';
import Sidebar from '../Sidebar/Sidebar';
import './Cohort.scss';

import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};

type Props = {
  getAllCohorts: Function,
  cohorts: Array<Object>,
  submitting: boolean,
  activePage: number,
  perPage: number,
  handleCohortPageClick: Function,
  error: string,
  classes: Object,
};

class Cohorts extends Component<Props> {
  constructor(props: {}) {
    super(props);
    this.state = { roles: localStorage.getItem('s30Roles') || '' };
  }
  async deactivateAllCohorts() {
    const apiurl = `${ROOT_URL}/api/cohort`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    await axios.put(apiurl, {}, headers);
    alert('Deactivated Successfully');
    window.location.reload();
  }
  componentDidMount() {
    const { getAllCohorts } = this.props;
    getAllCohorts();
  }

  handleCohortPageClick = data => {
    const { handleCohortPageClick } = this.props;
    handleCohortPageClick(data.selected);
  };

  render() {
    const { cohorts, submitting, error, classes, activePage, perPage } = this.props;
    const { roles } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="cohort">
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting cohorts...</p>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <div className="table">
            <h3 className="title"> All Cohorts </h3>{' '}
            {roles.includes('Admin') && (
              <div className="columnAction">
                <button
                  type="submit"
                  onClick={this.deactivateAllCohorts}
                  style={{
                    width: '30%',
                    backgroundColor: 'red',
                    fontSize: '16px',
                  }}
                >
                  Deactivate All Cohorts
                </button>{' '}
              </div>
            )}{' '}
            <div className="thead">
              <div className="columnMedium"> Name </div>{' '}
              <div className="columnSmall"> #Problems </div>{' '}
              <div className="columnSmall"> DayIndex </div>{' '}
              <div className="columnSmall"> Morning ? </div>{' '}
              <div className="columnSmall"> Data Science? </div>{' '}
              <div className="columnMedium"> Start Date </div>{' '}
              <div className="columnSmall"> #Candidates </div>{' '}
              {roles.includes('Admin') && <div className="columnAction"> Start ? </div>}{' '}
              {roles.includes('Admin') && <div className="columnAction"> Edit </div>}{' '}
            </div>{' '}
            {cohorts.map((cohort, index) => {
              const {
                _id,
                name,
                startDate,
                isMorningCohort,
                emailService,
                isDataScienceCohort,
                dayIndex,
                candidates,
                length,
                problemIndex,
              } = cohort;
              const bottomIdx = activePage * perPage;
              const topIdx = bottomIdx + perPage;
              if (index >= 0 && index < 10) {
                return (
                  <Cohort
                    key={_id}
                    id={_id}
                    name={name}
                    startDate={startDate}
                    isMorningCohort={isMorningCohort}
                    isDataScienceCohort={isDataScienceCohort}
                    emailService={emailService}
                    dayIndex={dayIndex}
                    noOfCandidates={candidates.length}
                    problemIndex={problemIndex}
                    roles={roles}
                  />
                );
              }
              return null;
            })}{' '}
          </div>{' '}
          <Custompaginate
            pageCount={cohorts.length / perPage}
            onPageChange={this.handleCohortPageClick}
          />
        </div>{' '}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.cohortState.activePage,
  perPage: state.cohortState.perPage,
  cohorts: state.cohortState.allCohorts,
  error: state.cohortState.error,
  submitting: state.cohortState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getAllCohorts,
    handleCohortPageClick,
  })(Cohorts),
);
