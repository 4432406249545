//@flow
import React from 'react';
import './candidate.scss';

type Props = {
  interviewerName: string,
  date: string,
  startTime: string,
  endTime: string,
};
const Interview = (props: Props) => {
  return (
    <div className="tbody">
      <div className="row">
        <div className="columnLarge">{props.interviewerName}</div>
        <div className="columnMedium">{props.interviewerSlackId}</div>
        <div className="columnMedium">{props.date}</div>
        <div className="columnMedium">{props.startTime}</div>
        <div className="columnMedium">{props.endTime}</div>
      </div>
    </div>
  );
};

export default Interview;
