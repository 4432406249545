//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { addSalary } from '../../redux/actions';
// import DisplayFormikState from '../DisplayFormikState';
import Autocomplete from '../Select/Autocomplete';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import zIndex from '@material-ui/core/styles/zIndex';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    marginLeft: '5rem',
    width: '42rem',
    overflow: 'auto',
  },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  submittingAddSalary: boolean,
  allSalaryCompanies: Array<Object>,
  addSalary: Function,
  values: Object,
  error: String,
  errors: Object,
  handleChange: Function,
  resetForm: Function,
  classes: Object,
};

class AddSalaryForm extends Component<Props> {
  render() {
    const {
      values,
      handleChange,
      submitting,
      error,
      errors,
      classes,
      allSalaryCompanies,
      addSalary,
      resetForm,
    } = this.props;
    return (
      <form
        style={{ overflow: 'auto' }}
        className={classes.container}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          // addSalary(values);
          resetForm();
        }}
      >
        <h4>Add a Salary</h4>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding Salary</p>}
        />
                    
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <TextField
          id="baseSalary"
          label="Base Salary"
          value={values.baseSalary}
          onChange={handleChange}
          name="baseSalary"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.baseSalary}
        />
        <TextField
          id="stock"
          label="Stock"
          value={values.stock}
          onChange={handleChange}
          name="stock"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.stock}
        />
        <TextField
          id="signingBonus"
          label="Signing Bonus"
          value={values.signingBonus}
          onChange={handleChange}
          name="signingBonus"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.signingBonus}
        />
        <TextField
          id="relocationExpense"
          label="Relocation"
          value={values.relocationExpense}
          onChange={handleChange}
          name="relocationExpense"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.relocationExpense}
        />
        <br></br>
        <TextField
          style={styles.textField}
          variant="outlined"
          id="date"
          label="Offer Date"
          value={values.dateOfferReceived}
          type="date"
          onChange={handleChange}
          InputLabelProps={{
            className: classes.resize,
            shrink: true,
          }}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
        />
        <br></br>
        <Autocomplete
          handleChange={handleChange('companyId')}
          value={values.companyId}
          label="Company"
          suggestions={allSalaryCompanies}
        />
        <br></br>
        <Autocomplete
          handleChange={handleChange('levelId')}
          value={values.levelId}
          label="Role"
          suggestions={allSalaryCompanies}
        />
        <br></br>
        <button type="submit">Submit</button>
        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  error: state.salaryState.error,
  allSalaryCompanies: state.salaryState.allSalaryCompanies,
});

const mapDispatchToProps = dispatch => ({
  // signup: (values, history) => dispatch(addSalary(values, history)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddSalaryForm));
