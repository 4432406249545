import axios from 'axios';

import ROOT_URL from './config/baseUrl';

const authHeader = () => {
  const token = localStorage.getItem('token') || {};
  return {
    headers: {
      Authorization: token,
    },
  };
};

//Auth
export const signin = user => {
  const apiurl = `${ROOT_URL}/signin`;
  return axios.post(apiurl, user);
};

export const signout = () => {
  const apiurl = `${ROOT_URL}/signout`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const getUpdatedUser = () => {
  const apiurl = `${ROOT_URL}/api/getUpdatedUser`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const signup = user => {
  const apiurl = `${ROOT_URL}/createCandidate`;
  const headers = authHeader();
  return axios.post(apiurl, user, headers);
};

export const forgotPassword = username => {
  const apiurl = `${ROOT_URL}/forgotpassword`;
  return axios.post(apiurl, username);
};

export const resetPassword = passwordObj => {
  const apiurl = `${ROOT_URL}/reset`;
  const headers = authHeader();
  return axios.post(apiurl, passwordObj, headers);
};

//Candidate

export const addCandidate = candidate => {
  const apiurl = `${ROOT_URL}/api/candidate`;
  const headers = authHeader();
  return axios.post(apiurl, candidate, headers);
};

export const getAllCandidates = async () => {
  const apiurl = `${ROOT_URL}/api/candidate`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const getRecommendedCandidates = () => {
  const apiurl = `${ROOT_URL}/api/recommendedcandidate`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const updateCandidate = candidateInfo => {
  const { id } = candidateInfo;
  const apiurl = `${ROOT_URL}/api/candidate/${id}`;
  const headers = authHeader();
  return axios.put(apiurl, candidateInfo, headers);
};

export const updateCandidateProfile = formData => {
  const id = formData.get('_id');
  const apiurl = `${ROOT_URL}/api/candidate/${id}`;
  const headers = authHeader();
  return axios.put(apiurl, formData, headers);
};

export const unsubscribeCandidate = id => {
  const apiurl = `${ROOT_URL}/api/unsubscribe/${id}`;
  return axios.put(apiurl, {});
};

export const recommendCandidate = (candidateId, interviewerId, isRecommended) => {
  const apiurl = `${ROOT_URL}/api/interviewer/candidate/${interviewerId}/${candidateId}`;
  const headers = authHeader();
  return axios.put(apiurl, { isRecommended }, headers);
};

//Cohort
export const addCohort = cohortObj => {
  const apiurl = `${ROOT_URL}/api/cohort`;
  const headers = authHeader();
  return axios.post(apiurl, cohortObj, headers);
};

export const getAllCohorts = async () => {
  const apiurl = `${ROOT_URL}/api/cohort`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const updateCohort = cohortInfo => {
  const { id } = cohortInfo;
  const apiurl = `${ROOT_URL}/api/cohort/${id}`;
  const headers = authHeader();
  return axios.put(apiurl, cohortInfo, headers);
};

// school
export const addSchool = schoolObj => {
  const apiurl = `${ROOT_URL}/api/school`;
  const headers = authHeader();
  return axios.post(apiurl, schoolObj, headers);
};

export const getAllSchools = async () => {
  const apiurl = `${ROOT_URL}/api/school`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const updateSchool = schoolInfo => {
  const { id } = schoolInfo;
  const apiurl = `${ROOT_URL}/api/school/${id}`;
  const headers = authHeader();
  return axios.put(apiurl, schoolInfo, headers);
};

//company
export const addCompany = companyObj => {
  const apiurl = `${ROOT_URL}/api/company`;
  const headers = authHeader();
  return axios.post(apiurl, companyObj, headers);
};

export const getAllCompanies = () => {
  const apiurl = `${ROOT_URL}/api/company`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const updateCompany = companyObj => {
  const { id } = companyObj;
  const apiurl = `${ROOT_URL}/api/company/${id}`;
  const headers = authHeader();
  return axios.put(apiurl, companyObj, headers);
};
// Problem with Url
export const getAllProblemsWithUrl = cohort => {
  const apiurl = `${ROOT_URL}/api/getProblemWithUrl`;
  const headers = authHeader();
  return axios.post(apiurl, cohort, headers);
};

export const addProblemWithUrl = problemObj => {
  const apiurl = `${ROOT_URL}/api/problemWithUrl`;
  const headers = authHeader();
  return axios.post(apiurl, problemObj, headers);
};

export const updateProblemWithUrl = (probId, videoUrl) => {
  const apiurl = `${ROOT_URL}/api/problemWithUrl/${probId}`;
  const headers = authHeader();
  const data = { videoUrl: videoUrl };
  return axios.put(apiurl, data, headers);
};

//dsproblem

export const getAllDSProblems = () => {
  const apiurl = `${ROOT_URL}/api/dsproblem`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const addDSProblem = dsproblemObj => {
  const apiurl = `${ROOT_URL}/api/dsproblem`;
  const headers = authHeader();
  return axios.post(apiurl, dsproblemObj, headers);
};

export const updateDSProblemById = (dsprobId, newDSProblem) => {
  const apiurl = `${ROOT_URL}/api/dsproblem/${dsprobId}`;
  const headers = authHeader();
  return axios.put(apiurl, newDSProblem, headers);
};

export const deleteDSProblemById = probId => {
  const apiurl = `${ROOT_URL}/api/dsproblem/${probId}`;
  const headers = authHeader();
  return axios.delete(apiurl, headers);
};
//problem

export const getAllProblems = () => {
  const apiurl = `${ROOT_URL}/api/problem`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const addProblem = problemObj => {
  const apiurl = `${ROOT_URL}/api/problem`;
  const headers = authHeader();
  return axios.post(apiurl, problemObj, headers);
};

export const updateProblemById = (probId, newProblem) => {
  const apiurl = `${ROOT_URL}/api/problem/${probId}`;
  const headers = authHeader();
  return axios.put(apiurl, newProblem, headers);
};

export const deleteProblemById = probId => {
  const apiurl = `${ROOT_URL}/api/problem/${probId}`;
  const headers = authHeader();
  return axios.delete(apiurl, headers);
};

// Interviewer
export const getAllInterviewers = async () => {
  const apiurl = `${ROOT_URL}/api/interviewer`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};
export const getAllActiveInterviewers = async () => {
  const apiurl = `${ROOT_URL}/api/activeinterviewer`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};
export const updateInterviewer = interviewerInfo => {
  const { id } = interviewerInfo;
  const apiurl = `${ROOT_URL}/api/interviewer/${id}`;
  const headers = authHeader();
  return axios.put(apiurl, interviewerInfo, headers);
};

// CohortManager
export const getAllCohortManagers = async () => {
  const apiurl = `${ROOT_URL}/api/cohortManager`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

// recommendedCandidate
export const updateRecommendedCandidate = candidateInfo => {
  const { id } = candidateInfo;
  const apiurl = `${ROOT_URL}/api/candidate/${id}`;
  const headers = authHeader();
  return axios.put(apiurl, candidateInfo, headers);
};
// export const updateRecommendedCandidate = formData => {
//   const apiurl = `${ROOT_URL}/api/recommendedCandidate/`;
//   const headers = authHeader();
//   headers.headers['content-type'] = 'multipart/form-data';
//   return axios.put(apiurl, formData, headers);
// };

// applicant
export const addApplicant = applicantObj => {
  const apiurl = `${ROOT_URL}/api/applicant`;
  const headers = authHeader();
  return axios.post(apiurl, applicantObj, headers);
};

export const getAllApplicants = async () => {
  const apiurl = `${ROOT_URL}/api/applicant`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

// pricing

export const getAllPricings = async () => {
  const apiurl = `${ROOT_URL}/api/pricing`;
  return axios.get(apiurl);
};

//performance of Interviewer
export const addInterviewPerformance = performanceObj => {
  const apiurl = `${ROOT_URL}/api/performance`;
  const headers = authHeader();
  return axios.post(apiurl, performanceObj, headers);
};

export const getAllInterviewPerformances = () => {
  const apiurl = `${ROOT_URL}/api/performance`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

// feedback

export const addInterviewFeedback = feedbackObj => {
  const apiurl = `${ROOT_URL}/api/feedback`;
  const headers = authHeader();
  return axios.post(apiurl, feedbackObj, headers);
};

export const getAllInterviewFeedbacks = () => {
  const apiurl = `${ROOT_URL}/api/feedback`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const updateInterviewFeedbackById = (feedbackId, newInterviewFeedback) => {
  const apiurl = `${ROOT_URL}/api/feedback/${feedbackId}`;
  const headers = authHeader();
  return axios.put(apiurl, newInterviewFeedback, headers);
};

export const deleteInterviewFeedbackById = feedbackId => {
  const apiurl = `${ROOT_URL}/api/feedback/${feedbackId}`;
  const headers = authHeader();
  return axios.delete(apiurl, headers);
};

// leadership feedback

export const addLeadershipInterviewFeedback = feedbackObj => {
  const apiurl = `${ROOT_URL}/api/leadershipfeedback`;
  const headers = authHeader();
  return axios.post(apiurl, feedbackObj, headers);
};

export const getAllLeadershipInterviewFeedbacks = () => {
  const apiurl = `${ROOT_URL}/api/leadershipfeedback`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const updateLeadershipInterviewFeedbackById = (feedbackId, newInterviewFeedback) => {
  const apiurl = `${ROOT_URL}/api/leadershipfeedback/${feedbackId}`;
  const headers = authHeader();
  return axios.put(apiurl, newInterviewFeedback, headers);
};

export const deleteLeadershipInterviewFeedbackById = feedbackId => {
  const apiurl = `${ROOT_URL}/api/leadershipfeedback/${feedbackId}`;
  const headers = authHeader();
  return axios.delete(apiurl, headers);
};
// roles
export const getAllRoles = async () => {
  const apiurl = `${ROOT_URL}/api/role`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

// interviewKit

export const getInterviewKitCompanies = () => {
  const apiurl = `${ROOT_URL}/api/company`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const getInterviewKitById = id => {
  const apiurl = `${ROOT_URL}/api/interviewKitProblem/${id}`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};
export const addInterviewQuestion = formData => {
  const apiurl = `${ROOT_URL}/api/addQuestionToKit`;
  const headers = authHeader();
  headers.headers['content-type'] = 'multipart/form-data';
  return axios.post(apiurl, formData, headers);
};

export const UpdateInterviewQuestion = question => {
  const apiurl = `${ROOT_URL}/api/updateQuestion`;
  const headers = authHeader();
  return axios.post(apiurl, question, headers);
};

// alumniData

export const getAlumniListCompanies = () => {
  const apiurl = `${ROOT_URL}/api/getalumnisheets`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const getAlumniListById = id => {
  const apiurl = `${ROOT_URL}/api/getalumnisheet/${id}`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};
export const addAlumni = alumni => {
  const apiurl = `${ROOT_URL}/api/addperson`;
  const headers = authHeader();
  return axios.post(apiurl, alumni, headers);
};
// jobOpening

export const getJobOpenings = () => {
  const apiurl = `${ROOT_URL}/api/getjobs`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

export const addJobOpening = data => {
  const apiurl = `${ROOT_URL}/api/addjob`;
  const headers = authHeader();
  return axios.post(apiurl, data, headers);
};

export const getPrStatus = async () => {
  const apiurl = `${ROOT_URL}/api/prStatus`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};

// salary
export const getSalaryCompanies = () => {
  const apiurl = `${ROOT_URL}/api/salaryCompanies`;
  const headers = authHeader();
  return axios.get(apiurl, headers);
};
