//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { addDSProblem } from '../../redux/actions';
import Texteditor from './RichTextEditor';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '60rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  submitting: boolean,
  addDSProblem: Function,
  history: Object,
  error: string,
  classes: Object,
};

type State = {
  name: string,
  dsproblemStatement: string,
  difficultyLevel: string,
  techniques: string,
  companyTags: string,
  solution: string,
  sourceLink: string,
  gitLink: string,
};
class AddDSProblem extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      techniques: '',
      solution: '',
      sourceLink: '',
      gitLink: '',
    };
  }

  handleInput = (e, type) => {
    e.preventDefault();
    this.setState({
      [type]: e.target.value,
    });
  };

  addDSProblem = e => {
    e.preventDefault();
    const { addDSProblem, history } = this.props;

    addDSProblem(this.state, history);
    this.setState({
      name: '',
      techniques: '',
      solution: '',
      sourceLink: '',
      gitLink: '',
    });
  };

  setDSProblemSolution = receivedHtml => {
    this.setState({
      solution: receivedHtml,
    });
  };

  render() {
    const { classes, submitting, error } = this.props;
    const { name, techniques, sourceLink, gitLink } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <form
          style={{ overflow: 'auto' }}
          className={classes.container}
          onSubmit={this.addDSProblem}
        >
          <h4>Add a DSProblem</h4>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Adding DSProblem...</p>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'name')}
            value={name}
            id="name"
            label="Name"
            placeholder="Name"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            id="techniques"
            label="Techniques"
            onChange={e => this.handleInput(e, 'techniques')}
            value={techniques}
            placeholder="Techniques"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'gitLink')}
            value={gitLink || ''}
            id="gitLink"
            label="Git Link"
            placeholder="Git Link"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'sourceLink')}
            value={sourceLink || ''}
            id="sourceLink"
            label="Source Link"
            placeholder="Source Link"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <b>DSProblem Solution</b>
          <Texteditor setDSProblemSolution={this.setDSProblemSolution} />
          <button
            style={{
              marginTop: '20',
            }}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.dsproblemState.error,
  submitting: state.dsproblemState.submitting,
});

const mapDispatchToProps = dispatch => ({
  addDSProblem: (values, history) => dispatch(addDSProblem(values, history)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddDSProblem));
