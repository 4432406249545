//@flow
import React, { Component } from 'react';
import Highlight from 'react-highlight.js';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';

import moment from 'moment';

const styles = {
  img: {
    maxWidth: '80%',
    height: 'auto',
    marginBottom: '5px',
  },
  root: {
    backgroundColor: 'red',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem',
    width: '80rem',
  },
  resize: {
    fontSize: 17,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
  displayTextStyle: {
    fontSize: 50,
  },
};

type Props = {
  match: Object,
  classes: Object,
  history: Object,
  sNo: Number,
  title: string,
  desc: string,
  link: string,
  dateAsked: Number,
  frequency: Number,
  position: String,
  round: String,
  recruiterName: String,
};

// const CompanyQuestion = (props: Props) => (
//   <div className="tbody">
//     <div className="row">
//       <div className="columnSmall">{props.index}</div>
//       <div className="columnLarge">{props.title}</div>
//       <div className="columnSmall">{props.frequency}</div>
//       <div className="columnMedium">{props.position}</div>
//       <div className="columnMedium">{props.round}</div>
//       <div className="columnMedium">Date</div>
//       <div className="columnAction">
//         <button type="submit">Upvote</button>
//       </div>
//       {(localStorage.getItem('s30Roles') || {}).includes('Admin') && (
//         <div className="columnAction">
//           <button type="submit">Approve</button>
//         </div>
//       )}
//     </div>
//   </div>
// );

class CompanyQuestion extends Component<Props, State> {
  render() {
    const { classes } = this.props;
    const {
      index,
      title,
      description,
      experience,
      imageLinks,
      link,
      dateAsked,
      frequency,
      recruiterName,
    } = this.props.location.state;

    return (
      <div className="mainBody">
        <Sidebar />
        <form style={{ overflow: 'auto' }} className={classes.container}>
          <h1>
            Problem #{index + 1} : {title}
          </h1>
          <br />
          <label>Description</label>
          <p style={{ fontSize: 16 }}>{description}</p>
          <br />
          <br />
          <label>Interview Experience</label>
          <p style={{ fontSize: 16 }}>{experience}</p>
          <br />
          <p style={{ fontSize: 16 }}>
            <b>Date:</b>{' '}
            <>
              {moment(dateAsked)
                .format('DD,MMM,YYYY')
                .toString()}
            </>
          </p>
          <br />
          {link ? (
            <p>
              <b>Link : </b>
              <a style={{ cursor: 'pointer' }} onClick={() => window.open(`${link}`, '_blank')}>
                {link}
              </a>
            </p>
          ) : null}

          {imageLinks ? (
            <>
              {' '}
              {imageLinks.map((imag, index) => {
                return <img style={styles.img} src={imag}></img>;
              })}
            </>
          ) : null}
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(CompanyQuestion);
