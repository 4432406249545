import { ALUMNILIST } from '../constants';

const initialState = {
  allAlumniList: [],
  allAlumniListCompanies: [],
  filteredallAlumniListCompanies: [],
  activePage: 0,
  activePageList: 0,
  perPage: 10,
  error: false,
  submitting: false,
};
const alumniListReducer = (alumniListState = initialState, action) => {
  switch (action.type) {
    case ALUMNILIST.CHANGEACTIVEALUMNILISTPAGE:
      return { ...alumniListState, activePage: action.payload, activePageList: 0 };
    case ALUMNILIST.CHANGEACTIVEALUMNILISTPERSONPAGE:
      return { ...alumniListState, activePage: 0, activePageList: action.payload };
    case ALUMNILIST.GETALUMNILISTSUCCESS:
      return {
        ...alumniListState,
        allAlumniList: [...action.payload],
        submitting: false,
      };
    case ALUMNILIST.SEARCHALUMNILISTCOMPANY:
      return {
        ...alumniListState,
        filteredallAlumniListCompanies: [...action.payload],
        submitting: false,
      };
    case ALUMNILIST.ADDALUMNISUCCESS:
      return {
        ...alumniListState,
        submitting: false,
      };

    case ALUMNILIST.GETALUMNILISTCOMPANIESSUCCESS:
      return {
        ...alumniListState,
        activePageList: 0,
        activePage: 0,
        allAlumniListCompanies: [...action.payload],
        submitting: false,
      };
    case ALUMNILIST.ALUMNILISTERROR:
      return { ...alumniListState, error: action.error, submitting: false };
    case ALUMNILIST.SUBMITTINGGETALUMNILIST:
      return { ...alumniListState, submitting: true };
    default:
      return alumniListState;
  }
};

export default alumniListReducer;
