import { call, put } from 'redux-saga/effects';
import {
  addCandidate,
  unsubscribeCandidate,
  getAllCandidates,
  getRecommendedCandidates,
  updateCandidate,
  updateCandidateProfile,
  recommendCandidate,
  getUpdatedUser,
} from '../../api';
import {
  addCandidateSuccess,
  getAllCandidatesSuccess,
  getRecommendedCandidatesSuccess,
  candidateError,
  submittingCandidateRequest,
  unsubscribeCandidateSuccess,
  updateCandidateSuccess,
  updateCandidateProfileSuccess,
  getUpdatedUserSuccess,
} from '../actions/index';

import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* addCandidateSaga(action) {
  try {
    yield put(submittingCandidateRequest());
    const { candidate, history } = action;
    candidate.schoolId = candidate.schoolId.value;
    candidate.companyId = candidate.companyId.value;
    const newCandidate = yield call(addCandidate, candidate);
    yield put(addCandidateSuccess(newCandidate.data));
    history.push('/candidates');
  } catch (error) {
    yield put(candidateError(error.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}

export function* getAllCandidatesSaga() {
  try {
    yield put(submittingCandidateRequest());
    const candidates = yield call(getAllCandidates);
    yield put(getAllCandidatesSuccess(candidates.data));
  } catch (error) {
    yield put(candidateError(error.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}

export function* getRecommendedCandidatesSaga() {
  try {
    yield put(submittingCandidateRequest());
    const candidates = yield call(getRecommendedCandidates);
    // console.log('I am a carr');
    console.log(candidates);
    yield put(getRecommendedCandidatesSuccess(candidates.data));
  } catch (error) {
    yield put(candidateError(error.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}

export function* unsubscribeCandidateSaga(action) {
  try {
    const { id } = action;
    yield call(unsubscribeCandidate, id);
    history.push('/unsubscribesuccess');
    yield put(unsubscribeCandidateSuccess());
  } catch (error) {
    yield put(candidateError(error.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}

export function* updateCandidateSaga(action) {
  try {
    yield put(submittingCandidateRequest());
    const { allCandidates, candidateInfo, history } = action;
    const { id, interviewerId } = candidateInfo;
    if (interviewerId) candidateInfo.interviewerId = candidateInfo.interviewerId.value;
    const updatedCandidate = yield call(updateCandidate, candidateInfo);
    const modifiedCandidates = allCandidates.map(candidate => {
      if (candidate._id === id) {
        candidate = updatedCandidate.data.updatedCandidate;
      }
      return candidate;
    });
    yield put(updateCandidateSuccess(modifiedCandidates));
    history.push('/candidates');
  } catch (error) {
    yield put(candidateError(error.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}

export function* updateCandidateProfileSaga(action) {
  try {
    const { candidateInfo } = action;
    yield put(submittingCandidateRequest());
    const updatedCandidate = yield call(updateCandidate, candidateInfo);
    history.push('/profileSuccess');
    // const { candidateInfo } = action;
    // if (candidateInfo.companiesToRecruit && candidateInfo.companiesToRecruit[0].value) {
    //   for (let i = 0; i < candidateInfo.companiesToRecruit.length; i++) {
    //     candidateInfo.companiesToRecruit[i] = candidateInfo.companiesToRecruit[i].value;
    //   }
    // }
    // let item = candidateInfo;
    // const formData = new FormData();
    // let keys = Object.keys(item);
    // keys.forEach(ele => {
    //   if (item[ele]) {
    //     formData.append(ele, item[ele]);
    //   }
    // });
    // const response = yield call(updateCandidateProfile, formData);
    // yield put(updateCandidateProfileSuccess({ response }));
    // const updatedUser = yield call(getUpdatedUser);
    // yield put(getUpdatedUserSuccess(updatedUser.data.user));
  } catch (error) {
    yield put(candidateError(error.response.data.message.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}

export function* recommendCandidateSaga(action) {
  const { allCandidates, candidateId, interviewerId, isRecommended } = action;
  try {
    yield put(submittingCandidateRequest());
    const candidateRecommended = yield call(
      recommendCandidate,
      candidateId,
      interviewerId,
      isRecommended,
    );
    const modifiedCandidates = allCandidates.map(candidate => {
      if (candidate._id === candidateId) {
        candidate = candidateRecommended.data.updatedCandidate;
      }
      return candidate;
    });
    yield put(updateCandidateSuccess(modifiedCandidates));
    history.push('/candidates');
  } catch (error) {
    yield put(candidateError(error.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}
