import { SALARY } from '../constants';

const initialState = {
  allSalaries: [],
  allSalaryCompanies: [],
  activePage: 0,
  activePageOfCompany: 0,
  perPage: 27,
  perPageSalaries: 8,
  error: false,
  submittingGetSalary: false,
  submittingAddSalary: false,
};
const salaryReducer = (salaryState = initialState, action) => {
  switch (action.type) {
    case SALARY.CHANGEACTIVESALARYPAGE:
      return { ...salaryState, activePage: action.payload, activePageOfCompany: 0 };
    case SALARY.CHANGEACTIVESALARYOFCOMPANYPAGE:
      return { ...salaryState, activePage: 0, activePageOfCompany: action.payload };
    case SALARY.GETSALARIESOFACOMPANYSUCCESS:
      return {
        ...salaryState,
        allSalaries: [...action.payload],
        submittingGetSalary: false,
      };
    case SALARY.ADDSALARYSUCCESS:
      return {
        ...salaryState,
        submittingAddSalary: false,
      };
    case SALARY.UPDATESALARY:
      return {
        ...salaryState,
        submittingAddSalary: true,
      };
    case SALARY.UPDATESALARYSUCCESS:
      return {
        ...salaryState,
        submittingAddSalary: false,
      };
    case SALARY.GETSALARYCOMPANIESSUCCESS:
      return {
        ...salaryState,
        activePageOfCompany: 0,
        activePage: 0,
        allSalaryCompanies: [...action.payload],
        submittingGetSalary: false,
      };
    case SALARY.SALARYERROR:
      return { ...salaryState, error: action.error, submitting: false };
    case SALARY.SUBMITTINGGETSALARY:
      return { ...salaryState, submittingGetSalary: true };
    default:
      return salaryState;
  }
};

export default salaryReducer;
