//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import ProblemsWithUrl from './ProblemsWithUrl';
import Sidebar from '../Sidebar/Sidebar';
import { components } from 'react-select';

type Props = {
  user: Object,
};

const initialAddFeedbackValues = {
  cohort: '',
};

class ProblemsWithUrlFormik extends Component<Props> {
  render() {
    return (
      <div className="mainBody">
        <Formik
          initialValues={initialAddFeedbackValues}
          render={props => <ProblemsWithUrl {...props} />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authState.user,
});
export default connect(mapStateToProps)(ProblemsWithUrlFormik);
