import { call, put } from 'redux-saga/effects';
import { getAllSchools, addSchool, updateSchool } from '../../api';
import {
  getAllSchoolsSuccess,
  schoolError,
  addSchoolSuccess,
  submittingSchoolRequest,
  toggleSchoolEditSuccess,
  updateSchoolSuccess,
} from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllSchoolsSaga() {
  try {
    yield put(submittingSchoolRequest());
    const schools = yield call(getAllSchools);
    schools.data.forEach(ele => {
      ele.isEditing = false;
    });
    yield put(getAllSchoolsSuccess(schools.data));
  } catch (error) {
    yield put(schoolError(error.toString()));
    yield delay(3000);
    yield put(schoolError(''));
  }
}

export function* addSchoolSaga(action) {
  try {
    yield put(submittingSchoolRequest());
    const { history, schoolObj } = action;
    const school = yield call(addSchool, schoolObj);
    yield put(addSchoolSuccess(school));
    history.push('/schools');
  } catch (error) {
    yield put(schoolError(error.toString()));
    yield delay(3000);
    yield put(schoolError(''));
  }
}
export function* toggleSchoolEditSaga(action) {
  const { id, allSchools } = action;
  const editStateSchools = allSchools.map(school => {
    if (school._id === id) {
      school = { ...school, isEditing: !school.isEditing };
    }
    return school;
  });
  yield put(toggleSchoolEditSuccess(editStateSchools));
}

export function* updateSchoolSaga(action) {
  try {
    yield put(submittingSchoolRequest());
    const { schoolInfo, allSchools } = action;
    const { id } = schoolInfo;
    const updatedSchool = yield call(updateSchool, schoolInfo);
    const modifiedSchools = allSchools.map(school => {
      if (school._id === id) {
        school = updatedSchool.data.school;
        school.isEditing = false;
      }
      return school;
    });
    yield put(updateSchoolSuccess(modifiedSchools));
  } catch (error) {
    yield put(schoolError(error.toString()));
    yield delay(3000);
    yield put(schoolError(''));
  }
}
