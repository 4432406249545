//@flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import Sidebar from '../../Sidebar/Sidebar';
import CandidateProfileForm from './CandidateProfileForm';

type Props = {
  history: Object,
  user: Object,
};
const CandidateProfileValidationSchema = Yup.object().shape({
  imageUrl: Yup.string().required('Required'),
  resumeUrl: Yup.string().required('Required'),
  workExperience: Yup.number()
    .typeError('Experience must be a number')
    .positive('Experience must be greater than zero'),
  // pastInternships: Yup.string().required('Required'),
  recruitingCompaniesFromS30: Yup.string().required('Required'),
  recruitingCompaniesNotFromS30: Yup.string().required('Required'),
});

const CandidateProfile = props => {
  const {
    _id,
    linkedInUrl,
    imageUrl,
    resumeUrl,
    workExperience,
    pastInternships,
    availableFrom,
  } = props.user.candidateId;
  const newProps = {
    id: _id,
    linkedInUrl,
    imageUrl,
    resumeUrl,
    workExperience,
    pastInternships,
    availableFrom,
  };
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        enableReinitialize={true}
        initialValues={newProps}
        render={props => <CandidateProfileForm {...props} />}
        validationSchema={CandidateProfileValidationSchema}
      />
    </div>
  );
};
const mapStateToProps = state => ({
  user: state.authState.user,
});
export default connect(
  mapStateToProps,
  {},
)(CandidateProfile);
