//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { addCompany } from '../../redux/actions';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  error: string,
  classes: Object,
  submitting: boolean,
  addCompany: Function,
  history: Object,
};

class AddCompany extends Component<Props> {
  form = ({ errors, values, handleChange }) => (
    <Form>
      <div className="formContainer">
        <h4>Add a New Company</h4>
        <TextField
          required
          value={values.name}
          onChange={handleChange}
          id="name"
          label="Name"
          placeholder="Facebook"
          margin="normal"
          inputProps={{
            style: { fontSize: 15 },
          }}
        />
        <button type="submit">Submit</button>
      </div>
    </Form>
  );

  render() {
    const { submitting, error, classes } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding Company...</p>}
        />
                    
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={(values, { resetForm }) => {
            const { addCompany, history } = this.props;
            addCompany(values, history);
            resetForm();
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Company Name is required'),
          })}
          component={this.form}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addCompany: (values, history) => dispatch(addCompany(values, history)),
});

const mapStateToProps = state => ({
  error: state.companyState.error,
  submitting: state.companyState.submitting,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddCompany),
);
