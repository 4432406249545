//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllProblemsWithUrl, handleProblemsWithUrlPageClick } from '../../redux/actions';
import Problem from './Problem';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
import SearchBar from '../SearchBar/SearchBar';
// import Autocomplete from '../Select/Autocomplete';
import { MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import './Problem.scss';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getAllProblemsWithUrl: Function,
  problemsWithUrl: Array<Object>,
  handleProblemsWithUrlPageClick: Function,
  activePage: number,
  perPage: number,
  handleChange: Function,
  error: string,
  values: Object,
  user: Object,
  classes: Object,
  filteredProblemsWithUrl: Array<Object>,
  submitting: boolean,
};
class ProblemsWithUrl extends Component<Props> {
  componentDidMount() {
    const { getAllProblemsWithUrl, values } = this.props;
    getAllProblemsWithUrl({ cohort: values.cohort._id });
  }
  submit() {
    const { getAllProblemsWithUrl, values } = this.props;
    getAllProblemsWithUrl({ cohort: values.cohort });
  }

  handleProblemsWithUrlPageClick = data => {
    const { handleProblemsWithUrlPageClick } = this.props;
    handleProblemsWithUrlPageClick(data.selected);
  };

  render() {
    const roles = localStorage.getItem('s30Roles') || {};
    var {
      problemsWithUrl,
      activePage,
      filteredProblemsWithUrl,
      perPage,
      submitting,
      error,
      values,
      handleChange,
      user,
      classes,
    } = this.props;
    var allCohortManagerCohorts;
    if (user.cohortManagerId) {
      allCohortManagerCohorts = user.cohortManagerId.cohortIds;
    }

    return (
      <div className="mainBody">
        <Sidebar />
        <div className="problem">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Name" type="problemWithUrlSearchByName" />
            <SearchBar placeHolder="Search by Technique" type="problemWithUrlSearchByTechnique" />
            <SearchBar placeHolder="Search by Company" type="problemWithUrlSearchByCompany" />
          </div>

          <div className="table">
            <br></br>
            <p>
              Videos will be posted on Sunday evenings. Till then refer to video links in slack
              channel. <strong>Click on name of problem to see solutions done in class</strong>
            </p>
            <h4 className="title">Solutions and Videos</h4>
            {roles.includes('CohortManager') && (
              <div style={{ flexDirection: 'row' }}>
                <TextField
                  id="cohortId"
                  variant="outlined"
                  select
                  label="Cohort"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  placeHolder="Choose Cohort"
                  value={values.cohort}
                  onChange={handleChange('cohort')}
                  margin="normal"
                >
                  {allCohortManagerCohorts.map(cohort => (
                    <MenuItem key={cohort._id} value={cohort._id} style={{ fontSize: 15 }}>
                      {cohort.name}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <Autocomplete
                  value={values.cohort}
                  handleChange={handleChange('cohort')}
                  label="Cohorts"
                  suggestions={user.cohortManagerId.cohortIds}
                /> */}
                <button
                  type="submit"
                  onClick={() => {
                    this.submit();
                  }}
                >
                  Get Problems
                </button>
              </div>
            )}
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Problems With Url...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />

            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnLarge">Name</div>
              <div className="columnMedium">Techniques</div>
              <div className="columnMedium">Companies</div>
              <div className="columnAction"> Video </div>
              {roles.includes('Admin') && <div className="columnAction"> Edit </div>}
              {roles.includes('CohortManager') && (
                <div className="columnAction"> Edit Video Url</div>
              )}
              {/* {roles.includes('Admin') && <div className="columnAction"> Delete </div>} */}
            </div>
            {filteredProblemsWithUrl.length > 0
              ? filteredProblemsWithUrl.map((problem, index) => {
                  const bottomIdx = activePage * perPage;
                  var problemIndex = problem.problemId.problemIndex;
                  if (problemIndex == null) {
                    problemIndex = problem.problemId.dsproblemIndex;
                  }
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Problem
                        key={problem.problemId._id}
                        index={problemIndex}
                        techniques={problem.problemId.techniques}
                        companyTags={problem.problemId.companyTags}
                        id={problem.problemId._id}
                        videoUrl={problem.videoUrl}
                        name={problem.problemId.name}
                      />
                    );
                  }
                  return null;
                })
              : problemsWithUrl.map((problem, index) => {
                  const bottomIdx = activePage * perPage;
                  var problemIndex = problem.problemId.problemIndex;
                  if (problemIndex == null) {
                    problemIndex = problem.problemId.dsproblemIndex;
                  }
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Problem
                        key={problem.problemId._id}
                        index={problemIndex}
                        techniques={problem.problemId.techniques}
                        id={problem.problemId._id}
                        companyTags={problem.problemId.companyTags}
                        problemWithUrlId={problem._id}
                        videoUrl={problem.videoUrl}
                        name={problem.problemId.name}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={problemsWithUrl.length / perPage}
            onPageChange={this.handleProblemsWithUrlPageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.problemWithUrlState.activePage,
  perPage: state.problemWithUrlState.perPage,
  problemsWithUrl: state.problemWithUrlState.allProblemsWithUrl,
  filteredProblemsWithUrl: state.problemWithUrlState.filteredProblemsWithUrl,
  error: state.problemWithUrlState.error,
  user: state.authState.user,
  submitting: state.problemWithUrlState.submitting,
});
const mapDispatchToProps = dispatch => ({
  getAllProblemsWithUrl: cohort => dispatch(getAllProblemsWithUrl(cohort)),
  handleProblemsWithUrlPageClick: data => dispatch(handleProblemsWithUrlPageClick(data)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProblemsWithUrl));
