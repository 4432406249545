import ROOT_URL from './config/baseUrl';
import React from 'react';
import { getUpdatedUser } from './redux/actions';
import openSocket from 'socket.io-client';
import { connect } from 'react-redux';
import { CLIENT_RENEG_LIMIT } from 'tls';
import { readFileSync } from 'fs';
import { notification } from './components/Notification/Notification';

const accessToken = localStorage.getItem('token') || '';
const Socket = openSocket(ROOT_URL, {
  query: { accessToken },
});

Socket.on('jobAdded', data => {
  const { Company, Position } = data;
  notification(`Job Added for ${Position} at ${Company}`);
});

export default Socket;
