//@flow
import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
type Props = {
  pageCount: number,
  onPageChange: Function,
};
const Custompaginate = (props: Props) => (
  <div>
    <ReactPaginate
      previousLabel="previous"
      nextLabel="next"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={props.pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={props.onPageChange}
      containerClassName="pagination"
      subContainerClassName="pages pagination"
      activeClassName="active"
    />
  </div>
);

Custompaginate.propTypes = {
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Custompaginate;
