//@flow
import React from 'react';

// components
import Features from '../Features/Features';
import './Landing.css';
import Chatwidget from '../ChatWidget/ChatWidget';
import Testimonials from '../Testimonial/Testimonials';
import TeamMembers from '../Team/TeamMembers';

import { Link } from 'react-router-dom';

const Landing = () => (
  <div className="Landing">
    <div className="Intro__content">
      <Chatwidget />
      <div className="Intro__content__summary">Prepare for Top Tech Interviews</div>
      {/* <h4>
        Life Time access to 180 hrs duration class videos, mock interviews and company referrals.
        60% coversion rate in FAANMGs. Best in industry.
      </h4> */}
      <b></b>
      <Link to="/apply">
        <button
          style={{
            marginTop: '20',
          }}
          type="submit"
        >
          Apply Now
        </button>
      </Link>
      <b></b>
    </div>
    <Features />
    <div className="Intro__content__timings">
      <p style={{ marginTop: '3rem' }}>
        <b>Course Duration SWE & Data Scie Cohort</b>: 17 Weeks
        {/* <br></br>
        <br></br>
        <b>Course Duration Data Science</b>: 17 Weeks */}
        <br></br>
        <br></br>
        <b>Course Duration System Design</b>: 6 Weeks
      </p>
      <p style={{ marginTop: '3rem' }}>
        For all tracks, Monday through Friday classes + Sunday mock interviews
      </p>
      <p style={{ margin: '1rem' }}>
        <b>Software Engineering Interview Prep cohort</b>: October 21, 2024
      </p>
      <p style={{ margin: '1rem' }}>
        <b>Data Science Interview Prep cohort</b>: December 9
      </p>
      <p style={{ margin: '1rem' }}>
        <b>System Design Interview Prep cohort</b>: Nov 4
      </p>
      {/* <p style={{ margin: '1rem' }}>
        <b>Projects cohort</b>: October 7, 2024
      </p> */}
      {/* <p style={{ margin: '1rem' }}>
        <b>Data Science/Engineer Interview Prep cohort</b>:
      </p> */}
    </div>
    <Testimonials />
    {/* <TeamMembers /> */}
    <h4 style={{ marginTop: '2rem' }}>
      Get into the right mindset for Tech Interviews with O(1) Book
      <img
        src={require('../../static/amazon.png')}
        alt="Youtube Icon"
        className="icon-small"
        style={{ marginLeft: '1rem' }}
        onClick={() => window.open(`https://www.amazon.com/dp/B0C9SC74SY`, '_blank')}
      />
    </h4>
  </div>
);

export default Landing;
