//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { addCohort, getAllCohortManagers } from '../../redux/actions';

// import DisplayFormikState from '../DisplayFormikState';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Field } from 'formik';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  allCohortManagers: Array<Object>,
  error: string,
  submitting: boolean,
  values: Object,
  errors: Object,
  classes: Object,
  handleChange: Function,
  resetForm: Function,
  addCohort: Function,
  getAllCohortManagers: Function,
  history: Object,
};

class AddCohortForm extends Component<Props> {
  componentDidMount() {
    const { getAllCohortManagers } = this.props;
    getAllCohortManagers();
  }

  render() {
    const {
      values,
      handleChange,
      errors,
      classes,
      allCohortManagers,
      addCohort,
      resetForm,
      submitting,
      error,
      history,
    } = this.props;
    return (
      <form
        className={classes.container}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          addCohort(values, history);
          resetForm();
        }}
      >
        <h4>Add a Cohort</h4>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Creating New Cohort...</p>}
        />
                    
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <TextField
          required
          variant="outlined"
          value={values.name}
          onChange={handleChange}
          id="name"
          label="Name"
          placeholder="April2021"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.name}
        />
        <label>
          <Field
            type="checkbox"
            name="isDataScienceCohort"
            value={values.isDataScienceCohort}
            onChange={handleChange('isDataScienceCohort')}
          />
          Data Science Cohort?
        </label>
        <label>
          <Field
            type="checkbox"
            name="isMorningCohort"
            value={values.isMorningCohort}
            onChange={handleChange('isMorningCohort')}
          />
          Morning Cohort?
        </label>
        <TextField
          style={styles.textField}
          variant="outlined"
          id="date"
          label="Start Date"
          value={values.startDate}
          type="date"
          onChange={handleChange('startDate')}
          InputLabelProps={{
            className: classes.resize,
            shrink: true,
          }}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
        />
        <TextField
          required
          id="cohortManagerId"
          variant="outlined"
          select
          label="CohortManager"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.cohortManagerId}
          onChange={handleChange('cohortManagerId')}
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.cohortManagerId}
        >
          {allCohortManagers.map(cohortManager => (
            <MenuItem key={cohortManager._id} value={cohortManager._id} style={{ fontSize: 15 }}>
              {cohortManager.userId.firstName}
            </MenuItem>
          ))}
        </TextField>
        <button type="submit">Submit</button>
        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  allCohortManagers: state.cohortManagerState.allCohortManagers,
  error: state.cohortState.error,
  submitting: state.cohortState.submitting,
});

const mapDispatchToProps = dispatch => ({
  addCohort: (values, history) => dispatch(addCohort(values, history)),
  getAllCohortManagers: () => dispatch(getAllCohortManagers()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddCohortForm));
