//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInterviewKit, handleInterviewKitQuestionPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import './InterviewKit.scss';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';

import moment from 'moment';

// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getInterviewKit: Function,
  companyQuestions: Array<Object>,
  handleInterviewKitQuestionPageClick: Function,
  activePageQues: number,
  perPageQues: number,
  error: string,
  classes: Object,
  submitting: boolean,
  recruiterName: String,
};
class companyQuestions extends Component<Props> {
  componentDidMount() {
    const { getInterviewKit } = this.props;
    const { id } = this.props.match.params;
    getInterviewKit(id);
  }
  updateProblem = (id, problemInfo) => {
    const apiurl = `${ROOT_URL}/api/interviewKitProblem/${id}`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .put(apiurl, problemInfo, headers)
      .then(() => {
        this.componentDidMount();
      })
      .catch(() => {
        console.log('error in adding frequency');
      });
  };
  handleInterviewKitQuestionPageClick = data => {
    const { handleInterviewKitQuestionPageClick } = this.props;
    handleInterviewKitQuestionPageClick(data.selected);
  };

  render() {
    const roles = localStorage.getItem('s30Roles') || {};
    const {
      companyQuestions,
      activePageQues,
      perPageQues,
      submitting,
      error,
      classes,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="interviewQues">
          <div className="table">
            <h4 className="title">Interview Kit</h4>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Company Questions...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Title</div>
              <div className="columnSmall">Frequency</div>
              <div className="columnSmall">Position</div>
              <div className="columnSmall">Round</div>
              <div className="columnSmall">Date</div>
              <div className="columnSmall">Added By</div>
              <div className="columnAction">UpVote</div>
              {roles.includes('Admin') && <div className="columnAction"> Edit </div>}
              {roles.includes('Admin') && <div className="columnSmall"> Recruiter </div>}
            </div>
            {companyQuestions.map((problem, index) => {
              const {
                _id,
                title,
                description,
                link,
                isQCCleared,
                imageLinks,
                dateAsked,
                frequency,
                position,
                round,
                userId,
                recruiterName,
              } = problem;
              const bottomIdx = activePageQues * perPageQues;
              const { id } = this.props.match.params;
              const topIdx = bottomIdx + perPageQues;
              if (index >= bottomIdx && index < topIdx) {
                return (
                  <div key={_id} className="tbody">
                    <div className="row">
                      <Link
                        className="row"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        to={{
                          pathname: `/kit/${id}/${_id}`,
                          state: {
                            index,
                            title,
                            imageLinks,
                            description,
                            link,
                            dateAsked,
                            frequency,
                            recruiterName,
                          },
                        }}
                      >
                        {' '}
                        <div className="columnSmall">{index + 1}</div>
                        <div className="columnMedium">{title}</div>
                        <div className="columnSmall">{frequency}</div>
                        <div className="columnSmall">{position} </div>
                        <div className="columnSmall">{round} </div>
                        <div className="columnSmall">
                          {' '}
                          <>
                            {moment(dateAsked)
                              .format('DD,MMM,YYYY')
                              .toString()}
                          </>
                        </div>
                        <div className="columnSmall">
                          {roles.includes('Admin') && (
                            <>
                              {userId.firstName} {userId.lastName}
                            </>
                          )}
                        </div>
                      </Link>
                      <div className="columnAction">
                        <button
                          type="submit"
                          onClick={e => {
                            e.preventDefault();
                            const newFreq = frequency + 1;
                            this.updateProblem(_id, { frequency: newFreq });
                          }}
                        >
                          Upvote
                        </button>
                      </div>
                      {/*{roles.includes('Admin') && <div className="columnAction"> Edit </div>}*/}
                      {roles.includes('Admin') && (
                        <div className="columnAction">
                          {!isQCCleared ? (
                            <button
                              type="submit"
                              onClick={e => {
                                e.preventDefault();
                                this.updateProblem(_id, { isQCCleared: true });
                              }}
                              style={{
                                backgroundColor: 'red',
                              }}
                            >
                              Approve
                            </button>
                          ) : null}
                        </div>
                      )}
                      {roles.includes('Admin') && recruiterName && <>{recruiterName}</>}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <Custompaginate
            pageCount={companyQuestions.length / perPageQues}
            onPageChange={this.handleInterviewKitQuestionPageClick}
          />
        </div>
      </div>
    );
  }
}
//some

const mapStateToProps = state => ({
  activePageQues: state.interviewKitState.activePageQues,
  perPageQues: state.interviewKitState.perPageQues,
  companyQuestions: state.interviewKitState.allInterviewKit,
  error: state.interviewKitState.error,
  submitting: state.interviewKitState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getInterviewKit, handleInterviewKitQuestionPageClick })(
    companyQuestions,
  ),
);
