import { call, put } from 'redux-saga/effects';
import history from '../../history';
import {
  getAllLeadershipInterviewFeedbacks,
  addLeadershipInterviewFeedback,
  updateLeadershipInterviewFeedbackById,
  deleteLeadershipInterviewFeedbackById,
} from '../../api';
import {
  getAllLeadershipInterviewFeedbacksSuccess,
  leadershipInterviewFeedbackError,
  addLeadershipInterviewFeedbackSuccess,
  updateLeadershipInterviewFeedbackSuccess,
  deleteLeadershipInterviewFeedbackSuccess,
  submittingGetLeadershipInterviewFeedbackRequest,
  submittingAddLeadershipInterviewFeedbackRequest,
  submittingUpdateLeadershipInterviewFeedbackRequest,
  submittingDeleteLeadershipInterviewFeedbackRequest,
} from '../actions/index';
// import Socket from '../../Socket';
const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllLeadershipInterviewFeedbacksSaga(action) {
  try {
    yield put(submittingGetLeadershipInterviewFeedbackRequest());
    const { role } = action;
    const interviewFeedbacks = yield call(getAllLeadershipInterviewFeedbacks);
    if (role === 'candidate') {
      yield put(
        getAllLeadershipInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsCandidate),
      );
    } else if (role === 'admin') {
      yield put(getAllLeadershipInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsAdmin));
    } else if (role === 'cm') {
      yield put(
        getAllLeadershipInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsCohortManager),
      );
    } else {
      yield put(
        getAllLeadershipInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsInterviewer),
      );
    }
  } catch (error) {
    yield put(leadershipInterviewFeedbackError(error.toString()));
    yield delay(3000);
    yield put(leadershipInterviewFeedbackError(''));
  }
}

export function* addLeadershipInterviewFeedbackSaga(action) {
  try {
    yield put(submittingAddLeadershipInterviewFeedbackRequest());
    const { feedback } = action;
    const interviewFeedback = yield call(addLeadershipInterviewFeedback, feedback);
    yield put(addLeadershipInterviewFeedbackSuccess(interviewFeedback.data));
    history.push('/leadershipfeedbacks');
  } catch (error) {
    yield put(leadershipInterviewFeedbackError(error.response.data.message));
    yield delay(3000);
    yield put(leadershipInterviewFeedbackError(''));
  }
}

export function* updateLeadershipInterviewFeedbackSaga(action) {
  try {
    yield put(submittingUpdateLeadershipInterviewFeedbackRequest());
    const { feedbackId, allInterviewFeedbacks, newInterviewFeedback } = action;
    let updatedInterviewFeedback = yield call(
      updateLeadershipInterviewFeedbackById,
      feedbackId,
      newInterviewFeedback,
    );
    updatedInterviewFeedback = updatedInterviewFeedback.data.feedback;
    const modifiedInterviewFeedbacks = allInterviewFeedbacks.map(feedback => {
      if (feedback._id === feedbackId) {
        feedback = updatedInterviewFeedback;
      }
      return feedback;
    });
    yield put(updateLeadershipInterviewFeedbackSuccess(modifiedInterviewFeedbacks));
    history.push('/leadershipfeedbacks');
  } catch (error) {
    yield put(leadershipInterviewFeedbackError(error.toString()));
    yield delay(3000);
    yield put(leadershipInterviewFeedbackError(''));
  }
}
export function* deleteLeadershipInterviewFeedbackSaga(action) {
  try {
    yield put(submittingDeleteLeadershipInterviewFeedbackRequest());
    const { feedbackId, feedbacks } = action;
    yield call(deleteLeadershipInterviewFeedbackById, feedbackId);
    const modifiedInterviewFeedbacks = feedbacks.filter(feedback => feedback.id !== feedbackId);
    yield put(deleteLeadershipInterviewFeedbackSuccess(modifiedInterviewFeedbacks));
    history.push('/leadershipfeedbacks');
  } catch (error) {
    yield put(leadershipInterviewFeedbackError(error.toString()));
    yield delay(3000);
    yield put(leadershipInterviewFeedbackError(''));
  }
}
