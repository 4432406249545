//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getAllInterviewers,
  getAllActiveInterviewers,
  handleInterviewerPageClick,
} from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Interviewer from './Interviewer';
import SearchBar from '../SearchBar/SearchBar';
import Sidebar from '../Sidebar/Sidebar';
import './interviewer.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  allInterviewers: Array<Object>,
  handleInterviewerPageClick: Function,
  getAllInterviewers: Function,
  getAllActiveInterviewers: Function,
  error: string,
  submitting: boolean,
  filteredInterviewers: Array<Object>,
  classes: Object,
};

class Interviewers extends Component<State, Props> {
  constructor() {
    super();
    this.state = {
      value: false,
    };
  }
  componentDidMount() {
    this.getInterviewers();
  }
  handleValue() {
    var newValue = !this.state.value;
    this.setState({ value: newValue });
    this.getInterviewers(newValue);
  }
  getInterviewers(value) {
    const { getAllInterviewers, getAllActiveInterviewers } = this.props;
    if (value) getAllInterviewers();
    else getAllActiveInterviewers();
  }
  handleInterviewerPageClick = data => {
    const { handleInterviewerPageClick } = this.props;
    handleInterviewerPageClick(data.selected);
  };

  render() {
    const {
      activePage,
      perPage,
      allInterviewers,
      filteredInterviewers,
      submitting,
      error,
      classes,
    } = this.props;
    const { value } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="interviewer">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Name" type="interviewerSearchByName" />
          </div>
          <div className="table">
            <h3 className="title">Interviewer</h3>
            <label>
              <input
                type="checkbox"
                value="Display all"
                onChange={e => {
                  this.handleValue();
                }}
              />
              View All Interviewers
            </label>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Name</div>
              <div className="columnMedium">Slack Id</div>
              <div className="columnSmall">isQC?</div>
              <div className="columnMedium">Date</div>
              <div className="columnSmall">Start Time</div>
              <div className="columnSmall">End Time</div>
              <div className="columnAction">Edit</div>
              <div className="columnAction">QC</div>
              <div className="columnAction">Leadership</div>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Submitting...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            {filteredInterviewers && filteredInterviewers.length > 0
              ? filteredInterviewers.map((interviewer, index) => {
                  const {
                    _id,
                    isQC,
                    availableTimeslot,
                    userId,
                    isActive,
                    isLeadershipInterviewer,
                  } = interviewer;
                  const { firstName, lastName, slackId } = userId;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Interviewer
                        key={_id}
                        id={_id}
                        index={index}
                        isActive={isActive}
                        slackId={slackId}
                        availableTimeslot={availableTimeslot}
                        isLeadershipInterviewer={isLeadershipInterviewer}
                        isQC={isQC}
                        name={`${firstName} ${lastName}`}
                      />
                    );
                  }
                  return null;
                })
              : allInterviewers.map((interviewer, index) => {
                  const {
                    _id,
                    isQC,
                    availableTimeslot,
                    userId,
                    isActive,
                    isLeadershipInterviewer,
                  } = interviewer;
                  const { firstName, lastName, slackId } = userId;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Interviewer
                        key={_id}
                        id={_id}
                        index={index}
                        isActive={isActive}
                        slackId={slackId}
                        isLeadershipInterviewer={isLeadershipInterviewer}
                        availableTimeslot={availableTimeslot}
                        isQC={isQC}
                        name={`${firstName} ${lastName}`}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={allInterviewers.length / perPage}
            onPageChange={this.handleInterviewerPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.interviewerState.activePage,
  perPage: state.interviewerState.perPage,
  allInterviewers: state.interviewerState.allInterviewers,
  filteredInterviewers: state.interviewerState.filteredInterviewers,
  error: state.interviewerState.error,
  submitting: state.interviewerState.submittingGetRequest,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getAllInterviewers,
    handleInterviewerPageClick,
    getAllActiveInterviewers,
  })(Interviewers),
);
