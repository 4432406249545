//@flow
import React from 'react';
import { Formik } from 'formik';
import AddFeedbackForm from './AddFeedbackForm';
import Sidebar from '../Sidebar/Sidebar';

type Props = {
  history: Object,
  name: string,
  id: string,
  isLeadership: Boolean,
};

const initialAddFeedbackValues = {
  candidateId: '',
  candidateName: '',
  bugTracking: 0,
  coding: 0,
  problemSolving: 0,
  communication: 0,
  thingsYouDidWell: '',
  thingsToImprove: '',
  isLeadership: false,
};

const AddFeedback = (props: Props) => {
  const { history } = props;
  const { id, name, isLeadership } = props.location.state;
  initialAddFeedbackValues.candidateId = id;
  initialAddFeedbackValues.candidateName = name;
  initialAddFeedbackValues.isLeadership = isLeadership;
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddFeedbackValues}
        render={props => <AddFeedbackForm history={history} {...props} />}
      />
    </div>
  );
};
export default AddFeedback;
