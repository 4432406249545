//@flow
import React from 'react';
// import './Candidate.css';

const ApplySuccess = () => (
  <div className="mainBody" style={{ justifyContent: 'center', marginTop: '3rem' }}>
    <h4>Application Successful. We will contact you within 24 hours</h4>
  </div>
);

export default ApplySuccess;
