import { call, put } from 'redux-saga/effects';
import { getAllRoles } from '../../api';
import { getAllRolesSuccess } from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllRolesSaga() {
  try {
    const roles = yield call(getAllRoles);
    roles.data.forEach(ele => {
      ele.isEditing = false;
    });
    yield put(getAllRolesSuccess(roles.data));
  } catch (error) {
    yield put(error);
    yield delay(3000);
    yield put(' ');
  }
}
