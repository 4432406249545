//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import './Payment.scss';

import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';
import history from '../../history';

type Props = {
  name: string,
  amountPayableNow: number,
  postJobPayableAmount: number,
  totalAmountPayable: number,
  dueDate: string,
  id: string,
};

const handleRazPayClick = async (e, amountToPay, billingLocation) => {
  e.preventDefault();
  try {
    const apiurl = `${ROOT_URL}/api/razorpay-admitfee-checkout-session`;
    let result = await axios.post(apiurl, { amountToPay, billingLocation });
    const { data } = result;
    const options = {
      key: process.env.RazorPay_Key_Id,
      name: 'S 30 Interview Prep Private Ltd',
      description: 'Interview Prep Batch Fee',
      amount: data.order.amount,
      order_id: data.order.id,
      handler: async response => {
        try {
          const values = {
            paymentId: response.razorpay_payment_id,
          };
          const url = `${ROOT_URL}/api/razorpayAdmitPayment`;
          const captureResponse = await axios.post(url, values);
          alert(`Payment successful. Please check your email for receipt.`);
          history.push('/');
        } catch (err) {
          alert('Transaction Failed, Ping Jaspinder/Sowmya on slack/LinkedIn');
        }
      },
      theme: {
        color: '#000005',
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  } catch (error) {
    console.log(error);
  }
};

const Payment = (props: Props) => {
  const amount = parseInt(props.amountPayableNow);
  return (
    // <div className="paymentPlan">
    //   <h5 className="header" style={{ justifyContent: 'center' }}>
    //     {props.name}
    //   </h5>
    //   <p style={{ marginTop: '10px' }}>Amount Payable Now: ${amount}</p>
    //   <p>Post Job: ${props.postJobPayableAmount}</p>
    //   <p>Total Amount: {props.totalAmountPayable}</p>
    //   <p>Due Date: {props.dueDate}</p>
    //   <RazorpayCheckout amount={amount} description={props.name} />
    // </div>
    <div className="paymentPlan">
      <h5 className="header" style={{ justifyContent: 'center' }}>
        {props.name}
      </h5>
      <p>Please use US/Canada based cards only.</p>
      <p style={{ marginTop: '10px' }}>Amount Payable: ${props.amountPayableNow}</p>
      <p>Post Job Payable Amount: ${props.postJobPayableAmount}</p>
      <p>Total Amount: ${props.totalAmountPayable}</p>
      <p>Due Date: {props.dueDate}</p>

      {/* <Link to={`/checkout/${props.id}`}>
        <button
          style={{
            marginTop: '20',
          }}
          type="submit"
        >
          Pay Securely
        </button>
      </Link> */}
      <button type="submit" onClick={e => handleRazPayClick(e, amount, 1)}>
        Pay Securely
      </button>
    </div>
  );
};

export default Payment;
