//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Rating from 'react-rating';
import { connect } from 'react-redux';
import { getUpdatedUser, addLeadershipInterviewFeedback } from '../../redux/actions';
import Autocomplete from '../Select/Autocomplete';
// import { getAllCandidates } from '../../redux/actions';
//Socket
// import Socket from 'socket.io-client';
// import DisplayFormikState from '../DisplayFormikState';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
    lineHeight: 1.5,
  },

  resizeName: {
    fontSize: 18,
  },
  textField: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 30,
    fontSize: 18,
    fontWeight: 500,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  values: Object,
  handleChange: Function,
  classes: Object,
  resetForm: Function,
  submitting: boolean,
  error: string,
  addLeadershipInterviewFeedback: Function,
};
class AddLeadershipFeedbackForm extends Component<Props> {

  render() {
    const {
      values,
      handleChange,
      classes,
      resetForm,
      submitting,
      error,
      addLeadershipInterviewFeedback,
    } = this.props;
    return (
      <div>
        <form
          style={styles.container}
          onSubmit={e => {
            e.preventDefault();
            addLeadershipInterviewFeedback(values);
            resetForm();
          }}
        >
          <div style={styles.miniContainer}>
            <h1>Add Leadership Feedback</h1>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Adding Feedback...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={values.candidateName}
              label="Candidate Name"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
                readOnly: true,
              }}
            />

            <br />
            <TextField
              multiline
              rows={6}
              style={styles.textFields}
              value={values.thingsYouDidWell}
              onChange={handleChange('thingsYouDidWell')}
              variant="outlined"
              id="thingsDidWell"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              label="Things that the student did well"
            />
            <br />
            <TextField
              multiline
              rows={6}
              style={styles.textFields}
              variant="outlined"
              onChange={handleChange('thingsToImprove')}
              value={values.thingsToImprove}
              id="thingsNeedImprove"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              label="Things that the student need to improve"
            />

            <br />
            <br />
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  // allCandidates: state.candidateState.allCandidates,
  submitting: state.leadershipFeedbackState.submittingPostRequest,
  error: state.leadershipFeedbackState.error,
});
const mapDispatchToProps = dispatch => ({
  addLeadershipInterviewFeedback: values => dispatch(addLeadershipInterviewFeedback(values)),
  getUpdatedUser: () => dispatch(getUpdatedUser()),
  // getAllCandidates: () => dispatch(getAllCandidates()),
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddLeadershipFeedbackForm));
