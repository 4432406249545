import { call, put } from 'redux-saga/effects';
import {
  getInterviewKitById,
  getInterviewKitCompanies,
  addInterviewQuestion,
  UpdateInterviewQuestion,
} from '../../api';
import {
  getInterviewKitCompaniesSuccess,
  getInterviewKitSuccess,
  interviewKitError,
  submittingInterviewKitRequest,
  addInterviewQuestionSuccess,
} from '../actions/index';
import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getInterviewKitCompaniesSaga() {
  try {
    yield put(submittingInterviewKitRequest());
    const companies = yield call(getInterviewKitCompanies);
    yield put(getInterviewKitCompaniesSuccess(companies.data));
  } catch (error) {
    yield put(interviewKitError(error.toString()));
    yield delay(3000);
    yield put(interviewKitError(''));
  }
}

export function* getInterviewKitByIdSaga(action) {
  try {
    yield put(submittingInterviewKitRequest());
    const { id } = action;
    const kit = yield call(getInterviewKitById, id);
    console.log(kit);
    yield put(getInterviewKitSuccess(kit.data));
  } catch (error) {
    yield put(interviewKitError(error.toString()));
    yield delay(3000);
    yield put(interviewKitError(''));
  }
}
export function* addInterviewQuestionSaga(action) {
  try {
    yield put(submittingInterviewKitRequest());
    const { question } = action;
    let item = question;
    const formData = new FormData();
    let keys = Object.keys(item);
    keys.forEach(ele => {
      if (item[ele]) {
        if (ele == 'files') {
          for (let i = 0; i < item[ele].length; i++) {
            formData.append(`images[${i}]`, item[ele]);
          }
        } else {
          formData.append(ele, item[ele]);
        }
      }
    });
    const response = yield call(addInterviewQuestion, formData);

    // if (candidateInfo.companiesToRecruit && candidateInfo.companiesToRecruit[0].value) {
    //   for (let i = 0; i < candidateInfo.companiesToRecruit.length; i++) {
    //     candidateInfo.companiesToRecruit[i] = candidateInfo.companiesToRecruit[i].value;
    //   }
    // }
    // let item = candidateInfo;
    // const formData = new FormData();
    // let keys = Object.keys(item);
    // keys.forEach(ele => {
    //   if (item[ele]) {
    //     formData.append(ele, item[ele]);
    //   }
    // });
    // const response = yield call(updateCandidateProfile, formData);
    // yield put(updateCandidateProfileSuccess({ response }));
    // const updatedUser = yield call(getUpdatedUser);
    // yield put(getUpdatedUserSuccess(updatedUser.data.user));

    // const interviewQuestion = yield call(addInterviewQuestion, question);
    // yield put(addInterviewQuestionSuccess(interviewQuestion));
    // history.push('/kit');
  } catch (error) {
    yield put(interviewKitError(error.toString()));
    yield delay(3000);
    yield put(interviewKitError(''));
  }
}

export function* updateInterviewQuestionSaga(action) {
  try {
    yield put(submittingInterviewKitRequest());
    const { question } = action;
    const interviewQuestion = yield call(UpdateInterviewQuestion, action.payload);
    yield put(addInterviewQuestionSuccess(interviewQuestion));
  } catch (error) {
    yield put(interviewKitError(error.toString()));
    yield delay(3000);
    yield put(interviewKitError(''));
  }
}
