//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import Rating from 'react-rating';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { updateProblemWithUrlById } from '../../redux/actions';
import { connect } from 'react-redux';
// import DisplayFormikState from '../DisplayFormikState';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import UpdateProblem from './UpdateProblem';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
    lineHeight: 1.5,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  submitting: boolean,
  history: Object,
  classes: Object,
  values: Object,
  handleChange: Function,
  resetForm: Function,
  error: string,
  updateProblemWithUrlById: Function,
  allProblems: Array<Object>,
};
class UpdateProblemUrlForm extends Component<Props> {
  render() {
    const {
      values,
      allProblems,
      updateProblemWithUrlById,
      handleChange,
      classes,
      submitting,
      error,
      resetForm,
    } = this.props;
    const { id, videoUrl } = values;
    // let resultString = values.result + '';
    return (
      <div className="mainBody">
        <form
          style={styles.container}
          onSubmit={e => {
            e.preventDefault();
            updateProblemWithUrlById(allProblems, id, videoUrl);
            resetForm();
          }}
        >
          <div style={styles.miniContainer}>
            <h1> Edit Problem URL </h1>
            <br />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Updating Url...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <br />
            <br />
            <label>Video Url</label>
            <TextField
              style={styles.textFields}
              value={values.videoUrl}
              placeholder={values.videoUrl}
              onChange={handleChange('videoUrl')}
              variant="outlined"
              id="videoUrl"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              label="Video Url"
            />
            <button type="submit">Submit</button>
            {/* {<DisplayFormikState {...values} />} */}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allProblems: state.problemWithUrlState.allProblemsWithUrl,
  error: state.problemWithUrlState.error,
  submitting: state.problemWithUrlState.submittingPutRequest,
});
const mapDispatchToProps = dispatch => ({
  updateProblemWithUrlById: (allProblems, id, videoUrl) =>
    dispatch(updateProblemWithUrlById(allProblems, id, videoUrl)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpdateProblemUrlForm),
);
