//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInterviewKitCompanies, handleInterviewKitPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import './InterviewKit.scss';
import history from '../../history';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';
import SearchBar from '../SearchBar/SearchBar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getInterviewKitCompanies: Function,
  companies: Array<Object>,
  handleInterviewKitPageClick: Function,
  activePage: number,
  perPage: number,
  error: string,
  classes: Object,
  submitting: boolean,
};
class CompanyList extends Component<Props> {
  componentDidMount() {
    const { getInterviewKitCompanies } = this.props;
    getInterviewKitCompanies();
  }

  handleInterviewKitPageClick = data => {
    const { handleInterviewKitPageClick } = this.props;
    handleInterviewKitPageClick(data.selected);
  };

  render() {
    const {
      companies,
      activePage,
      perPage,
      submitting,
      error,
      classes,
      filteredCompanies,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="interviewKit">
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting Companies...</p>}
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <div className="table" style={{ margin: '20px' }}>
            <button
              type="submit"
              onClick={e => {
                e.preventDefault();
                history.push('./addkit');
              }}
              style={{ width: '20%' }}
            >
              Add New Question
            </button>
            <div className="searchContainer">
              <SearchBar placeHolder="Search by Company" type="interviewKitCompanySearchByName" />
            </div>
            <h4 className="title">Interview Kit</h4>
            {/* <div className="thead">
              <div className="columnLarge">Name</div>
            </div> */}
            <div className="tbody">
              {filteredCompanies.length > 0
                ? filteredCompanies.map((company, index) => {
                    const { name, _id } = company;
                    const bottomIdx = activePage * perPage;
                    const topIdx = bottomIdx + perPage;
                    if (index >= bottomIdx && index < topIdx) {
                      return (
                        <div className="row">
                          <Link to={`/kit/${_id}`} key={_id} className="link">
                            <div className="columnLarge">{name}</div>
                          </Link>
                        </div>
                      );
                    }
                    return null;
                  })
                : companies.map((company, index) => {
                    const { name, _id } = company;
                    const bottomIdx = activePage * perPage;
                    const topIdx = bottomIdx + perPage;
                    if (index >= bottomIdx && index < topIdx) {
                      return (
                        <div className="row">
                          <Link to={`/kit/${_id}`} key={_id} className="link">
                            <div className="columnLarge">{name}</div>
                          </Link>
                        </div>
                      );
                    }
                    return null;
                  })}
            </div>
          </div>

          <br />
          <Custompaginate
            pageCount={companies.length / perPage}
            onPageChange={this.handleInterviewKitPageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.interviewKitState.activePage,
  perPage: state.interviewKitState.perPage,
  companies: state.interviewKitState.allInterviewKitCompanies,
  filteredCompanies: state.interviewKitState.filteredInterviewKitCompanies,
  error: state.interviewKitState.error,
  submitting: state.interviewKitState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getInterviewKitCompanies, handleInterviewKitPageClick })(CompanyList),
);
