//@flow
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../Sidebar/Sidebar';

import AddCandidateForm from './AddCandidateForm';

type Props = {
  history: Object,
};

const AddCandidateValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const initialAddCandidateValues = {
  firstName: '',
  lastName: '',
  email: '',
  cohortId: '',
  schoolId: '',
  companyId: '',
};

const AddCandidate = (props: Props) => {
  const { history } = props;
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddCandidateValues}
        render={props => <AddCandidateForm history={history} {...props} />}
        validationSchema={AddCandidateValidationSchema}
      />
    </div>
  );
};

export default AddCandidate;
