//@flow
import React from 'react';
import './Pricing.scss';

type Props = {
  name: string,
  amountPayableNow: string,
  postJobPayableAmount: string,
  totalAmountPayable: string,
  dueDate: string,
};
const Pricing = (props: Props) => {
  return (
    <div className="testimonial" style={{ margin: '3rem', width: 340, padding: '24px' }}>
      <h4>{props.name}</h4>
      <div style={{}}>
        <p style={{ marginTop: '4rem' }}>
          Total Amount: <b>{props.totalAmountPayable}</b>
        </p>
        <p>Installments: {props.dueDate}</p>
        <p>Discount: {props.discount}</p>
      </div>
    </div>
  );
};

export default Pricing;
