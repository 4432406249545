//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllInterviewFeedbacks, handleInterviewFeedbackPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Feedback from './Feedback';
import SearchBar from '../SearchBar/SearchBar';
import Sidebar from '../Sidebar/Sidebar';
import './feedback.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  feedbackAsInterviewer: Array<Object>,
  feedbacks: Array<Object>,
  handleInterviewFeedbackPageClick: Function,
  getAllInterviewFeedbacks: Function,
  error: string,
  submitting: boolean,
  location: Object,
  classes: Object,
  filteredFeedbacks: Array<Object>,
};

class Feedbacks extends Component<Props> {
  componentDidMount() {
    const roleToMount = this.props.location.search.substr(1);
    const { getAllInterviewFeedbacks } = this.props;
    getAllInterviewFeedbacks(roleToMount);
  }
  componentWillReceiveProps(nextProps) {
    const { getAllInterviewFeedbacks } = this.props;
    var roleToMount = nextProps.location.search.substr(1);
    if (roleToMount !== this.props.location.search.substr(1)) {
      getAllInterviewFeedbacks(roleToMount);
    }
  }

  handleInterviewFeedbackPageClick = data => {
    const { handleInterviewFeedbackPageClick } = this.props;
    handleInterviewFeedbackPageClick(data.selected);
  };

  render() {
    const {
      activePage,
      perPage,
      feedbacks,
      submitting,
      error,
      classes,
      //  feedbackAsInterviewer,
      filteredFeedbacks,
    } = this.props;
    var overallRating = 0;
    if (feedbacks.length > 4) {
      var size = feedbacks.length;
      overallRating =
        (feedbacks[size - 5].rating +
          feedbacks[size - 4].rating +
          feedbacks[size - 3].rating +
          feedbacks[size - 2].rating +
          feedbacks[size - 1].rating) /
        5;
    }
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="feedback">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Interviewer" type="feedbackSearchByInterviewer" />
            <SearchBar placeHolder="Search by Candidate" type="feedbackSearchByCandidate" />
          </div>
          <div className="table">
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Feedbacks</p>}
            />
                        
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <h4 className="title">Feedbacks</h4>
            <>
              Overall Rating for Referrals : {overallRating}. Till 5 interviews are not done overall
              rating remains 0
            </>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Candidate</div>
              <div className="columnMedium">Interviewer</div>
              <div className="columnMedium">Date</div>
              <div className="columnSmall">Rating</div>
              <div className="columnLarge">Result</div>
              <div className="columnAction">
                {' '}
                <></>
              </div>
            </div>
            {filteredFeedbacks.length > 0
              ? filteredFeedbacks.map((feedback, index) => {
                  const {
                    _id,
                    candidateId,
                    interviewerId,
                    createdAt,
                    rating,
                    interviewPerformanceAdded,
                  } = feedback;
                  let candidateName = '';
                  if (candidateId != null && candidateId.userId != null) {
                    candidateName =
                      candidateId.userId.firstName + ' ' + candidateId.userId.lastName;
                  }
                  const interviewerName =
                    interviewerId.userId.firstName + ' ' + interviewerId.userId.lastName;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Feedback
                        key={_id}
                        id={_id}
                        index={index}
                        candidateName={candidateName}
                        interviewerName={interviewerName}
                        interviewerId={interviewerId._id}
                        createdAt={createdAt}
                        rating={rating}
                        interviewPerformanceAdded={interviewPerformanceAdded}
                      />
                    );
                  }
                  return null;
                })
              : feedbacks.map((feedback, index) => {
                  const {
                    _id,
                    candidateId,
                    interviewerId,
                    createdAt,
                    rating,
                    interviewPerformanceAdded,
                  } = feedback;
                  let candidateName = '';
                  if (candidateId != null && candidateId.userId != null) {
                    candidateName =
                      candidateId.userId.firstName + ' ' + candidateId.userId.lastName;
                  }
                  const interviewerName =
                    interviewerId.userId.firstName + ' ' + interviewerId.userId.lastName;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Feedback
                        key={_id}
                        id={_id}
                        index={index}
                        candidateName={candidateName}
                        interviewerName={interviewerName}
                        interviewerId={interviewerId._id}
                        createdAt={createdAt}
                        rating={rating}
                        interviewPerformanceAdded={interviewPerformanceAdded}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={feedbacks.length / perPage}
            onPageChange={this.handleInterviewFeedbackPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.feedbackState.activePage,
  perPage: state.feedbackState.perPage,
  feedbacks: state.feedbackState.allFeedbacks,
  filteredFeedbacks: state.feedbackState.filteredFeedbacks,
  error: state.feedbackState.error,
  submitting: state.feedbackState.submittingGetRequest,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllInterviewFeedbacks, handleInterviewFeedbackPageClick })(
    Feedbacks,
  ),
);
