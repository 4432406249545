//@flow
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import ROOT_URL from '../../config/baseUrl';
import { updateProblemById } from '../../redux/actions';
import Texteditor from './RichTextEditor';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '60rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  submitting: boolean,
  allProblems: Array<Object>,
  history: Object,
  updateProblemById: Function,
  error: string,
  classes: Object,
  match: Object,
};

type State = {
  _id: string,
  name: string,
  problemStatement: string,
  difficultyLevel: string,
  techniques: string,
  problemIndex: string,
  companyTags: string,
  solution: string,
  sourceLink: string,
  gitLink: string,
};
class UpdateProblem extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      name: '',
      problemStatement: '',
      difficultyLevel: '',
      techniques: '',
      problemIndex: '',
      companyTags: '',
      solution: '',
      sourceLink: '',
      gitLink: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { id } = this.props.match.params;
    const apiurl = `${ROOT_URL}/api/problem/${id}`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(apiurl, headers)
      .then(res => {
        const {
          _id,
          name,
          problemStatement,
          difficultyLevel,
          techniques,
          problemIndex,
          companyTags,
          solution,
          sourceLink,
          gitLink,
        } = res.data;
        this.setState({
          _id,
          name,
          problemStatement,
          difficultyLevel,
          techniques,
          problemIndex,
          companyTags,
          solution,
          sourceLink,
          gitLink,
        });
      })
      .catch(() => {
        console.log('error in getting problems');
      });
  }

  handleInput = (e, type) => {
    e.preventDefault();
    this.setState({
      [type]: e.target.value,
    });
  };

  updateProblem = e => {
    e.preventDefault();
    const { allProblems } = this.props;
    const { history } = this.props;
    const probId = this.state._id;
    this.props.updateProblemById(allProblems, probId, this.state, history);
    this.setState({
      _id: '',
      name: '',
      problemStatement: '',
      difficultyLevel: '',
      techniques: '',
      problemIndex: '',
      companyTags: '',
      solution: '',
      sourceLink: '',
      gitLink: '',
    });
  };

  setProblemStatement = receivedHtml => {
    this.setState({
      problemStatement: receivedHtml,
    });
  };

  setProblemSolution = receivedHtml => {
    this.setState({
      solution: receivedHtml,
    });
  };

  render() {
    const { classes, submitting, error } = this.props;
    const {
      name,
      problemIndex,
      difficultyLevel,
      techniques,
      companyTags,
      sourceLink,
      gitLink,
      problemStatement,
      solution,
    } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <form
          style={{ overflow: 'auto' }}
          className={classes.container}
          onSubmit={this.updateProblem}
        >
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Updating Problem...</p>}
          />
                      
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <h4>Update Problem</h4>
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'name')}
            value={name || ''}
            id="name"
            label="Name"
            placeholder="Name"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            variant="outlined"
            onChange={e => this.handleInput(e, 'difficultyLevel')}
            value={difficultyLevel || ''}
            id="difficultyLevel"
            label="Difficulty Level"
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />
          <TextField
            variant="outlined"
            onChange={e => this.handleInput(e, 'problemIndex')}
            value={problemIndex || ''}
            id="problemIndex"
            label="Problem Index"
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />
          <TextField
            id="techniques"
            label="Techniques"
            onChange={e => this.handleInput(e, 'techniques')}
            value={techniques || ''}
            placeholder="Techniques"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            id="companyTags"
            label="Company Tags"
            onChange={e => this.handleInput(e, 'companyTags')}
            value={companyTags || ''}
            placeholder="Company Tags"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'gitLink')}
            value={gitLink || ''}
            id="gitLink"
            label="Git Link"
            placeholder="Git Link"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'sourceLink')}
            value={sourceLink || ''}
            id="sourceLink"
            label="Source Link"
            placeholder="Source Link"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <b>Current Statement</b>
          <p>{ReactHtmlParser(problemStatement)}</p>
          <Texteditor setProblemStatement={this.setProblemStatement} />
          <b>Current Solution</b>
          <p>{ReactHtmlParser(solution)}</p>
          <Texteditor setProblemSolution={this.setProblemSolution} />
          <button
            style={{
              marginTop: '20',
            }}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

// UpdateProblem.propTypes = {
//   match: PropTypes.shape.isRequired,
//   submitting: PropTypes.bool.isRequired,
//   allProblems: PropTypes.arrayOf.isRequired,
//   history: PropTypes.shape.isRequired,
//   updateProblemById: PropTypes.func.isRequired,
//   error: PropTypes.string.isRequired,
// };

const mapStateToProps = state => ({
  error: state.problemState.error,
  submitting: state.problemState.submitting,
  allProblems: state.problemState.allProblems,
});

export default withStyles(styles)(connect(mapStateToProps, { updateProblemById })(UpdateProblem));
