import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';

const ReferralSuccess = () => (
  <div className="mainBody">
    <Sidebar />
    <h4 style={{ margin: '8rem' }}>
      Invited Successfully. Please{' '}
      <Link to={'/referral'} className="link">
        <u>Click here</u>
      </Link>{' '}
      to invite more friends.
    </h4>
  </div>
);

export default ReferralSuccess;
