//@flow
import React from 'react';
// import './Candidate.css';

const ApplySuccess = () => (
  <div
    className="mainBody"
    style={{
      display: 'block',
      textAlign: 'left',
      padding: '3rem',
      marginTop: '3rem',
      overflow: 'auto',
    }}
  >
    <h1>PRIVACY STATEMENT</h1>
    <h3>WHAT DO WE DO WITH YOUR INFORMATION?</h3>
    <p>
      When you browse {'{ '}S30{' }'}, we also automatically receive your computer&rsquo;s internet
      protocol (IP) address in order to provide us with information that helps us learn about your
      browser and operating system. We also collect the personal information you give us such as
      your name, address and email address.
    </p>
    <h3>CONSENT</h3>
    <p>How do you get my consent?</p>
    <p>
      When you provide us with personal information to complete a transaction, we imply that you
      consent to our collecting it and using it for that specific reason only.
    </p>
    <p>
      If we ask for your personal information for a secondary reason, like marketing, we will either
      ask you directly for your expressed consent, or provide you with an opportunity to say no.
    </p>
    <p>How do I withdraw my consent?</p>
    <p>
      If after you opt-in, you change your mind, you may withdraw your consent for us to contact
      you, for the continued collection, use or disclosure of your information, at anytime, by
      contacting us at jaspinder@thes30.com.
    </p>
    <h3>DISCLOSURE</h3>
    <p>
      We may disclose your personal information if we are required by law to do so or if you violate
      our Terms of Service.
    </p>
    <h3>PAYMENT</h3>
    <p>
      All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI
      Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American
      Express and Discover.
    </p>
    <p>
      PCI-DSS requirements help ensure the secure handling of credit card information by our store
      and its service providers.
    </p>
    <h3>THIRD-PARTY SERVICES</h3>
    <p>
      In general, the third-party providers used by us will only collect, use and disclose your
      information to the extent necessary to allow them to perform the services they provide to us.
    </p>
    <p>
      However, certain third-party service providers, such as payment gateways and other payment
      transaction processors, have their own privacy policies in respect to the information we are
      required to provide to them for your purchase-related transactions.
    </p>
    <p>
      For these providers, we recommend that you read their privacy policies so you can understand
      the manner in which your personal information will be handled by these providers.
    </p>
    <p>
      In particular, remember that certain providers may be located in or have facilities that are
      located in a different jurisdiction than either you or us. So if you elect to proceed with a
      transaction that involves the services of a third-party service provider, then your
      information may become subject to the laws of the jurisdiction(s) in which that service
      provider or its facilities are located.
    </p>
    <p>
      As an example, if you are located in Canada and your transaction is processed by a payment
      gateway located in the India, then your personal information used in completing that
      transaction may be subject to disclosure under Indian legislation.
    </p>
    <h3>SECURITY</h3>
    <p>
      To protect your personal information, we take reasonable precautions and follow industry best
      practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered
      or destroyed.
    </p>
    <p>
      If you provide us with your credit card information, the information is encrypted using secure
      socket layer technology (SSL) and stored with a AES-256 encryption. Although no method of
      transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS
      requirements and implement additional generally accepted industry standards.
    </p>
    <h3>AGE OF CONSENT</h3>
    <p>
      By using this site, you represent that you are at least the age of majority in your state or
      province of residence, or that you are the age of majority in your state or province of
      residence and you have given us your consent to allow any of your minor dependents to use this
      site.
    </p>
    <h3>CHANGES TO THIS PRIVACY POLICY</h3>
    <p>
      We reserve the right to modify this privacy policy at any time, so please review it
      frequently. Changes and clarifications will take effect immediately upon their posting on the
      website. If we make material changes to this policy, we will notify you here that it has been
      updated, so that you are aware of what information we collect, how we use it, and under what
      circumstances, if any, we use and/or disclose it.
    </p>
    <h3>QUESTIONS AND CONTACT INFORMATION</h3>
    <p>
      If you would like to: access, correct, amend or delete any personal information we have about
      you, register a complaint, or simply want more information contact our Privacy Compliance
      Officer at jaspinder@thes30.com.
    </p>
  </div>
);

export default ApplySuccess;
