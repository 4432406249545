import { call, put } from 'redux-saga/effects';
import { getAllProblems, addProblem, updateProblemById, deleteProblemById } from '../../api';
import {
  getAllProblemsSuccess,
  problemError,
  addProblemSuccess,
  updateProblemSuccess,
  deleteProblemSuccess,
  submittingProblemRequest,
} from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllProblemsSaga() {
  try {
    yield put(submittingProblemRequest());
    const problems = yield call(getAllProblems);
    yield put(getAllProblemsSuccess(problems.data));
  } catch (error) {
    yield put(problemError(error.toString()));
    yield delay(3000);
    yield put(problemError(''));
  }
}

export function* addProblemSaga(action) {
  try {
    yield put(submittingProblemRequest());
    const { history, problemObj } = action;
    const problem = yield call(addProblem, problemObj);
    yield put(addProblemSuccess(problem));
    history.push('/problems');
  } catch (error) {
    yield put(problemError(error.toString()));
    yield delay(3000);
    yield put(problemError(''));
  }
}

export function* updateProblemSaga(action) {
  try {
    yield put(submittingProblemRequest());
    const { probId, allProblems, newProblem, history } = action;

    const updatedProblem = yield call(updateProblemById, probId, newProblem);
    const modifiedProblems = allProblems.filter(prob => {
      if (prob.id === probId) {
        return updatedProblem;
      }
      return prob;
    });
    yield put(updateProblemSuccess(modifiedProblems));
    history.push('/problems');
  } catch (error) {
    yield put(problemError(error.toString()));
    yield delay(3000);
    yield put(problemError(''));
  }
}

export function* deleteProblemSaga(action) {
  try {
    yield put(submittingProblemRequest());
    const { probId, allProblems } = action;
    yield call(deleteProblemById, probId);
    const modifiedProblems = allProblems.filter(prob => prob._id !== probId);
    yield put(deleteProblemSuccess(modifiedProblems));
  } catch (error) {
    yield put(problemError(error.toString()));
    yield delay(3000);
    yield put(problemError(''));
  }
}
