//@flow
import React from 'react';
// import './Candidate.css';

const Unsubscribesuccess = () => (
  <div className="Applicationsuccess__Container">
    <h4>Unsubscribe Successful</h4>
  </div>
);

export default Unsubscribesuccess;
