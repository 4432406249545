//@flow
import React from 'react';
// import './Candidate.css';

const CandidateProfileUpdatedSuccess = () => (
  <div className="mainBody" style={{ justifyContent: 'center', marginTop: '3rem' }}>
    <h4>
      Profile Updated Successfully. You will get a request for resume when a referee selects your
      profile
    </h4>
  </div>
);

export default CandidateProfileUpdatedSuccess;
