//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import Interview from './Interview';
import './candidate.scss';
// $FlowFixMe.
import moment from 'moment';

//Socket
// import Socket from 'socket.io-client';
const styles = {
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};
type Props = { user: Object, getUpdatedUser: Function };
class CandidateLeadershipDashboard extends Component<Props> {
  render() {
    const { user } = this.props;
    let timeslot = {};
    let userId = {};
    if (
      user.candidateId != undefined &&
      user.candidateId.assignedLeadershipInterviewer != null &&
      user.candidateId.isLeadershipScheduled
    ) {
      timeslot = user.candidateId.assignedLeadershipInterviewer.timeslot;
      userId = user.candidateId.assignedLeadershipInterviewer.interviewerId.userId;
    }
    return (
      <div className="interviewer">
        <div className="table">
          <h3 className="title">Upcoming Leadership Interview Schedule</h3>
          {user.candidateId != undefined &&
            user.candidateId.assignedLeadershipInterviewer != null &&
            user.candidateId.isLeadershipScheduled ? (
              <>
                <div className="thead">
                  <div className="columnLarge">Interviewer Name</div>
                  <div className="columnMedium">Slack Id</div>
                  <div className="columnMedium">Date</div>
                  <div className="columnMedium">Start Time</div>
                  <div className="columnMedium">End Time</div>
                </div>
                <Interview
                  interviewerName={userId.firstName + ' ' + userId.lastName}
                  interviewerSlackId={userId.slackId}
                  date={moment(timeslot.startTime)
                    .format('ddd MMM D, YYYY')
                    .toString()}
                  startTime={moment(timeslot.startTime)
                    .format('hh : mm A')
                    .toString()}
                  endTime={moment(timeslot.endTime)
                    .format('hh : mm A')
                    .toString()}
                />
              </>
            ) : (
              <h4>No Interviews Pending</h4>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
});

export default withStyles(styles)(connect(mapStateToProps)(CandidateLeadershipDashboard));
