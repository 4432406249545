//@flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import Sidebar from '../Sidebar/Sidebar';
import { connect } from 'react-redux';
import UpdateCandidateForm from './UpdateCandidateForm';

type Props = {
  history: Object,
  candidates: Array<Object>,
  match: Object,
};

const initialUpdateCandidateValues = {
  id: '',
  //Todo Laddi
  cohortId: '',
  interviewerId: '',
  newCohortId: '',
};
class UpdateCandidate extends Component<Props> {
  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { id } = this.props.match.params;
    const { candidates } = this.props;
    const candidateToUpdate = candidates.filter(candidate => candidate._id === id)[0];
    initialUpdateCandidateValues.id = id;

    //Todo Laddi
    let allCohortIds = candidateToUpdate.allCohortIds;
    initialUpdateCandidateValues.cohortId = allCohortIds[allCohortIds.length - 1]._id;
  }

  render() {
    const { history } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <Formik
          initialValues={initialUpdateCandidateValues}
          render={props => <UpdateCandidateForm history={history} {...props} />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidates: state.candidateState.allCandidates,
});

export default connect(mapStateToProps)(UpdateCandidate);
