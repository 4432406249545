import { call, put } from 'redux-saga/effects';
import history from '../../history';
import {
  getAllInterviewFeedbacks,
  addInterviewFeedback,
  updateInterviewFeedbackById,
  deleteInterviewFeedbackById,
} from '../../api';
import {
  getAllInterviewFeedbacksSuccess,
  interviewFeedbackError,
  addInterviewFeedbackSuccess,
  updateInterviewFeedbackSuccess,
  deleteInterviewFeedbackSuccess,
  submittingGetInterviewFeedbackRequest,
  submittingAddInterviewFeedbackRequest,
  submittingUpdateInterviewFeedbackRequest,
  submittingDeleteInterviewFeedbackRequest,
} from '../actions/index';
import Socket from '../../Socket';
const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllInterviewFeedbacksSaga(action) {
  try {
    yield put(submittingGetInterviewFeedbackRequest());
    const { role } = action;
    const interviewFeedbacks = yield call(getAllInterviewFeedbacks);
    if (role === 'candidate') {
      yield put(getAllInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsCandidate));
    } else if (role === 'admin') {
      yield put(getAllInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsAdmin));
    } else if (role === 'cm') {
      yield put(getAllInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsCohortManager));
    } else {
      yield put(getAllInterviewFeedbacksSuccess(interviewFeedbacks.data.feedbackAsInterviewer));
    }
  } catch (error) {
    yield put(interviewFeedbackError(error.toString()));
    yield delay(3000);
    yield put(interviewFeedbackError(''));
  }
}

export function* addInterviewFeedbackSaga(action) {
  try {
    yield put(submittingAddInterviewFeedbackRequest());
    const { feedback } = action;
    // feedback.candidateId = feedback.candidateId.value;
    const interviewFeedback = yield call(addInterviewFeedback, feedback);
    yield put(addInterviewFeedbackSuccess(interviewFeedback.data));
    Socket.emit('feedbackAdded');
    history.push('/dashboard');
  } catch (error) {
    yield put(interviewFeedbackError(error.response.data.message));
    yield delay(3000);
    yield put(interviewFeedbackError(''));
  }
}

export function* updateInterviewFeedbackSaga(action) {
  try {
    yield put(submittingUpdateInterviewFeedbackRequest());
    const { feedbackId, allInterviewFeedbacks, newInterviewFeedback } = action;
    let updatedInterviewFeedback = yield call(
      updateInterviewFeedbackById,
      feedbackId,
      newInterviewFeedback,
    );
    updatedInterviewFeedback = updatedInterviewFeedback.data.feedback;
    const modifiedInterviewFeedbacks = allInterviewFeedbacks.map(feedback => {
      if (feedback._id === feedbackId) {
        feedback = updatedInterviewFeedback;
      }
      return feedback;
    });
    yield put(updateInterviewFeedbackSuccess(modifiedInterviewFeedbacks));
    history.push('/feedbacks');
  } catch (error) {
    yield put(interviewFeedbackError(error.toString()));
    yield delay(3000);
    yield put(interviewFeedbackError(''));
  }
}
export function* deleteInterviewFeedbackSaga(action) {
  try {
    yield put(submittingDeleteInterviewFeedbackRequest());
    const { feedbackId, feedbacks } = action;
    yield call(deleteInterviewFeedbackById, feedbackId);
    const modifiedInterviewFeedbacks = feedbacks.filter(feedback => feedback.id !== feedbackId);
    yield put(deleteInterviewFeedbackSuccess(modifiedInterviewFeedbacks));
    history.push('/feedbacks');
  } catch (error) {
    yield put(interviewFeedbackError(error.toString()));
    yield delay(3000);
    yield put(interviewFeedbackError(''));
  }
}
