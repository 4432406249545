//@flow
import React from 'react';
// import './Candidate.css';

const ForgotPasswordSuccess = () => (
  <div className="mainBody" style={{ justifyContent: 'center', marginTop: '3rem' }}>
    <h4>Check your email to reset password</h4>
  </div>
);

export default ForgotPasswordSuccess;
