//@flow
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AddAlumniForm from './AddAlumniForm';
import Sidebar from '../Sidebar/Sidebar';
import history from '../../history';

const AddAlumniValidationSchema = Yup.object().shape({
  Name: Yup.string().required('Required'),
  LinkedInProfile: Yup.string().required('Required'),
  Company: Yup.string().required('Required'),
});
const initialAddAlumniValues = {
  Name: '',
  LinkedInProfile: '',
  Company: '',
  Email: '',
  Title: '',
  PhoneNumber: '',
  WantsCC: false,
};

const AddAlumni = (props: Props) => {
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddAlumniValues}
        render={props => <AddAlumniForm history={history} {...props} />}
        validationSchema={AddAlumniValidationSchema}
      />
    </div>
  );
};
//s
export default AddAlumni;
