//@flow
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ReactMarkdown from 'react-markdown';

const markdown1 = `
# Negotiations 
> Congrats on getting your first offer.

- Its a race against time to get other offers now. The competing offers provide best negotition leverage. 
- Delay the process of signing the offer letter with current company.
- Expedite the recruiting process with other companies.
`;
const markdown2 = `
## Tips 
> Once hiring decision is made by hiring manager, then for the first time you have an upper hand in hiring process. 

> HRs are under tremendous pressure to close the candidate within permissible **range** of salary. 
- Benchmark the offers. Use [{S30}'s salary data range here]().
- Always negotiate.
  1. I don't have much experience. I think this offer is what they will be giving to all new engineers. **Shut up and negotiate**.
  2. What if they find a better candidate than me. **Shut up and negotiate**.
  3. The first offer is always on lowest number. Don't be impressed just because you had been a financially struggling student. Understand your value and **Shut up and negotiate**.
  4. I don't think I performed really well in interview, still they have recruited me. I should be thankful. **Shut up and negotiate**. 
  5. Negotiations are not always about money. Your colleague will know about your salary package (despite confidentiatlity everyone mostly knows about your salary). You team colleagues will take you for granted if you are a *highly agreeable* engineer. So just **Shut up and negotiate**.
  6. Every recruiter wants you to negotiate, to be sure that you are a high value candidate. So just **Shut up and negotiate**.
- Stay away from phone calls, get the things in written in emails. There are two advantages of this.
  1. HRs are highly experienced at verbal negotiations. No matter how clever you think you are, HRs are better than you.
  2. You will have written records.
- How to stay away from phone calls. Tell the HRs that you are busy wrapping up tech interviews with other companies and will be highly thankful if we discuss things through email. **And no they won't withdraw your offer just because you are interviewing with others. Infact, it makes you more desirable.**
- Never say yes or no to the numbers told by HRs. Always tell them that you are highly thankful about the prospect of working with her team but have to run numbers with my family.
- When you receive an offer from competitor company. Tell immediately via email to the recruiter that you have a very strong offer from XYZ company (please don't specify numbers, its unethical to tell numbers of a company to competitor).
- Recruiters will mostly make a better offer. Mostly, more signing bonus and relocation expense. Signing bonus and relocation expenses are low hanging fruits for recruiters to close the candiates. Politely say that you will run the numbers with family and friends and fall back to you.
- Please fall back to competitive offer company and negotiate better. Then email the recruiter of parent company, that you have better competing offer and the current offer is not particularly exciting as far as city (where you will work) expenses are concerned.
- After recruiters have given you a fair signing bonus, relocation expenses and stock options. Tell the recruiter that you are more interested in basic salary. Mostly at this stage recruiter will say that its tough to increase basic salary because I will have to run through finance approval process. Thats not true as they are given a basic salary range as well.
- Once she makes an above average offer including better base salary than salary data range, you can sign the offer. Never ever negotiate after signing the offer. Its highly unethical to negotiate afterwards.
`;
const NegotiationTips = () => (
  <div className="mainBody">
    <Sidebar />
    <div className="getInterviews">
      <ReactMarkdown source={markdown1}></ReactMarkdown>
      <h4>Draft delaying signing of offer letter</h4>
      <article>
        <p>Dear [Recruiter&apos;s Name]</p>
        <p>
          I am thrilled to have received the offer of internship/full time from [Name of the
          Company]. I had a great learning experience during the whole interview process and am
          highly excited to join the team [Name of the Company].
        </p>
        <p>
          [Recruiter&apos;s Name], I want to be respectful to other recruiting companies that I am
          currently in process with and wish to complete my recruiting process with them. Kindly
          extend my deadline to sign the offer letter by three weeks.
        </p>
        <p>Thanks</p>
        <p>[Your Name]</p>
      </article>
      <h4>Draft expedite the offer from other companies</h4>
      <article>
        <p>Dear [Recruiter&apos;s Name]</p>
        <p>
          Quick Update: I have received a strong offer from Amazon and I&apos;m in the process of
          closing my recruitment process.&nbsp;
        </p>
        <p>
          I had a great time interviewing with your Team Google so far. I am highly excited about
          further exploring my fit with the Team Google Cloud and complete my recruiting process on
          priority.&nbsp;
        </p>
        <p>Request you to expedite my recruiting process with your team.</p>
        <p>Thanks&nbsp;</p>
        <p>[Your Name]</p>
      </article>
      <h4>Draft to respectfully deny the offer from smaller company that you accepted earlier</h4>
      <article>
        <p>Dear [Recruiter&apos;s Name]</p>
        <p>
          I had a great learning experience during the whole interview process and I am highly
          excited to have received an offer from team[Name of the Company].
        </p>
        <p>
          [Recruiter&apos;s Name], I have received an offer from Google today. For my long term
          success as a software engineer, it is imperative that I take the offer from Google. I
          surely would love to work with you in future as a senior software engineer.
        </p>
        <p>Thanks</p>
        <p>[Your Name]</p>
      </article>
      <ReactMarkdown source={markdown2}></ReactMarkdown>
    </div>
  </div>
);

export default NegotiationTips;
