import { PRICINGS } from '../constants';

const initialState = {
  allPricings: [],
  error: false,
};
const pricingReducer = (pricingState = initialState, action) => {
  switch (action.type) {
    case PRICINGS.GETPRICINGSSUCCESS:
      return { ...pricingState, allPricings: [...action.payload] };
    case PRICINGS.PRICINGERROR:
      return { ...pricingState, error: action.error };
    default:
      return pricingState;
  }
};
export default pricingReducer;
