import { ROLES } from '../constants';

const initialState = {
  allRoles: [],
};
const roleReducer = (roleState = initialState, action) => {
  switch (action.type) {
    case ROLES.GETROLESSUCCESS:
      return { ...roleState, allRoles: [...action.payload] };
    default:
      return roleState;
  }
};
export default roleReducer;
