//@flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllSchools, handleSchoolPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import School from './School';
import Sidebar from '../Sidebar/Sidebar';
import './Schools.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  schools: Array<Object>,
  handleSchoolPageClick: Function,
  getAllSchools: Function,
  error: string,
  submitting: boolean,
  classes: Object,
};

class Schools extends Component<Props> {
  componentDidMount() {
    const { getAllSchools } = this.props;
    getAllSchools();
  }

  handleSchoolPageClick = data => {
    const { handleSchoolPageClick } = this.props;
    handleSchoolPageClick(data.selected);
  };

  render() {
    const { activePage, perPage, schools, submitting, error, classes } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="school">
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting Schools...</p>}
          />
                      
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <div className="table">
            <h3 className="title">All Schools</h3>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnLarge">Name</div>
              <div className="columnAction">Edit</div>
            </div>
            {schools.map((school, index) => {
              const bottomIdx = activePage * perPage;
              const topIdx = bottomIdx + perPage;
              if (index >= bottomIdx && index < topIdx) {
                return (
                  <School
                    key={school._id}
                    id={school._id}
                    index={index + 1}
                    name={school.name}
                    isEditing={school.isEditing}
                  />
                );
              }
              return null;
            })}
          </div>
          <Custompaginate
            pageCount={schools.length / perPage}
            onPageChange={this.handleSchoolPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.schoolState.activePage,
  perPage: state.schoolState.perPage,
  schools: state.schoolState.allSchools,
  error: state.schoolState.error,
  submitting: state.schoolState.submitting,
});

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      { getAllSchools, handleSchoolPageClick },
    )(Schools),
  ),
);
