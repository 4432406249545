//@flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ResetPasswordSuccess extends Component<{}> {
  componentDidMount() {
    localStorage.removeItem('token');
  }
  render() {
    return (
      <div className="mainBody" style={{ justifyContent: 'center', marginTop: '3rem' }}>
        <h4>
          Password Changed. Please{' '}
          <Link to={'/signin'} className="link">
            <u>Click here</u>
          </Link>{' '}
          to sigin again.
        </h4>
      </div>
    );
  }
}

export default ResetPasswordSuccess;
