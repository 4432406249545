import { JOBOPENING } from '../constants';

const initialState = {
  allJobOpenings: [],
  recentJobOpenings: [],
  filteredJobOpenings: [],
  activePage: 0,
  perPage: 10,
  error: false,
  submitting: false,
};
const jobOpeningReducer = (jobOpeningState = initialState, action) => {
  switch (action.type) {
    case JOBOPENING.CHANGEACTIVEJOBOPENINGPAGE:
      return { ...jobOpeningState, activePage: action.payload };
    case JOBOPENING.GETJOBOPENINGSUCCESS:
      return {
        ...jobOpeningState,
        allJobOpenings: [...action.payload],
        submitting: false,
      };
    case JOBOPENING.GETRECENTJOBOPENINGSUCCESS:
      return {
        ...jobOpeningState,
        recentJobOpenings: [...action.payload],
        submitting: false,
      };
    case JOBOPENING.ADDJOBOPENINGSUCCESS:
      return {
        ...jobOpeningState,
        submitting: false,
      };
    case JOBOPENING.SEARCHJOBOPENING:
      return { ...jobOpeningState, filteredJobOpenings: [...action.payload], submitting: false };
    case JOBOPENING.JOBOPENINGERROR:
      return { ...jobOpeningState, error: action.error, submitting: false };
    case JOBOPENING.SUBMITTINGJOBOPENING:
      return { ...jobOpeningState, submitting: true };
    default:
      return jobOpeningState;
  }
};

export default jobOpeningReducer;
