//@flow
import React from 'react';
// import './Candidate.css';

const Refund = () => (
  <div
    className="mainBody"
    style={{
      display: 'block',
      textAlign: 'left',
      padding: '3rem',
      marginTop: '3rem',
      overflow: 'auto',
    }}
  >
    <p>
      <strong>Cacellation Policy</strong>
    </p>
    <p>
      <br></br>
      <br></br>
      You can cancel your admit anytime till end of first week of your cohort classes. Students are
      refunded 100% of the fee (no questions asked)
    </p>
    <p>
      <strong>Refund Policy</strong>
    </p>
    <p>
      <br></br>
      <br></br>
      Kindly note that your admit belongs to current cohort classes and mock interviews only. We
      will not be able to give you access to subsequent cohort classes or mock interviews. Once you
      request refund, we take 48 hours to review your request. If your refund request is approved,
      the amount is refunded to the customer through the same mode as that of payment.The refund
      once processed gets credited in duration of 5 to 6 business days.
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        <p>
          a. A student who makes the initial payment and cancels before the first week of class.
        </p>
        <p>
          b. A student who request a withdrawal from the course within week 1 and week 8 of class
          receives a prorated refund of tuition if applicable. See table below.
        </p>
        <p>
          c. Student who are removed from S30 due to violations of the Code of Conduct will receive
          a prorated refund of tuition if applicable.
        </p>
        <p>Refund table</p>
        <table border="1">
          <tr>
            <th>If termination occurs </th>
            <th> S30 may keep </th>
            <th> Student Refund</th>
          </tr>
          <tr>
            <td>Prior to or during the First week </td>
            <td>0% </td>
            <td>100%</td>
          </tr>
          <tr>
            <td>After First Week</td>
            <td>100%</td>
            <td> 0%</td>
          </tr>
          {/* <tr>
            <td>During the Second month </td>
            <td> 75% </td>
            <td>25%</td>
          </tr>
          <tr>
            <td>After the Second month</td>
            <td> 100% </td> <td> 0%</td>
          </tr> */}
        </table>
      </span>
    </p>
  </div>
);

export default Refund;
