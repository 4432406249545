//@flow

export const AUTH = {
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  HYDRATEAUTHSTATE: 'HYDRATEAUTHSTATE',
  GETUSER: 'GETUSER',
  GETUPDATEDUSER: 'GETUPDATEDUSER',
  GETUPDATEDUSERSUCCESS: 'GETUPDATEDUSERSUCCESS',
  SIGNIN: 'SIGNIN',
  SIGNINSUCCESS: 'SIGNINSUCCESS',
  SIGNOUT: 'SIGNOUT',
  SIGNOUTSUCCESS: 'SIGNOUTSUCCESS',
  SIGNUP: 'SIGNUP',
  SIGNUPSUCCESS: 'SIGNUPSUCCESS',
  FORGOTPASSWORD: 'FORGOTPASSWORD',
  FORGOTPASSWORDSUCCESS: 'FORGOTPASSWORDSUCCESS',
  RESETPASSWORD: 'RESETPASSWORD',
  RESETPASSWORDSUCCESS: 'RESETPASSWORDSUCCESS',
  SUBMITTINGSIGNINREQUEST: 'SUBMITTINGSIGNINREQUEST',
  SUBMITTINGSIGNOUTREQUEST: 'SUBMITTINGSIGNOUTREQUEST',
  SUBMITTINGSIGNUPREQUEST: 'SUBMITTINGSIGNUPREQUEST',
  SUBMITTINGFORGOTPASSWORDREQUEST: 'SUBMITTINGFORGOTPASSWORDREQUEST',
  SUBMITTINGRESETPASSWORDREQUEST: 'SUBMITTINGRESETPASSWORDREQUEST',
};

export const COHORTS = {
  ADDCOHORT: 'ADDCOHORT',
  ADDCOHORTSUCCESS: 'ADDCOHORTSUCCESS',
  COHORTERROR: 'COHORTERROR',
  CHANGEACTIVECOHORTPAGE: 'CHANGEACTIVECOHORTPAGE',
  GETCOHORTS: 'GETCOHORTS',
  GETCOHORTSSUCCESS: 'GETCOHORTSSUCCESS',
  SUBMITTING_COHORT: 'SUBMITTING_COHORT',
  UPDATECOHORTBYID: 'UPDATECOHORTBYID',
  UPDATECOHORTSUCCESS: 'UPDATECOHORTSUCCESS',
};

export const CANDIDATES = {
  ADDCANDIDATE: 'ADDCANDIDATE',
  ADDCANDIDATESUCCESS: 'ADDCANDIDATESUCCESS',
  CHANGEACTIVECANDIDATEPAGE: 'CHANGEACTIVECANDIDATEPAGE',
  CANDIDATEERROR: 'CANDIDATEERROR',
  GETCANDIDATES: 'GETCANDIDATES',
  GETCANDIDATESSUCCESS: 'GETCANDIDATESSUCCESS',
  GETRECOMMENDEDCANDIDATES: 'GETRECOMMENDEDCANDIDATES',
  GETRECOMMENDEDCANDIDATESSUCCESS: 'GETRECOMMENDEDCANDIDATESSUCCESS',
  SUBMITTING_CANDIDATE: 'SUBMITTING_CANDIDATE',
  SEARCHCANDIDATE: 'SEARCHCANDIDATE',
  UPDATECANDIDATE: 'UPDATECANDIDATE',
  UPDATECANDIDATESUCCESS: 'UPDATECANDIDATESUCCESS',
  UPDATECANDIDATEPROFILE: 'UPDATECANDIDATEPROFILE',
  UPDATECANDIDATEPROFILESUCCESS: 'UPDATECANDIDATEPROFILESUCCESS',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  UNSUBSCRIBESUCCESS: 'UNSUBSCRIBESUCCESS',
  RECOMMENDCANDIDATE: 'RECOMMENDCANDIDATE',
  RECOMMENDCANDIDATESUCCESS: 'RECOMMENDCANDIDATESUCCESS',
  UPDATERECOMMENDEDCANDIDATE: 'UPDATERECOMMENDEDCANDIDATE',
  UPDATERECOMMENDEDCANDIDATESUCCESS: 'UPDATERECOMMENDEDCANDIDATESUCCESS',
};

export const SCHOOLS = {
  ADDSCHOOL: 'ADDSCHOOL',
  ADDSCHOOLSUCCESS: 'ADDSCHOOLSUCCESS',
  CHANGEACTIVESCHOOLPAGE: 'CHANGEACTIVESCHOOLPAGE',
  GETSCHOOLS: 'GETSCHOOLS',
  GETSCHOOLSSUCCESS: 'GETSCHOOLSSUCCESS',
  SCHOOLERROR: 'SCHOOLERROR',
  SUBMITTING_SCHOOL: 'SUBMITTING_SCHOOL',
  UPDATESCHOOL: 'UPDATESCHOOL',
  TOGGLESCHOOLEDIT: 'TOGGLESCHOOLEDIT',
  TOGGLESCHOOLEDITSUCCESS: 'TOGGLESCHOOLEDITSUCCESS',
  UPDATESCHOOLSUCCESS: 'UPDATESCHOOLSUCCESS',
};

export const SALARY = {
  GETSALARIESOFACOMPANY: 'GETSALARIESOFACOMPANY',
  GETSALARIESOFACOMPANYSUCCESS: 'GETSALARIESOFACOMPANYSUCCESS',
  GETSALARYCOMPANIES: 'GETSALARYCOMPANIES',
  GETSALARYCOMPANIESSUCCESS: 'GETSALARYCOMPANIESSUCCESS',
  SUBMITTINGGETSALARY: 'SUBMITTINGGETSALARY',
  SUBMITTINGADDSALARY: 'SUBMITTINGADDSALARY',
  CHANGEACTIVESALARYCOMPANYPAGE: 'CHANGEACTIVESALARYCOMPANYPAGE',
  CHANGEACTIVESALARIESOFACOMPANYPAGE: 'CHANGEACTIVESALARIESOFACOMPANYPAGE',
  SALARYERROR: 'SALARYERROR',
  ADDSALARY: 'ADDSALARY',
  ADDSALARYSUCCESS: 'ADDSALARYSUCCESS',
  UPDATESALARY: 'UPDATESALARY',
};

export const ALUMNILIST = {
  GETALUMNILIST: 'GETALUMNILIST',
  GETALUMNILISTSUCCESS: 'GETALUMNILISTSUCCESS',
  GETALUMNILISTCOMPANIES: 'GETALUMNILISTCOMPANIES',
  GETALUMNILISTCOMPANIESSUCCESS: 'GETALUMNILISTCOMPANIESSUCCESS',
  SUBMITTINGGETALUMNILIST: 'SUBMITTINGGETALUMNILIST',
  CHANGEACTIVEALUMNILISTPAGE: 'CHANGEACTIVEALUMNILISTPAGE',
  CHANGEACTIVEALUMNILISTPERSONPAGE: 'CHANGEACTIVEALUMNILISTPERSONPAGE',
  ALUMNILISTERROR: 'ALUMNILISTERROR',
  ADDALUMNI: 'ADDALUMNI',
  ADDALUMNISUCCESS: 'ADDALUMNISUCCESS',
  SEARCHALUMNILISTCOMPANY: 'SEARCHALUMNILISTCOMPANY',
};
export const JOBOPENING = {
  GETJOBOPENING: 'GETJOBOPENING',
  GETJOBOPENINGSUCCESS: 'GETJOBOPENINGSUCCESS',
  GETRECENTJOBOPENINGSUCCESS: 'GETRECENTJOBOPENINGSUCCESS',
  SUBMITTINGJOBOPENING: 'SUBMITTINGJOBOPENING',
  CHANGEACTIVEJOBOPENINGPAGE: 'CHANGEACTIVEJOBOPENINGPAGE',
  JOBOPENINGERROR: 'JOBOPENINGERROR',
  ADDJOBOPENING: 'ADDJOBOPENING',
  SEARCHJOBOPENING: 'SEARCHJOBOPENING',
  ADDJOBOPENINGSUCCESS: 'ADDJOBOPENINGSUCCESS',
};

export const ROLES = {
  GETROLES: 'GETROLES',
  GETROLESSUCCESS: 'GETROLESSUCCESS',
};

export const COMPANIES = {
  ADDCOMPANY: 'ADDCOMPANY',
  ADDCOMPANYSUCCESS: 'ADDCOMPANYSUCCESS',
  CHANGEACTIVECOMPANYPAGE: 'CHANGEACTIVECOMPANYPAGE',
  COMPANYERROR: 'COMPANYERROR',
  GETCOMPANIES: 'GETCOMPANIES',
  GETCOMPANIESSUCCESS: 'GETCOMPANIESSUCCESS',
  SUBMITTING_COMPANY: 'SUBMITTING_COMPANY',
  TOGGLECOMPANYEDIT: 'TOGGLECOMPANYEDIT',
  TOGGLECOMPANYEDITSUCCESS: 'TOGGLECOMPANYEDITSUCCESS',
  UPDATECOMPANY: 'UPDATECOMPANY',
  UPDATECOMPANYSUCCESS: 'UPDATECOMPANYSUCCESS',
  SEARCHCOMPANY: 'SEARCHCOMPANY',
};

export const PROBLEMS = {
  ADDPROBLEM: 'ADDPROBLEM',
  ADDPROBLEMSUCCESS: 'ADDPROBLEMSUCCESS',
  CHANGEACTIVEPROBLEMPAGE: 'CHANGEACTIVEPROBLEMPAGE',
  DELETEPROBLEMBYID: 'DELETEPROBLEMBYID',
  DELETEPROBLEMSUCCESS: 'DELETEPROBLEMSUCCESS',
  GETPROBLEMS: 'GETPROBLEMS',
  SEARCHPROBLEM: 'SEARCHPROBLEM',
  GETPROBLEMSSUCCESS: 'GETPROBLEMSSUCCESS',
  PROBLEMERROR: 'PROBLEMERROR',
  SUBMITTING_PROBLEM: 'SUBMITTING_PROBLEM',
  UPDATEPROBLEMBYID: 'UPDATEPROBLEMBYID',
  UPDATEPROBLEMSUCCESS: 'UPDATEPROBLEMSUCCESS',
};

export const DSPROBLEMS = {
  ADDDSPROBLEM: 'ADDDSPROBLEM',
  ADDDSPROBLEMSUCCESS: 'ADDDSPROBLEMSUCCESS',
  CHANGEACTIVEDSPROBLEMPAGE: 'CHANGEACTIVEDSPROBLEMPAGE',
  DELETEDSPROBLEMBYID: 'DELETEDSPROBLEMBYID',
  DELETEDSPROBLEMSUCCESS: 'DELETEDSPROBLEMSUCCESS',
  GETDSPROBLEMS: 'GETDSPROBLEMS',
  SEARCHDSPROBLEM: 'SEARCHDSPROBLEM',
  GETDSPROBLEMSSUCCESS: 'GETDSPROBLEMSSUCCESS',
  DSPROBLEMERROR: 'DSPROBLEMERROR',
  SUBMITTING_DSPROBLEM: 'SUBMITTING_DSPROBLEM',
  UPDATEDSPROBLEMBYID: 'UPDATEDSPROBLEMBYID',
  UPDATEDSPROBLEMSUCCESS: 'UPDATEDSPROBLEMSUCCESS',
};

export const PROBLEMSWITHURL = {
  ADDPROBLEMWITHURL: 'ADDPROBLEMWITHURL',
  ADDPROBLEMWITHURLSUCCESS: 'ADDPROBLEMWITHURLSUCCESS',
  UPDATEPROBLEMWITHURLBYID: 'UPDATEPROBLEMWITHURLBYID',
  UPDATEPROBLEMWITHURLBYIDSUCCESS: 'UPDATEPROBLEMWITHURLBYIDSUCCESS',
  CHANGEACTIVEPROBLEMSWITHURLPAGE: 'CHANGEACTIVEPROBLEMSWITHURLPAGE',
  GETPROBLEMSWITHURL: 'GETPROBLEMSWITHURL',
  SEARCHPROBLEMSWITHURL: 'SEARCHPROBLEMSWITHURL',
  SUBMITTING_PROBLEMWITHURL: 'SUBMITTING_PROBLEMWITHURL',
  GETPROBLEMSWITHURLSUCCESS: 'GETPROBLEMSWITHURLSUCCESS',
  PROBLEMWITHURLERROR: 'PROBLEMWITHURLMERROR',
};

export const INTERVIEWER = {
  GETINTERVIEWERS: 'GETINTERVIEWERS',
  GETINTERVIEWERSSUCCESS: 'GETINTERVIEWERSSUCCESS',
  GETACTIVEINTERVIEWERS: 'GETACTIVEINTERVIEWERS',
  GETACTIVEINTERVIEWERSSUCCESS: 'GETACTIVEINTERVIEWERSSUCCESS',
  UPDATEINTERVIEWER: 'UPDATEINTERVIEWER',
  SEARCHINTERVIEWER: 'SEARCHINTERVIEWER',
  UPDATEINTERVIEWERSUCCESS: 'UPDATEINTERVIEWERSUCCESS',
  INTERVIEWERERROR: 'INTERVIEWERERROR',
  CHANGEACTIVEINTERVIEWERPAGE: 'CHANGEACTIVEINTERVIEWERPAGE',
  SUBMITTINGGETINTERVIEWER: 'SUBMITTINGGETINTERVIEWER',
  SUBMITTINGUPDATEINTERVIEWER: 'SUBMITTINGUPDATEINTERVIEWER',
};

export const COHORTMANAGER = {
  GETCOHORTMANAGERS: 'GETCOHORTMANAGERS',
  GETCOHORTMANAGERSSUCCESS: 'GETCOHORTMANAGERSSUCCESS',
  COHORTMANAGERERROR: 'COHORTMANAGERERROR',
};

// export const RECOMMENDEDCANDIDATE = {
//   UPDATERECOMMENDEDCANDIDATE: 'UPDATERECOMMENDEDCANDIDATE',
//   UPDATERECOMMENDEDCANDIDATECANDIDATESUCCESS: 'UPDATERECOMMENDEDCANDIDATECANDIDATESUCCESS',
//   RECOMMENDEDCANDIDATEERROR: 'RECOMMENDEDCANDIDATEERROR',
//   SUBMITTING_RECOMMENDEDCANDIDATE: 'SUBMITTING_RECOMMENDEDCANDIDATE',
// };

export const APPLICANTS = {
  ADDAPPLICANT: 'ADDAPPLICANT',
  ADDAPPLICANTSUCCESS: 'ADDAPPLICANTSUCCESS',
  CHANGEACTIVEAPPLICANTPAGE: 'CHANGEACTIVEAPPLICANTPAGE',
  GETAPPLICANTS: 'GETAPPLICANTS',
  GETAPPLICANTSSUCCESS: 'GETAPPLICANTSSUCCESS',
  APPLICANTERROR: 'APPLICANTERROR',
  SUBMITTING_APPLICANT: 'SUBMITTING_APPLICANT',
  SEARCHAPPLICANT: 'SEARCHAPPLICANT',
};

export const PRICINGS = {
  GETPRICINGS: 'GETPRICINGS',
  GETPRICINGSSUCCESS: 'GETPRICINGSSUCCESS',
  PRICINGERROR: 'PRICINGERROR',
};

export const INTERVIEWPERFORMANCES = {
  ADDINTERVIEWPERFORMANCE: 'ADDINTERVIEWPERFORMANCE',
  ADDINTERVIEWPERFORMANCESUCCESS: 'ADDINTERVIEWPERFORMANCESUCCESS',
  GETINTERVIEWPERFORMANCES: 'GETINTERVIEWPERFORMANCES',
  GETINTERVIEWPERFORMANCESSUCCESS: 'GETINTERVIEWPERFORMANCESSUCCESS',
  DELETEINTERVIEWPERFORMANCEBYID: 'DELETEINTERVIEWPERFORMANCEBYID',
  DELETEINTERVIEWPERFORMANCESUCCESS: 'DELETEINTERVIEWPERFORMANCESUCCESS',
  UPDATEINTERVIEWPERFORMANCEBYID: 'UPDATEINTERVIEWPERFORMANCEBYID',
  UPDATEINTERVIEWPERFORMANCESUCCESS: 'UPDATEINTERVIEWPERFORMANCESUCCESS',
  INTERVIEWPERFORMANCEERROR: 'INTERVIEWPERFORMANCEERROR',
  SEARCHINTERVIEWPERFORMANCE: 'SEARCHINTERVIEWPERFORMANCE',
  CHANGEACTIVEINTERVIEWPERFORMANCEPAGE: 'CHANGEACTIVEINTERVIEWPERFORMANCEPAGE',
  SUBMITTINGADDINTERVIEWPERFORMANCE: 'SUBMITTINGADDINTERVIEWPERFORMANCE',
  SUBMITTINGGETINTERVIEWPERFORMANCE: 'SUBMITTINGGETINTERVIEWPERFORMANCE',
  SUBMITTINGUPDATEINTERVIEWPERFORMANCE: 'SUBMITTINGUPDATEINTERVIEWPERFORMANCE',
  SUBMITTINGDELETEINTERVIEWPERFORMANCE: 'SUBMITTINGDELETEINTERVIEWPERFORMANCE',
};

export const INTERVIEWFEEDBACKS = {
  ADDINTERVIEWFEEDBACK: 'ADDINTERVIEWFEEDBACK',
  ADDINTERVIEWFEEDBACKSUCCESS: 'ADDINTERVIEWFEEDBACKSUCCESS',
  GETINTERVIEWFEEDBACKS: 'GETINTERVIEWFEEDBACKS',
  GETINTERVIEWFEEDBACKSSUCCESS: 'GETINTERVIEWFEEDBACKSSUCCESS',
  DELETEINTERVIEWFEEDBACKBYID: 'DELETEINTERVIEWFEEDBACKBYID',
  DELETEINTERVIEWFEEDBACKSUCCESS: 'DELETEINTERVIEWFEEDBACKSUCCESS',
  UPDATEINTERVIEWFEEDBACKBYID: 'UPDATEINTERVIEWFEEDBACKBYID',
  UPDATEINTERVIEWFEEDBACKSUCCESS: 'UPDATEINTERVIEWFEEDBACKSUCCESS',
  INTERVIEWFEEDBACKERROR: 'INTERVIEWFEEDBACKERROR',
  SEARCHINTERVIEWFEEDBACK: 'SEARCHINTERVIEWFEEDBACK',
  CHANGEACTIVEINTERVIEWFEEDBACKPAGE: 'CHANGEACTIVEINTERVIEWFEEDBACKPAGE',
  SUBMITTINGADDINTERVIEWFEEDBACK: 'SUBMITTINGADDINTERVIEWFEEDBACK',
  SUBMITTINGGETINTERVIEWFEEDBACK: 'SUBMITTINGGETINTERVIEWFEEDBACK',
  SUBMITTINGUPDATEINTERVIEWFEEDBACK: 'SUBMITTINGUPDATEINTERVIEWFEEDBACK',
  SUBMITTINGDELETEINTERVIEWFEEDBACK: 'SUBMITTINGDELETEINTERVIEWFEEDBACK',
};

export const LEADERSHIPINTERVIEWFEEDBACKS = {
  ADDLEADERSHIPINTERVIEWFEEDBACK: 'ADDLEADERSHIPINTERVIEWFEEDBACK',
  ADDLEADERSHIPINTERVIEWFEEDBACKSUCCESS: 'ADDLEADERSHIPINTERVIEWFEEDBACKSUCCESS',
  GETLEADERSHIPINTERVIEWFEEDBACKS: 'GETLEADERSHIPINTERVIEWFEEDBACKS',
  GETLEADERSHIPINTERVIEWFEEDBACKSSUCCESS: 'GETLEADERSHIPINTERVIEWFEEDBACKSSUCCESS',
  DELETELEADERSHIPINTERVIEWFEEDBACKBYID: 'DELETELEADERSHIPINTERVIEWFEEDBACKBYID',
  DELETELEADERSHIPINTERVIEWFEEDBACKSUCCESS: 'DELETELEADERSHIPINTERVIEWFEEDBACKSUCCESS',
  UPDATELEADERSHIPINTERVIEWFEEDBACKBYID: 'UPDATELEADERSHIPINTERVIEWFEEDBACKBYID',
  UPDATELEADERSHIPINTERVIEWFEEDBACKSUCCESS: 'UPDATELEADERSHIPINTERVIEWFEEDBACKSUCCESS',
  LEADERSHIPINTERVIEWFEEDBACKERROR: 'LEADERSHIPINTERVIEWFEEDBACKERROR',
  SEARCHLEADERSHIPINTERVIEWFEEDBACK: 'SEARCHLEADERSHIPINTERVIEWFEEDBACK',
  CHANGEACTIVELEADERSHIPINTERVIEWFEEDBACKPAGE: 'CHANGEACTIVELEADERSHIPINTERVIEWFEEDBACKPAGE',
  SUBMITTINGADDLEADERSHIPINTERVIEWFEEDBACK: 'SUBMITTINGADDLEADERSHIPINTERVIEWFEEDBACK',
  SUBMITTINGGETLEADERSHIPINTERVIEWFEEDBACK: 'SUBMITTINGGETLEADERSHIPINTERVIEWFEEDBACK',
  SUBMITTINGUPDATELEADERSHIPINTERVIEWFEEDBACK: 'SUBMITTINGUPDATELEADERSHIPINTERVIEWFEEDBACK',
  SUBMITTINGDELETELEADERSHIPINTERVIEWFEEDBACK: 'SUBMITTINGDELETELEADERSHIPINTERVIEWFEEDBACK',
};

export const PRSTATUS = {
  CHANGEACTIVEPRSTATUSPAGE: 'CHANGEACTIVEPRSTATUSPAGE',
  GETPRSTATUS: 'GETPRSTATUS',
  GETPRSTATUSSUCCESS: 'GETPRSTATUSSUCCESS',
  SUBMITTING_PRSTATUS: 'SUBMITTING_PRSTATUS',
  PRSTATUSERROR: 'PRSTATUSERROR',
};

export const INTERVIEWKIT = {
  GETINTERVIEWKIT: 'GETINTERVIEWKIT',
  GETINTERVIEWKITSUCCESS: 'GETINTERVIEWKITSUCCESS',
  GETINTERVIEWKITCOMPANIES: 'GETINTERVIEWKITCOMPANIES',
  GETINTERVIEWKITCOMPANIESSUCCESS: 'GETINTERVIEWKITCOMPANIESSUCCESS',
  SUBMITTINGGETINTERVIEWKIT: 'SUBMITTINGGETINTERVIEWKIT',
  CHANGEACTIVEINTERVIEWKITPAGE: 'CHANGEACTIVEINTERVIEWKITPAGE',
  CHANGEACTIVEINTERVIEWKITQUESTIONPAGE: 'CHANGEACTIVEINTERVIEWKITQUESTIONPAGE',
  INTERVIEWKITERROR: 'INTERVIEWKITERROR',
  ADDINTERVIEWQUESTION: 'ADDINTERVIEWQUESTION',
  ADDINTERVIEWQUESTIONSUCCESS: 'ADDINTERVIEWQUESTIONSUCCESS',
  UPDATEINTERVIEWQUESTION: 'UPDATEINTERVIEWQUESTION',
  SEARCHINTERVIEWKITCOMPANY: 'SEARCHINTERVIEWKITCOMPANY',
};
