import { PROBLEMSWITHURL } from '../constants';

const initialState = {
  allProblemsWithUrl: [],
  filteredProblemsWithUrl: [],
  activePage: 0,
  perPage: 8,
  error: false,
  submitting: false,
};
const problemWithUrlReducer = (problemWithUrlState = initialState, action) => {
  switch (action.type) {
    case PROBLEMSWITHURL.ADDPROBLEMWITHURLSUCCESS:
      return {
        ...problemWithUrlState,
        allProblemsWithUrl: [...problemWithUrlState.allProblemsWithUrl, { ...action.payload }],
        submitting: false,
      };
    case PROBLEMSWITHURL.CHANGEACTIVEPROBLEMSWITHURLPAGE:
      return { ...problemWithUrlState, activePage: action.payload };
    case PROBLEMSWITHURL.GETPROBLEMSWITHURLSUCCESS:
      return {
        ...problemWithUrlState,
        allProblemsWithUrl: [...action.payload],
        filteredProblemsWithUrl: [],
        submitting: false,
      };
    case PROBLEMSWITHURL.SEARCHPROBLEMSWITHURL:
      return {
        ...problemWithUrlState,
        filteredProblemsWithUrl: [...action.payload],
        submitting: false,
      };
    case PROBLEMSWITHURL.SUBMITTING_PROBLEMWITHURL:
      return { ...problemWithUrlState, submitting: true };
    case PROBLEMSWITHURL.PROBLEMWITHURLERROR:
      return { ...problemWithUrlState, error: action.error, submitting: false };
    case PROBLEMSWITHURL.UPDATEPROBLEMWITHURLBYIDSUCCESS:
      return { ...problemWithUrlState, allProblemsWithUrl: [...action.payload], submitting: false };
    default:
      return problemWithUrlState;
  }
};

export default problemWithUrlReducer;
