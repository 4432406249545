//@flow
import React from 'react';
import { connect } from 'react-redux';
import { updateInterviewer } from '../../redux/actions';
import { Link } from 'react-router-dom';

// $FlowFixMe.
import moment from 'moment';
const Interviewer = props => {
  const {
    id,
    index,
    name,
    slackId,
    isQC,
    availableTimeslot,
    allInterviewers,
    isActive,
    isLeadershipInterviewer,
  } = props;
  return (
    <div className="tbody">
      <Link to={`/interviewer/${props.id}`} key={id} className="link">
        <div className="row">
          <div className="columnSmall">{index + 1}</div>
          <div className="columnMedium">{name}</div>
          <div className="columnMedium">{slackId}</div>
          <div className="columnSmall">{isQC ? 'Yes' : 'No'}</div>
          <div className="columnMedium">
            {availableTimeslot && (
              <>
                {moment(availableTimeslot.startTime)
                  .format('ddd MMM D, YYYY')
                  .toString()}
              </>
            )}
          </div>
          <div className="columnSmall">
            {availableTimeslot && (
              <>
                {moment(availableTimeslot.startTime)
                  .format('hh : mm A')
                  .toString()}
              </>
            )}
          </div>

          <div className="columnSmall">
            {availableTimeslot && (
              <>
                {moment(availableTimeslot.endTime)
                  .format('hh : mm A')
                  .toString()}
              </>
            )}
          </div>
          <div className="columnAction">
            {isActive ? (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateInterviewer(allInterviewers, { id, isActive: !isActive });
                }}
                style={{
                  backgroundColor: 'red',
                }}
              >
                Deactivate
              </button>
            ) : (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateInterviewer(allInterviewers, { id, isActive: !isActive });
                }}
              >
                Activate
              </button>
            )}
          </div>
          <div className="columnAction">
            {isQC ? (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateInterviewer(allInterviewers, { id, isQC: !isQC });
                }}
                style={{
                  backgroundColor: 'red',
                }}
              >
                Deactivate
              </button>
            ) : (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateInterviewer(allInterviewers, { id, isQC: !isQC });
                }}
              >
                Activate
              </button>
            )}
          </div>
          <div className="columnAction">
            {isLeadershipInterviewer ? (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateInterviewer(allInterviewers, {
                    id,
                    isLeadershipInterviewer: !isLeadershipInterviewer,
                  });
                }}
                style={{
                  backgroundColor: 'red',
                }}
              >
                Not LS
              </button>
            ) : (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateInterviewer(allInterviewers, {
                    id,
                    isLeadershipInterviewer: !isLeadershipInterviewer,
                  });
                }}
              >
                LS
              </button>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

const mapStateToProps = state => ({
  allInterviewers: state.interviewerState.allInterviewers,
  error: state.interviewerState.error,
  submitting: state.interviewerState.submittingPutRequest,
});
const mapDispatchToProps = dispatch => ({
  updateInterviewer: (allInterviewers, interviewerInfo) =>
    dispatch(updateInterviewer(allInterviewers, interviewerInfo)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Interviewer);
