//@flow
import React, { Component } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';

type Props = {
  ButtonTitle: string,
  acceptedFiles: Array<string>,
  setFieldValue: Function,
  field: Array<string>,
};
type State = {
  open: boolean,
  files: Array<Object>,
};
export default class DropzoneDialogExample extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      files: [],
    };
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSave = (files: Array<Object>) => {
    //Saving files to state for further use and closing Modal.
    this.setState({
      files: files,
      open: false,
    });
    // console.log()
    // const { setFieldValue, field } = this.props;
    // setFieldValue(field, files);
    const { setFieldValue, field } = this.props;
    setFieldValue(field, files);
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    return (
      <div>
        <Button style={{ backgroundColor: 'lightgrey' }} onClick={this.handleOpen}>
          {this.props.ButtonTitle}
        </Button>
        <DropzoneDialog
          open={this.state.open}
          onSave={this.handleSave.bind(this)}
          acceptedFiles={this.props.acceptedFiles}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}
