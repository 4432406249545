//@flow
import React, { Component } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { render } from 'react-dom';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';
import Custompaginate from '../Custompaginate/Custompaginate';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

class RecruiterDataCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      recruiterCompanies: [],
      perPage: 27,
      activePage: 0,
    };
  }
  async componentDidMount() {
    try {
      await this.setState({
        submitting: true,
      });
      const apiurl = `${ROOT_URL}/api/getRecruiterDataCompanies`;
      const token = localStorage.getItem('token');
      const headers = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(apiurl, headers);
      console.log(response);
      await this.setState({
        submitting: false,
        recruiterCompanies: [...response.data],
      });
    } catch (error) {
      console.log(error);
    }
  }
  handleRecruiterCompaniesPageClick = async data => {
    try {
      await this.setState({ activePage: data.selected });
    } catch (error) {}
  };

  render() {
    const { classes } = this.props;
    const { submitting, recruiterCompanies, perPage, activePage } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="interviewKit">
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting recruiter companies...</p>}
          />
          <div className="table" style={{ margin: '20px' }}>
            <h4 className="title">Companies</h4>
            <div className="tbody">
              {recruiterCompanies.map(company => {
                const { name, index } = company;
                const bottomIdx = activePage * perPage;
                const topIdx = bottomIdx + perPage;
                if (index >= bottomIdx && index < topIdx) {
                  return (
                    <div className="row">
                      <Link to={`/recruiters/${index}`} key={index} className="link">
                        <div className="columnLarge">{name}</div>
                      </Link>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <Custompaginate
            pageCount={recruiterCompanies.length / perPage}
            onPageChange={this.handleRecruiterCompaniesPageClick}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RecruiterDataCompanies);
