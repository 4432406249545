//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
// $FlowFixMe.
import { connect } from 'react-redux';
import { addJobOpening } from '../../redux/actions';
import Snackbar from '@material-ui/core/Snackbar';
import history from '../../history';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
    lineHeight: 1.5,
  },

  resizeName: {
    fontSize: 18,
  },
  textField: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 30,
    fontSize: 18,
    fontWeight: 500,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  values: Object,
  handleChange: Function,
  classes: Object,
  resetForm: Function,
  submitting: boolean,
  user: Object,
  history: Object,
  error: string,
  errors: Object,
  addJobOpening: Function,
};
class AddJobOpeningForm extends Component<Props> {
  render() {
    const {
      values,
      handleChange,
      classes,
      resetForm,
      history,
      submitting,
      error,
      errors,
      user,
      addJobOpening,
    } = this.props;
    const allTypes = [
      { id: 1, value: 'Intern' },
      { id: 2, value: 'New Grad' },
      { id: 3, value: 'Experienced' },
    ];
    const allMonths = [
      { id: 1, value: 'January' },
      { id: 2, value: 'Febraury' },
      { id: 3, value: 'March' },
      { id: 4, value: 'April' },
      { id: 5, value: 'May' },
      { id: 6, value: 'June' },
      { id: 7, value: 'July' },
      { id: 8, value: 'August' },
      { id: 9, value: 'September' },
      { id: 10, value: 'October' },
      { id: 11, value: 'November' },
      { id: 12, value: 'December' },
    ];
    const allYears = [
      { id: 1, value: '2023' },
      { id: 2, value: '2024' },
      { id: 3, value: '2025' },
    ];
    return (
      <div>
        <form
          style={styles.container}
          onSubmit={e => {
            e.preventDefault();
            values.candidateName = user.firstName + ' ' + user.lastName;
            addJobOpening(values);
          }}
        >
          <div style={styles.miniContainer}>
            <h1>Add Job Opening</h1>

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Success! The job has been added to the Job List</p>}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <TextField
              required
              variant="outlined"
              style={styles.textField}
              value={values.Company}
              onChange={handleChange('Company')}
              label="Company Name"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.myHelperTextStyle,
                },
              }}
              helperText={errors.Company}
            />
            <TextField
              required
              variant="outlined"
              style={styles.textField}
              value={values.Link}
              onChange={handleChange('Link')}
              label="Job Link"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              required
              variant="outlined"
              style={styles.textField}
              value={values.Position}
              onChange={handleChange('Position')}
              label="Job Position"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              variant="outlined"
              select
              style={styles.textField}
              value={values.Type}
              onChange={handleChange('Type')}
              label="Type"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            >
              {allTypes.map(type => (
                <MenuItem key={type.id} value={type.value} style={{ fontSize: 15 }}>
                  {type.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              select
              style={styles.textField}
              value={values.Month}
              onChange={handleChange('Month')}
              label="Month Posted"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            >
              {allMonths.map(month => (
                <MenuItem key={month.id} value={month.value} style={{ fontSize: 15 }}>
                  {month.value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              select
              style={styles.textField}
              value={values.Year}
              onChange={handleChange('Year')}
              label="Year Posted"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            >
              {allYears.map(year => (
                <MenuItem key={year.id} value={year.value} style={{ fontSize: 15 }}>
                  {year.value}
                </MenuItem>
              ))}
            </TextField>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
  submitting: state.jobOpeningState.submitting,
  error: state.jobOpeningState.error,
});
const mapDispatchToProps = dispatch => ({
  addJobOpening: values => dispatch(addJobOpening(values)),
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddJobOpeningForm));
