import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import defaultUser from '../../static/default-user.jpg';
import { updateCandidateProfile } from '../../redux/actions';
import { connect } from 'react-redux';
import moment from 'moment';
const styles = {
  card: {
    maxWidth: 345,
    marginLeft: '3rem',
    height: 'auto',
    width: 290,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  submit: {
    backgroundColor: '#009688',
    margin: '2px',
  },
};

class DisplayCard extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
  }
  handleChange = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { classes, candidate, updateCandidateProfile, id } = this.props;
    const {
      userId,
      approachedBy,
      availableFrom,
      imageUrl,
      resumeUrl,
      pastInternships,
      workExperience,
    } = candidate;
    const { firstName, lastName } = userId;
    const { expanded } = this.state;
    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="candidate" className={classes.avatar}>
              {firstName.charAt(0)}
            </Avatar>
          }
          titleTypographyProps={{ variant: 'h3' }}
          title={`${firstName} ${lastName}`}
        />
        <CardMedia
          className={classes.media}
          image={imageUrl || defaultUser}
          title="Profile image"
        />
        <CardContent>
          <Typography variant="headline" component="h4">
            Available:
            {moment(availableFrom)
              .format('ddd MMM D, YYYY')
              .toString()}
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={this.handleChange}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="headline" color="textSecondary" component="h4">
              Past Internships: {pastInternships || 'NA'}
            </Typography>
            <Typography variant="headline" color="textSecondary" component="h4">
              Work Experience: {workExperience || 'NA'}
            </Typography>
            <Typography variant="headline" color="textSecondary" component="h4">
              Approached By:
              <ul>
                {approachedBy.length > 0
                  ? approachedBy.map((recruiter, index) => {
                      return <li>{`${recruiter.firstName} ${recruiter.lastName}`}</li>;
                    })
                  : 'NA'}
              </ul>
            </Typography>
            <a href={resumeUrl} target="_blank">
              <button
                className={classes.submit}
                aria-label="add to favorites"
                onClick={() => {
                  updateCandidateProfile(candidate);
                }}
              >
                Approach
              </button>
            </a>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  // allCompanies: state.companyState.allCompanies,
  // error: state.candidateState.error,
  // submitting: state.candidateState.submitting,
});

const mapDispatchToProps = dispatch => ({
  updateCandidateProfile: values => dispatch(updateCandidateProfile(values)),
});
export default withStyles(styles)(
  //DisplayCard);
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DisplayCard),
);
