//@flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
// // import UpdateProblemUrlForm from './UpdateProblemUrlForm';
import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';
import UpdateProblemUrlForm from './UpdateProblemUrlForm';
type Props = {
  match: Object,
};
type State = {
  id: string,
  videoUrl: string,
};
class UpdateProblemUrl extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      id: '',
      videoUrl: ''
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({
      id,
    });
  }


  render() {
    return (
      <div className="mainBody">
        <Formik
          enableReinitialize={true}
          initialValues={this.state}
          render={props => <UpdateProblemUrlForm {...props} />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allProblems: state.problemWithUrlState.allProblemsWithUrl,
});

export default connect(mapStateToProps)(UpdateProblemUrl);
