//@flow
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux/actions';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  submitting: boolean,
  error: string,
  classes: Object,
  location: Object,
  resetPassword: Function,
};
class ResetPassword extends Component<Props> {
  componentDidMount() {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      const token = this.props.location.search.substr(1);
      localStorage.setItem('token', token);
    }

  }

  form = ({ handleSubmit, values, handleChange, resetForm }) => (
    <Form onSubmit={handleSubmit}>
      <div className="formContainer">
        <h3>Reset Password</h3>
        <TextField
          required
          variant="outlined"
          id="password"
          label="New Password"
          onChange={handleChange}
          value={values.password}
          type="password"
          margin="normal"
          inputProps={{
            style: { fontSize: 15 },
          }}
        />
        <TextField
          required
          variant="outlined"
          id="confirmPassword"
          label="Confirm Password"
          onChange={handleChange}
          value={values.confirmPassword}
          type="password"
          margin="normal"
          inputProps={{
            style: { fontSize: 15 },
          }}
        />
        <button type="submit">Submit</button>
      </div>
    </Form>
  );

  render() {
    const { submitting, error, classes } = this.props;
    return (
      <div className="mainBody" style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Submitting...</p>}
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          onSubmit={(values, { resetForm }) => {
            const { resetPassword } = this.props;
            resetPassword(values);
            resetForm();
          }}
          component={this.form}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.authState.error,
    submitting: state.authState.submittingResetPasswordRequest,
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { resetPassword },
  )(ResetPassword),
);
