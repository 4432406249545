//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllLeadershipInterviewFeedbacks, handleLeadershipInterviewFeedbackPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import LeadershipFeedback from './LeadershipFeedback';
import SearchBar from '../SearchBar/SearchBar';
import Sidebar from '../Sidebar/Sidebar';
import './feedback.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  feedbackAsInterviewer: Array<Object>,
  feedbacks: Array<Object>,
  handleLeadershipInterviewFeedbackPageClick: Function,
  getAllLeadershipInterviewFeedbacks: Function,
  error: string,
  submitting: boolean,
  location: Object,
  classes: Object,
  filteredFeedbacks: Array<Object>,
};

class LeadershipFeedbacks extends Component<Props> {
  componentDidMount() {
    const roleToMount = this.props.location.search.substr(1);
    const { getAllLeadershipInterviewFeedbacks } = this.props;
    getAllLeadershipInterviewFeedbacks(roleToMount);
  }
  componentWillReceiveProps(nextProps) {
    const { getAllLeadershipInterviewFeedbacks } = this.props;
    var roleToMount = nextProps.location.search.substr(1);
    if (roleToMount !== this.props.location.search.substr(1)) {
      getAllLeadershipInterviewFeedbacks(roleToMount);
    }
  }

  handleInterviewFeedbackPageClick = data => {
    const { handleLeadershipInterviewFeedbackPageClick } = this.props;
    handleLeadershipInterviewFeedbackPageClick(data.selected);
  };

  render() {
    const {
      activePage,
      perPage,
      feedbacks,
      submitting,
      error,
      classes,
      filteredFeedbacks,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="feedback">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Interviewer" type="feedbackSearchByInterviewer" />
            <SearchBar placeHolder="Search by Candidate" type="feedbackSearchByCandidate" />
          </div>
          <div className="table">
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Leadership Feedbacks</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <h3 className="title">Feedbacks</h3>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Candidate</div>
              <div className="columnMedium">Interviewer</div>
              <div className="columnMedium">Date</div>
            </div>
            {filteredFeedbacks.length > 0
              ? filteredFeedbacks.map((feedback, index) => {
                const { _id, candidateId, interviewerId, createdAt } = feedback;
                const candidateName =
                  candidateId.userId.firstName + ' ' + candidateId.userId.lastName;
                const interviewerName =
                  interviewerId.userId.firstName + ' ' + interviewerId.userId.lastName;
                const bottomIdx = activePage * perPage;
                const topIdx = bottomIdx + perPage;
                if (index >= bottomIdx && index < topIdx) {
                  return (
                    <LeadershipFeedback
                      key={_id}
                      id={_id}
                      index={index}
                      candidateName={candidateName}
                      interviewerName={interviewerName}
                      createdAt={createdAt}

                    />
                  );
                }
                return null;
              })
              : feedbacks.map((feedback, index) => {
                const { _id, candidateId, interviewerId, createdAt } = feedback;
                const candidateName =
                  candidateId.userId.firstName + ' ' + candidateId.userId.lastName;
                const interviewerName =
                  interviewerId.userId.firstName + ' ' + interviewerId.userId.lastName;
                const bottomIdx = activePage * perPage;
                const topIdx = bottomIdx + perPage;
                if (index >= bottomIdx && index < topIdx) {
                  return (
                    <LeadershipFeedback
                      key={_id}
                      id={_id}
                      index={index}
                      candidateName={candidateName}
                      interviewerName={interviewerName}
                      createdAt={createdAt}
                    />
                  );
                }
                return null;
              })}
          </div>
          <Custompaginate
            pageCount={feedbacks.length / perPage}
            onPageChange={this.handleInterviewFeedbackPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.leadershipFeedbackState.activePage,
  perPage: state.leadershipFeedbackState.perPage,
  feedbacks: state.leadershipFeedbackState.allFeedbacks,
  filteredFeedbacks: state.leadershipFeedbackState.filteredFeedbacks,
  error: state.leadershipFeedbackState.error,
  submitting: state.leadershipFeedbackState.submittingGetRequest,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { getAllLeadershipInterviewFeedbacks, handleLeadershipInterviewFeedbackPageClick },
  )(LeadershipFeedbacks),
);
