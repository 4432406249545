//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPrStatus, handlePrStatusPageClick } from '../../redux/actions';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import './PrStatus.scss';
import Custompaginate from '../Custompaginate/Custompaginate';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};
type Props = { getPrStatus: Function, handlePrStatusPageClick: Function };
class PrStatus extends Component<Props> {
  componentDidMount() {
    const { getPrStatus } = this.props;
    getPrStatus();
  }

  handlePrStatusPageClick = data => {
    const { handlePrStatusPageClick } = this.props;
    handlePrStatusPageClick(data.selected);
  };
  render() {
    const { prMap, activePage, perPage, submitting, classes } = this.props;
    const allRepos = Object.keys(prMap);
    return (
      <div className="prStatus">
        <div className="table">
          <h3 className="title">PR Status</h3>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting Pr Status...</p>}
          />
          <div className="tbody">
            {allRepos.length > 0 &&
              allRepos.map((repo, index) => {
                const bottomIdx = activePage * perPage;
                const topIdx = bottomIdx + perPage;
                const status = prMap[repo];
                if (index >= bottomIdx && index < topIdx && status.length > 0) {
                  return (
                    <div className="row">
                      <div className="columnMedium">{repo}</div>
                      <button
                        type="submit"
                        onClick={() =>
                          window.open(`https://github.com/super30admin/${repo}`, '_blank')
                        }
                      >
                        Complete
                      </button>
                    </div>
                  );
                } else if (
                  index >= bottomIdx &&
                  index < topIdx &&
                  (status.length == 0 || status == undefined)
                ) {
                  return (
                    <div className="row">
                      <div className="columnMedium">{repo}</div>
                      <button
                        style={{
                          backgroundColor: 'red',
                        }}
                        type="submit"
                        onClick={() =>
                          window.open(`https://github.com/super30admin/${repo}`, '_blank')
                        }
                      >
                        Click to Complete
                      </button>
                    </div>
                  );
                }
              })}
          </div>
          <Custompaginate
            pageCount={allRepos.length / perPage}
            onPageChange={this.handlePrStatusPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.prStatusState.activePage,
  perPage: state.prStatusState.perPage,
  prMap: state.prStatusState.prMap,
  submitting: state.prStatusState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getPrStatus, handlePrStatusPageClick })(PrStatus),
);
