//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllProblems, handleProblemPageClick } from '../../redux/actions';
import Problem from './Problem';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
import SearchBar from '../SearchBar/SearchBar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import './Problem.scss';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getAllProblems: Function,
  problems: Array<Object>,
  handleProblemPageClick: Function,
  activePage: number,
  perPage: number,
  error: string,
  classes: Object,
  filteredProblems: Array<Object>,
  submitting: boolean,
};
class Problems extends Component<Props> {
  componentDidMount() {
    const { getAllProblems } = this.props;
    getAllProblems();
  }

  handleProblemPageClick = data => {
    const { handleProblemPageClick } = this.props;
    handleProblemPageClick(data.selected);
  };

  render() {
    const roles = localStorage.getItem('s30Roles') || {};
    const {
      problems,
      activePage,
      filteredProblems,
      perPage,
      submitting,
      error,
      classes,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="problem">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Name" type="problemSearchByName" />
            <SearchBar placeHolder="Search by Technique" type="problemSearchByTechnique" />
            <SearchBar placeHolder="Search by Company" type="problemSearchByCompany" />
          </div>
          <div className="table">
            <h3 className="title">All Problems</h3>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Problems...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnLarge">Name</div>
              <div className="columnMedium">Techniques</div>
              <div className="columnMedium">Companies</div>
              <div className="columnAction"> Video </div>
              {roles.includes('Admin') && <div className="columnAction"> Edit </div>}

              {/* {roles.includes('Admin') && <div className="columnAction"> Delete </div>} */}
            </div>
            {filteredProblems.length > 0
              ? filteredProblems.map((problem, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Problem
                        key={problem._id}
                        index={problem.problemIndex}
                        techniques={problem.techniques}
                        companyTags={problem.companyTags}
                        id={problem._id}
                        name={problem.name}
                        videoUrl={problem.videoUrl}
                      />
                    );
                  }
                  return null;
                })
              : problems.map((problem, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Problem
                        key={problem._id}
                        index={problem.problemIndex}
                        techniques={problem.techniques}
                        companyTags={problem.companyTags}
                        id={problem._id}
                        videoUrl={problem.videoUrl}
                        name={problem.name}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={problems.length / perPage}
            onPageChange={this.handleProblemPageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.problemState.activePage,
  perPage: state.problemState.perPage,
  problems: state.problemState.allProblems,
  filteredProblems: state.problemState.filteredProblems,
  error: state.problemState.error,
  submitting: state.problemState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllProblems, handleProblemPageClick })(Problems),
);
