import { call, put } from 'redux-saga/effects';
import { addApplicant, getAllApplicants } from '../../api';
import {
  getAllApplicantsSuccess,
  applicantError,
  addApplicantSuccess,
  submittingApplicantRequest,
} from '../actions/index';

import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllApplicantsSaga() {
  try {
    yield put(submittingApplicantRequest());
    const applicants = yield call(getAllApplicants);
    yield put(getAllApplicantsSuccess(applicants.data));
  } catch (error) {
    yield put(applicantError(error.toString()));
    yield delay(3000);
    yield put(applicantError(''));
  }
}

export function* addApplicantSaga(action) {
  try {
    yield put(submittingApplicantRequest());
    const { applicantObj } = action;
    applicantObj.plan = applicantObj.plan.value;
    const applicant = yield call(addApplicant, applicantObj);
    yield put(addApplicantSuccess(applicant));
    history.push('/applySuccess');
  } catch (error) {
    yield put(applicantError(error.response.data.toString()));
    yield delay(3000);
    yield put(applicantError(''));
  }
}
