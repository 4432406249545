//@flow
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ReactMarkdown from 'react-markdown';

const markdown = `
# Impact Resumes
**Please go through below tips first and then drop your resume for feedback by staff in {S30} resume channel.**
> "You get just 20 secs to grab a recruiters eye through your resume and an interview invite"

## Content 
> There is something wrong with my resume. What is it? 
- Most probably its the content and not only the format. The number one mistake that student do in their resume is not having numbers in their content. Any bullet where you have not quantified the impact of what you did, is just skipped by recruiters.
Without numbers you are just telling the recruiters that these were your job responsibilities. Great? Were you good at it or not. The numbers show that.
- Numbers are absolutely mandatory for work experiences which are towards top. 
- The students keep putting the technologies that they worked with, the recruiters don't understand much tech details. All that the recruiters understand that your experience is relevant to the role that you are applying for or not. And did you make an impact working in this relevant role? 
- A lot of students tell me that they don't know the impact of their work. It clearly shows that a student doesn't value his/her work and is hardly passionate about his previous job. All the more reason for recruiters to skip the resume.
- Don't be emotional about dropping the bullets that are not relevant to role that you are applying for. 
- Stay more focused on what you did and what was its impact. Listing those 10 technologies again and again in content doesn't help. Besides they are already covered in Technical Skills section.
- Content should be action oriented with action verbs. Kindly don't list job responsibilities. Here is [list of action verbs](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/Action-Verb-List.pdf)

## Format
> Do it once, do it perfect
- Format is to be taken care of once you have great content. I see a lot of students making execuse that something is wrong with their resume format. In reality, they don't spend time in making their resume an impact resume (the one with numbers) or have lack or experience. 
- Use this [downloadable standard format](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/Standard_Format_Resume.docx). Tech recruiters are used to looking at standard format. Their eyes and subconsious skips non standard resumes. So don't try adventures here. If you wish to validate this claim then look at 50 resumes in standard format and then your non standard resume.
- Chronology
  1. Summary (Optional)
  2. Education
  3. Experience
  4. Projects
  5. Additional Information
- Single page. 
- Please use standard resume naming conventions. 'FirstName_LastName_Resume.pdf'.
- Font style: Calibri.
- Don't use Italics, underlines and bold text in resume. Bold text in headers is fine.
- Use full links to LinkedIn and Github. Recruiters give printed hard copies to hiring managers a lot of times.
- All dates, bullets, sections are aligned evenly and consistently throughout the resume. The best way to ensure it to print your resume and look at it from a distance of 2 ft.
## Examples of Good Resumes
1. [Good One](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/Loren_Lipsum_Resume.pdf)
2. [Another Good One](https://super30jaspinder.s3.amazonaws.com/ResumeAndLinkedIn/Lore_Lipsum_Resume.pdf)


`;
const ResumeTips = () => (
  <div className="mainBody">
    <Sidebar />
    <div className="getInterviews">
      <ReactMarkdown source={markdown}></ReactMarkdown>
    </div>
  </div>
);

export default ResumeTips;
