//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllInterviewers, handleInterviewerPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Interviewer from './Interviewer';
import SearchBar from '../SearchBar/SearchBar';
import Sidebar from '../Sidebar/Sidebar';
import './interview.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  allInterviewers: Array<Object>,
  filteredInterviewer: Array<Object>,
  handleInterviewerPageClick: Function,
  getAllInterviewers: Function,
  error: string,
  submitting: boolean,
  classes: Object,
};

class Interviews extends Component<Props> {
  componentDidMount() {
    const { getAllInterviewers } = this.props;
    getAllInterviewers();
  }

  handleInterviewerPageClick = data => {
    const { handleInterviewerPageClick } = this.props;
    handleInterviewerPageClick(data.selected);
  };

  render() {
    const {
      activePage,
      perPage,
      allInterviewers,
      filteredInterviewer,
      submitting,
      error,
      classes,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="interviewer">
          {/* <div className="searchContainer">
            <SearchBar placeHolder="Search by Name" type="InterviewerSearchByName" />
            <SearchBar placeHolder="Search by Cohort" type="InterviewerSearchByCohort" />
          </div> */}
          <div className="table">
            <h3 className="title">Interviews</h3>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Name</div>
              <div className="columnMedium">Candidate</div>
              <div className="columnMedium">Slack Id</div>
              <div className="columnMedium">Date</div>
              <div className="columnMedium">Start Time</div>
              <div className="columnMedium">End Time</div>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Submitting...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            {filteredInterviewer && filteredInterviewer.length > 0
              ? filteredInterviewer
                  .filter(function(interviewer) {
                    return interviewer.assignedCandidateIds.length > 0;
                  })
                  .map((interviewer, index) => {
                    const { firstName, lastName } = interviewer.userId;
                    const { assignedCandidateIds } = interviewer;
                    const bottomIdx = activePage * perPage;
                    const topIdx = bottomIdx + perPage;
                    if (index >= bottomIdx && index < topIdx) {
                      return (
                        <Interviewer
                          key={index}
                          index={index + 1}
                          name={`${firstName} ${lastName}`}
                          assignedCandidateIds={assignedCandidateIds}
                        />
                      );
                    }
                    return null;
                  })
              : allInterviewers
                  .filter(function(interviewer) {
                    return interviewer.assignedCandidateIds.length > 0;
                  })
                  .map((interviewer, index) => {
                    const { firstName, lastName } = interviewer.userId;
                    const { assignedCandidateIds } = interviewer;
                    const bottomIdx = activePage * perPage;
                    const topIdx = bottomIdx + perPage;
                    if (index >= bottomIdx && index < topIdx) {
                      return (
                        <Interviewer
                          key={index}
                          index={index + 1}
                          name={`${firstName} ${lastName}`}
                          assignedCandidateIds={assignedCandidateIds}
                        />
                      );
                    }
                    return null;
                  })}
          </div>
          <Custompaginate
            pageCount={allInterviewers.length / perPage}
            onPageChange={this.handleInterviewerPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.interviewerState.activePage,
  perPage: state.interviewerState.perPage,
  allInterviewers: state.interviewerState.allInterviewers,
  filteredInterviewer: state.interviewerState.filteredInterviewer,
  error: state.interviewerState.error,
  submitting: state.interviewerState.submittingGetRequest,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllInterviewers, handleInterviewerPageClick })(Interviews),
);
