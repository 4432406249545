import { INTERVIEWFEEDBACKS } from '../constants';

const initialState = {
  allFeedbacks: [],
  filteredFeedbacks: [],
  activePage: 0,
  perPage: 10,
  error: false,
  submittingPostRequest: false,
  submittingGetRequest: false,
  submittingPutRequest: false,
  submittingDeleteRequest: false,
};

const feedbackStateReducer = (feedbackState = initialState, action) => {
  switch (action.type) {
    case INTERVIEWFEEDBACKS.ADDINTERVIEWFEEDBACKSUCCESS:
      return {
        ...feedbackState,
        allFeedbacks: [...feedbackState.allFeedbacks, { ...action.payload }],
        submittingPostRequest: false,
      };
    case INTERVIEWFEEDBACKS.GETINTERVIEWFEEDBACKSSUCCESS:
      return {
        ...feedbackState,
        allFeedbacks: [...action.payload],
        filteredFeedbacks: [],
        submittingGetRequest: false,
      };
    case INTERVIEWFEEDBACKS.UPDATEINTERVIEWFEEDBACKSUCCESS:
      return {
        ...feedbackState,
        allFeedbacks: [...action.payload],
        submittingPutRequest: false,
      };
    case INTERVIEWFEEDBACKS.DELETEINTERVIEWFEEDBACKSUCCESS:
      return {
        ...feedbackState,
        allFeedbacks: [...action.payload],
        submittingDeleteRequest: false,
      };

    // error
    case INTERVIEWFEEDBACKS.INTERVIEWFEEDBACKERROR:
      return {
        ...feedbackState,
        error: action.error,
        submittingPostRequest: false,
        submittingPutRequest: false,
        submittingGetRequest: false,
        submittingDeleteRequest: false,
      };
    //search
    case INTERVIEWFEEDBACKS.SEARCHINTERVIEWFEEDBACK:
      return { ...feedbackState, filteredFeedbacks: [...action.payload], submitting: false };
    //change active page
    case INTERVIEWFEEDBACKS.CHANGEACTIVEINTERVIEWFEEDBACKPAGE:
      return { ...feedbackState, activePage: action.payload };
    //submitting
    case INTERVIEWFEEDBACKS.SUBMITTINGADDINTERVIEWFEEDBACK:
      return { ...feedbackState, submittingPostRequest: true };
    case INTERVIEWFEEDBACKS.SUBMITTINGUPDATEINTERVIEWFEEDBACK:
      return { ...feedbackState, submittingPutRequest: true };
    case INTERVIEWFEEDBACKS.SUBMITTINGGETINTERVIEWFEEDBACK:
      return { ...feedbackState, submittingGetRequest: true };
    case INTERVIEWFEEDBACKS.SUBMITTINGDELETEINTERVIEWFEEDBACK:
      return { ...feedbackState, submittingDeleteRequest: true };

    default:
      return feedbackState;
  }
};

export default feedbackStateReducer;
