//@flow
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  addProblemWithUrl,
  getAllCohorts,
  getAllProblems,
  getAllDSProblems,
} from '../../redux/actions';
import DisplayFormikState from '../DisplayFormikState';
import MultiSelect from '../Select/MultiSelect';
import Autocomplete from '../Select/Autocomplete';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  error: string,
  submitting: boolean,
  allCohorts: Array<Object>,
  allProblems: Array<Object>,
  addProblemWithUrl: Function,
  values: Object,
  handleChange: Function,
  resetForm: Function,
  getAllCohorts: Function,
  getAllProblems: Function,
  classes: Object,
};

class AddVideoUrlForm extends Component<Props> {
  componentDidMount() {
    const { getAllCohorts, getAllProblems, getAllDSProblems } = this.props;
    getAllCohorts();
    getAllProblems();
    getAllDSProblems();
  }
  render() {
    const {
      values,
      handleChange,
      submitting,
      error,
      classes,
      allCohorts,
      allProblems,
      allDSProblems,
      addProblemWithUrl,
      resetForm,
    } = this.props;
    let isDataScienceCohort = values.cohortId.isDataScienceCohort;
    let isNormalCohort = values.cohortId.isDataScienceCohort == false;
    console.log(values);
    return (
      <form
        className={classes.container}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          addProblemWithUrl(values);
          resetForm();
        }}
      >
        <h4>Add a Video Url</h4>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding Video Url</p>}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error.message || error}</p>}
        />
        <TextField
          id="cohortId"
          variant="outlined"
          select
          label="Cohort"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.cohortId}
          onChange={handleChange('cohortId')}
          margin="normal"
        >
          {allCohorts.map(cohort => (
            <MenuItem key={cohort._id} value={cohort} style={{ fontSize: 15 }}>
              {cohort.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          required
          variant="outlined"
          value={values.videoUrl}
          onChange={handleChange}
          id="videoUrl"
          label="Youtube Url"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
        />
        {isNormalCohort && (
          <>
            <h5>Normal Cohort Problems</h5>
            <Autocomplete
              handleChange={handleChange('problemId')}
              value={values.problemId}
              label="Problem"
              suggestions={allProblems}
            />
          </>
        )}
        {/* <h5>Normal Cohort Problem</h5>
        <Autocomplete
          handleChange={handleChange('problemId')}
          value={values.problemId}
          label="Problem"
          suggestions={allProblems}
        /> */}
        {isDataScienceCohort && (
          <>
            <h5>Data Science Cohort Problems</h5>
            <Autocomplete
              handleChange={handleChange('problemId')}
              value={values.problemId}
              label="Problem"
              suggestions={allDSProblems}
            />
          </>
        )}
        {/* <h5>Data Science Problem</h5>
        <Autocomplete
          handleChange={handleChange('problemId')}
          value={values.problemId}
          label="Data Science Problem"
          suggestions={allDSProblems}
        /> */}
        <button type="submit">Submit</button>
        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  allCohorts: state.authState.user.cohortManagerId.cohortIds,
  allProblems: state.problemState.allProblems,
  allDSProblems: state.dsproblemState.allDSProblems,
  error: state.problemWithUrlState.error,
  submitting: state.problemWithUrlState.submitting,
});

const mapDispatchToProps = dispatch => ({
  addProblemWithUrl: (values, history) => dispatch(addProblemWithUrl(values, history)),
  getAllCohorts: () => dispatch(getAllCohorts()),
  getAllProblems: () => dispatch(getAllProblems()),
  getAllDSProblems: () => dispatch(getAllDSProblems()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddVideoUrlForm));
