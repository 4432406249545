/* eslint-disable no-param-reassign */

import {
  AUTH,
  APPLICANTS,
  SCHOOLS,
  ROLES,
  COMPANIES,
  PROBLEMS,
  DSPROBLEMS,
  PROBLEMSWITHURL,
  PRICINGS,
  COHORTS,
  CANDIDATES,
  INTERVIEWER,
  COHORTMANAGER,
  INTERVIEWFEEDBACKS,
  LEADERSHIPINTERVIEWFEEDBACKS,
  INTERVIEWKIT,
  JOBOPENING,
  ALUMNILIST,
  PRSTATUS,
  SALARY,
  INTERVIEWPERFORMANCES,
} from '../constants';

// Auth Actions please maintain alphabetical order
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';
export const SIGNUP = 'SIGNUP';
// Auth Actions

export const signin = (user, history) => ({
  type: AUTH.SIGNIN,
  user,
  history,
});

export const signinSuccess = user => ({
  type: AUTH.SIGNINSUCCESS,
  user,
});

export const getUpdatedUser = () => ({
  type: AUTH.GETUPDATEDUSER,
});

export const getUpdatedUserSuccess = (user, prMap) => ({
  type: AUTH.GETUPDATEDUSERSUCCESS,
  user,
  prMap,
});

export const signup = (user, refereeObj) => ({
  type: AUTH.SIGNUP,
  user,
  refereeObj,
});

export const signupSuccess = payload => ({
  type: AUTH.SIGNUPSUCCESS,
  payload,
});

export const authError = error => ({
  type: AUTH.AUTHENTICATION_ERROR,
  error,
});

export const signout = () => ({
  type: AUTH.SIGNOUT,
});

export const signoutSuccess = () => ({
  type: AUTH.SIGNOUTSUCCESS,
});

export const hydrateAuthState = isAuthorized => ({
  type: AUTH.HYDRATEAUTHSTATE,
  isAuthorized,
});

export const submittingSignInRequest = () => ({
  type: AUTH.SUBMITTINGSIGNINREQUEST,
});
export const submittingSignOutRequest = () => ({
  type: AUTH.SUBMITTINGSIGNOUTREQUEST,
});
export const submittingSignUpRequest = () => ({
  type: AUTH.SUBMITTINGSIGNUPREQUEST,
});
export const submittingForgotPasswordRequest = () => ({
  type: AUTH.SUBMITTINGFORGOTPASSWORDREQUEST,
});
export const submittingResetPasswordRequest = () => ({
  type: AUTH.SUBMITTINGRESETPASSWORDREQUEST,
});

export const forgotPassword = username => ({
  type: AUTH.FORGOTPASSWORD,
  username,
});
export const forgotPasswordSuccess = () => ({
  type: AUTH.FORGOTPASSWORDSUCCESS,
});
export const resetPassword = passwordObj => ({
  type: AUTH.RESETPASSWORD,
  passwordObj,
});
export const resetPasswordSuccess = () => ({
  type: AUTH.RESETPASSWORDSUCCESS,
});

//Candidate

export const submittingCandidateRequest = () => ({
  type: CANDIDATES.SUBMITTING_CANDIDATE,
});
export const candidateError = error => ({
  type: CANDIDATES.CANDIDATEERROR,
  error,
});
export const addCandidate = (candidate, history) => ({
  type: CANDIDATES.ADDCANDIDATE,
  candidate,
  history,
});
export const addCandidateSuccess = payload => ({
  type: CANDIDATES.ADDCANDIDATESUCCESS,
  payload,
});
export const getAllCandidates = () => ({
  type: CANDIDATES.GETCANDIDATES,
});
export const getRecommendedCandidates = () => ({
  type: CANDIDATES.GETRECOMMENDEDCANDIDATES,
});
export const handleCandidateSearch = payload => ({
  type: CANDIDATES.SEARCHCANDIDATE,
  payload,
});

export const getAllCandidatesSuccess = payload => ({
  type: CANDIDATES.GETCANDIDATESSUCCESS,
  payload,
});
export const getRecommendedCandidatesSuccess = payload => ({
  type: CANDIDATES.GETRECOMMENDEDCANDIDATESSUCCESS,
  payload,
});
export const updateCandidate = (allCandidates, candidateInfo, history) => ({
  type: CANDIDATES.UPDATECANDIDATE,
  allCandidates,
  candidateInfo,
  history,
});
export const updateCandidateProfile = candidateInfo => ({
  type: CANDIDATES.UPDATECANDIDATEPROFILE,
  candidateInfo,
});

export const updateCandidateSuccess = payload => ({
  type: CANDIDATES.UPDATECANDIDATESUCCESS,
  payload,
});
export const updateCandidateProfileSuccess = payload => ({
  type: CANDIDATES.UPDATECANDIDATEPROFILESUCCESS,
  payload,
});

export const handleCandidatePageClick = data => dispatch => {
  dispatch({
    type: CANDIDATES.CHANGEACTIVECANDIDATEPAGE,
    payload: data,
  });
};

export const unsubscribeCandidate = id => ({
  type: CANDIDATES.UNSUBSCRIBE,
  id,
});

export const unsubscribeCandidateSuccess = () => ({
  type: CANDIDATES.UNSUBSCRIBESUCCESS,
});

export const recommendCandidate = (allCandidates, candidateId, interviewerId, isRecommended) => ({
  type: CANDIDATES.RECOMMENDCANDIDATE,
  allCandidates,
  candidateId,
  interviewerId,
  isRecommended,
});

// Cohort Actions

export const submittingCohortRequest = () => ({
  type: COHORTS.SUBMITTING_COHORT,
});

export const cohortError = error => ({
  type: COHORTS.COHORTERROR,
  error,
});

export const addCohort = (cohortObj, history) => ({
  type: COHORTS.ADDCOHORT,
  cohortObj,
  history,
});

export const addCohortSuccess = payload => ({
  type: COHORTS.ADDCOHORTSUCCESS,
  payload,
});

export const getAllCohorts = () => ({
  type: COHORTS.GETCOHORTS,
});

export const getAllCohortsSuccess = payload => ({
  type: COHORTS.GETCOHORTSSUCCESS,
  payload,
});
export const handleCohortPageClick = data => dispatch => {
  dispatch({
    type: COHORTS.CHANGEACTIVECOHORTPAGE,
    payload: data,
  });
};

export const updateCohort = (allCohorts, cohortInfo) => ({
  type: COHORTS.UPDATECOHORTBYID,
  allCohorts,
  cohortInfo,
});

export const updateCohortSuccess = payload => ({
  type: COHORTS.UPDATECOHORTSUCCESS,
  payload,
});
// dsProblem Actions

export const submittingDSProblemRequest = () => ({
  type: DSPROBLEMS.SUBMITTING_DSPROBLEM,
});
export const addDSProblem = (dsproblemObj, history) => ({
  type: DSPROBLEMS.ADDDSPROBLEM,
  dsproblemObj,
  history,
});

export const addDSProblemSuccess = payload => ({
  type: DSPROBLEMS.ADDDSPROBLEMSUCCESS,
  payload,
});

export const getAllDSProblems = () => ({
  type: DSPROBLEMS.GETDSPROBLEMS,
});

export const getAllDSProblemsSuccess = payload => ({
  type: DSPROBLEMS.GETDSPROBLEMSSUCCESS,
  payload,
});

export const handleDSProblemSearch = payload => ({
  type: DSPROBLEMS.SEARCHDSPROBLEM,
  payload,
});

export const dsproblemError = error => ({
  type: DSPROBLEMS.DSPROBLEMERROR,
  error,
});

export const handleDSProblemPageClick = data => dispatch => {
  dispatch({
    type: DSPROBLEMS.CHANGEACTIVEDSPROBLEMPAGE,
    payload: data,
  });
};

export const deleteDSProblemById = (allDSProblems, dsprobId) => ({
  type: PROBLEMS.DELETEDSPROBLEMBYID,
  allDSProblems,
  dsprobId,
});

export const deleteDSProblemSuccess = payload => ({
  type: DSPROBLEMS.DELETEDSPROBLEMSUCCESS,
  payload,
});

export const updateDSProblemById = (allDSProblems, dsprobId, newDSProblem, history) => ({
  type: DSPROBLEMS.UPDATEDSPROBLEMBYID,
  allDSProblems,
  dsprobId,
  newDSProblem,
  history,
});

export const updateDSProblemSuccess = payload => ({
  type: DSPROBLEMS.UPDATEDSPROBLEMSUCCESS,
  payload,
});

// Problem Actions

export const submittingProblemRequest = () => ({
  type: PROBLEMS.SUBMITTING_PROBLEM,
});
export const addProblem = (problemObj, history) => ({
  type: PROBLEMS.ADDPROBLEM,
  problemObj,
  history,
});

export const addProblemSuccess = payload => ({
  type: PROBLEMS.ADDPROBLEMSUCCESS,
  payload,
});

export const getAllProblems = () => ({
  type: PROBLEMS.GETPROBLEMS,
});

export const getAllProblemsSuccess = payload => ({
  type: PROBLEMS.GETPROBLEMSSUCCESS,
  payload,
});

export const handleProblemSearch = payload => ({
  type: PROBLEMS.SEARCHPROBLEM,
  payload,
});

export const problemError = error => ({
  type: PROBLEMS.PROBLEMERROR,
  error,
});

export const handleProblemPageClick = data => dispatch => {
  dispatch({
    type: PROBLEMS.CHANGEACTIVEPROBLEMPAGE,
    payload: data,
  });
};

export const deleteProblemById = (allProblems, probId) => ({
  type: PROBLEMS.DELETEPROBLEMBYID,
  allProblems,
  probId,
});

export const deleteProblemSuccess = payload => ({
  type: PROBLEMS.DELETEPROBLEMSUCCESS,
  payload,
});

export const updateProblemById = (allProblems, probId, newProblem, history) => ({
  type: PROBLEMS.UPDATEPROBLEMBYID,
  allProblems,
  probId,
  newProblem,
  history,
});

export const updateProblemSuccess = payload => ({
  type: PROBLEMS.UPDATEPROBLEMSUCCESS,
  payload,
});

//Problem with URLs Actions
export const submittingProblemWithUrlRequest = () => ({
  type: PROBLEMSWITHURL.SUBMITTING_PROBLEMWITHURL,
});
export const addProblemWithUrl = (problemObj, history) => ({
  type: PROBLEMSWITHURL.ADDPROBLEMWITHURL,
  problemObj,
  history,
});

export const addProblemWithUrlSuccess = payload => ({
  type: PROBLEMSWITHURL.ADDPROBLEMWITHURLSUCCESS,
  payload,
});

export const getAllProblemsWithUrl = cohort => ({
  type: PROBLEMSWITHURL.GETPROBLEMSWITHURL,
  cohort,
});

export const getAllProblemsWithUrlSuccess = payload => ({
  type: PROBLEMSWITHURL.GETPROBLEMSWITHURLSUCCESS,
  payload,
});

export const handleProblemWithUrlSearch = payload => ({
  type: PROBLEMSWITHURL.SEARCHPROBLEMSWITHURL,
  payload,
});

export const problemWithUrlError = error => ({
  type: PROBLEMSWITHURL.PROBLEMWITHURLERROR,
  error,
});

export const handleProblemsWithUrlPageClick = data => dispatch => {
  dispatch({
    type: PROBLEMSWITHURL.CHANGEACTIVEPROBLEMSWITHURLPAGE,
    payload: data,
  });
};
export const updateProblemWithUrlById = (allProblems, probId, videoUrl) => ({
  type: PROBLEMSWITHURL.UPDATEPROBLEMWITHURLBYID,
  allProblems,
  probId,
  videoUrl,
});

export const updateProblemWithUrlSuccess = payload => ({
  type: PROBLEMSWITHURL.UPDATEPROBLEMWITHURLBYIDSUCCESS,
  payload,
});

// School Actions

export const addSchool = (schoolObj, history) => ({
  type: SCHOOLS.ADDSCHOOL,
  schoolObj,
  history,
});

export const addSchoolSuccess = payload => ({
  type: SCHOOLS.ADDSCHOOLSUCCESS,
  payload,
});

export const submittingSchoolRequest = () => ({
  type: SCHOOLS.SUBMITTING_SCHOOL,
});

export const toggleSchoolEdit = (allSchools, id) => ({
  type: SCHOOLS.TOGGLESCHOOLEDIT,
  allSchools,
  id,
});

export const toggleSchoolEditSuccess = payload => ({
  type: SCHOOLS.TOGGLESCHOOLEDITSUCCESS,
  payload,
});

export const getAllSchools = () => ({
  type: SCHOOLS.GETSCHOOLS,
});

export const getAllSchoolsSuccess = payload => ({
  type: SCHOOLS.GETSCHOOLSSUCCESS,
  payload,
});

export const schoolError = error => ({
  type: SCHOOLS.SCHOOLERROR,
  error,
});

export const updateSchool = (allSchools, schoolInfo) => ({
  type: SCHOOLS.UPDATESCHOOL,
  allSchools,
  schoolInfo,
});

export const updateSchoolSuccess = payload => ({
  type: SCHOOLS.UPDATESCHOOLSUCCESS,
  payload,
});

export const handleSchoolPageClick = data => dispatch => {
  dispatch({
    type: SCHOOLS.CHANGEACTIVESCHOOLPAGE,
    payload: data,
  });
};

// Company Actions

export const getAllCompanies = () => ({
  type: COMPANIES.GETCOMPANIES,
});

export const getAllCompaniesSuccess = payload => ({
  type: COMPANIES.GETCOMPANIESSUCCESS,
  payload,
});
export const addCompany = (companyObj, history) => ({
  type: COMPANIES.ADDCOMPANY,
  companyObj,
  history,
});

export const addCompanySuccess = payload => ({
  type: COMPANIES.ADDCOMPANYSUCCESS,
  payload,
});

export const toggleCompanyEdit = (allCompanies, id) => ({
  type: COMPANIES.TOGGLECOMPANYEDIT,
  allCompanies,
  id,
});

export const toggleCompanyEditSuccess = payload => ({
  type: COMPANIES.TOGGLECOMPANYEDITSUCCESS,
  payload,
});

export const updateCompany = (allCompanies, companyObj) => ({
  type: COMPANIES.UPDATECOMPANY,
  allCompanies,
  companyObj,
});

export const updateCompanySuccess = payload => ({
  type: COMPANIES.UPDATECOMPANYSUCCESS,
  payload,
});

export const companyError = error => ({
  type: COMPANIES.COMPANYERROR,
  error,
});

export const submittingRequest = () => ({
  type: COMPANIES.SUBMITTING_COMPANY,
});

export const handleCompanyPageClick = data => dispatch => {
  dispatch({
    type: COMPANIES.CHANGEACTIVECOMPANYPAGE,
    payload: data,
  });
};

export const handleCompanySearch = payload => ({
  type: COMPANIES.SEARCHCOMPANY,
  payload,
});

// interviewer
export const getAllInterviewers = () => ({
  type: INTERVIEWER.GETINTERVIEWERS,
});

export const getAllActiveInterviewers = () => ({
  type: INTERVIEWER.GETACTIVEINTERVIEWERS,
});

export const getAllInterviewersSuccess = payload => ({
  type: INTERVIEWER.GETINTERVIEWERSSUCCESS,
  payload,
});
export const getAllActiveInterviewersSuccess = payload => ({
  type: INTERVIEWER.GETACTIVEINTERVIEWERSSUCCESS,
  payload,
});
export const updateInterviewer = (allInterviewers, interviewerInfo) => ({
  type: INTERVIEWER.UPDATEINTERVIEWER,
  allInterviewers,
  interviewerInfo,
});
export const updateInterviewerSuccess = payload => ({
  type: INTERVIEWER.UPDATEINTERVIEWERSUCCESS,
  payload,
});
export const interviewerError = error => ({
  type: INTERVIEWER.INTERVIEWERERROR,
  error,
});
export const handleInterviewerPageClick = data => dispatch => {
  dispatch({
    type: INTERVIEWER.CHANGEACTIVEINTERVIEWERPAGE,
    payload: data,
  });
};
export const submittingGetInterviewerRequest = () => ({
  type: INTERVIEWER.SUBMITTINGGETINTERVIEWER,
});
export const submittingUpdateInterviewerRequest = () => ({
  type: INTERVIEWER.SUBMITTINGUPDATEINTERVIEWER,
});

export const handleInterviewerSearch = payload => ({
  type: INTERVIEWER.SEARCHINTERVIEWER,
  payload,
});

// cohortManager
export const getAllCohortManagers = () => ({
  type: COHORTMANAGER.GETCOHORTMANAGERS,
});

export const getAllCohortManagersSuccess = payload => ({
  type: COHORTMANAGER.GETCOHORTMANAGERSSUCCESS,
  payload,
});

export const cohortManagerError = error => ({
  type: COHORTMANAGER.COHORTMANAGERERROR,
  error,
});

// recommendedCandidate

export const updateRecommendedCandidate = (candidates, candidateInfo) => ({
  type: CANDIDATES.UPDATERECOMMENDEDCANDIDATE,
  candidates,
  candidateInfo,
});

// export const submittingRecommendedCandidateRequest = () => ({
//   type: RECOMMENDEDCANDIDATE.SUBMITTING_RECOMMENDEDCANDIDATE,
// });

export const updateRecommendedCandidateSuccess = payload => ({
  type: CANDIDATES.UPDATERECOMMENDEDCANDIDATESUCCESS,
  payload,
});

// export const recommendedCandidateError = error => ({
//   type: RECOMMENDEDCANDIDATE.RECOMMENDEDCANDIDATEERROR,
//   error,
// });

// Applicant Actions

export const addApplicant = (applicantObj, history) => ({
  type: APPLICANTS.ADDAPPLICANT,
  applicantObj,
  history,
});

export const addApplicantSuccess = payload => ({
  type: APPLICANTS.ADDAPPLICANTSUCCESS,
  payload,
});

export const submittingApplicantRequest = () => ({
  type: APPLICANTS.SUBMITTING_APPLICANT,
});

export const getAllApplicants = () => ({
  type: APPLICANTS.GETAPPLICANTS,
});

export const getAllApplicantsSuccess = payload => ({
  type: APPLICANTS.GETAPPLICANTSSUCCESS,
  payload,
});

export const applicantError = error => ({
  type: APPLICANTS.APPLICANTERROR,
  error,
});

export const handleApplicantPageClick = data => dispatch => {
  dispatch({
    type: APPLICANTS.CHANGEACTIVEAPPLICANTPAGE,
    payload: data,
  });
};
export const handleApplicantSearch = payload => ({
  type: APPLICANTS.SEARCHAPPLICANT,
  payload,
});

// pricing

export const getAllPricings = () => ({
  type: PRICINGS.GETPRICINGS,
});

export const getAllPricingsSuccess = payload => ({
  type: PRICINGS.GETPRICINGSSUCCESS,
  payload,
});

export const pricingError = error => ({
  type: PRICINGS.PRICINGERROR,
  error,
});

// Performances change here

export const interviewPerformanceError = error => ({
  type: INTERVIEWPERFORMANCES.INTERVIEWPERFORMANCEERROR,
  error,
});
export const handleInterviewPerformancePageClick = data => dispatch => {
  dispatch({
    type: INTERVIEWPERFORMANCES.CHANGEACTIVEINTERVIEWPERFORMANCEPAGE,
    payload: data,
  });
};

export const submittingAddInterviewPerformanceRequest = () => ({
  type: INTERVIEWPERFORMANCES.SUBMITTINGADDINTERVIEWPERFORMANCE,
});
export const submittingGetInterviewPerformanceRequest = () => ({
  type: INTERVIEWPERFORMANCES.SUBMITTINGGETINTERVIEWPERFORMANCE,
});
export const addInterviewPerformance = performance => ({
  type: INTERVIEWPERFORMANCES.ADDINTERVIEWPERFORMANCE,
  performance,
});
export const addInterviewPerformanceSuccess = payload => ({
  type: INTERVIEWPERFORMANCES.ADDINTERVIEWPERFORMANCESUCCESS,
  payload,
});

export const getAllInterviewPerformances = role => ({
  type: INTERVIEWPERFORMANCES.GETINTERVIEWPERFORMANCES,
  role,
});

export const getAllInterviewPerformancesSuccess = payload => ({
  type: INTERVIEWPERFORMANCES.GETINTERVIEWPERFORMANCESSUCCESS,
  payload,
});

// InterviewFeedback

export const handleInterviewFeedbackSearch = payload => ({
  type: INTERVIEWFEEDBACKS.SEARCHINTERVIEWFEEDBACK,
  payload,
});

export const interviewFeedbackError = error => ({
  type: INTERVIEWFEEDBACKS.INTERVIEWFEEDBACKERROR,
  error,
});

export const handleInterviewFeedbackPageClick = data => dispatch => {
  dispatch({
    type: INTERVIEWFEEDBACKS.CHANGEACTIVEINTERVIEWFEEDBACKPAGE,
    payload: data,
  });
};

export const submittingAddInterviewFeedbackRequest = () => ({
  type: INTERVIEWFEEDBACKS.SUBMITTINGADDINTERVIEWFEEDBACK,
});
export const submittingGetInterviewFeedbackRequest = () => ({
  type: INTERVIEWFEEDBACKS.SUBMITTINGGETINTERVIEWFEEDBACK,
});
export const submittingUpdateInterviewFeedbackRequest = () => ({
  type: INTERVIEWFEEDBACKS.SUBMITTINGUPDATEINTERVIEWFEEDBACK,
});
export const submittingDeleteInterviewFeedbackRequest = () => ({
  type: INTERVIEWFEEDBACKS.SUBMITTINGDELETEINTERVIEWFEEDBACK,
});

export const addInterviewFeedback = feedback => ({
  type: INTERVIEWFEEDBACKS.ADDINTERVIEWFEEDBACK,
  feedback,
});

export const addInterviewFeedbackSuccess = payload => ({
  type: INTERVIEWFEEDBACKS.ADDINTERVIEWFEEDBACKSUCCESS,
  payload,
});

export const getAllInterviewFeedbacks = role => ({
  type: INTERVIEWFEEDBACKS.GETINTERVIEWFEEDBACKS,
  role,
});

export const getAllInterviewFeedbacksSuccess = payload => ({
  type: INTERVIEWFEEDBACKS.GETINTERVIEWFEEDBACKSSUCCESS,
  payload,
});

export const updateInterviewFeedbackById = (
  allInterviewFeedbacks,
  feedbackId,
  newInterviewFeedback,
) => ({
  type: INTERVIEWFEEDBACKS.UPDATEINTERVIEWFEEDBACKBYID,
  allInterviewFeedbacks,
  feedbackId,
  newInterviewFeedback,
});

export const updateInterviewFeedbackSuccess = payload => ({
  type: INTERVIEWFEEDBACKS.UPDATEINTERVIEWFEEDBACKSUCCESS,
  payload,
});

export const deleteInterviewFeedbackById = (feedbacks, feedbackId) => ({
  type: INTERVIEWFEEDBACKS.DELETEINTERVIEWFEEDBACKBYID,
  feedbacks,
  feedbackId,
});

export const deleteInterviewFeedbackSuccess = payload => ({
  type: INTERVIEWFEEDBACKS.DELETEINTERVIEWFEEDBACKSUCCESS,
  payload,
});

// role action
export const getAllRoles = () => ({
  type: ROLES.GETROLES,
});

export const getAllRolesSuccess = payload => ({
  type: ROLES.GETROLESSUCCESS,
  payload,
});

// salary actions

export const submittingGetSalary = () => ({
  type: SALARY.SUBMITTINGGETSALARY,
});

export const submittingAddSalary = () => ({
  type: SALARY.SUBMITTINGADDSALARY,
});

export const addSalary = salary => ({
  type: SALARY.ADDSALARY,
  salary,
});

export const addSalarySuccess = payload => ({
  type: SALARY.ADDSALARYSUCCESS,
  payload,
});

export const getSalaryCompanies = () => ({
  type: SALARY.GETSALARYCOMPANIES,
});

export const getSalaryCompaniesSuccess = payload => ({
  type: SALARY.GETSALARYCOMPANIESSUCCESS,
  payload,
});

export const handleSalaryCompanyPageClick = data => dispatch => {
  dispatch({
    type: SALARY.CHANGEACTIVESALARYCOMPANYPAGE,
    payload: data,
  });
};

export const salaryError = error => ({
  type: SALARY.SALARYERROR,
  error,
});

// interview kit action

export const submittingInterviewKitRequest = () => ({
  type: INTERVIEWKIT.SUBMITTINGGETINTERVIEWKIT,
});

export const getInterviewKit = id => ({
  type: INTERVIEWKIT.GETINTERVIEWKIT,
  id,
});

export const getInterviewKitSuccess = payload => ({
  type: INTERVIEWKIT.GETINTERVIEWKITSUCCESS,
  payload,
});

export const getInterviewKitCompanies = () => ({
  type: INTERVIEWKIT.GETINTERVIEWKITCOMPANIES,
});

export const getInterviewKitCompaniesSuccess = payload => ({
  type: INTERVIEWKIT.GETINTERVIEWKITCOMPANIESSUCCESS,
  payload,
});

export const interviewKitError = error => ({
  type: INTERVIEWKIT.INTERVIEWKITERROR,
  error,
});

export const handleInterviewKitPageClick = data => dispatch => {
  dispatch({
    type: INTERVIEWKIT.CHANGEACTIVEINTERVIEWKITPAGE,
    payload: data,
  });
};
export const handleInterviewKitQuestionPageClick = data => dispatch => {
  dispatch({
    type: INTERVIEWKIT.CHANGEACTIVEINTERVIEWKITQUESTIONPAGE,
    payload: data,
  });
};

export const addInterviewQuestion = question => ({
  type: INTERVIEWKIT.ADDINTERVIEWQUESTION,
  question,
});

export const addInterviewQuestionSuccess = payload => ({
  type: INTERVIEWKIT.ADDINTERVIEWQUESTIONSUCCESS,
  payload,
});

export const updateInterviewQuestionRequest = payload => ({
  type: INTERVIEWKIT.UPDATEINTERVIEWQUESTION,
  payload,
});

export const handleInterviewKitCompanySearch = payload => ({
  type: INTERVIEWKIT.SEARCHINTERVIEWKITCOMPANY,
  payload,
});

// alumni list action

export const submittingAlumniListRequest = () => ({
  type: ALUMNILIST.SUBMITTINGGETALUMNILIST,
});

export const getAlumniList = id => ({
  type: ALUMNILIST.GETALUMNILIST,
  id,
});

export const getAlumniListSuccess = payload => ({
  type: ALUMNILIST.GETALUMNILISTSUCCESS,
  payload,
});

export const getAlumniListCompanies = () => ({
  type: ALUMNILIST.GETALUMNILISTCOMPANIES,
});

export const getAlumniListCompaniesSuccess = payload => ({
  type: ALUMNILIST.GETALUMNILISTCOMPANIESSUCCESS,
  payload,
});

export const AlumniListError = error => ({
  type: ALUMNILIST.ALUMNILISTERROR,
  error,
});

export const handleAlumniListPageClick = data => dispatch => {
  dispatch({
    type: ALUMNILIST.CHANGEACTIVEALUMNILISTPAGE,
    payload: data,
  });
};
export const handleAlumniListPersonPageClick = data => dispatch => {
  dispatch({
    type: ALUMNILIST.CHANGEACTIVEALUMNILISTPERSONPAGE,
    payload: data,
  });
};

export const addAlumni = alumni => ({
  type: ALUMNILIST.ADDALUMNI,
  alumni,
});

export const addAlumniSuccess = payload => ({
  type: ALUMNILIST.ADDALUMNISUCCESS,
  payload,
});

export const handleAlumniListCompanySearch = payload => ({
  type: ALUMNILIST.SEARCHALUMNILISTCOMPANY,
  payload,
});

// job opening action

export const submittingjobOpeningRequest = () => ({
  type: JOBOPENING.SUBMITTINGJOBOPENING,
});

export const getJobOpening = () => ({
  type: JOBOPENING.GETJOBOPENING,
});

export const getJobOpeningSuccess = payload => ({
  type: JOBOPENING.GETJOBOPENINGSUCCESS,
  payload,
});

export const getRecentJobOpeningSuccess = payload => ({
  type: JOBOPENING.GETRECENTJOBOPENINGSUCCESS,
  payload,
});

export const jobOpeningError = error => ({
  type: JOBOPENING.JOBOPENINGERROR,
  error,
});

export const handleJobOpeningPageClick = data => dispatch => {
  dispatch({
    type: JOBOPENING.CHANGEACTIVEJOBOPENINGPAGE,
    payload: data,
  });
};
export const handleJobSearch = payload => ({
  type: JOBOPENING.SEARCHJOBOPENING,
  payload,
});
export const addJobOpening = data => ({
  type: JOBOPENING.ADDJOBOPENING,
  data,
});

export const addJobOpeningSuccess = payload => ({
  type: JOBOPENING.ADDJOBOPENINGSUCCESS,
  payload,
});

// LeadershipInterviewFeedback

export const handleLeadershipInterviewFeedbackSearch = payload => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.SEARCHLEADERSHIPINTERVIEWFEEDBACK,
  payload,
});

export const leadershipInterviewFeedbackError = error => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.LEADERSHIPINTERVIEWFEEDBACKERROR,
  error,
});

export const handleLeadershipInterviewFeedbackPageClick = data => dispatch => {
  dispatch({
    type: LEADERSHIPINTERVIEWFEEDBACKS.CHANGEACTIVELEADERSHIPINTERVIEWFEEDBACKPAGE,
    payload: data,
  });
};

export const submittingAddLeadershipInterviewFeedbackRequest = () => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGADDLEADERSHIPINTERVIEWFEEDBACK,
});
export const submittingGetLeadershipInterviewFeedbackRequest = () => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGGETLEADERSHIPINTERVIEWFEEDBACK,
});
export const submittingUpdateLeadershipInterviewFeedbackRequest = () => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGUPDATELEADERSHIPINTERVIEWFEEDBACK,
});
export const submittingDeleteLeadershipInterviewFeedbackRequest = () => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGDELETELEADERSHIPINTERVIEWFEEDBACK,
});

export const addLeadershipInterviewFeedback = feedback => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.ADDLEADERSHIPINTERVIEWFEEDBACK,
  feedback,
});

export const addLeadershipInterviewFeedbackSuccess = payload => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.ADDLEADERSHIPINTERVIEWFEEDBACKSUCCESS,
  payload,
});

export const getAllLeadershipInterviewFeedbacks = role => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.GETLEADERSHIPINTERVIEWFEEDBACKS,
  role,
});

export const getAllLeadershipInterviewFeedbacksSuccess = payload => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.GETLEADERSHIPINTERVIEWFEEDBACKSSUCCESS,
  payload,
});

export const updateLeadershipInterviewFeedbackById = (
  allInterviewFeedbacks,
  feedbackId,
  newInterviewFeedback,
) => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.UPDATELEADERSHIPINTERVIEWFEEDBACKBYID,
  allInterviewFeedbacks,
  feedbackId,
  newInterviewFeedback,
});

export const updateLeadershipInterviewFeedbackSuccess = payload => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.UPDATELEADERSHIPINTERVIEWFEEDBACKSUCCESS,
  payload,
});

export const deleteLeadershipInterviewFeedbackById = (feedbacks, feedbackId) => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.DELETELEADERSHIPINTERVIEWFEEDBACKBYID,
  feedbacks,
  feedbackId,
});

export const deleteLeadershipInterviewFeedbackSuccess = payload => ({
  type: LEADERSHIPINTERVIEWFEEDBACKS.DELETELEADERSHIPINTERVIEWFEEDBACKSUCCESS,
  payload,
});

// prStatus actions

export const getPrStatus = () => ({
  type: PRSTATUS.GETPRSTATUS,
});

export const getPrStatusSuccess = payload => ({
  type: PRSTATUS.GETPRSTATUSSUCCESS,
  payload,
});

export const submittingPrStatusRequest = () => ({
  type: PRSTATUS.SUBMITTING_PRSTATUS,
});

export const handlePrStatusPageClick = data => dispatch => {
  dispatch({
    type: PRSTATUS.CHANGEACTIVEPRSTATUSPAGE,
    payload: data,
  });
};

export const prStatusError = error => ({
  type: PRSTATUS.PRSTATUSERROR,
  error,
});
