//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { updateCandidate, getAllInterviewers, getAllCohorts } from '../../redux/actions';
// import DisplayFormikState from '../DisplayFormikState';
import Autocomplete from '../Select/Autocomplete';

const styles = {
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  submitting: boolean,
  allCohorts: Array<Object>,
  candidates: Array<Object>,
  allInterviewers: Array<Object>,
  updateCandidate: Function,
  getAllInterviewers: Function,
  getAllCohorts: Function,
  history: Object,
  classes: Object,
  values: Object,
  handleChange: Function,
  resetForm: Function,
  error: string,
};

class UpdateRecomendedCandidateForm extends Component<Props> {
  componentDidMount() {
    const { getAllInterviewers, getAllCohorts } = this.props;
    getAllInterviewers();
    getAllCohorts();
  }

  renderProgress() {
    const { submitting } = this.props;
    if (!submitting) return null;
    return <h4 style={{ color: 'red' }}>Submitting Interviewer...</h4>;
  }

  renderAlert = () => {
    const { error } = this.props;
    if (!error) return null;
    return <p style={{ color: '#e50914' }}>{error}</p>;
  };

  render() {
    const {
      values,
      handleChange,
      classes,
      allCohorts,
      allInterviewers,
      candidates,
      updateCandidate,
      resetForm,
      history,
    } = this.props;
    const interviewerSuggestions = allInterviewers.map(ele => ({
      _id: ele._id,
      name: `${ele.userId.firstName} ${ele.userId.lastName}`,
    }));
    return (
      <form
        className={classes.container}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          updateCandidate(candidates, values, history);
          resetForm();
        }}
      >
        <h4>Update Candidate</h4>
        {this.renderProgress()}
        {this.renderAlert()}
        <TextField
          id="cohortId"
          variant="outlined"
          select
          label="Cohort"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.cohortId}
          onChange={handleChange('cohortId')}
          margin="normal"
        >
          {allCohorts.map(cohort => (
            <MenuItem key={cohort._id} value={cohort._id} style={{ fontSize: 15 }}>
              {cohort.name}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          handleChange={handleChange('interviewerId')}
          value={values.interviewerId}
          label="Interviewer"
          suggestions={interviewerSuggestions}
        />
        <button type="submit">Submit</button>
        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  allCohorts: state.cohortState.allCohorts,
  allInterviewers: state.interviewerState.allInterviewers,
  candidates: state.candidateState.allCandidates,
  error: state.candidateState.error,
  submitting: state.candidateState.submitting,
});

const mapDispatchToProps = dispatch => ({
  updateCandidate: (candidates, values, history) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    dispatch(updateCandidate(candidates, values, history)),
  getAllInterviewers: () => dispatch(getAllInterviewers()),
  getAllCohorts: () => dispatch(getAllCohorts()),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpdateRecomendedCandidateForm),
);
