//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import Interview from './Interview';
import './interviewer.scss';
// $FlowFixMe.
import moment from 'moment';

const styles = {
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};
type Props = {
  user: Object,
};
class LeadershipInterviewerDashboard extends Component<Props> {
  render() {
    const { user } = this.props;
    const interviewer = user.interviewerId;
    return (
      <div className="interviewer">
        <div className="table">
          <h3 className="title">Upcoming Candidate HR Interview Schedule</h3>
          {interviewer !== undefined &&
          interviewer.assignedLeadershipCandidateIds != undefined &&
          interviewer.assignedLeadershipCandidateIds.length > 0 ? (
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Candidate Name</div>
              <div className="columnMedium">Cohort</div>
              <div className="columnSmall">Slack Id</div>
              <div className="columnSmall">Strong Student?</div>
              <div className="columnMedium">Date</div>
              <div className="columnMedium">Start Time</div>
              <div className="columnMedium">End Time</div>
              <div className="columnSmall">Add Feedback</div>
            </div>
          ) : (
            <h4>No Interviews Pending</h4>
          )}
          {interviewer !== undefined &&
            interviewer.assignedLeadershipCandidateIds != undefined &&
            interviewer.assignedLeadershipCandidateIds.map((data, index) => {
              return (
                <Interview
                  key={data._id}
                  index={index}
                  id={data.candidateId._id}
                  candidateName={
                    data.candidateId.userId.firstName + ' ' + data.candidateId.userId.lastName
                  }
                  isLeadership={true}
                  slackId={data.candidateId.userId.slackId}
                  isQCCleared={data.candidateId.isQCCleared}
                  cohort={data.candidateId.cohortId.name}
                  date={moment(data.timeSlot.startTime)
                    .format('ddd MMM D, YYYY')
                    .toString()}
                  startTime={moment(data.timeSlot.startTime)
                    .format('hh : mm A')
                    .toString()}
                  endTime={moment(data.timeSlot.endTime)
                    .format('hh : mm A')
                    .toString()}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {},
  )(LeadershipInterviewerDashboard),
);
