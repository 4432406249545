//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { addCandidate, getAllCohorts, getAllSchools, getAllCompanies } from '../../redux/actions';
// import DisplayFormikState from '../DisplayFormikState';
import Autocomplete from '../Select/Autocomplete';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  error: string,
  submitting: boolean,
  allCohorts: Array<Object>,
  allSchools: Array<Object>,
  addCandidate: Function,
  values: Object,
  errors: Object,
  handleChange: Function,
  resetForm: Function,
  allCompanies: Array<Object>,
  getAllCohorts: Function,
  getAllSchools: Function,
  getAllCompanies: Function,
  history: Object,
  classes: Object,
};

class AddCandidateForm extends Component<Props> {
  componentDidMount() {
    const { getAllCohorts, getAllSchools, getAllCompanies } = this.props;
    getAllCohorts();
    getAllSchools();
    getAllCompanies();
  }

  render() {
    const {
      values,
      handleChange,
      errors,
      classes,
      allCohorts,
      allSchools,
      allCompanies,
      addCandidate,
      resetForm,
      history,
      submitting,
      error,
    } = this.props;
    return (
      <form
        className={classes.container}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          addCandidate(values, history);
          resetForm();
        }}
      >
        <h4>Add a Candidate</h4>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding Candidate</p>}
        />
                    
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <TextField
          required
          variant="outlined"
          value={values.firstName}
          onChange={handleChange}
          id="firstName"
          label="First Name"
          placeholder="Jane"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.firstName}
        />
        <TextField
          required
          variant="outlined"
          value={values.lastName}
          onChange={handleChange}
          id="lastName"
          label="Last Name"
          placeholder="Doe"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          helperText={errors.lastName}
        />
        <TextField
          id="email"
          label="Email"
          type="email"
          value={values.email}
          onChange={handleChange}
          name="email"
          placeholder="janedoe@gmail.com"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.email}
        />
        <TextField
          id="cohortId"
          variant="outlined"
          select
          label="Cohort"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.cohortId}
          onChange={handleChange('cohortId')}
          margin="normal"
        >
          {allCohorts.map(cohort => (
            <MenuItem key={cohort._id} value={cohort._id} style={{ fontSize: 15 }}>
              {cohort.name}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          handleChange={handleChange('schoolId')}
          value={values.schoolId}
          label="School"
          suggestions={allSchools}
        />
        <Autocomplete
          handleChange={handleChange('companyId')}
          value={values.companyId}
          label="Company"
          suggestions={allCompanies}
        />
        <button type="submit">Submit</button>
        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  allCohorts: state.cohortState.allCohorts,
  allSchools: state.schoolState.allSchools,
  allCompanies: state.companyState.allCompanies,
  error: state.candidateState.error,
  submitting: state.candidateState.submitting,
});

const mapDispatchToProps = dispatch => ({
  addCandidate: (values, history) => dispatch(addCandidate(values, history)),
  getAllCohorts: () => dispatch(getAllCohorts()),
  getAllSchools: () => dispatch(getAllSchools()),
  getAllCompanies: () => dispatch(getAllCompanies()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddCandidateForm));
