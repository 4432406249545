//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import './feedback.scss';

type Props = {
  id: string,
  cohortId: Object,
  interviewerId: Object,
  recommendedCandidateId: string,
  recommendCandidate: Function,
  isInterviewer: boolean,
  isCohortManager: boolean,
  name: string,
  email: string,
  index: number,
  candidateName: string,
  interviewerName: string,
  createdAt: string,
  rating: number,
  candidates: Array<Object>,
};

const Feedback = (props: Props) => {
  const {
    id,
    index,
    candidateName,
    interviewerName,
    interviewerId,
    createdAt,
    rating,
    interviewPerformanceAdded,
  } = props;
  const roles = localStorage.getItem('s30Roles') || {};
  return (
    <div className="tbody">
      <Link to={`/feedback/${id}`} key={id} className="link">
        <div className="row">
          <div className="columnSmall">{index + 1}</div>
          <div className="columnMedium">{candidateName}</div>
          <div className="columnMedium">{interviewerName}</div>
          <div className="columnMedium">
            <Moment format="YYYY/MM/DD">{createdAt}</Moment>
          </div>
          <div className="columnSmall">{rating}</div>
          <div className="columnLarge">{rating >= 8 ? <b>Pass</b> : <b>Need To Improve</b>}</div>

          <Link to={`/addperformance/${interviewerId}/${id}`} key={id} className="link">
            <div className="columnAction">
              {!interviewPerformanceAdded && !roles.includes('Interviewer') ? (
                <button type="submit">Rate</button>
              ) : (
                <></>
              )}
            </div>
          </Link>
        </div>
      </Link>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Feedback);
