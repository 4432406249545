//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../Sidebar/Sidebar';
import moment from 'moment';
import { render } from 'react-dom';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';

class StudentPaymentCancelled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: null,
    };
  }
  async componentDidMount() {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        headers: {
          Authorization: token,
        },
      };
      const { sessionId } = this.props.match.params;
      const apiurl = `${ROOT_URL}/api/cancelled-checkout-session/${sessionId}`;
      const response = await axios.get(apiurl, headers);
      await this.setState({
        session: response.data.session,
      });
    } catch (error) {}
  }

  render() {
    const { session } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div style={{ display: 'flex', justifyContent: 'center', margin: '4rem' }}>
          <h4>
            Payment didn't go through. Please contact tech team at S30.<br></br>
          </h4>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.authState.user.candidateId,
});

export default connect(mapStateToProps)(StudentPaymentCancelled);
