import { COHORTMANAGER } from '../constants';

const initialState = {
  allCohortManagers: [],
  error: false,
};

const cohortManagerReducer = (cohortManagerState = initialState, action) => {
  switch (action.type) {
    case COHORTMANAGER.GETCOHORTMANAGERSSUCCESS:
      return { ...cohortManagerState, allCohortManagers: [...action.payload] };
    case COHORTMANAGER.COHORTMANAGERERROR:
      return { ...cohortManagerState, error: action.error };
    default:
      return cohortManagerState;
  }
};
export default cohortManagerReducer;
