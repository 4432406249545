import { call, put } from 'redux-saga/effects';
import { getJobOpenings, addJobOpening } from '../../api';
import {
  getJobOpeningSuccess,
  getRecentJobOpeningSuccess,
  jobOpeningError,
  submittingjobOpeningRequest,
  addJobOpeningSuccess,
} from '../actions/index';
import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getJobOpeningSaga() {
  try {
    yield put(submittingjobOpeningRequest());
    const jobs = yield call(getJobOpenings);
    var recentJobs = jobs.data.slice(jobs.data.length - 10);
    recentJobs = recentJobs.reverse();
    yield put(getRecentJobOpeningSuccess(recentJobs));
    const jobsData = jobs.data.sort((a, b) => {
      return a.Company < b.Company ? -1 : a.Company > b.Company ? 1 : 0;
    });
    yield put(getJobOpeningSuccess(jobsData));
  } catch (error) {
    yield put(jobOpeningError(error.toString()));
    yield delay(3000);
    yield put(jobOpeningError(''));
  }
}

export function* addJobOpeningSaga(action) {
  try {
    yield put(submittingjobOpeningRequest());
    const { data } = action;
    const jobOpening = yield call(addJobOpening, data);
    yield put(addJobOpeningSuccess(jobOpening));
    history.push('/jobs');
  } catch (error) {
    yield put(jobOpeningError(error.toString()));
    yield delay(3000);
    yield put(jobOpeningError(''));
  }
}
