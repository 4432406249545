//@flow
import React, { useState } from 'react';
import plus from '../../static/plus.png';
import minus from '../../static/minus.png';
import './Faq.scss';

type Props = {
  ques: string,
  ans: string,
  no: number,
};
const Faq = (props: Props) => {
  const { ques, ans, no } = props;

  const [newShow, setShow] = useState(false);
  return (
    <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={e => setShow(!newShow)}>
      <hr />
      <p className="ques">
        <img alt="" src={newShow ? minus : plus} />
        <b>{ques}</b>
      </p>
      <div>{newShow ? <p className="ans">{ans}</p> : null}</div>
    </div>
  );
};
export default Faq;
