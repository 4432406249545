//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updateCompany, toggleCompanyEdit } from '../../redux/actions';

type Props = {
  companies: Array<Object>,
  id: string,
  name: string,
  index: number,
  isEditing: boolean,
  updateCompany: Function,
  toggleCompanyEdit: Function,
};
type State = {
  isEditing: boolean,
  id: string,
  name: string,
  emailService: boolean,
};
class Company extends Component<Props, State> {
  form = ({ handleSubmit, handleChange, values, errors }) => (
    <form method="POST" onSubmit={handleSubmit}>
      <div className="row">
        {<p style={{ color: 'red' }}>{errors.name}</p>}
        <div className="columnLarge">
          <label htmlFor="ApplyForm__FirstName">
            Name
            <input id="name" onChange={handleChange} value={values.name} type="text" />
          </label>
        </div>
        <div className="columnAction">
          <button type="submit">Submit</button>
        </div>
      </div>
    </form>
  );

  toggleCompanyEdit = (e, id) => {
    e.preventDefault();
    const { toggleCompanyEdit, companies } = this.props;
    toggleCompanyEdit(companies, id);
  };

  render() {
    const { index, name, id, isEditing } = this.props;
    return (
      <div className="tbody">
        {!isEditing ? (
          <div className="row">
            <div className="columnSmall">{index}</div>
            <div className="columnMedium">{name}</div>
            <div className="columnAction">
              <button type="submit" onClick={e => this.toggleCompanyEdit(e, id)}>
                Edit
              </button>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={{
              id,
              name,
            }}
            onSubmit={values => {
              const { updateCompany, companies } = this.props;
              updateCompany(companies, values);
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Company Name is required'),
            })}
            component={this.form}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.companyState.allCompanies,
});

export default connect(mapStateToProps, { updateCompany, toggleCompanyEdit })(Company);
