import { call, put } from 'redux-saga/effects';
import { getAllPricings } from '../../api';
import { getAllPricingsSuccess, pricingError } from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllPricingsSaga() {
  try {
    const pricings = yield call(getAllPricings);
    yield put(getAllPricingsSuccess(pricings.data));
  } catch (error) {
    yield put(pricingError(error.toString()));
    yield delay(3000);
    yield put(pricingError(''));
  }
}
