import { call, put } from 'redux-saga/effects';
import { getAllCohortManagers } from '../../api';
import { getAllCohortManagersSuccess, cohortManagerError } from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllCohortManagersSaga() {
  try {
    const cohortManagers = yield call(getAllCohortManagers);
    yield put(getAllCohortManagersSuccess(cohortManagers.data));
  } catch (error) {
    yield put(cohortManagerError(error.toString()));
    yield delay(3000);
    yield put(cohortManagerError(''));
  }
}
