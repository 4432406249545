import { DSPROBLEMS } from '../constants';

const initialState = {
  allDSProblems: [],
  filteredDSProblems: [],
  activePage: 0,
  perPage: 8,
  error: false,
  submitting: false,
};
const dsproblemReducer = (dsproblemState = initialState, action) => {
  switch (action.type) {
    case DSPROBLEMS.ADDDSPROBLEMSUCCESS:
      return {
        ...dsproblemState,
        allDSProblems: [...dsproblemState.allDSProblems, { ...action.payload }],
        submitting: false,
      };
    case DSPROBLEMS.CHANGEACTIVEDSPROBLEMPAGE:
      return { ...dsproblemState, activePage: action.payload };
    case DSPROBLEMS.DELETEDSPROBLEMSUCCESS:
      return { ...dsproblemState, allDSProblems: [...action.payload], submitting: false };
    case DSPROBLEMS.GETDSPROBLEMSSUCCESS:
      return {
        ...dsproblemState,
        allDSProblems: [...action.payload],
        filteredDSProblems: [],
        submitting: false,
      };
    case DSPROBLEMS.SEARCHDSPROBLEM:
      return { ...dsproblemState, filteredDSProblems: [...action.payload], submitting: false };
    case DSPROBLEMS.DSPROBLEMERROR:
      return { ...dsproblemState, error: action.error, submitting: false };
    case DSPROBLEMS.SUBMITTING_DSPROBLEM:
      return { ...dsproblemState, submitting: true };
    case DSPROBLEMS.UPDATEDSPROBLEMSUCCESS:
      return { ...dsproblemState, allDSProblems: [...action.payload], submitting: false };
    default:
      return dsproblemState;
  }
};

export default dsproblemReducer;
