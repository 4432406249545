//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllCompanies, handleCompanyPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Company from './Company';
import Sidebar from '../Sidebar/Sidebar';
import './Company.scss';
import SearchBar from '../SearchBar/SearchBar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  companies: Array<Object>,
  handleCompanyPageClick: Function,
  getAllCompanies: Function,
  error: string,
  classes: Object,
  submitting: boolean,
};
class Companies extends Component<Props> {
  componentDidMount() {
    const { getAllCompanies } = this.props;
    getAllCompanies();
  }

  handleCompanyPageClick = data => {
    const { handleCompanyPageClick } = this.props;
    handleCompanyPageClick(data.selected);
  };

  render() {
    const {
      companies,
      activePage,
      perPage,
      submitting,
      error,
      classes,
      filteredCompanies,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Getting Companies...</p>}
        />
                
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <div className="company">
          <div className="table">
            <h3 className="title">All Companies</h3>
            <div className="searchContainer">
              <SearchBar placeHolder="Search by Company" type="companySearchByName" />
            </div>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Name</div>
              <div className="columnAction">Edit</div>
            </div>
            {filteredCompanies.length > 0
              ? filteredCompanies.map((company, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Company
                        key={company._id}
                        id={company._id}
                        index={index + 1}
                        name={company.name}
                        isEditing={company.isEditing}
                      />
                    );
                  }
                  return null;
                })
              : companies.map((company, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Company
                        key={company._id}
                        id={company._id}
                        index={index + 1}
                        name={company.name}
                        isEditing={company.isEditing}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={companies.length / perPage}
            onPageChange={this.handleCompanyPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.companyState.activePage,
  perPage: state.companyState.perPage,
  companies: state.companyState.allCompanies,
  error: state.companyState.error,
  submitting: state.companyState.submitting,
  filteredCompanies: state.companyState.filteredCompanies,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllCompanies, handleCompanyPageClick })(Companies),
);
