//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCohort } from '../../redux/actions';
import moment from 'moment';

type Props = {
  cohorts: Array<Object>,
  id: string,
  name: string,
  isDataScienceCohort: Boolean,
  problemIndex: number,
  dayIndex: number,
  emailService: boolean,
  isWestCoastCohort: Boolean,
  isMorningCohort: Boolean,
  updateCohort: Function,
  noOfCandidates: number,
};

type State = {
  isEditing: boolean,
  id: string,
  name: string,
  startDate: Date,
  problemIndex: number,
  dayIndex: number,
  emailService: boolean,
  isDataScienceCohort: boolean,
  noOfCandidates: number,
};
class Cohort extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      id: props.id,
      name: props.name,
      noOfCandidates: props.noOfCandidates,
      problemIndex: props.problemIndex,
      dayIndex: props.dayIndex,
      emailService: props.emailService,
      startDate: props.startDate,
      isMorningCohort: props.isMorningCohort,
      isDataScienceCohort: props.isDataScienceCohort,
    };
  }

  updateCohort = () => {
    const { updateCohort, cohorts } = this.props;
    updateCohort(cohorts, this.state);
    this.toggleEdit();
  };

  handleInput = (e, type) => {
    e.preventDefault();
    this.setState({
      [type]: e.target.value,
    });
  };

  toggleEdit = () => {
    const { isEditing } = this.state;
    this.setState({
      isEditing: !isEditing,
    });
  };

  toggelEmailService = async () => {
    const { emailService } = this.state;
    await this.setState({
      emailService: !emailService,
    });
    await this.updateCohort();
    this.toggleEdit();
  };

  render() {
    const {
      isEditing,
      name,
      problemIndex,
      isDataScienceCohort,
      dayIndex,
      noOfCandidates,
      emailService,
      startDate,
      isMorningCohort,
    } = this.state;
    const { roles } = this.props;
    return (
      <div className="tbody">
        {!isEditing ? (
          <div className="row">
            <div className="columnMedium">{name}</div>
            <div className="columnSmall">{problemIndex}</div>
            <div className="columnSmall">{dayIndex}</div>
            <div className="columnSmall">{isMorningCohort ? 1 : 0}</div>
            <div className="columnSmall">{isDataScienceCohort ? 1 : 0}</div>
            <div className="columnMedium">
              {moment(startDate)
                .format('DD,MMM,YYYY')
                .toString()}
            </div>
            <div className="columnSmall">{noOfCandidates}</div>
            {roles.includes('Admin') && (
              <div className="columnAction">
                {!emailService ? (
                  <button
                    type="submit"
                    onClick={this.toggelEmailService}
                    style={{
                      backgroundColor: 'red',
                    }}
                  >
                    Start
                  </button>
                ) : (
                  <button type="submit" onClick={this.toggelEmailService}>
                    Stop
                  </button>
                )}
              </div>
            )}
            {roles.includes('Admin') && (
              <div className="columnAction">
                <button type="submit" onClick={this.toggleEdit}>
                  Edit
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="row">
            <form className="Cohortform" onSubmit={this.updateCohort}>
              <div>
                <input onChange={e => this.handleInput(e, 'name')} value={name} name="name" />
              </div>
              <div>
                <input
                  onChange={e => this.handleInput(e, 'problemIndex')}
                  value={problemIndex}
                  name="problemIndex"
                />
              </div>
              <div>
                <input
                  onChange={e => this.handleInput(e, 'dayIndex')}
                  value={dayIndex}
                  name="dayIndex"
                />
              </div>
              <div>
                <input
                  onChange={e => this.handleInput(e, 'isMorningCohort')}
                  value={isMorningCohort}
                  name="isMorningCohort"
                />
              </div>
              <div>
                <input
                  onChange={e => this.handleInput(e, 'isDataScienceCohort')}
                  value={isDataScienceCohort}
                  name="isDataScienceCohort"
                />
              </div>
            </form>
            <button type="submit" onClick={this.updateCohort}>
              Submit
            </button>
            <button type="submit" onClick={this.toggleEdit}>
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cohorts: state.cohortState.allCohorts,
});

export default connect(mapStateToProps, { updateCohort })(Cohort);
