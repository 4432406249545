//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllPricings } from '../../redux/actions';
import LegacyPayment from './LegacyPayment';
import './Payment.scss';

type Props = {
  pricings: Array<Object>,
  getAllPricings: Function,
};
class LegacyPayments extends Component<Props> {
  componentDidMount() {
    const { getAllPricings } = this.props;
    getAllPricings();
  }

  render() {
    const { pricings } = this.props;
    return (
      <div className="mainBody">
        <div className="paymentPlans">
          {pricings.map((plan, index) => {
            return (
              index >= 2 && (
                <LegacyPayment
                  key={plan._id}
                  id={plan._id}
                  amountPayableNow={plan.amountPayableNow}
                  postJobPayableAmount={plan.postJobPayableAmount}
                  totalAmountPayable={plan.totalAmountPayable}
                  name={plan.name}
                  dueDate={plan.dueDate}
                />
              )
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pricings: state.pricingState.allPricings,
  error: state.pricingState.error,
});

export default connect(mapStateToProps, { getAllPricings })(LegacyPayments);
