import { SCHOOLS } from '../constants';

const initialState = {
  allSchools: [],
  activePage: 0,
  perPage: 10,
  error: false,
  submitting: false,
};
const schoolReducer = (schoolState = initialState, action) => {
  switch (action.type) {
    case SCHOOLS.ADDSCHOOLSUCCESS:
      return {
        ...schoolState,
        allSchools: [...schoolState.allSchools, { ...action.payload }],
        submitting: false,
      };
    case SCHOOLS.GETSCHOOLSSUCCESS:
      return { ...schoolState, allSchools: [...action.payload], submitting: false };
    case SCHOOLS.SCHOOLERROR:
      return { ...schoolState, error: action.error, submitting: false };
    case SCHOOLS.CHANGEACTIVESCHOOLPAGE:
      return { ...schoolState, activePage: action.payload };
    case SCHOOLS.SUBMITTING_SCHOOL:
      return { ...schoolState, submitting: true };
    case SCHOOLS.TOGGLESCHOOLEDITSUCCESS:
      return { ...schoolState, allSchools: [...action.payload], submitting: false };
    case SCHOOLS.UPDATESCHOOLSUCCESS:
      return { ...schoolState, allSchools: [...action.payload], submitting: false };
    default:
      return schoolState;
  }
};
export default schoolReducer;
