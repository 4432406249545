//@flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
import { addSchool } from '../../redux/actions';
import Sidebar from '../Sidebar/Sidebar';
import './Schools.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  error: string,
  submitting: boolean,
  addSchool: Function,
  classes: Object,
  history: Object,
};

class AddSchool extends Component<Props> {
  form = ({ values, handleChange }) => (
    <Form>
      <div className="formContainer">
        <h4>Add a new School</h4>
        <TextField
          required
          value={values.name}
          onChange={handleChange}
          id="name"
          label="Name"
          placeholder="USC"
          margin="normal"
          inputProps={{
            style: { fontSize: 15 },
          }}
        />
        <button type="submit">Submit</button>
      </div>
    </Form>
  );

  render() {
    const { submitting, error, classes } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding School...</p>}
        />{' '}
                    
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={(values, { resetForm }) => {
            const { addSchool, history } = this.props;
            addSchool(values, history);
            resetForm();
          }}
          component={this.form}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addSchool: (values, history) => dispatch(addSchool(values, history)),
});

const mapStateToProps = state => ({
  error: state.schoolState.error,
  submitting: state.schoolState.submitting,
});

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(AddSchool),
  ),
);
