import React, { Component } from 'react';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import moment from 'moment';
import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';
import history from '../../history';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 50,
  },
  resize: {
    fontSize: 15,
  },
  textField: {
    marginLeft: 80,
  },
};
type Props = { classes: Object, match: Object };
type State = {
  submitting: boolean,
  submitted: boolean,
  startTime: string,
  endTime: string,
  date: string,
};
class TimePicker extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      submitting: false,
      submitted: false,
      startTime: '18:00',
      endTime: '20:15',
      id: '',
      date: moment().format('YYYY-MM-DD'),
    };
  }
  componentDidMount() {
    if (this.props.match) {
      this.setState({ id: this.props.match.params.id });
    }
  }

  handleInput = (e, type) => {
    e.preventDefault();
    this.setState({ [type]: e.target.value });
  };

  addTimeslot = async e => {
    e.preventDefault();
    await this.setState({ submitting: true });
    let apiurl;
    const { id } = this.state;
    if (id != '') {
      apiurl = `${ROOT_URL}/api/interviewer/timeslot/${id}`;
    } else {
      apiurl = `${ROOT_URL}/api/interviewer`;
    }
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    try {
      await axios.put(apiurl, this.state, headers);
      this.setState({ submitting: false, submitted: true });
      setTimeout(() => {
        this.setState({ submitted: false });
        history.push('/interviewers');
      }, 1000);
    } catch (error) {
      console.log('error in adding timeslot', error);
    }
  };

  addTimeslotFromSheet = async e => {
    e.preventDefault();
    await this.setState({ submitting: true });
    const apiurl = `${ROOT_URL}/api/interviewersheet`;

    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    try {
      await axios.put(apiurl, {}, headers);
      this.setState({ submitting: false, submitted: true });
      setTimeout(() => {
        this.setState({ submitted: false });
        history.push('/interviewers');
      }, 1000);
    } catch (error) {
      console.log('error in adding timeslot', error);
    }
  };

  addAlumniTimeslotFromSheet = async e => {
    e.preventDefault();
    await this.setState({ submitting: true });
    const apiurl = `${ROOT_URL}/api/interviewerAlumnisheet`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    try {
      await axios.put(apiurl, {}, headers);
      this.setState({ submitting: false, submitted: true });
      setTimeout(() => {
        this.setState({ submitted: false });
        history.push('/interviewers');
      }, 1000);
    } catch (error) {
      console.log('error in adding timeslot', error);
    }
  };

  render() {
    const { startTime, submitting, submitted, endTIme, id } = this.state;
    const { classes } = this.props;
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className={id != '' ? 'mainBody' : ''}
      >
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding Timeslot...</p>}
        />

        <div>
          <h3> Pick Timeslot for Mock Interviews</h3>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitted}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Timeslot Added</p>}
          />
          <form className={classes.container}>
            <TextField
              className={classes.textField}
              variant="outlined"
              id="date"
              label="Pick Date"
              type="date"
              onChange={e => this.handleInput(e, 'date')}
              value={this.state.date}
              InputLabelProps={{
                className: classes.resize,
                shrink: true,
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              id="startTime"
              label="Start Time"
              onChange={e => this.handleInput(e, 'startTime')}
              type="time"
              value={this.state.startTime}
              InputLabelProps={{
                className: classes.resize,
                shrink: true,
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              id="endTime"
              label="End Time"
              onChange={e => this.handleInput(e, 'endTime')}
              type="time"
              value={this.state.endTime}
              InputLabelProps={{
                className: classes.resize,
                shrink: true,
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
            <button style={{ marginLeft: 60 }} onClick={e => this.addTimeslot(e)}>
              Add
            </button>
            {id == '' && (
              <button style={{ marginLeft: 60 }} onClick={e => this.addTimeslotFromSheet(e)}>
                Add from sheets
              </button>
            )}
          </form>
        </div>
        <div>
          <button style={{ margin: 30 }} onClick={e => this.addAlumniTimeslotFromSheet(e)}>
            Alumni Slot Sheets
          </button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TimePicker);
