import addNotification from 'react-push-notification';

export const notification = (title, subtitle, message) => {
  addNotification({
    title,
    subtitle,
    message,
    theme: 'darkblue',
    native: true // when using native, your OS will handle theming.
  });
}
