//@flow
import React, { Component } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { render } from 'react-dom';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';
import Custompaginate from '../Custompaginate/Custompaginate';
import './recruiterData.scss';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

class RecruiterDataByCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      recruiters: [],
      perPage: 8,
      activePage: 0,
    };
  }
  async componentDidMount() {
    try {
      await this.setState({
        submitting: true,
      });
      const { id } = this.props.match.params;
      const apiurl = `${ROOT_URL}/api/getRecruiterDataByCompanyId/${id}`;
      const token = localStorage.getItem('token');
      const headers = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(apiurl, headers);
      await this.setState({
        submitting: false,
        recruiters: [...response.data],
      });
    } catch (error) {
      console.log(error);
    }
  }
  handleRecruiterPageClick = async data => {
    try {
      await this.setState({ activePage: data.selected });
    } catch (error) {}
  };
  render() {
    const { classes } = this.props;
    const { submitting, recruiters, perPage, activePage } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="recruiterData">
          <div className="table">
            <h3 className="title">Recruiters</h3>
            <p>Any questions about recruiters or want to add new recruiter to list?</p>
            <p>Ping Sowmya N on slack</p>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Recruiters...</p>}
            />

            <div className="thead">
              <div className="columnAction">S. No</div>
              <div className="columnMedium">Name</div>
              <div className="columnMedium">Title</div>
              <div className="columnMedium">Location</div>
              <div className="columnMedium">LinkedIn</div>
            </div>
            {recruiters.map((recruiter, index) => {
              const { sNo, recruiterName, recruiterTitle, linkedinUrl, location } = recruiter;
              const bottomIdx = activePage * perPage;
              const topIdx = bottomIdx + perPage;
              if (index >= bottomIdx && index < topIdx) {
                return (
                  <div className="tbody">
                    <div className="row">
                      <div className="columnAction">{sNo}</div>
                      <div className="columnMedium">{recruiterName}</div>
                      <div className="columnMedium">{recruiterTitle}</div>
                      <div className="columnMedium">{location}</div>
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(`${linkedinUrl}`, '_blank')}
                      >
                        <div className="columnMedium">LinkedIn Profile</div>
                      </a>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <Custompaginate
            pageCount={recruiters.length / perPage}
            onPageChange={this.handleRecruiterPageClick}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RecruiterDataByCompany);
