//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import { connect } from 'react-redux';
import { addAlumni } from '../../redux/actions';
import Snackbar from '@material-ui/core/Snackbar';
import { MenuItem } from '@material-ui/core';
import history from '../../history';
import { Field } from 'formik';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
    lineHeight: 1.5,
  },

  resizeName: {
    fontSize: 18,
  },
  textField: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 30,
    fontSize: 18,
    fontWeight: 500,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  values: Object,
  handleChange: Function,
  classes: Object,
  resetForm: Function,
  submitting: boolean,
  alumniListState: Array,
  user: Object,
  history: Object,
  error: string,
  errors: Object,
  addAlumni: Function,
};

class addAlumniForm extends Component<Props> {
  render() {
    const {
      values,
      handleChange,
      classes,
      resetForm,
      history,
      submitting,
      error,
      allCompanies,
      errors,
      user,
      addAlumni,
    } = this.props;

    return (
      <div>
        <form
          style={styles.container}
          onSubmit={e => {
            e.preventDefault();
            addAlumni(values);
          }}
        >
          <div style={styles.miniContainer}>
            <h1>Add Alumni</h1>

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Success! Great Going Sowmya</p>}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <TextField
              required
              variant="outlined"
              style={styles.textField}
              value={values.Name}
              onChange={handleChange('Name')}
              label="Name"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.myHelperTextStyle,
                },
              }}
              helperText={errors.username}
            />
            <TextField
              required
              variant="outlined"
              style={styles.textField}
              value={values.LinkedInProfile}
              onChange={handleChange('LinkedInProfile')}
              label="LinkedIn Profile"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              required
              id="Company"
              variant="outlined"
              select
              label="Company"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              value={values.Company}
              onChange={handleChange('Company')}
              margin="normal"
              FormHelperTextProps={{
                classes: {
                  root: classes.myHelperTextStyle,
                },
              }}
            >
              {allCompanies.map(company => (
                <MenuItem key={company.index} value={company.index} style={{ fontSize: 15 }}>
                  {company.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              style={styles.textField}
              value={values.PhoneNumber}
              onChange={handleChange('PhoneNumber')}
              label="Phone Number"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={values.Title}
              onChange={handleChange('Title')}
              label="Title"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={values.Email}
              onChange={handleChange('Email')}
              label="Email"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
              }}
            />
            <label>
              <Field
                type="checkbox"
                name="WantsCC"
                value={values.WantsCC}
                onChange={handleChange('WantsCC')}
              />
              Wants CC?
            </label>
            <button type="submit">Submit</button>
            <br />
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  submitting: state.alumniListState.submitting,
  error: state.alumniListState.error,
  allCompanies: state.alumniListState.allAlumniListCompanies,
});
const mapDispatchToProps = dispatch => ({
  addAlumni: values => dispatch(addAlumni(values)),
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(addAlumniForm));
