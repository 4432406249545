//@flow
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ReactMarkdown from 'react-markdown';
import './GetInterview.scss';

const markdown = `
> "Getting interviews is difficult. But more difficult than that is, 
getting an offer once you have an interview call."

# How to get Interviews 
> Human mind desires something which is desired by other humans as well.

## Don'ts 
- Don't reach out to the recruiters with a desparate mindset.
- Don't try to use a long template to reach out. They don't have time to read your long paragraphs of technical projects and past experience. 
- Don't try to go "impress" recruiters with your technical expertise. They don't understand technical stuff.
- Don't form an opinion based on single recruiter response. Reach out to multiple recruiters of same company to complete the picture.
- Don't expect that everyone will respond. Standard response percentage is less than 10% in cold outreach.
- Don't stalk them everyday. They may not be recruiting fresh grads yet.

## Do's
- Target University Recruiters if you are a new grad.
- Target General Software Engineer roles.
- Try and trigger this feeling in recriter mind. "If other recruiters are inviting him for interviews, he must be good."
- Your initial reach out should be 2/3 sentences max. Be respectful of their time. You are one of 100 students who has reached out to her. She won't even read it, if it more than six seven sentences. 
- Understand what are the positions they are recruiting for. Most recruiters announce it on LinkedIn. 
- Engage with them regularly on Twitter and Instagram. Most other students won't do it.

## Example Formats
`;
const GetInterviews = () => (
  <div className="mainBody">
    <Sidebar />
    <div className="getInterviews">
      <ReactMarkdown source={markdown}></ReactMarkdown>
      <h4>When you have an offer</h4>
      <article>
        <p>Dear [Name of recruiter]</p>
        <p>
          <br></br>
        </p>
        <p>
          I am an M.S computer science student currently actively interviewing with top tech
          companies. I recently got a full time offer from Amazon. However, I am highly passionate
          about working with team Google. I was wondering if you are recruiting new grad engineers
          at Google.
        </p>
        <p>
          <br></br>
        </p>
        <p>Thanks&nbsp;</p>
        <p>[Your Name]&nbsp;</p>
      </article>
      <h4>When you have an interview invite from xyz company</h4>
      <article>
        <p>Dear [Name of recruiter]</p>
        <p>
          <br></br>
        </p>
        <p>
          I am an M.S computer science student currently actively interviewing with top tech
          companies. I am pretty confident that I will be able to get an offer. However, I am highly
          passionate about working with team Google. I was wondering if you are recruiting new grad
          engineers at Google.
        </p>
        <p>
          <br></br>
        </p>
        <p>Thanks&nbsp;</p>
        <p>[Your Name]&nbsp;</p>
      </article>
      <h4>When you have don't have an interview invite</h4>
      <article>
        <p>Dear [Name of recruiter]</p>
        <p>
          <br></br>
        </p>
        <p>
          I am an M.S computer science student. After putting in four months into the interview
          preparation, I have started actively interviewing with top tech companies. I am highly
          passionate about working with team Google. I was wondering if you are recruiting new grad
          engineers at Google.
        </p>
        <p>
          <br></br>
        </p>
        <p>Thanks&nbsp;</p>
        <p>[Your Name]&nbsp;</p>
      </article>
    </div>
  </div>
);

export default GetInterviews;
