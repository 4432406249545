//@flow
import React from 'react';
import './interviewer.scss';
import { Link } from 'react-router-dom';

type Props = {
  id: string,
  index: number,
  candidateName: string,
  slackId: string,
  date: string,
  startTime: string,
  endTime: string,
  isLeadership: Boolean,
};
const Interview = (props: Props) => {
  return (
    <div className="tbody">
      <div>
        <div className="row">
          <div className="columnAction">{props.index + 1}</div>
          <div className="columnSmall">{props.candidateName}</div>
          <div className="columnSmall">{props.cohort}</div>
          <div className="columnMedium">{props.slackId}</div>
          <div className="columnSmall">{props.isQCCleared ? <b>true</b> : <b>false</b>}</div>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(`${props.linkedInUrl}`, '_blank')}
          >
            <div className="columnSmall">LinkedInProfile</div>
          </a>
          <div className="columnMedium">{props.date}</div>
          <div className="columnSmall">{props.startTime}</div>
          <div className="columnSmall">{props.endTime}</div>
          <div className="columnSmall">
            {props.isLeadership ? (
              <Link
                // to="/addfeedback"
                to={{
                  pathname: '/addleadershipfeedback',
                  state: {
                    id: props.id,
                    name: props.candidateName,
                    isLeadership: props.isLeadership,
                  },
                }}
              >
                <button>Add </button>
              </Link>
            ) : (
              <Link
                // to="/addfeedback"
                to={{
                  pathname: '/addfeedback',
                  state: {
                    id: props.id,
                    name: props.candidateName,
                    isLeadership: props.isLeadership,
                  },
                }}
              >
                <button>Add </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interview;
