//@flow
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateCandidate } from '../../redux/actions';
import moment from 'moment';
import './candidate.scss';

type Props = {
  id: string,
  cohortId: Object,
  interviewerId: Object,
  recommendedCandidateId: string,
  recommendCandidate: Function,
  isInterviewer: boolean,
  isCohortManager: boolean,
  name: string,
  email: string,
  index: number,
  isScheduled: Boolean,
  isQCCleared: Boolean,
  history: Object,
  candidates: Array<Object>,
  updateCandidate: Function,
  isAvailableForInterview: boolean,
  isSystemDesignAuthorized: Boolean,
  nextPaymentDueDate: Date,
  totalPayment: Number,
  paidTillNow: Number,
};

const Candidate = (props: Props) => {
  const {
    id,
    index,
    name,
    email,
    // cohortId,
    allCohortIds,
    isAvailableForInterview,
    isBlackListed,
    isScheduled,
    isQCCleared,
    candidates,
    history,
    roles,
    isSystemDesignAuthorized,
    nextPaymentDueDate,
    totalPayment,
    paidTillNow,
    refereeId,
    passReferralBonusToIncomingStudent,
  } = props;
  let paymentOverDue = false;
  const discount = refereeId ? (passReferralBonusToIncomingStudent ? 100 : 50) : 0;
  if (nextPaymentDueDate) {
    paymentOverDue =
      moment().diff(nextPaymentDueDate) > 0 && totalPayment - paidTillNow - discount > 1;
  }
  return (
    <div className="tbody">
      <div
        className="row"
        style={{
          background: paymentOverDue ? 'red' : 'none',
        }}
      >
        <div className="columnSmall">{index + 1}</div>
        <div className="columnSmall">{name}</div>
        <div className="columnMedium">{email}</div>
        <div className="columnSmall">{isScheduled ? <b>true</b> : <b>false</b>}</div>
        <div className="columnSmall">{isQCCleared ? <b>true</b> : <b>false</b>}</div>
        <div className="columnMedium">
          {allCohortIds.map(cohortId => {
            return <>{cohortId.name} </>;
          })}
        </div>
        {roles.includes('CohortManager') && (
          <div className="columnAction">
            {!isBlackListed ? (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateCandidate(
                    candidates,
                    {
                      id,
                      isBlackListed: !isBlackListed,
                    },
                    history,
                  );
                }}
                style={{
                  backgroundColor: 'red',
                }}
              >
                Blacklist
              </button>
            ) : (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateCandidate(
                    candidates,
                    {
                      id,
                      isBlackListed: !isBlackListed,
                    },
                    history,
                  );
                }}
              >
                Remove
              </button>
            )}
          </div>
        )}
        {roles.includes('CohortManager') && (
          <div className="columnAction">
            {isAvailableForInterview ? (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateCandidate(
                    candidates,
                    {
                      id,
                      isAvailableForInterview: !isAvailableForInterview,
                    },
                    history,
                  );
                }}
                style={{
                  backgroundColor: 'red',
                }}
              >
                Deactivate
              </button>
            ) : (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateCandidate(
                    candidates,
                    {
                      id,
                      isAvailableForInterview: !isAvailableForInterview,
                    },
                    history,
                  );
                }}
              >
                Activate
              </button>
            )}
          </div>
        )}

        {roles.includes('CohortManager') && (
          <div className="columnAction">
            {isSystemDesignAuthorized ? (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateCandidate(
                    candidates,
                    {
                      id,
                      isSystemDesignAuthorized: !isSystemDesignAuthorized,
                    },
                    history,
                  );
                }}
                style={{
                  backgroundColor: 'red',
                }}
              >
                Dectivate
              </button>
            ) : (
              <button
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  props.updateCandidate(
                    candidates,
                    {
                      id,
                      isSystemDesignAuthorized: !isSystemDesignAuthorized,
                    },
                    history,
                  );
                }}
              >
                Activate
              </button>
            )}
          </div>
        )}
        {roles.includes('CohortManager') && (
          <div className="columnAction">
            <Link to={`/candidate/update/${props.id}`} key={props.id}>
              <button type="submit">Edit</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ candidates: state.candidateState.allCandidates });
const mapDispatchToProps = dispatch => ({
  updateCandidate: (candidates, values, history) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    dispatch(updateCandidate(candidates, values, history)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Candidate);
