//@flow
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { forgotPassword } from '../../redux/actions';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  submitting: boolean,
  error: string,
  classes: Object,
  forgotPassword: Function,
};
class ForgotPassword extends Component<Props> {
  form = ({ handleSubmit, values, handleChange, resetForm }) => (
    <Form onSubmit={handleSubmit}>
      <div className="formContainer">
        <h3>Forgot Password</h3>

        <TextField
          required
          variant="outlined"
          id="username"
          label="Email"
          onChange={handleChange}
          value={values.username}
          type="text"
          margin="normal"
          inputProps={{
            style: { fontSize: 15 },
          }}
        />
        <button type="submit">Submit</button>
      </div>
    </Form>
  );

  render() {
    const { submitting, error, classes } = this.props;
    return (
      <div className="mainBody" style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Submitting...</p>}
        />
                    
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <Formik
          initialValues={{
            username: '',
          }}
          onSubmit={(values, { resetForm }) => {
            const { forgotPassword } = this.props;
            forgotPassword(values);
            resetForm();
          }}
          component={this.form}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.authState.error,
    submitting: state.authState.submittingForgotPasswordRequest,
  };
};

const mapDispatchToProps = dispatch => ({
  forgotPassword: values => dispatch(forgotPassword(values)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ForgotPassword),
);
