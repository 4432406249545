import { combineReducers } from 'redux';
import authReducer from './auth';
import applicantReducer from './applicant';
import candidateReducer from './candidate';
import companyReducer from './company';
import cohortReducer from './cohort';
import schoolReducer from './school';
import problemReducer from './problem';
import dsproblemReducer from './dsproblem';
import problemWithUrlReducer from './problemWithUrl';
import pricingReducer from './pricing';
import interviewerReducer from './interviewer';
import cohortManagerReducer from './cohortManager';
import feedbackStateReducer from './interviewFeedback';
import performanceStateReducer from './interviewPerformance';
import roleReducer from './role';
import interviewKitReducer from './interviewKit';
import leadershipFeedbackStateReducer from './leadershipInterviewFeedback';
import jobOpeningReducer from './jobOpening';
import alumniListReducer from './alumniList';
import prStatusReducer from './prStatus';
import salaryReducer from './salary';

const rootReducer = combineReducers({
  authState: authReducer,
  applicantState: applicantReducer,
  candidateState: candidateReducer,
  companyState: companyReducer,
  cohortState: cohortReducer,
  problemState: problemReducer,
  dsproblemState: dsproblemReducer,
  problemWithUrlState: problemWithUrlReducer,
  pricingState: pricingReducer,
  schoolState: schoolReducer,
  interviewerState: interviewerReducer,
  cohortManagerState: cohortManagerReducer,
  feedbackState: feedbackStateReducer,
  performanceState: performanceStateReducer,
  roleState: roleReducer,
  interviewKitState: interviewKitReducer,
  leadershipFeedbackState: leadershipFeedbackStateReducer,
  jobOpeningState: jobOpeningReducer,
  alumniListState: alumniListReducer,
  prStatusState: prStatusReducer,
  salaryState: salaryReducer,
});

export default rootReducer;
