//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Card
import DisplayCard from '../Select/DisplayCard';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import './recruiter.scss';

// $FlowFixMe.

const styles = {
  // container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  // resize: {
  //   fontSize: 15,
  // },
  // myHelperTextStyle: {
  //   fontSize: 12,
  //   color: 'Red',
  // },
};
type Props = {
  user: Object,
  updateCandidateProfile: Function,
};
class RecruiterDashboard extends Component<Props> {
  render() {
    const id = this.props.user._id;
    const { candidatesApproached } = this.props.user.recruiterId;
    const candidates = this.props.user.recruiterId.companyId.recommendedCandidateIds;
    return (
      <div className="recruiter">
        <br></br>
        <br></br>
        <h3>Please click on Recommended Candidates in side bar</h3>
        {/* <h4>
          Add your job opening and reach 6000+ S30 community students to "Job Openings" in top bar{' '}
        </h4>
        <h5>You can use green button Add New Job </h5> */}
        {/* <div className="container">
          {candidates.map((candidate, index) => {
            if (!candidatesApproached.includes(candidate.userId._id))
              return (
                <div>
                  <DisplayCard key={index} id={id} candidate={candidate} {...this.props} />
                </div>
              );
          })}
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
});
const mapDispatchToProps = dispatch => ({});
export default withStyles(styles)(connect(mapStateToProps)(RecruiterDashboard));
