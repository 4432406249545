//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllDSProblems, handleDSProblemPageClick } from '../../redux/actions';
import DSProblem from './DSProblem';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
import SearchBar from '../SearchBar/SearchBar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import './DSProblem.scss';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getAllDSProblems: Function,
  dsproblems: Array<Object>,
  handleDSProblemPageClick: Function,
  activePage: number,
  perPage: number,
  error: string,
  classes: Object,
  filteredDSProblems: Array<Object>,
  submitting: boolean,
};
class DSProblems extends Component<Props> {
  componentDidMount() {
    const { getAllDSProblems } = this.props;
    getAllDSProblems();
  }

  handleDSProblemPageClick = data => {
    const { handleDSProblemPageClick } = this.props;
    handleDSProblemPageClick(data.selected);
  };

  render() {
    const roles = localStorage.getItem('s30Roles') || {};
    const {
      dsproblems,
      activePage,
      filteredDSProblems,
      perPage,
      submitting,
      error,
      classes,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="dsproblem">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Name" type="dsproblemSearchByName" />
          </div>
          <div className="table">
            <h3 className="title">All DSProblems</h3>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting DSProblems...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnLarge">Name</div>
              <div className="columnMedium">Techniques</div>
              <div className="columnMedium">Companies</div>
              <div className="columnAction"> Video </div>
              {roles.includes('Admin') && <div className="columnAction"> Edit </div>}

              {/* {roles.includes('Admin') && <div className="columnAction"> Delete </div>} */}
            </div>
            {filteredDSProblems.length > 0
              ? filteredDSProblems.map((dsproblem, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <DSProblem
                        key={dsproblem._id}
                        index={dsproblem.dsproblemIndex}
                        techniques={dsproblem.techniques}
                        companyTags={dsproblem.companyTags}
                        id={dsproblem._id}
                        name={dsproblem.name}
                        videoUrl={dsproblem.videoUrl}
                      />
                    );
                  }
                  return null;
                })
              : dsproblems.map((dsproblem, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <DSProblem
                        key={dsproblem._id}
                        index={dsproblem.dsproblemIndex}
                        companyTags={dsproblem.companyTags}
                        techniques={dsproblem.techniques}
                        id={dsproblem._id}
                        videoUrl={dsproblem.videoUrl}
                        name={dsproblem.name}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={dsproblems.length / perPage}
            onPageChange={this.handleDSProblemPageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.dsproblemState.activePage,
  perPage: state.dsproblemState.perPage,
  dsproblems: state.dsproblemState.allDSProblems,
  filteredDSProblems: state.dsproblemState.filteredDSProblems,
  error: state.dsproblemState.error,
  submitting: state.dsproblemState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllDSProblems, handleDSProblemPageClick })(DSProblems),
);
