/* eslint-disable */

//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllCandidates, handleCandidatePageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Candidate from './Candidate';
import SearchBar from '../SearchBar/SearchBar';
import Sidebar from '../Sidebar/Sidebar';
import './candidate.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  history: Object,
  candidates: Array<Object>,
  handleCandidatePageClick: Function,
  getAllCandidates: Function,
  error: string,
  submitting: boolean,
  classes: Object,
  filteredCandidates: Array<Object>,
  nextPaymentDueDate: Date,
  totalPayment: number,
  paidTillNow: number,
};

class Candidates extends Component<Props> {
  constructor(props: {}) {
    super(props);
    this.state = { roles: localStorage.getItem('s30Roles') || '' };
  }
  componentDidMount() {
    const { getAllCandidates } = this.props;
    getAllCandidates();
  }

  handleCandidatePageClick = data => {
    const { handleCandidatePageClick } = this.props;
    handleCandidatePageClick(data.selected);
  };

  render() {
    const {
      activePage,
      perPage,
      candidates,
      filteredCandidates,
      submitting,
      error,
      history,
      classes,
    } = this.props;
    const { roles } = this.state;

    return (
      <div className="mainBody">
        <Sidebar />
        <div className="candidate">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Name" type="candidateSearchByName" />
            <SearchBar placeHolder="Search by Cohort" type="candidateSearchByCohort" />
          </div>
          <div className="table">
            <h3 className="title">Candidates</h3>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Submitting...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnSmall">Name</div>
              <div className="columnMedium">Email</div>
              <div className="columnSmall">Scheduled?</div>
              <div className="columnSmall">QC Cleared?</div>
              <div className="columnMedium">Cohort</div>
              {roles.includes('CohortManager') && <div className="columnAction">Blacklist</div>}
              {roles.includes('CohortManager') && <div className="columnAction">Available</div>}
              {roles.includes('CohortManager') && <div className="columnAction">SD</div>}
              {roles.includes('CohortManager') && <div className="columnAction">Edit</div>}
            </div>
            {filteredCandidates.length > 0
              ? filteredCandidates.map((candidate, index) => {
                  const {
                    _id,
                    // cohortId,
                    allCohortIds,
                    isBlackListed,
                    isScheduled,
                    isAvailableForInterview,
                    isSystemDesignAuthorized,
                    isQCCleared,
                    userId,
                    nextPaymentDueDate,
                    totalPayment,
                    paidTillNow,
                    refereeId,
                    passReferralBonusToIncomingStudent,
                    linkedInUrl,
                  } = candidate;
                  const { email, firstName, lastName } = userId;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Candidate
                        key={_id}
                        id={_id}
                        history={history}
                        index={index}
                        // cohortId={cohortId}
                        allCohortIds={allCohortIds}
                        isBlackListed={isBlackListed}
                        isQCCleared={isQCCleared}
                        linkedInUrl={linkedInUrl}
                        isAvailableForInterview={isAvailableForInterview}
                        isSystemDesignAuthorized={isSystemDesignAuthorized}
                        isScheduled={isScheduled}
                        name={`${firstName} ${lastName}`}
                        email={email}
                        roles={roles}
                        nextPaymentDueDate={nextPaymentDueDate}
                        totalPayment={totalPayment}
                        paidTillNow={paidTillNow}
                        refereeId={refereeId}
                        passReferralBonusToIncomingStudent={passReferralBonusToIncomingStudent}
                      />
                    );
                  }
                  return null;
                })
              : candidates.map((candidate, index) => {
                  const {
                    _id,
                    // cohortId,
                    allCohortIds,
                    isScheduled,
                    isBlackListed,
                    isAvailableForInterview,
                    isSystemDesignAuthorized,
                    isQCCleared,
                    userId,
                    nextPaymentDueDate,
                    totalPayment,
                    paidTillNow,
                    refereeId,
                    passReferralBonusToIncomingStudent,
                    linkedInUrl,
                  } = candidate;
                  const { email, firstName, lastName } = userId;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Candidate
                        key={_id}
                        id={_id}
                        index={index}
                        history={history}
                        // cohortId={cohortId}
                        allCohortIds={allCohortIds}
                        isBlackListed={isBlackListed}
                        isAvailableForInterview={isAvailableForInterview}
                        isSystemDesignAuthorized={isSystemDesignAuthorized}
                        isQCCleared={isQCCleared}
                        isScheduled={isScheduled}
                        name={`${firstName} ${lastName}`}
                        email={email}
                        roles={roles}
                        nextPaymentDueDate={nextPaymentDueDate}
                        totalPayment={totalPayment}
                        paidTillNow={paidTillNow}
                        refereeId={refereeId}
                        linkedInUrl={linkedInUrl}
                        passReferralBonusToIncomingStudent={passReferralBonusToIncomingStudent}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={candidates.length / perPage}
            onPageChange={this.handleCandidatePageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.candidateState.activePage,
  perPage: state.candidateState.perPage,
  candidates: state.candidateState.allCandidates,
  filteredCandidates: state.candidateState.filteredCandidates,
  error: state.candidateState.error,
  submitting: state.candidateState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllCandidates, handleCandidatePageClick })(Candidates),
);
