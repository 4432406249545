//@flow
import React from 'react';
import './Applicant.scss';
import moment from 'moment';

import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';

type Props = {
  refreeId: string,
  name: string,
  index: number,
  email: String,
  school: String,
  linkedInUrl: String,
  passReferralBonusToIncomingStudent: Boolean,
};

const deleteApplicant = async id => {
  const apiurl = `${ROOT_URL}/api/applicant/${id}`;
  const token = localStorage.getItem('token');
  const headers = {
    headers: {
      Authorization: token,
    },
  };
  await axios.delete(apiurl, headers);
  alert('Deleted Successfully');
  window.location.reload();
};

const Applicant = (props: Props) => {
  let {
    index,
    applicantId,
    name,
    email,
    school,
    refreeId,
    createdAt,
    linkedInUrl,
    roles,
    passReferralBonusToIncomingStudent,
  } = props;
  if (!linkedInUrl.match(/^https?:\/\//i)) {
    linkedInUrl = 'http://' + linkedInUrl;
  }
  let id;
  if (passReferralBonusToIncomingStudent == undefined) {
    passReferralBonusToIncomingStudent = false;
  }
  if (refreeId != undefined) {
    id = refreeId._id;
  }
  return (
    <div className="tbody">
      <div className="row">
        <div className="columnAction">{index}</div>
        {roles.includes('CohortManager') && (
          <div className="columnSmall">
            {moment(createdAt)
              .format('DD,MMM,YYYY')
              .toString()}
          </div>
        )}
        <div className="columnSmall">{name}</div>
        <div className="columnMedium">{email}</div>
        <div className="columnMedium">{school}</div>
        {roles.includes('Candidate') && (
          <div className="columnMedium">
            <button type="submit" onClick={() => window.open(linkedInUrl, '_blank')}>
              LinkedIn Profile
            </button>
          </div>
        )}
        {refreeId != undefined ? (
          <>
            {roles.includes('CohortManager') && (
              <div className="columnMedium">{refreeId.firstName + ' ' + refreeId.lastName}</div>
            )}
            {roles.includes('CohortManager') && (
              <div className="columnSmall">
                {passReferralBonusToIncomingStudent ? 'true' : 'false'}
              </div>
            )}
          </>
        ) : (
          <>
            {roles.includes('CohortManager') && (
              <div className="columnMedium">Check with Student</div>
            )}
            {roles.includes('CohortManager') && <div className="columnSmall">false</div>}
          </>
        )}
        {roles.includes('CohortManager') && (
          <div className="columnAction">
            <button
              type="submit"
              onClick={() =>
                window.open(
                  `/adduser?${id}?${passReferralBonusToIncomingStudent}?${linkedInUrl}`,
                  '_blank',
                )
              }
            >
              Add User
            </button>
          </div>
        )}
        {roles.includes('CohortManager') && (
          <div className="columnAction">
            <button
              type="submit"
              style={{ background: 'red' }}
              onClick={e => {
                e.preventDefault();
                deleteApplicant(applicantId);
              }}
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Applicant;
