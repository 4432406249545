//@flow
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';

import {
  styles,
  Control,
  Menu,
  SingleValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
} from './Common';

const components = {
  Control,
  Menu,
  SingleValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

const Autocomplete = props => {
  const { classes, theme, label, suggestions, handleChange, value } = props;
  const modifiedSuggestions = suggestions.map(suggestion => ({
    value: suggestion._id,
    label:
      suggestion.userId != null
        ? suggestion.userId.firstName + ' ' + suggestion.userId.lastName
        : suggestion.name == null
        ? suggestion.firstName + ' ' + suggestion.lastName
        : suggestion.name,
  }));
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };
  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          textFieldProps={{
            label: `${label}`,
            InputLabelProps: {
              shrink: true,
            },
          }}
          styles={selectStyles}
          options={modifiedSuggestions}
          components={components}
          value={value}
          onChange={handleChange}
          isClearable
        />
      </NoSsr>
    </div>
  );
};
// class Autocomplete extends React.Component {
//   render() {
//     const { classes, theme, label, suggestions, handleChange, value } = this.props;
//     const modifiedSuggestions = suggestions.map(suggestion => ({
//       value: suggestion._id,
//       label: suggestion.name,
//     }));
//     const selectStyles = {
//       input: base => ({
//         ...base,
//         color: theme.palette.text.primary,
//         '& input': {
//           font: 'inherit',
//         },
//       }),
//     };

//     return (
//       <div className={classes.root}>
//         <NoSsr>
//           <Select
//             classes={classes}
//             textFieldProps={{
//               label: `${label}`,
//               InputLabelProps: {
//                 shrink: true,
//               },
//             }}
//             styles={selectStyles}
//             options={modifiedSuggestions}
//             components={components}
//             value={value}
//             onChange={handleChange}
//             isClearable
//           />
//         </NoSsr>
//       </div>
//     );
//   }
// }

Autocomplete.propTypes = {
  classes: PropTypes.shape.isRequired,
  theme: PropTypes.shape.isRequired,
  label: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
