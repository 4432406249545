//@flow
import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../Sidebar/Sidebar';

import AddUserForm from './AddUserForm';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddUserValidationSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  pincode: Yup.string()
    .min(5, 'Enter a valid digit pincode')
    .max(7, 'Enter a valid digit pincode')
    .required('Required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  // phoneNumber: Yup.string()
  //   .min(10, 'Enter a valid phoneNumber')
  //   .max(13, 'Enter a valid phoneNumber')
  //   .required('Required'),
});

const initialAddUserValues = {
  username: '',
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  country: {
    id: 233,
    name: 'United States',
    iso3: 'USA',
    iso2: 'US',
    numeric_code: '840',
    phone_code: 1,
    capital: 'Washington',
    currency: 'USD',
    currency_name: 'United States dollar',
    currency_symbol: '$',
    tld: '.us',
    native: 'United States',
    region: 'Americas',
    subregion: 'Northern America',
    latitude: '38.00000000',
    longitude: '-97.00000000',
    emoji: '🇺🇸',
  },
  pincode: '',
  phoneNumber: '',
  email: '',
  slackId: '',
  password: '',
  confirmPassword: '',
  cohortId: '',
  schoolId: '',
  paidTillNow: 0,
  totalPayment: 0,
  noOfPendingInstallments: 1,
  billingLocation: 1,
  // roleIds: [],
};
type Props = {};

const AddUser = (props: Props) => {
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddUserValues}
        render={props => <AddUserForm {...props} />}
        validationSchema={AddUserValidationSchema}
      />
    </div>
  );
};

export default AddUser;
