//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="copyright">
        <ul>
          <li>
            <a href="/">© S 30 Interview Prep Private Ltd,{year} India</a>
          </li>
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms">Terms of Service</a>
          </li>
          <li>
            <a href="/refund">Cancellation and Refund Policy</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
          {/* <li>
            <a href="/">Press</a>
          </li> */}
        </ul>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
