import { call, put } from 'redux-saga/effects';
import { getPrStatus } from '../../api';
import { getPrStatusSuccess, submittingPrStatusRequest, prStatusError } from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getprStatusSaga() {
  try {
    yield put(submittingPrStatusRequest());
    const prStatus = yield call(getPrStatus);
    console.log(prStatus);
    yield put(getPrStatusSuccess(prStatus.data));
  } catch (error) {
    yield put(prStatusError(error.toString()));
    yield delay(3000);
    yield put(prStatusError(''));
  }
}
