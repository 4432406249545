import { AUTH, CANDIDATES } from '../constants';

const initialState = {
  user: {},
  isAuthorized: false,
  error: false,
  submittingSignInRequest: false,
  submittingForgotPasswordRequest: false,
  submittingResetPasswordRequest: false,
  submittingSignOutRequest: false,
  submittingSignUpRequest: false,
  loaded: false,
};
const authReducer = (auth = initialState, action) => {
  switch (action.type) {
    case AUTH.AUTHENTICATION_ERROR:
      return {
        ...auth,
        error: action.error,
        submittingSignInRequest: false,
        submittingSignOutRequest: false,
        submittingForgotPasswordRequest: false,
        submittingResetPasswordRequest: false,
      };
    case AUTH.SIGNINSUCCESS:
      return {
        ...auth,
        isAuthorized: true,
        user: { ...action.user },
        submittingSignInRequest: false,
      };
    case AUTH.GETUPDATEDUSERSUCCESS:
      return {
        ...auth,
        isAuthorized: true,
        user: { ...action.user },
      };
    case AUTH.SIGNOUTSUCCESS:
      return { ...auth, isAuthorized: false, submittingSignOutRequest: false };
    case AUTH.SIGNUPSUCCESS:
      return { ...auth, submittingSignUpRequest: false };
    case AUTH.HYDRATEAUTHSTATE:
      return {
        ...auth,
        isAuthorized: action.isAuthorized,
      };
    case AUTH.GETUSER:
      return {
        ...auth,
        user: { ...action.user },
      };
    case AUTH.FORGOTPASSWORDSUCCESS:
      return { ...auth, submittingForgotPasswordRequest: false };
    case AUTH.RESETPASSWORDSUCCESS:
      return { ...auth, submittingResetPasswordRequest: false };
    case AUTH.SUBMITTINGSIGNINREQUEST:
      return { ...auth, submittingSignInRequest: true };
    case AUTH.SUBMITTINGSIGNOUTREQUEST:
      return { ...auth, submittingSignOutRequest: true };
    case AUTH.SUBMITTINGFORGOTPASSWORDREQUEST:
      return { ...auth, submittingForgotPasswordRequest: true };
    case AUTH.SUBMITTINGRESETPASSWORDREQUEST:
      return { ...auth, submittingResetPasswordRequest: true };
    default:
      return auth;
  }
};
export default authReducer;
