import { call, put } from 'redux-saga/effects';
import {
  // getInterviewKitById,
  getSalaryCompanies,
  // addSalaryOfaCompany,
  // UpdateSalaryOfaCompany,
} from '../../api';
import {
  getSalaryCompaniesSuccess,
  salaryError,
  submittingAddSalary,
  submittingGetSalary,
  // addSalaryOfaCompanySuccess,
} from '../actions/index';
import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getSalaryCompaniesSaga() {
  try {
    yield put(submittingGetSalary);
    const companies = yield call(getSalaryCompanies);
    yield put(getSalaryCompaniesSuccess(companies.data));
  } catch (error) {
    yield put(salaryError(error.toString()));
    yield delay(3000);
    yield put(salaryError(''));
  }
}

// export function* getInterviewKitByIdSaga(action) {
//   try {
//     yield put(submittingInterviewKitRequest());
//     const { id } = action;
//     const kit = yield call(getInterviewKitById, id);
//     console.log(kit);
//     yield put(getInterviewKitSuccess(kit.data));
//   } catch (error) {
//     yield put(interviewKitError(error.toString()));
//     yield delay(3000);
//     yield put(interviewKitError(''));
//   }
// }
// export function* addSalaryOfaCompanySaga(action) {
//   try {
//     yield put(submittingInterviewKitRequest());
//     const { question } = action;
//     let item = question;
//     const formData = new FormData();
//     let keys = Object.keys(item);
//     keys.forEach(ele => {
//       if (item[ele]) {
//         if (ele == 'files') {
//           for (let i = 0; i < item[ele].length; i++) {
//             formData.append(`images[${i}]`, item[ele]);
//           }
//         } else {
//           formData.append(ele, item[ele]);
//         }
//       }
//     });
//     const response = yield call(addSalaryOfaCompany, formData);
//   } catch (error) {
//     yield put(interviewKitError(error.toString()));
//     yield delay(3000);
//     yield put(interviewKitError(''));
//   }
// }

// export function* updateSalaryOfaCompanySaga(action) {
//   try {
//     yield put(submittingInterviewKitRequest());
//     const { question } = action;
//     const interviewQuestion = yield call(UpdateSalaryOfaCompany, action.payload);
//     yield put(addSalaryOfaCompanySuccess(interviewQuestion));
//   } catch (error) {
//     yield put(interviewKitError(error.toString()));
//     yield delay(3000);
//     yield put(interviewKitError(''));
//   }
// }
