//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Rating from 'react-rating';
import { connect } from 'react-redux';
import { getUpdatedUser, addInterviewFeedback } from '../../redux/actions';
import Autocomplete from '../Select/Autocomplete';
// import { getAllCandidates } from '../../redux/actions';
//Socket
// import Socket from 'socket.io-client';
// import DisplayFormikState from '../DisplayFormikState';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
    lineHeight: 1.5,
  },

  resizeName: {
    fontSize: 18,
  },
  textField: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 30,
    fontSize: 18,
    fontWeight: 500,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  values: Object,
  handleChange: Function,
  classes: Object,
  // allCandidates: Array<Object>,
  resetForm: Function,
  submitting: boolean,
  error: string,
  addInterviewFeedback: Function,
  // getAllCandidates: Function,
  // getUpdatedUser: Function,
};
class AddFeedbackForm extends Component<Props> {
  // componentDidMount() {
  //   const { getAllCandidates } = this.props;
  //   getAllCandidates();
  // }

  // getUpdatedUser = async () => {
  //   await this.props.getUpdatedUser();
  // };

  render() {
    const {
      values,
      handleChange,
      classes,
      // allCandidates,
      resetForm,
      submitting,
      error,
      addInterviewFeedback,
    } = this.props;
    return (
      <div>
        <form
          style={styles.container}
          onSubmit={e => {
            e.preventDefault();
            // this.addSocketInterviewFeedback(values);
            // console.log(values, "values")
            addInterviewFeedback(values);
            resetForm();
            alert('Added Successfully');
          }}
        >
          <div style={styles.miniContainer}>
            <h1>Add Feedback</h1>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Adding Feedback...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <TextField
              variant="outlined"
              style={styles.textField}
              value={values.candidateName}
              label="Candidate Name"
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.resizeName,
                },
                readOnly: true,
              }}
            />
            {/* <Autocomplete
              value={values.candidateId}
              handleChange={handleChange('candidateId')}
              label="Candidates"
              suggestions={allCandidates}
            /> */}
            <br />
            {/* <label>Confidence</label>
            <Rating
              stop={10}
              initialRating={values.confidence}
              onChange={handleChange('confidence')}
              value={values.confidence}
            /> */}
            <br />
            <label>Data structures and algorithm</label>
            <Rating
              stop={10}
              initialRating={values.bugTracking}
              onChange={handleChange('bugTracking')}
              value={values.bugTracking}
            />
            <br />
            <label>Coding</label>
            <Rating
              stop={10}
              initialRating={values.coding}
              onChange={handleChange('coding')}
              value={values.coding}
            />
            <br />
            <label>Problem Solving</label>
            <Rating
              initialRating={values.problemSolving}
              onChange={handleChange('problemSolving')}
              value={values.problemSolving}
              stop={10}
            />
            <br />
            <label>Communication</label>
            <Rating
              initialRating={values.communication}
              onChange={handleChange('communication')}
              value={values.communication}
              stop={10}
            />
            <br />
          </div>
          <div style={styles.miniContainer}>
            <br />
            <br />
            <br />
            <TextField
              multiline
              rows={6}
              style={styles.textFields}
              value={values.thingsYouDidWell}
              onChange={handleChange('thingsYouDidWell')}
              variant="outlined"
              id="thingsDidWell"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              label="Things that the student did well"
            />
            <br />
            <TextField
              multiline
              rows={6}
              style={styles.textFields}
              variant="outlined"
              onChange={handleChange('thingsToImprove')}
              value={values.thingsToImprove}
              id="thingsNeedImprove"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              label="Things that the student need to improve"
            />
            <br></br>
            <button type="submit">Submit</button>
            {/* {<DisplayFormikState {...values} />} */}
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  // allCandidates: state.candidateState.allCandidates,
  submitting: state.feedbackState.submittingPostRequest,
  error: state.feedbackState.error,
});
const mapDispatchToProps = dispatch => ({
  addInterviewFeedback: values => dispatch(addInterviewFeedback(values)),
  getUpdatedUser: () => dispatch(getUpdatedUser()),
  // getAllCandidates: () => dispatch(getAllCandidates()),
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddFeedbackForm));
