//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getAllInterviewPerformances,
  handleInterviewPerformancePageClick,
} from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Performance from './Performance';
import SearchBar from '../SearchBar/SearchBar';
import Sidebar from '../Sidebar/Sidebar';
import './performance.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  performanceAsInterviewer: Array<Object>,
  performances: Array<Object>,
  handleInterviewPerformancePageClick: Function,
  getAllInterviewPerformances: Function,
  error: string,
  submitting: boolean,
  location: Object,
  classes: Object,
  filteredPerformances: Array<Object>,
};

class Performances extends Component<Props> {
  componentDidMount() {
    const roleToMount = this.props.location.search.substr(1);
    const { getAllInterviewPerformances } = this.props;
    getAllInterviewPerformances(roleToMount);
  }
  componentWillReceiveProps(nextProps) {
    const { getAllInterviewPerformances } = this.props;
    var roleToMount = nextProps.location.search.substr(1);
    if (roleToMount !== this.props.location.search.substr(1)) {
      getAllInterviewPerformances(roleToMount);
    }
  }

  handleInterviewPerformancePageClick = data => {
    const { handleInterviewPerformancePageClick } = this.props;
    handleInterviewPerformancePageClick(data.selected);
  };

  render() {
    const {
      activePage,
      perPage,
      performances,
      submitting,
      error,
      classes,
      //  performanceAsInterviewer,
      filteredPerformances,
    } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="performance">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Interviewer" type="performanceSearchByInterviewer" />
            <SearchBar placeHolder="Search by Candidate" type="performanceSearchByCandidate" />
          </div>
          <div className="table">
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Performances</p>}
            />
                        
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <h3 className="title">Performances</h3>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Candidate</div>
              <div className="columnMedium">Interviewer</div>
              <div className="columnMedium">Date</div>
              <div className="columnSmall">Rating</div>
              <div className="columnMedium">Result</div>
            </div>
            {filteredPerformances.length > 0
              ? filteredPerformances.map((performance, index) => {
                  const { _id, candidateId, interviewerId, createdAt, rating } = performance;
                  const candidateName =
                    candidateId.userId.firstName + ' ' + candidateId.userId.lastName;
                  const interviewerName =
                    interviewerId.userId.firstName + ' ' + interviewerId.userId.lastName;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Performance
                        key={_id}
                        id={_id}
                        index={index}
                        candidateName={candidateName}
                        interviewerName={interviewerName}
                        createdAt={createdAt}
                        rating={rating}
                      />
                    );
                  }
                  return null;
                })
              : performances.map((performance, index) => {
                  const { _id, candidateId, interviewerId, createdAt, rating } = performance;
                  const candidateName =
                    candidateId.userId.firstName + ' ' + candidateId.userId.lastName;
                  const interviewerName =
                    interviewerId.userId.firstName + ' ' + interviewerId.userId.lastName;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Performance
                        key={_id}
                        id={_id}
                        index={index}
                        interviewerName={interviewerName}
                        candidateName={candidateName}
                        createdAt={createdAt}
                        rating={rating}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={performances.length / perPage}
            onPageChange={this.handleInterviewPerformancePageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.performanceState.activePage,
  perPage: state.performanceState.perPage,
  performances: state.performanceState.allPerformances,
  filteredPerformances: state.performanceState.filteredPerformances,
  error: state.performanceState.error,
  submitting: state.performanceState.submittingGetRequest,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllInterviewPerformances, handleInterviewPerformancePageClick })(
    Performances,
  ),
);
