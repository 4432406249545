import { LEADERSHIPINTERVIEWFEEDBACKS } from '../constants';

const initialState = {
  allFeedbacks: [],
  filteredFeedbacks: [],
  activePage: 0,
  perPage: 10,
  error: false,
  submittingPostRequest: false,
  submittingGetRequest: false,
  submittingPutRequest: false,
  submittingDeleteRequest: false,
};

const leadershipFeedbackStateReducer = (leadershipFeedbackState = initialState, action) => {
  switch (action.type) {
    case LEADERSHIPINTERVIEWFEEDBACKS.ADDLEADERSHIPINTERVIEWFEEDBACKSUCCESS:
      return {
        ...leadershipFeedbackState,
        allFeedbacks: [...leadershipFeedbackState.allFeedbacks, { ...action.payload }],
        submittingPostRequest: false,
      };
    case LEADERSHIPINTERVIEWFEEDBACKS.GETLEADERSHIPINTERVIEWFEEDBACKSSUCCESS:
      return {
        ...leadershipFeedbackState,
        allFeedbacks: [...action.payload],
        filteredFeedbacks: [],
        submittingGetRequest: false,
      };
    case LEADERSHIPINTERVIEWFEEDBACKS.UPDATELEADERSHIPINTERVIEWFEEDBACKSUCCESS:
      return {
        ...leadershipFeedbackState,
        allFeedbacks: [...action.payload],
        submittingPutRequest: false,
      };
    case LEADERSHIPINTERVIEWFEEDBACKS.DELETELEADERSHIPINTERVIEWFEEDBACKSUCCESS:
      return {
        ...leadershipFeedbackState,
        allFeedbacks: [...action.payload],
        submittingDeleteRequest: false,
      };

    // error
    case LEADERSHIPINTERVIEWFEEDBACKS.LEADERSHIPINTERVIEWFEEDBACKERROR:
      return {
        ...leadershipFeedbackState,
        error: action.error,
        submittingPostRequest: false,
        submittingPutRequest: false,
        submittingGetRequest: false,
        submittingDeleteRequest: false,
      };
    //search
    case LEADERSHIPINTERVIEWFEEDBACKS.SEARCHLEADERSHIPINTERVIEWFEEDBACK:
      return { ...leadershipFeedbackState, filteredFeedbacks: [...action.payload], submitting: false };
    //change active page
    case LEADERSHIPINTERVIEWFEEDBACKS.CHANGEACTIVELEADERSHIPINTERVIEWFEEDBACKPAGE:
      return { ...leadershipFeedbackState, activePage: action.payload };
    //submitting
    case LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGADDLEADERSHIPINTERVIEWFEEDBACK:
      return { ...leadershipFeedbackState, submittingPostRequest: true };
    case LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGUPDATELEADERSHIPINTERVIEWFEEDBACK:
      return { ...leadershipFeedbackState, submittingPutRequest: true };
    case LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGGETLEADERSHIPINTERVIEWFEEDBACK:
      return { ...leadershipFeedbackState, submittingGetRequest: true };
    case LEADERSHIPINTERVIEWFEEDBACKS.SUBMITTINGDELETELEADERSHIPINTERVIEWFEEDBACK:
      return { ...leadershipFeedbackState, submittingDeleteRequest: true };

    default:
      return leadershipFeedbackState;
  }
};

export default leadershipFeedbackStateReducer;
