//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAlumniListCompanies, handleAlumniListPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import './AlumniList.scss';
import history from '../../history';
import SearchBar from '../SearchBar/SearchBar';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getAlumniListCompanies: Function,
  companies: Array<Object>,
  filteredCompanies: Array<Object>,
  handleAlumniListPageClick: Function,
  activePage: number,
  perPage: number,
  error: string,
  classes: Object,
  submitting: boolean,
};
class AlumniCompanyList extends Component<Props> {
  constructor(props: {}) {
    super(props);
    this.state = { roles: localStorage.getItem('s30Roles') || '' };
  }
  componentDidMount() {
    const { getAlumniListCompanies } = this.props;
    getAlumniListCompanies();
  }

  handleAlumniListPageClick = data => {
    const { handleAlumniListPageClick } = this.props;
    handleAlumniListPageClick(data.selected);
  };

  render() {
    const {
      companies,
      activePage,
      perPage,
      submitting,
      error,
      classes,
      filteredCompanies,
    } = this.props;
    const { roles } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="alumniList">
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting Companies...</p>}
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />

          <div className="table">
            <h3 className="title">Company List</h3>
            <div className="searchContainer">
              <SearchBar placeHolder="Search by Company" type="alumniListCompanySearchByName" />
            </div>
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnLarge">Name</div>
            </div>
            {filteredCompanies.length > 0
              ? filteredCompanies.map((company, i) => {
                  const { name, index } = company;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (i >= bottomIdx && i < topIdx) {
                    return (
                      <div className="tbody">
                        <div className="row">
                          <Link to={`/alumnilist/${index}`} key={index} className="link">
                            <div className="innerRow">
                              <div className="columnSmall">{i + 1}</div>
                              <div className="columnLarge">{name}</div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
              : companies.map((company, i) => {
                  const { name, index } = company;
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (i >= bottomIdx && i < topIdx) {
                    return (
                      <div className="tbody">
                        <div className="row">
                          <Link to={`/alumnilist/${index}`} key={index} className="link">
                            <div className="innerRow">
                              <div className="columnSmall">{i + 1}</div>
                              <div className="columnLarge">{name}</div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
          </div>
          <br />
          {roles.includes('CohortManager') && (
            <button
              type="submit"
              onClick={e => {
                e.preventDefault();
                history.push('./addalumni');
              }}
            >
              Add New Alumni
            </button>
          )}
          <br />
          <Custompaginate
            pageCount={companies.length / perPage}
            onPageChange={this.handleAlumniListPageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.alumniListState.activePage,
  perPage: state.alumniListState.perPage,
  companies: state.alumniListState.allAlumniListCompanies,
  error: state.alumniListState.error,
  submitting: state.alumniListState.submitting,
  filteredCompanies: state.alumniListState.filteredallAlumniListCompanies,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAlumniListCompanies, handleAlumniListPageClick })(
    AlumniCompanyList,
  ),
);
