//@flow
import React from 'react';
import { connect } from 'react-redux';
// import './Candidate.css';
import { unsubscribeCandidate } from '../../redux/actions';

type Props = {
  location: Object,
  unsubscribeCandidate: Function,
};

const Unsubscribe = (props: Props) => (
  <div className="Applicationsuccess__Container">
    <h4>Sure you want to Unsubscribe?</h4>
    <button
      onClick={() => {
        props.unsubscribeCandidate(props.location.search.substr(1));
      }}
      style={{
        marginTop: '20',
        width: '20%',
      }}
      type="submit"
    >
      Submit
    </button>
  </div>
);

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { unsubscribeCandidate },
)(Unsubscribe);
