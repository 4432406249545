import { call, put } from 'redux-saga/effects';
import { getAllCompanies, addCompany, updateCompany } from '../../api';
import {
  getAllCompaniesSuccess,
  companyError,
  submittingRequest,
  addCompanySuccess,
  toggleCompanyEditSuccess,
  updateCompanySuccess,
} from '../actions/index';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllCompaniesSaga() {
  try {
    yield put(submittingRequest());
    const companies = yield call(getAllCompanies);

    companies.data.forEach(ele => {
      ele.isEditing = false;
    });
    yield put(getAllCompaniesSuccess(companies.data));
  } catch (error) {
    yield put(companyError(error.toString()));
    yield delay(3000);
    yield put(companyError(''));
  }
}

export function* addCompanySaga(action) {
  try {
    yield put(submittingRequest());
    const { history, companyObj } = action;

    const company = yield call(addCompany, companyObj);

    yield put(addCompanySuccess(company));
    history.push('/companies');
  } catch (error) {
    yield put(companyError(error.toString()));
    yield delay(3000);
    yield put(companyError(''));
  }
}

export function* toggleCompanyEditSaga(action) {
  const { id, allCompanies } = action;
  const editStateCompanies = allCompanies.map(company => {
    if (company._id === id) {
      company = { ...company, isEditing: true };
    }

    return company;
  });
  yield put(toggleCompanyEditSuccess(editStateCompanies));
}

export function* updateCompanySaga(action) {
  try {
    yield put(submittingRequest());
    const { companyObj, allCompanies } = action;
    const { id } = companyObj;
    const updatedCompany = yield call(updateCompany, companyObj);

    const modifiedCompanies = allCompanies.map(company => {
      if (company._id === id) {
        company = updatedCompany.data.company;
        company.isEditing = false;
      }
      return company;
    });
    yield put(updateCompanySuccess(modifiedCompanies));
  } catch (error) {
    yield put(companyError(error.toString()));
    yield delay(3000);
    yield put(companyError(''));
  }
}
