const ROOT_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:5000'
    : 'https://super30backend.herokuapp.com';

export const SOCKET_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:443'
    : 'https://super30backend.herokuapp.com';
export { ROOT_URL as default };

//Some blah comment here
