//@flow
import React from 'react';
// $FlowFixMe.
import moment from 'moment';
type Props = {
  index: string,
  name: string,
  candidate: string,
  timeslot: Object,
};
const Interview = (props: Props) => {
  const { index, name, candidate, timeslot, slackId } = props;
  return (
    <div className="tbody">
      <div className="row">
        <div className="columnSmall">{index}</div>
        <div className="columnMedium">{name}</div>
        <div className="columnMedium">{candidate}</div>
        <div className="columnMedium">{slackId}</div>
        <div className="columnMedium">
          {timeslot && (
            <>
              {moment(timeslot.startTime)
                .format('ddd MMM D, YYYY')
                .toString()}
            </>
          )}
        </div>
        <div className="columnMedium">
          {timeslot && (
            <>
              {moment(timeslot.startTime)
                .format('hh : mm A')
                .toString()}
            </>
          )}
        </div>

        <div className="columnMedium">
          {timeslot && (
            <>
              {moment(timeslot.endTime)
                .format('hh : mm A')
                .toString()}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Interview;
