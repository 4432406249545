//@flow
import React, { Component } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import './Dashboard.css';
// Components
import Sidebar from '../Sidebar/Sidebar';
import Chatwidget from '../ChatWidget/ChatWidget';
import TimePicker from '../TimePicker/TimePicker';
import InterviewerDashboard from '../Interviewer/InterviewerDashboard';
import LeadershipInterviewerDashboard from '../Interviewer/LeadershipInterviewerDashboard';
import CandidateDashboard from '../Candidate/CandidateDashboard';
import CandidateLeadershipDashboard from '../Candidate/CandidateLeadershipDashboard';
import RecruiterDashboard from '../Recruiter/RecruiterDashboard';
import { getUpdatedUser } from '../../redux/actions';

import Socket from '../../Socket';

class Dashboard extends Component<{}> {
  componentDidMount() {
    const { getUpdatedUser } = this.props;
    getUpdatedUser();
    Socket.on('feedbackAdded', () => {
      getUpdatedUser();
    });
  }
  render() {
    const roles = localStorage.getItem('s30Roles') || {};
    return (
      <div className="mainBody">
        <Sidebar>
          <Switch></Switch>
        </Sidebar>
        <Chatwidget />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {roles.includes('Admin') && <TimePicker />}
          {roles.includes('Interviewer') && <InterviewerDashboard />}
          {/* {roles.includes('Interviewer') && <LeadershipInterviewerDashboard />} */}
          {roles.includes('Candidate') && <CandidateDashboard />}

          {/* {roles.includes('Candidate') && <CandidateLeadershipDashboard />} */}
          {roles.includes('Recruiter') && <RecruiterDashboard />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  getUpdatedUser: () => dispatch(getUpdatedUser()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
