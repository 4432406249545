//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getJobOpening, handleJobOpeningPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import './JobOpenings.scss';
import history from '../../history';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';

import SearchBar from '../SearchBar/SearchBar';

// import JobOpening from './JobOpening';

// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getJobOpening: Function,
  jobOpenings: Array<Object>,
  handleJobOpeningPageClick: Function,
  activePage: number,
  perPage: number,
  error: string,
  classes: Object,
  submitting: boolean,
};
class JobOpenings extends Component<Props> {
  componentDidMount() {
    const { getJobOpening } = this.props;
    getJobOpening();
  }
  changeStatus = link => {
    const apiurl = `${ROOT_URL}/api/changeStatus/`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .post(apiurl, { link: link }, headers)
      .then(() => {
        this.componentDidMount();
      })
      .catch(() => {
        console.log('error in changing status');
      });
  };

  handleJobOpeningPageClick = data => {
    const { handleJobOpeningPageClick } = this.props;
    handleJobOpeningPageClick(data.selected);
  };

  render() {
    const {
      jobOpenings,
      filteredJobOpenings,
      activePage,
      perPage,
      submitting,
      error,
      classes,
    } = this.props;
    return (
      <div className="mainBody">
        <div className="allPlansBody">
          <div className="jobOpenings">
            {/* <h3 className="title">Curriculum</h3> */}
            <h3 className="title">Job Openings</h3>
            <h5 className="title">
              If a link is not working or job has expired then please flag issue. Any questions
              about jobs? Ping Sowmya N on slack
            </h5>
            <div className="searchContainer">
              <SearchBar placeHolder="Search by Type" type="jobsSearchByType" />
              <SearchBar placeHolder="Search by Company" type="jobsSearchByCompany" />
            </div>
            <button
              type="submit"
              onClick={e => {
                e.preventDefault();
                history.push('./recentJobs');
              }}
            >
              View Recently Added
            </button>
            <div className="table">
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={submitting}
                autoHideDuration={6000}
                ContentProps={{
                  classes: { root: classes.submit },
                }}
                message={<p>Getting Job Openings...</p>}
              />

              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={error}
                autoHideDuration={6000}
                ContentProps={{
                  classes: { root: classes.error },
                }}
                message={<p>{error}</p>}
              />
              <div className="thead">
                <div className="columnSmall">S. No</div>
                {/* <div className="columnMedium">Problem</div> */}
                <div className="columnMedium">Company</div>
                <div className="columnSmall">Type</div>
                <div className="columnLarge">Position</div>
                <div className="columnSmall">Month Posted</div>
                <div className="columnSmall">Year</div>
                <div className="columnSmall">Link</div>
                <div className="columnAction">Flag Issue</div>
              </div>
              {filteredJobOpenings.length > 0
                ? filteredJobOpenings.map((job, index) => {
                    const { Company, Link, Position, Type, Month, Year, Status } = job;
                    const bottomIdx = activePage * perPage;
                    const topIdx = bottomIdx + perPage;
                    if (index >= bottomIdx && index < topIdx) {
                      return (
                        <div className="tbody">
                          <div className="row">
                            <div className="columnSmall">{index + 1}</div>
                            <div className="columnMedium">{Company}</div>
                            <div className="columnSmall">{Type}</div>
                            <div className="columnLarge">{Position}</div>
                            <div className="columnSmall">{Month}</div>
                            <div className="columnSmall">{Year}</div>
                            <a
                              style={{ cursor: 'pointer' }}
                              onClick={() => window.open(`${Link}`, '_blank')}
                            >
                              <div className="columnSmall">Job Link</div>
                            </a>

                            <div className="columnAction">
                              <button
                                type="submit"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeStatus(Link);
                                }}
                                style={{
                                  backgroundColor: Status == 'Inactive' ? 'red' : '',
                                }}
                              >
                                Flag Issue
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                : jobOpenings.map((job, index) => {
                    const { Company, Link, Position, Type, Month, Year, Status } = job;
                    const bottomIdx = activePage * perPage;
                    const topIdx = bottomIdx + perPage;
                    if (index >= bottomIdx && index < topIdx) {
                      return (
                        <div className="tbody">
                          <div className="row">
                            {/* <div className="innerRow"> */}
                            <div className="columnSmall">{index + 1}</div>
                            <div className="columnMedium">{Company}</div>
                            <div className="columnSmall">{Type}</div>
                            <div className="columnLarge">{Position}</div>
                            <div className="columnSmall">{Month}</div>
                            <div className="columnSmall">{Year}</div>
                            <a
                              style={{ cursor: 'pointer' }}
                              onClick={() => window.open(`${Link}`, '_blank')}
                            >
                              <div className="columnSmall">Job Link</div>
                            </a>

                            <div className="columnAction">
                              <button
                                type="submit"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeStatus(Link);
                                }}
                                style={{
                                  backgroundColor: Status == 'Inactive' ? 'red' : '',
                                }}
                              >
                                Flag Issue
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
            </div>
            <br />
            <button
              type="submit"
              onClick={e => {
                e.preventDefault();
                history.push('./addjob');
              }}
            >
              Add New Job
            </button>

            <Custompaginate
              pageCount={jobOpenings.length / perPage}
              onPageChange={this.handleJobOpeningPageClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.jobOpeningState.activePage,
  perPage: state.jobOpeningState.perPage,
  jobOpenings: state.jobOpeningState.allJobOpenings,
  filteredJobOpenings: state.jobOpeningState.filteredJobOpenings,
  error: state.jobOpeningState.error,
  submitting: state.jobOpeningState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getJobOpening, handleJobOpeningPageClick })(JobOpenings),
);
