import { call, put } from 'redux-saga/effects';
import { updateRecommendedCandidate } from '../../api';
import {
  updateRecommendedCandidateSuccess,
  candidateError,
  submittingCandidateRequest,
} from '../actions/index';

import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export default function* updateRecommendedCandidateSaga(action) {
  try {
    const { candidates, candidateInfo } = action;
    const { id } = candidateInfo;
    yield put(submittingCandidateRequest());
    yield call(updateRecommendedCandidate, candidateInfo);
    const modifiedCandidates = candidates.filter(candidate => {
      if (candidate._id !== id) {
        return candidate;
      }
    });
    yield put(updateRecommendedCandidateSuccess(modifiedCandidates));
  } catch (error) {
    yield put(candidateError(error.toString()));
    yield delay(3000);
    yield put(candidateError(''));
  }
}

// export default function* updateRecommendCandidateSaga(action) {
//   try {
//     yield put(submittingRecommendedCandidateRequest());
//     const { candidateInfo } = action;
//     const recruitingCompaniesFromS30Arr = [];
//     const recruitingCompaniesNotFromS30Arr = [];
//     candidateInfo.recruitingCompaniesFromS30.forEach(company => {
//       recruitingCompaniesFromS30Arr.push(company.value);
//     });
//     candidateInfo.recruitingCompaniesFromS30 = recruitingCompaniesFromS30Arr;
//     candidateInfo.recruitingCompaniesNotFromS30.forEach(company => {
//       recruitingCompaniesNotFromS30Arr.push(company.value);
//     });
//     let item = candidateInfo;
//     const formData = new FormData();
//     let keys = Object.keys(item);
//     keys.forEach(ele => {
//       if (item[ele]) {
//         formData.append(ele, item[ele]);
//       }
//     });
//     yield call(updateRecommendedCandidate, formData);
//     yield put(updateRecommendedCandidateSuccess());
//     history.push('/');
//   } catch (error) {
//     yield put(recommendedCandidateError(error.toString()));
//     yield delay(3000);
//     yield put(recommendedCandidateError(''));
//   }
// }
