import { INTERVIEWPERFORMANCES } from '../constants';

const initialState = {
  allPerformances: [],
  filteredPerformances: [],
  activePage: 0,
  perPage: 10,
  error: false,
  submittingPostRequest: false,
  submittingGetRequest: false,
  submittingPutRequest: false,
  submittingDeleteRequest: false,
};

const performanceStateReducer = (performanceState = initialState, action) => {
  switch (action.type) {
    case INTERVIEWPERFORMANCES.ADDINTERVIEWPERFORMANCESUCCESS:
      return {
        ...performanceState,
        allPerformances: [...performanceState.allPerformances, { ...action.payload }],
        submittingPostRequest: false,
      };
    case INTERVIEWPERFORMANCES.GETINTERVIEWPERFORMANCESSUCCESS:
      return {
        ...performanceState,
        allPerformances: [...action.payload],
        filteredPerformances: [],
        submittingGetRequest: false,
      };

    // error
    case INTERVIEWPERFORMANCES.INTERVIEWPERFORMANCEERROR:
      return {
        ...performanceState,
        error: action.error,
        submittingPostRequest: false,
        submittingPutRequest: false,
        submittingGetRequest: false,
        submittingDeleteRequest: false,
      };
    //search
    case INTERVIEWPERFORMANCES.SEARCHINTERVIEWPERFORMANCE:
      return { ...performanceState, filteredPerformances: [...action.payload], submitting: false };
    //change active page
    case INTERVIEWPERFORMANCES.CHANGEACTIVEINTERVIEWPERFORMANCEPAGE:
      return { ...performanceState, activePage: action.payload };
    //submitting
    case INTERVIEWPERFORMANCES.SUBMITTINGADDINTERVIEWPERFORMANCE:
      return { ...performanceState, submittingPostRequest: true };
    case INTERVIEWPERFORMANCES.SUBMITTINGUPDATEINTERVIEWPERFORMANCE:
      return { ...performanceState, submittingPutRequest: true };
    case INTERVIEWPERFORMANCES.SUBMITTINGGETINTERVIEWPERFORMANCE:
      return { ...performanceState, submittingGetRequest: true };
    case INTERVIEWPERFORMANCES.SUBMITTINGDELETEINTERVIEWPERFORMANCE:
      return { ...performanceState, submittingDeleteRequest: true };

    default:
      return performanceState;
  }
};

export default performanceStateReducer;
