//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { addProblem } from '../../redux/actions';
import Texteditor from './RichTextEditor';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '60rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  submitting: boolean,
  addProblem: Function,
  history: Object,
  error: string,
  classes: Object,
};

type State = {
  name: string,
  problemStatement: string,
  difficultyLevel: string,
  techniques: string,
  companyTags: string,
  solution: string,
  sourceLink: string,
  gitLink: string,
};
class AddProblem extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      problemStatement: '',
      difficultyLevel: '',
      techniques: '',
      companyTags: '',
      solution: '',
      sourceLink: '',
      gitLink: '',
    };
  }

  handleInput = (e, type) => {
    e.preventDefault();
    this.setState({
      [type]: e.target.value,
    });
  };

  addProblem = e => {
    e.preventDefault();
    const { addProblem, history } = this.props;
    addProblem(this.state, history);
    this.setState({
      name: '',
      problemStatement: '',
      difficultyLevel: '',
      techniques: '',
      companyTags: '',
      solution: '',
      sourceLink: '',
      gitLink: '',
    });
  };

  setProblemStatement = receivedHtml => {
    this.setState({
      problemStatement: receivedHtml,
    });
  };

  setProblemSolution = receivedHtml => {
    this.setState({
      solution: receivedHtml,
    });
  };

  render() {
    const { classes, submitting, error } = this.props;
    const { name, difficultyLevel, techniques, companyTags, sourceLink, gitLink } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <form style={{ overflow: 'auto' }} className={classes.container} onSubmit={this.addProblem}>
          <h4>Add a Problem</h4>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Adding Problem...</p>}
          />
                      
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'name')}
            value={name}
            id="name"
            label="Name"
            placeholder="Name"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'difficultyLevel')}
            value={difficultyLevel}
            id="difficultyLevel"
            label="Difficulty Level"
            placeholder="Difficulty Level"
            margin="normal"
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />
          <TextField
            id="techniques"
            label="Techniques"
            onChange={e => this.handleInput(e, 'techniques')}
            value={techniques}
            placeholder="Techniques"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            id="companyTags"
            label="Company Tags"
            onChange={e => this.handleInput(e, 'companyTags')}
            value={companyTags}
            placeholder="Company Tags"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'gitLink')}
            value={gitLink || ''}
            id="gitLink"
            label="Git Link"
            placeholder="Git Link"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <TextField
            required
            variant="outlined"
            onChange={e => this.handleInput(e, 'sourceLink')}
            value={sourceLink || ''}
            id="sourceLink"
            label="Source Link"
            placeholder="Source Link"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
          />
          <b>Problem Statement</b>
          <Texteditor setProblemStatement={this.setProblemStatement} />
          <b>Problem Solution</b>
          <Texteditor setProblemSolution={this.setProblemSolution} />
          <button
            style={{
              marginTop: '20',
            }}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.problemState.error,
  submitting: state.problemState.submitting,
});

const mapDispatchToProps = dispatch => ({
  addProblem: (values, history) => dispatch(addProblem(values, history)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddProblem),
);
