import { APPLICANTS } from '../constants';

const initialState = {
  allApplicants: [],
  filteredApplicants: [],
  activePage: 0,
  perPage: 8,
  error: false,
  submitting: false,
};
const applicantReducer = (applicantState = initialState, action) => {
  switch (action.type) {
    case APPLICANTS.ADDAPPLICANTSUCCESS:
      return {
        ...applicantState,
        allApplicants: [...applicantState.allApplicants, { ...action.payload }],
        submitting: false,
      };
    case APPLICANTS.GETAPPLICANTSSUCCESS:
      return { ...applicantState, allApplicants: [...action.payload], submitting: false };
    case APPLICANTS.APPLICANTERROR:
      return { ...applicantState, error: action.error, submitting: false };
    case APPLICANTS.CHANGEACTIVEAPPLICANTPAGE:
      return { ...applicantState, activePage: action.payload };
    case APPLICANTS.SEARCHAPPLICANT:
      return { ...applicantState, filteredApplicants: [...action.payload], submitting: false };
    case APPLICANTS.SUBMITTING_APPLICANT:
      return { ...applicantState, submitting: true };
    default:
      return applicantState;
  }
};
export default applicantReducer;
