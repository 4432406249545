import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import './CareerResources.scss';

// export default function CareerResources()
class CareerResources extends Component<Props, State> {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.target,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    return (
      <div className="careerResources">
        <li onClick={this.handleClick}>Career Resources ></li>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <NavLink style={{ listStyle: 'none' }} to="/recruiter/companies">
            <MenuItem style={{ fontSize: '14px' }} onClick={this.handleClose}>
              Recruiters
            </MenuItem>
          </NavLink>
          <NavLink style={{ listStyle: 'none' }} to="/resumetips">
            <MenuItem style={{ fontSize: '14px' }} onClick={this.handleClose}>
              Resume Tips
            </MenuItem>
          </NavLink>
          <NavLink style={{ listStyle: 'none' }} to="/linkedintips">
            <MenuItem style={{ fontSize: '14px' }} onClick={this.handleClose}>
              LinkedIn Tips
            </MenuItem>
          </NavLink>
          <NavLink style={{ listStyle: 'none' }} to="/gettingInterviews">
            <MenuItem style={{ fontSize: '14px' }} onClick={this.handleClose}>
              Getting Interviews Calls Templates
            </MenuItem>
          </NavLink>
          <NavLink style={{ listStyle: 'none' }} to="/negotiationtips">
            <MenuItem style={{ fontSize: '14px' }} onClick={this.handleClose}>
              Negotiation Tips
            </MenuItem>
          </NavLink>
        </Menu>
      </div>
    );
  }
}

export default CareerResources;
