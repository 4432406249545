//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { updateLeadershipInterviewFeedbackById } from '../../redux/actions';
import { connect } from 'react-redux';
// import DisplayFormikState from '../DisplayFormikState';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8rem',
    fontSize: 18,
  },
  miniContainer: {
    display: 'flex',
    height: '40rem',
    width: '50rem',
    margin: '1rem',
    flexDirection: 'column',
  },
  resize: {
    height: '70px',
    fontSize: 16,
    lineHeight: 1.5,
  },
  textFields: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
};
type Props = {
  submitting: boolean,
  history: Object,
  classes: Object,
  values: Object,
  handleChange: Function,
  resetForm: Function,
  error: string,
  updateLeadershipInterviewFeedbackById: Function,
  feedbacks: Array<Object>,
};
class UpdateLeadershipFeedbackForm extends Component<Props> {
  render() {
    const {
      values,
      feedbacks,
      updateLeadershipInterviewFeedbackById,
      handleChange,
      classes,
      submitting,
      error,
      resetForm,
    } = this.props;
    const { id } = values;
    // let resultString = values.result + '';
    return (
      <div className="mainBody">
        <form
          style={styles.container}
          onSubmit={e => {
            e.preventDefault();
            updateLeadershipInterviewFeedbackById(feedbacks, id, values);
            resetForm();
          }}
        >
          <div style={styles.miniContainer}>
            <h1> Edit Feedback</h1>
            <br />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Updating Feedback...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            {/* <label>Confidence</label>
            <Rating
              stop={10}
              initialRating={values.confidence}
              onChange={handleChange('confidence')}
              value={values.confidence}
            /> */}
            <br />
            <label>Things that you did well</label>
            <TextField
              multiline
              rows={6}
              style={styles.textFields}
              value={values.thingsYouDidWell}
              placeholder={values.thingsYouDidWell}
              onChange={handleChange('thingsYouDidWell')}
              variant="outlined"
              id="thingsDidWell"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              label="Things that the student did well"
            />
            <br />
            <TextField
              multiline
              rows={6}
              style={styles.textFields}
              variant="outlined"
              onChange={handleChange('thingsToImprove')}
              value={values.thingsToImprove}
              placeholder={values.thingsToImprove}
              id="thingsNeedImprove"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              label="Things that the student need to improve"
            />
            <button type="submit">Submit</button>
            {/* {<DisplayFormikState {...values} />} */}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  feedbacks: state.leadershipFeedbackState.allFeedbacks,
  error: state.leadershipFeedbackState.error,
  submitting: state.leadershipFeedbackState.submittingPutRequest,
});
const mapDispatchToProps = dispatch => ({
  updateLeadershipInterviewFeedbackById: (feedbacks, id, values) =>
    dispatch(updateLeadershipInterviewFeedbackById(feedbacks, id, values)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpdateLeadershipFeedbackForm),
);
