import { call, put } from 'redux-saga/effects';
import history from '../../history';
import { getAllInterviewPerformances, addInterviewPerformance } from '../../api';
import {
  getAllInterviewPerformancesSuccess,
  interviewPerformanceError,
  addInterviewPerformanceSuccess,
  updateInterviewPerformanceSuccess,
  deleteInterviewPerformanceSuccess,
  submittingGetInterviewPerformanceRequest,
  submittingAddInterviewPerformanceRequest,
} from '../actions/index';
import Socket from '../../Socket';
const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAllInterviewPerformancesSaga(action) {
  try {
    yield put(submittingGetInterviewPerformanceRequest());
    const { role } = action;
    const interviewPerformances = yield call(getAllInterviewPerformances);
    if (role === 'admin') {
      yield put(getAllInterviewPerformancesSuccess(interviewPerformances.data.performancesAsAdmin));
    } else {
      yield put(
        getAllInterviewPerformancesSuccess(interviewPerformances.data.performancesAsInterviewer),
      );
    }
  } catch (error) {
    yield put(interviewPerformanceError(error.toString()));
    yield delay(3000);
    yield put(interviewPerformanceError(''));
  }
}

export function* addInterviewPerformanceSaga(action) {
  try {
    yield put(submittingAddInterviewPerformanceRequest());
    const { performance } = action;
    const interviewPerformance = yield call(addInterviewPerformance, performance);
    yield put(addInterviewPerformanceSuccess(interviewPerformance.data));
    history.push('/feedbacks?candidate');
  } catch (error) {
    yield put(interviewPerformanceError(error.response.data.message));
    yield delay(3000);
    yield put(interviewPerformanceError(''));
  }
}

// export function* updateInterviewPerformanceSaga(action) {
//   try {
//     yield put(submittingUpdateInterviewPerformanceRequest());
//     const { performanceId, allInterviewPerformances, newInterviewPerformance } = action;
//     let updatedInterviewPerformance = yield call(
//       updateInterviewPerformanceById,
//       performanceId,
//       newInterviewPerformance,
//     );
//     updatedInterviewPerformance = updatedInterviewPerformance.data.performance;
//     const modifiedInterviewPerformances = allInterviewPerformances.map(performance => {
//       if (performance._id === performanceId) {
//         performance = updatedInterviewPerformance;
//       }
//       return performance;
//     });
//     yield put(updateInterviewPerformanceSuccess(modifiedInterviewPerformances));
//     history.push('/performances');
//   } catch (error) {
//     yield put(interviewPerformanceError(error.toString()));
//     yield delay(3000);
//     yield put(interviewPerformanceError(''));
//   }
// }
// export function* deleteInterviewPerformanceSaga(action) {
//   try {
//     yield put(submittingDeleteInterviewPerformanceRequest());
//     const { performanceId, performances } = action;
//     yield call(deleteInterviewPerformanceById, performanceId);
//     const modifiedInterviewPerformances = performances.filter(
//       performance => performance.id !== performanceId,
//     );
//     yield put(deleteInterviewPerformanceSuccess(modifiedInterviewPerformances));
//     history.push('/performances');
//   } catch (error) {
//     yield put(interviewPerformanceError(error.toString()));
//     yield delay(3000);
//     yield put(interviewPerformanceError(''));
//   }
// }
