//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import { signin } from '../../redux/actions';
import history from '../../history';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';

import Socket from '../../Socket';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '2rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  error: string,
  signin: Function,
  history: Object,
  classes: Object,
  submitting: boolean,
};

type State = {
  name: string,
};
class Signin extends Component<Props, State> {
  componentDidMount() {
    const token = localStorage.getItem('token');
    let isAuthorized;
    !token ? (isAuthorized = false) : (isAuthorized = true);
    if (isAuthorized) history.push('/dashboard');
  }

  form = ({ handleSubmit, handleChange, touched, values, errors }) => (
    <form style={styles.container} method="POST" onSubmit={handleSubmit}>
      {touched.username && errors.username && <p style={{ color: 'red' }}>{errors.username}</p>}
      <TextField
        required
        variant="outlined"
        value={values.username}
        onChange={handleChange}
        id="username"
        placeholder="Username"
        margin="normal"
        inputProps={{
          style: { fontSize: 15 },
        }}
      />
      {touched.password && errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
      <TextField
        required
        variant="outlined"
        value={values.password}
        onChange={handleChange}
        id="password"
        type="password"
        placeholder="Password"
        margin="normal"
        inputProps={{
          style: { fontSize: 15 },
        }}
      />
      <p style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Link to={'/forgotpassword'} className="link">
          Forgot username or password?
        </Link>
      </p>
      <button
        style={{
          marginTop: '20',
        }}
        type="submit"
      >
        Submit
      </button>
    </form>
  );

  render() {
    const { submitting, error, classes } = this.props;
    return (
      <div className="mainBody" style={{ flexDirection: 'column', alignItems: 'center' }}>
        <h1>Sign In</h1>
        {/* <marquee style={styles.myHelperTextStyle}>
          If facing any issues with signin, please clear your browser history
        </marquee> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Submitting...</p>}
        />
                    
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error}</p>}
        />
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={(values, { resetForm }) => {
            const { signin, history } = this.props;
            signin(values, history);
            resetForm();
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required'),
          })}
          component={this.form}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  signin: (values, history) => dispatch(signin(values, history)),
});

const mapStateToProps = state => ({
  error: state.authState.error,
  submitting: state.authState.submittingSignInRequest,
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Signin));
