/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import freeze from 'redux-freeze';
import _ from 'lodash';
import rootReducer from './reducers';
import { watcherSaga } from './sagas/rootsaga';
import { loadState, saveState } from './localStorage';
// Persist
const persistedState = loadState();

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();
const middleware = _.compact([thunk, freeze, logger, sagaMiddleware]);

// eslint-disable-next-line no-underscore-dangle
const composePlugin = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composingMiddlewareAndDevTools = composePlugin(applyMiddleware(...middleware));
const store = createStore(rootReducer, persistedState, composingMiddlewareAndDevTools);
store.subscribe(() => {
  saveState({
    authState: store.getState().authState,
  });
});
sagaMiddleware.run(watcherSaga);
export default store;
