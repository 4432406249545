import { PROBLEMS } from '../constants';

const initialState = {
  allProblems: [],
  filteredProblems: [],
  activePage: 0,
  perPage: 8,
  error: false,
  submitting: false,
};
const problemReducer = (problemState = initialState, action) => {
  switch (action.type) {
    case PROBLEMS.ADDPROBLEMSUCCESS:
      return {
        ...problemState,
        allProblems: [...problemState.allProblems, { ...action.payload }],
        submitting: false,
      };
    case PROBLEMS.CHANGEACTIVEPROBLEMPAGE:
      return { ...problemState, activePage: action.payload };
    case PROBLEMS.DELETEPROBLEMSUCCESS:
      return { ...problemState, allProblems: [...action.payload], submitting: false };
    case PROBLEMS.GETPROBLEMSSUCCESS:
      return {
        ...problemState,
        allProblems: [...action.payload],
        filteredProblems: [],
        submitting: false,
      };
    case PROBLEMS.SEARCHPROBLEM:
      return { ...problemState, filteredProblems: [...action.payload], submitting: false };
    case PROBLEMS.PROBLEMERROR:
      return { ...problemState, error: action.error, submitting: false };
    case PROBLEMS.SUBMITTING_PROBLEM:
      return { ...problemState, submitting: true };
    case PROBLEMS.UPDATEPROBLEMSUCCESS:
      return { ...problemState, allProblems: [...action.payload], submitting: false };
    default:
      return problemState;
  }
};

export default problemReducer;
