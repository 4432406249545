//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSalaryCompanies, handleSalaryCompanyPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import history from '../../history';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getSalaryCompanies: Function,
  companies: Array<Object>,
  handleSalaryCompanyPageClick: Function,
  activePage: number,
  perPage: number,
  error: string,
  classes: Object,
  submitting: boolean,
};
class SalaryCompanyList extends Component<Props> {
  componentDidMount() {
    const { getSalaryCompanies } = this.props;
    getSalaryCompanies();
  }

  handleSalaryCompanyPageClick = data => {
    const { handleSalaryCompanyPageClick } = this.props;
    handleSalaryCompanyPageClick(data.selected);
  };

  render() {
    const { companies, activePage, perPage, submitting, error, classes } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="interviewKit">
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting Companies...</p>}
          />

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />

          <div className="table" style={{ margin: '20px' }}>
            <button
              type="submit"
              onClick={e => {
                e.preventDefault();
                history.push('./addSalary');
              }}
              style={{ width: '20%' }}
            >
              Add your offer
            </button>
            <h4 className="title">Salaries Offered</h4>
            {/* <div className="thead">
              <div className="columnLarge">Name</div>
            </div> */}
            <div className="tbody">
              {companies.map((company, index) => {
                const { name, _id } = company;
                const bottomIdx = activePage * perPage;
                const topIdx = bottomIdx + perPage;
                if (index >= bottomIdx && index < topIdx) {
                  return (
                    <div className="row">
                      <Link to={`/salary/${_id}`} key={_id} className="link">
                        <div className="columnLarge">{name}</div>
                      </Link>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>

          <br />
          <Custompaginate
            pageCount={companies.length / perPage}
            onPageChange={this.handleSalaryCompanyPageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePage: state.salaryState.activePage,
  perPage: state.salaryState.perPage,
  companies: state.salaryState.allSalaryCompanies,
  error: state.salaryState.error,
  submitting: state.salaryState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getSalaryCompanies, handleSalaryCompanyPageClick })(SalaryCompanyList),
);
