//@flow
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import ROOT_URL from '../../config/baseUrl';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem',
    width: '40rem',
    overflow: 'auto',
  },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};
type Props = {
  id: number,
  values: Object,
  amount: number,
  classes: Object,
  errors: Object,
  handleChange: Function,
  stripe: Object,
  name: string,
};
type State = { submitting: boolean, isComplete: boolean, isValidCard: boolean };

const StripeIndiaCheckoutForm = props => {
  const [submitting, setSubmitting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isValidCard, setIsValidCard] = useState(true);

  const { values, handleChange, errors, classes } = props;

  const stripe = useStripe();
  const elements = useElements();

  const submit = async ev => {
    const { id, values } = props;
    try {
      // let { token } = await this.props.stripe.createToken({ name });
      setSubmitting(true);
      const apiurl = `${ROOT_URL}/api/chargeIndiaAdmit/${id}`;
      // await axios.post(apiurl, { ...values, token });
      let re = await axios.post(apiurl, { ...values });
      const cardElement = elements.getElement(CardElement);
      const payload = await stripe.confirmCardPayment(re.data.client_secret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (payload.error) {
        setSubmitting(false);
        setIsComplete(false);
        alert(`Payment failed: ${payload.error.message}`);
      } else {
        setSubmitting(false);
        setIsComplete(true);
      }
    } catch (err) {
      alert(`Payment failed: ${payload.err.message}`);
      setSubmitting(false);
      setIsComplete(false);
    }
  };
  return (
    <>
      {isComplete && <h4>Purchase Complete, check inbox for a receipt email</h4>}
      {!isComplete && (
        <div style={{ display: 'flex', flexDirection: 'column', width: '40rem' }}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Submitting payment...</p>}
          />

          <h5>Personal Details</h5>
          <TextField
            required
            variant="outlined"
            value={values.name}
            onChange={handleChange}
            id="name"
            label="Name"
            placeholder="Your Name"
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.name}
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange}
            placeholder="janedoe@gmail.com"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.myHelperTextStyle,
              },
            }}
            helperText={errors.email}
          />
          <TextField
            required
            variant="outlined"
            value={values.address}
            onChange={handleChange}
            id="address"
            label="Address"
            margin="normal"
            inputProps={{
              style: { fontSize: 15 },
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              required
              variant="outlined"
              value={values.city}
              onChange={handleChange}
              id="city"
              label="City"
              margin="normal"
              inputProps={{
                style: { fontSize: 15 },
              }}
            />
            <TextField
              required
              variant="outlined"
              value={values.state}
              onChange={handleChange}
              id="state"
              label="State"
              margin="normal"
              inputProps={{
                style: { fontSize: 15 },
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              required
              variant="outlined"
              value={values.country}
              onChange={handleChange}
              id="country"
              label="Country"
              margin="normal"
              inputProps={{
                style: { fontSize: 15 },
              }}
            />
            <TextField
              required
              variant="outlined"
              value={values.postal_code}
              onChange={handleChange}
              id="postal_code"
              label="ZIP"
              margin="normal"
              inputProps={{
                style: { fontSize: 15 },
              }}
            />
          </div>
          <h5>Payment Details</h5>
          <CardElement options={{}} onChange={handleChange} />
          <button onClick={submit} style={{ marginTop: '20px' }}>
            Pay ${props.amount}
          </button>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(StripeIndiaCheckoutForm);
