//@flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllApplicants, handleApplicantPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Applicant from './Applicant';
import Sidebar from '../Sidebar/Sidebar';
import SearchBar from '../SearchBar/SearchBar';
import './Applicant.scss';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  activePage: number,
  perPage: number,
  applicants: Array<Object>,
  filteredApplicants: Array<Object>,
  handleApplicantPageClick: Function,
  getAllApplicants: Function,
  error: string,
  submitting: boolean,
  classes: Object,
};

class Applicants extends Component<Props> {
  constructor(props: {}) {
    super(props);
    this.state = { roles: localStorage.getItem('s30Roles') || '' };
  }
  componentDidMount() {
    const { getAllApplicants } = this.props;
    getAllApplicants();
  }

  handleApplicantPageClick = data => {
    const { handleApplicantPageClick } = this.props;
    handleApplicantPageClick(data.selected);
  };

  render() {
    const {
      activePage,
      perPage,
      applicants,
      submitting,
      error,
      classes,
      filteredApplicants,
    } = this.props;
    const { roles } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="applicant">
          <div className="searchContainer">
            <SearchBar placeHolder="Search by Name" type="applicantSearchByName" />
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={submitting}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>Getting Applicants...</p>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={error}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.error },
            }}
            message={<p>{error}</p>}
          />
          <div className="table">
            <h3 className="title">All Applicants</h3>

            <div className="thead">
              {/* <div className="columnSmall">S. No</div> */}
              <div className="columnAction">S. No</div>
              {roles.includes('CohortManager') && (
                <div className="columnSmall">Application Date</div>
              )}
              <div className="columnSmall">Name</div>
              <div className="columnMedium">Email</div>
              <div className="columnMedium">School</div>
              {roles.includes('Candidate') && <div className="columnMedium">LinkedIn</div>}
              {roles.includes('CohortManager') && <div className="columnMedium">Referred By</div>}
              {roles.includes('CohortManager') && <div className="columnSmall">Pass Bonus</div>}
              {roles.includes('CohortManager') && <div className="columnAction">Add User</div>}
              {roles.includes('CohortManager') && <div className="columnAction">Delete</div>}
            </div>
            {filteredApplicants.length > 0
              ? filteredApplicants.map((applicant, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Applicant
                        key={applicant._id}
                        applicantId={applicant._id}
                        linkedInUrl={applicant.linkedIn}
                        index={index + 1}
                        passReferralBonusToIncomingStudent={
                          applicant.passReferralBonusToIncomingStudent
                        }
                        name={applicant.firstName + ' ' + applicant.lastName}
                        email={applicant.email}
                        school={applicant.school}
                        createdAt={applicant.createdAt}
                        refreeId={applicant.referredByUserId}
                        roles={roles}
                      />
                    );
                  }
                  return null;
                })
              : applicants.map((applicant, index) => {
                  const bottomIdx = activePage * perPage;
                  const topIdx = bottomIdx + perPage;
                  if (index >= bottomIdx && index < topIdx) {
                    return (
                      <Applicant
                        key={applicant._id}
                        linkedInUrl={applicant.linkedIn}
                        applicantId={applicant._id}
                        index={index + 1}
                        passReferralBonusToIncomingStudent={
                          applicant.passReferralBonusToIncomingStudent
                        }
                        name={applicant.firstName + ' ' + applicant.lastName}
                        email={applicant.email}
                        school={applicant.school}
                        createdAt={applicant.createdAt}
                        refreeId={applicant.referredByUserId}
                        roles={roles}
                      />
                    );
                  }
                  return null;
                })}
          </div>
          <Custompaginate
            pageCount={applicants.length / perPage}
            onPageChange={this.handleApplicantPageClick}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activePage: state.applicantState.activePage,
  perPage: state.applicantState.perPage,
  applicants: state.applicantState.allApplicants,
  error: state.applicantState.error,
  submitting: state.applicantState.submitting,
  filteredApplicants: state.applicantState.filteredApplicants,
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, { getAllApplicants, handleApplicantPageClick })(Applicants)),
);
