//@flow
import React from 'react';
import { Formik } from 'formik';
import Sidebar from '../Sidebar/Sidebar';

import AddVideoUrlForm from './AddVideoUrlForm';

const initialAddVideoUrlValues = {
  cohortId: {},
  problemId: {},
  videoUrl: '',
};
type Props = {};
const AddVideoUrl = (props: Props) => {
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddVideoUrlValues}
        render={props => <AddVideoUrlForm {...props} />}
      />
    </div>
  );
};

export default AddVideoUrl;
