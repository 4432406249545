import { call, put } from 'redux-saga/effects';
import { getAlumniListById, getAlumniListCompanies, addAlumni } from '../../api';
import {
  getAlumniListCompaniesSuccess,
  getAlumniListSuccess,
  AlumniListError,
  submittingAlumniListRequest,
  addAlumniSuccess,
} from '../actions/index';
import history from '../../history';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* getAlumniListCompaniesSaga() {
  try {
    yield put(submittingAlumniListRequest());
    const companies = yield call(getAlumniListCompanies);
    yield put(getAlumniListCompaniesSuccess(companies.data));
  } catch (error) {
    yield put(AlumniListError(error.toString()));
    yield delay(3000);
    yield put(AlumniListError(''));
  }
}

export function* getAlumniListByIdSaga(action) {
  try {
    yield put(submittingAlumniListRequest());
    const { id } = action;
    const kit = yield call(getAlumniListById, id);
    yield put(getAlumniListSuccess(kit.data));
  } catch (error) {
    yield put(AlumniListError(error.toString()));
    yield delay(3000);
    yield put(AlumniListError(''));
  }
}
export function* addAlumniSaga(action) {
  try {
    yield put(submittingAlumniListRequest());
    const { alumni } = action;
    const newAlumni = yield call(addAlumni, alumni);
    yield put(addAlumniSuccess(newAlumni));
    history.push('/alumnilist');
  } catch (error) {
    yield put(AlumniListError(error.toString()));
    yield delay(3000);
    yield put(AlumniListError(''));
  }
}