//@flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';

const initialAddCustomerValues = {
  name: '',
  email: '',
  address: '',
  city: '',
  state: '',
  country: '',
  postal_code: '',
};
// const stripe = loadStripe('pk_test_e2J11SudbLQDVkTkUhiIpkai009uMS0FpO');
// const stripe = loadStripe('pk_live_sGTRJHYVQy3yeC33ynWAjMxL00ysY8zb9U');
// const stripe = loadStripe(
//   'pk_test_51Lc0gXSFXsYwS3QBqDWFo0BXt0d0i3j9NEhS2Vngw3eE1ESOWkjSRvHglI4k7U9vRUhc9ohYaubdu5Lf4WSXLteU00wgaL0Sej',
// );
const stripe = loadStripe(
  'pk_live_51MaEYCCIUgJDfX3U2dEmv6Vdw4c5iGFBlt0gIBLwpPSdbpVE8tboV9gVaU2yVCWb4CmnxZ9WSpYvtaD8hxmDLXJo00popNpDSv',
);

const CustomerValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});
type Props = { match: Object };
type State = { id: string, amount: string };
class StripeCheckout extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { id: '', amount: '' };
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    const apiurl = `${ROOT_URL}/api/pricing/${id}`;
    const pricing = await axios.get(apiurl);
    const amount = pricing.data.amountPayableNow;
    this.setState({
      id,
      amount,
    });
  }
  render() {
    const { id, amount } = this.state;
    return (
      <div className="mainBody" style={{ marginTop: '10px', justifyContent: 'center' }}>
        <Elements stripe={stripe}>
          <Formik
            initialValues={initialAddCustomerValues}
            render={props => <StripeCheckoutForm id={id} amount={amount} {...props} />}
            validationSchema={CustomerValidationSchema}
          />
        </Elements>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pricings: state.pricingState.allPricings,
});

export default connect(mapStateToProps, {})(StripeCheckout);
