import { call, put } from 'redux-saga/effects';
import { signin, signout, signup, forgotPassword, resetPassword, getUpdatedUser } from '../../api';
import Socket from '../../Socket';
import {
  signinSuccess,
  signupSuccess,
  authError,
  resetPasswordSuccess,
  signoutSuccess,
  forgotPasswordSuccess,
  getUpdatedUserSuccess,
  submittingSignInRequest,
  submittingSignOutRequest,
  submittingSignUpRequest,
  submittingForgotPasswordRequest,
  submittingResetPasswordRequest,
} from '../actions';
import history from '../../history';
import { SOCKET_URL } from '../../config/baseUrl';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* signinSaga(action) {
  try {
    yield put(submittingSignInRequest());
    const { history, user } = action;
    const signedInUser = yield call(signin, user);
    localStorage.setItem('token', signedInUser.data.token);
    localStorage.setItem('s30Roles', signedInUser.data.roles);
    yield put(signinSuccess(signedInUser.data.user));

    const token = localStorage.getItem('token');
    Socket.emit('signin', token);
    history.push('/dashboard');
  } catch (error) {
    yield put(authError(error.response.data.message));
    yield delay(3000);
    yield put(authError(''));
  }
}

export function* signoutSaga(action) {
  try {
    yield put(submittingSignOutRequest());
    yield call(signout);
    localStorage.removeItem('token');
    localStorage.removeItem('s30Roles');
    yield put(signoutSuccess());
    history.push('/signin');
  } catch (error) {
    yield put(authError(error.toString()));
    yield delay(3000);
    yield put(authError(''));
  }
}

export function* signupSaga(action) {
  try {
    yield put(submittingSignUpRequest());
    const { user, refereeObj } = action;
    user.refereeId = refereeObj.refereeId;
    user.passReferralBonusToIncomingStudent = refereeObj.passReferralBonusToIncomingStudent;
    user.linkedInUrl = refereeObj.linkedInUrl;
    const signedUpUser = yield call(signup, user);
    yield put(signupSuccess(signedUpUser));
    history.push('/dashboard');
  } catch (error) {
    yield put(authError(error.response.data.message));
    yield delay(3000);
    yield put(authError(''));
  }
}

export function* forgotPasswordSaga(action) {
  try {
    yield put(submittingForgotPasswordRequest());
    const { username } = action;
    yield call(forgotPassword, username);
    yield put(forgotPasswordSuccess());
    history.push('/forgotPasswordSuccess');
  } catch (error) {
    yield put(authError(error.response.data.message));
    yield delay(3000);
    yield put(authError(''));
  }
}

export function* resetPasswordSaga(action) {
  try {
    yield put(submittingResetPasswordRequest());
    const { passwordObj } = action;
    yield call(resetPassword, passwordObj);
    yield put(resetPasswordSuccess());
    history.push('/resetPasswordSuccess');
  } catch (error) {
    yield put(authError(error.response.data.message));
    yield delay(3000);
    yield put(authError(''));
  }
}

export function* getUpdatedUserSaga(action) {
  try {
    const updatedUser = yield call(getUpdatedUser);
    yield put(getUpdatedUserSuccess(updatedUser.data.user, updatedUser.data.prMap));
    history.push('/dashboard');
  } catch (error) {
    yield put(authError(error.response.data.message));
    yield delay(3000);
    yield put(authError(''));
  }
}
