//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import './performance.scss';

type Props = {
  id: string,
  cohortId: Object,
  interviewerId: Object,
  recommendedCandidateId: string,
  recommendCandidate: Function,
  isInterviewer: boolean,
  isCohortManager: boolean,
  name: string,
  email: string,
  index: number,
  candidateName: string,
  interviewerName: string,
  createdAt: string,
  rating: number,
  candidates: Array<Object>,
};

const Performance = (props: Props) => {
  const { id, index, candidateName, interviewerName, createdAt, rating } = props;
  return (
    <div className="tbody">
      <Link to={`/performance/${id}`} key={id} className="link">
        <div className="row">
          <div className="columnSmall">{index + 1}</div>
          <div className="columnMedium">{candidateName}</div>
          <div className="columnMedium">{interviewerName}</div>
          <div className="columnMedium">
            <Moment format="YYYY/MM/DD">{createdAt}</Moment>
          </div>
          <div className="columnSmall">{rating}</div>
          <div className="columnMedium">{rating >= 8 ? <b>Pass</b> : <b>Need To Improve</b>}</div>
        </div>
      </Link>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Performance);
