//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';
type Props = {
  link: string,
  name: string,
};
const Side = (props: Props) => {
  const { link, name } = props;
  return (
    <>
      <Link to={link}>
        <li>{name}</li>
      </Link>
    </>
  );
};
export default Side;
