//@flow
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../Sidebar/Sidebar';

import AddSalaryForm from './AddSalaryForm';

import moment from 'moment';

const AddSalaryValidationSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  stock: Yup.string()
    .min(4, 'Too Short!')
    .max(7, 'Too Long!')
    .required('Required'),
  baseSalary: Yup.string()
    .min(4, 'Too Short!')
    .max(7, 'Too Long!')
    .required('Required'),
  relocationExpense: Yup.string()
    .min(4, 'Too Short!')
    .max(7, 'Too Long!'),
  signingBonus: Yup.string()
    .min(4, 'Too Short!')
    .max(7, 'Too Long!'),
});

const initialAddSalaryValues = {
  companyId: '',
  levelId: '',
  baseSalary: '',
  stock: '',
  dateOfferReceived: moment().format('YYYY-MM-DD'),
  relocationExpense: '',
  signingBonus: '',
};
type Props = {};
const AddSalary = (props: Props) => {
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddSalaryValues}
        render={props => <AddSalaryForm {...props} />}
        validationSchema={AddSalaryValidationSchema}
      />
    </div>
  );
};

export default AddSalary;
