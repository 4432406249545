//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../Sidebar/Sidebar';
import { render } from 'react-dom';

class PayUStudentPaymentFail extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div style={{ display: 'flex', justifyContent: 'center', margin: '4rem' }}>
          <h4>
            Payment Failed. Please ping Jaspinder on slack or email jaspinder@thes30.com if issue
            persists
          </h4>
        </div>
        <p></p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authState.user,
});

export default connect(mapStateToProps)(PayUStudentPaymentFail);
