//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../Sidebar/Sidebar';
import { render } from 'react-dom';

class PayUStudentPaymentSuccess extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;
    return (
      <div className="mainBody">
        <Sidebar />
        <div style={{ display: 'flex', justifyContent: 'center', margin: '4rem' }}>
          <h4>Payment Successful</h4>
        </div>
        <p></p>
      </div>
    );
  }
}

export default PayUStudentPaymentSuccess;
