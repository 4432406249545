//@flow
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Glyphicon } from 'react-bootstrap';
import './Problem.css';

import { connect } from 'react-redux';

type Props = {
  setProblemStatement: Function,
  setProblemSolution: Function,
};

type State = {
  editorState: Object,
};
class Texteditor extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  onChange = editorState => {
    this.setState({
      editorState,
    });
  };

  handleReturn = async e => {
    const { setProblemStatement, setProblemSolution } = this.props;
    let { editorState } = this.state;
    e.preventDefault();
    const htmlContent = stateToHTML(editorState.getCurrentContent());
    if (setProblemStatement) await setProblemStatement(htmlContent);
    if (setProblemSolution) await setProblemSolution(htmlContent);
    // Reset state
    editorState = EditorState.push(editorState, ContentState.createFromText(''));
    await this.setState({
      editorState,
    });
  };

  handleInlineStyleEditorAction = type => {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleInlineStyle(editorState, type));
  };

  onToggleCode = () => {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleCode(editorState));
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="EditorContainer">
        <div className="EditorContainer__menubar">
          <Glyphicon
            glyph="bold"
            onClick={() => {
              this.handleInlineStyleEditorAction('BOLD');
            }}
          />
          <Glyphicon
            glyph="italic"
            onClick={() => {
              this.handleInlineStyleEditorAction('ITALIC');
            }}
          />
          <Glyphicon
            glyph="magnet"
            onClick={() => {
              this.handleInlineStyleEditorAction('CODE');
            }}
          />
          <p style={{ cursor: 'pointer' }} onClick={this.onToggleCode}>
            code
          </p>
        </div>
        <Editor
          onChange={editorState => {
            this.onChange(editorState);
          }}
          editorState={editorState}
          placeholder="Type a message..."
        />

        <button onClick={this.handleReturn} type="submit">
          Enter
        </button>
      </div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {},
)(Texteditor);
