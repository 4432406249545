//@flow
import React, { Component } from 'react';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';
import Highlight from 'react-highlight.js';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';

const styles = {
  root: {
    backgroundColor: 'red',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem',
    width: '80rem',
  },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
  displayTextStyle: {
    fontSize: 50,
  },
};
type Props = { classes: Object, match: Object };

type State = {
  _id: string,
  name: string,
  techniques: string,
  dsproblemIndex: string,
  solution: string,
  sourceLink: string,
  gitLink: string,
};
class ViewDSProblem extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      name: '',
      techniques: '',
      dsproblemIndex: '',
      solution: '',
      sourceLink: '',
      gitLink: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { id } = this.props.match.params;
    const apiurl = `${ROOT_URL}/api/dsproblem/${id}`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    axios
      .get(apiurl, headers)
      .then(res => {
        const { _id, name, techniques, dsproblemIndex, solution, sourceLink, gitLink } = res.data;
        this.setState({
          _id,
          name,
          techniques,
          dsproblemIndex,
          solution,
          sourceLink,
          gitLink,
        });
      })
      .catch(() => {
        console.log('error in getting dsproblems');
      });
  }

  render() {
    const { classes } = this.props;
    const { name, dsproblemIndex, techniques, sourceLink, gitLink, solution } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <form style={{ overflow: 'auto' }} className={classes.container}>
          <h1>
            DSProblem #{dsproblemIndex} : {name}
          </h1>

          <TextField
            id="techniques"
            label="Techniques"
            value={techniques || ''}
            placeholder="Techniques"
            margin="normal"
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.resize,
              },
              readOnly: true,
            }}
          />

          <p>
            <b>Source Link : </b>
            <a style={{ cursor: 'pointer' }} onClick={() => window.open(`${sourceLink}`, '_blank')}>
              {sourceLink}
            </a>
          </p>
          <p>
            <b>Git Link : </b>
            <a style={{ cursor: 'pointer' }} onClick={() => window.open(`${gitLink}`, '_blank')}>
              {gitLink}
            </a>
          </p>
          <b>DSProblem Solution</b>
          <div>
            <Highlight language="java">{ReactHtmlParser(solution)}</Highlight>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(ViewDSProblem);
